/* eslint-disable */
import React from "react";

// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useTranslation } from "react-i18next";
import "../less/quill-editor.css";
import ContentComponent from "./ContentComponent";

function SuggestedQuizzes(props) {
  console.log("suggestedquizlist", props);
  const { suggestedQuizList } = props;
  const { t } = useTranslation();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };

  return (
    <React.Fragment>
      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance1">
            <div class="text-left">
              <h3 style={{ color: "#294177", fontWeight: "600" }}>
                {t("suggested_quizes")}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                {suggestedQuizList
                  ? suggestedQuizList.length > 0
                    ? suggestedQuizList.map((content) => (
                        <ContentComponent content={content} {...props} />
                      ))
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SuggestedQuizzes;
