/* eslint-disable */ 
import React, { useState, useImperativeHandle,useEffect,useRef } from 'react'

import { useTranslation } from 'react-i18next';

import ReactQuill from "react-quill";
import JoditEditor from "jodit-react";
import { editorConfig } from '../Utils/jodit-editor';

import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import '../less/quill-editor.css';
import { modules, formats } from '../Utils/quill-editor';


const EditContentDeckName = React.forwardRef((props, ref) => {
    const editor = useRef(null);
    const { currentLanguage, language, questionNo } = props
    
    const { t } = useTranslation()
    const contentDetailName = language.content_name;
    const contentDetailDesc = language.content_desc;

    useImperativeHandle(ref, () => ({
        getContentDesc (languageid)  {
            console.log('editor.current.value',editor.current.value)
            console.log('fgh',document.getElementById('contentDesc_' + languageid).value)
            return document.getElementById('contentDesc_' + languageid).value
        }
    }))

    const createMarkup = (questionNo) => {
        //console.log('text',language.answerDetails[optionNo-1].text)
        return {
          __html: `<input type='text' placeholder='` + t("edit_content_title") + `' 
                   id= '`+ `contentName_`+ questionNo + `'` +
                  
                   ` value='`+ contentDetailName + `'`  +
                
                  `/>`
        }
      }

      /*
      <input type="text" placeholder="Edit content title" id="contentName_1" value="A text Q 1 " style="
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 30px;
">
      */

    return (
        <React.Fragment>
            <div class="tab-content"
                style={currentLanguage.id == language.id ? null : { display: "none" }}
            >
                <div class="tab-pane container active" id="EnglishTab">
                    <div class="create-topic-box">
                        <div class="header">{t('edit_content_title_description')}</div>
                        <div class="inner-box">
                            
                                <div class="inner-box-input" dangerouslySetInnerHTML={ createMarkup(questionNo)} /> 
                                {/*
                                <div class="mb-4">
                                    <input type="text" class="form-control" placeholder={t("edit_content_title")}
                                        id={"contentName_" + questionNo}
                                        defaultValue={contentDetailName}
                                    />
                                </div>
                                 */}
                            
                            <div>
                                
                                <ReactQuill class="form-control" id={"contentDesc_" + questionNo}
                                    placeholder={t("edit_content_description")}
                                    modules={modules}
                                    formats={formats}
                                    value={contentDetailDesc} />
                                
                                {/*
                                <JoditEditor
                                    id={"contentDesc_" + questionNo}
                                    ref={editor}
                                    config={editorConfig}
                                    value={contentDetailDesc}
                                    tabIndex={1}
                                    
                                    
                                />
                                */}
                                {/* onChange={e => handleContentDescChange(e)} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default EditContentDeckName
