/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentComponent from "./ContentComponent";
import GenreDetails from "./GenreDetails";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import { useCookies } from "react-cookie";
import "../less/test.css";

function CategoryContents(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  console.log(props);
  const genreId = props.location.state.genreId;
  const categoryId = props.match.params.cat_id;
  const parent_cat_Id = props.match.params.parent_cat_id
    ? props.match.params.parent_cat_id
    : 0;

  const [quizDetails, setQuizDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);
  const PAGE_LIMIT = 10;

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());

  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  useEffect(() => {
    fetchContents();
  }, []);

  const fetchContents = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/quiz_deck/list",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        page: currentPage,
        category_ids: categoryId,
        parent_cat_id: parent_cat_Id,
        genre_ids: genreId,
      }),
    })
      .then((response) => {
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setQuizDetails(response.data.data);
      })
      .catch((error) => {});
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchContents(data.currentPage);
    setCurrent(data.currentPage);
  };

  const goToDashboard = () => {
    props.history.push("/dashboard");
  };

  const goToGenreDetails = () => {
    props.history.push("/genredetails/" + genreId);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("contents")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li onClick={goToGenreDetails}>
                    <a href="javascript:void(0)" class="active">
                      {t("genre_details")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("contents")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GenreDetails
        cat_id={categoryId}
        parent_cat_id={parent_cat_Id}
        onCatChange={(_cat_id) => () => {}}
        genreId={genreId}
        showSubCategory={true}
        {...props}
      />

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          {quizDetails
            ? quizDetails.length > 0
              ? quizDetails.map((content) => (
                  <ContentComponent content={content} {...props} />
                ))
              : null
            : null}
        </div>
      </div>
      <div class="text-center pt-4">
        {total > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={total}
              totalPages={totalPages}
              currentPage={current}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null}
      </div>

      {quizDetails.length === 0 && total === 0 ? (
        <div className="container">
          <strong>{t("no_contents_available")}</strong>
        </div>
      ) : null}

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default CategoryContents;
