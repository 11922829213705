/* eslint-disable */ 
import React from 'react'


import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import { useTranslation } from 'react-i18next';

function  LinkedInPopupHandler(props) {

    console.log(props)
    const { t } = useTranslation()
    
    return (
       <React.Fragment>
           <LinkedInPopUp></LinkedInPopUp>
       </React.Fragment>
    )
}

export default LinkedInPopupHandler