/* eslint-disable */ 
import React, { useState, useEffect, Suspense  } from 'react';
import { BrowserRouter, Switch, Route, NavLink,Redirect } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import Login from '../Components/Login';
import Dashboard from '../Components/Dashboard';
import MyCredits from '../Components/MyCredits';

// import { BrowserRouter, Switch, Route, NavLink, Link, useHistory, Redirect } from 'react-router-dom';
import PrivateRoute from '../Utils/PrivateRoute'
import PublicRoute from '../Utils/PublicRoute'
import PurchaseCredits from '../Components/PurchaseCredits';
import ManageSubscriptions from './ManageSubscriptions';
import GenreListingPage from '../Components/GenreListingPage';
import GenreDetailsPage from '../Components/GenreDetailsPage';
import SelectAttributes from '../Components/SelectAttributes';
import CreateContentPage from '../Components/CreateContentPage';
import RepeatContentPage from '../Components/RepeatContentPage';
import RepeatPlayContentPage from '../Components/RepeatPlayContentPage';
import RepeatContentResults from '../Components/RepeatContentResults'
import SelectCategories from '../Components/SelectCategories';
import CreateContentDeck from '../Components/CreateContentDeck';
import AddQuestions from '../Components/AddQuestions';
import QuestionList from '../Components/QuestionList';
import PrivacyPolicyPage from '../Components/PrivacyPolicyPage';
import TermsAndConditionsPage from '../Components/TermsAndConditionsPage';
import CreatorTermsAndConditionsPage from '../Components/CreatorTermsAndConditionsPage';

import ContentDetailPage from '../Components/ContentDetailPage';
import PlayContentPage from '../Components/PlayContentPage';

import SingleCheckoutPage from '../Components/SingleCheckoutPage'

import ContentResults from '../Components/ContentResults';
import SearchPage from '../Components/SearchPage';
import CreatorProfile from '../Components/CreatorProfile';
import MyContents from '../Components/MyContents';
import ContentsEdit from '../Components/ContentsEdit';
import EditQuestion from '../Components/EditQuestion';
import MyFolders from '../Components/MyFolders';
import MyFolderDetails from '../Components/MyFolderDetails';
import AddToFolders from '../Components/AddToFolders';
import MyGroups from '../Components/MyGroups';
import CreateGroup from '../Components/CreateGroup';
import MyGroupDetails from '../Components/MyGroupDetails';
import CategoryContents from '../Components/CategoryContents';


import Notifications from '../Components/Notifications';
import Emails from '../Components/Emails';

import MyContentsButtons from '../Components/MyContentsButtons';
import MyContentsPrivate from '../Components/MyContentsPrivate';
import MyContentsGroup from '../Components/MyContentsGroup';

import MyContentsUnpublished from '../Components/MyContentsUnpublished';
import ContentsEditUnpublished from '../Components/ContentsEditUnpublished';
import InviteUserToTakeContent from '../Components/InviteUserToTakeContent';
import MyPlayedContents from '../Components/MyPlayedContents';
import SellerKYC from '../Components/SellerKYC';
import BecomeACreator from '../Components/BecomeACreator';
import UpdateProfile from '../Components/UpdateProfile';
import firebase from 'firebase'

import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import SalesReport from '../Components/SalesReport';
import BasicHeader from '../Components/BasicHeader';
import BasicFooter from '../Components/BasicFooter';
import ShortFooter from '../Components/ShortFooter'
import ContactUs from './ContactUs';
import ContentsDetailsEdit from './ContentsDetailsEdit';
import AboutUs from './AboutUs';
import UserTypesAndPricing from './UserTypesAndPricing';
import Campaign from './Campaign';
import Pricing from './Pricing';
import MyHomePage from './MyHomePage';
import Help from './Help';
import HowItWorks from './HowItWorks';
import Suggestions from './Suggestions';
import Advertisement from './Advertisement';
import ReportAbuse from './ReportAbuse';
import Vacancies from './Vacancies';
import News from './News';
import Calendar from './Calendar';
import FacebookDeletionStatus from './FacebookDeletionStatus';
import AboutMemorizeitall from './AboutMemorizeitall';
import GroupAdmin from './GroupAdmin';
import GroupDetails from './GroupDetails'
import GroupNews from './GroupNews'
import AQustionCompatibilityTiny from './AQustionCompatibilityTiny'


function HomePage(props) {
    console.log(props)   

    return (
        <React.Fragment>
               {/* <BasicHeader 
                    path="/dashboard"
                    
                /> */}

                <BrowserRouter forceRefresh= {true}>
                    <Switch>   
                    <PrivateRoute exact path="/" component={BasicHeader}/> 
                    <PrivateRoute path="/dashboard" component={BasicHeader}/>
                <PrivateRoute path="/mycredits" component={BasicHeader}/>
                <PrivateRoute path="/purchasecredits" component={BasicHeader}/>
                <PrivateRoute path="/managesubscriptions/:type" component={BasicHeader}/>
                <PrivateRoute path="/genrelist" component={BasicHeader}/>
                <PrivateRoute path="/genredetails/:genreId/:cat_id?/:parent_cat_id?" component={BasicHeader}/>
                <PrivateRoute path="/categorycontents/:cat_id/:parent_cat_id?" component={BasicHeader} />
                <PrivateRoute path="/selectattributes" component={BasicHeader} />
                <PrivateRoute path="/selectcategories" component={BasicHeader} />
                <PrivateRoute path="/createcontentdeck" component={BasicHeader} />
                <PrivateRoute path="/createcontentpage" component={BasicHeader} />
                <PrivateRoute path="/repeatcontentpage" component={BasicHeader} />
                <PrivateRoute path="/repeatcontentresults/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/mycontents" component={BasicHeader} />
                <PrivateRoute path="/mycontentsunpublished" component={BasicHeader} />
                <PrivateRoute path="/mycontentsbuttons" component={BasicHeader} />
                <PrivateRoute path="/addquestions/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/questionlist/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/termsandconditions" component={BasicHeader} />
                <PrivateRoute path="/creatortermsandconditions" component={BasicHeader} />
                <PrivateRoute path="/privacypolicy" component={BasicHeader} />
                <PrivateRoute path="/contentdetails/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/playcontent/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/repeatplaycontent/:repeatQuizdeckId" component={BasicHeader} />
                <PrivateRoute path="/paymentgateway" component={BasicHeader} />
                <PrivateRoute path="/contentresults/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/search" component={BasicHeader} />
                <PrivateRoute path="/creatorprofile/:profileId" component={BasicHeader} />
                <PrivateRoute path="/contentsedit/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/contentseditunpublished/:quizdeckId" component={BasicHeader} />
                <PrivateRoute path="/editquestion/:questionId" component={BasicHeader} />
                <PrivateRoute path="/myfolders" component={BasicHeader} />
                <PrivateRoute path="/myfolderdetails" component={BasicHeader} />
                <PrivateRoute path="/addtofolders" component={BasicHeader} />
                <PrivateRoute path="/mygroups" component={BasicHeader} />
                <PrivateRoute path="/creategroup" component={BasicHeader} />
                <PrivateRoute path="/groupdetails/:group_id" component={BasicHeader} />
                <PrivateRoute path="/groupnews/:group_id" component={BasicHeader} />
                <PrivateRoute path="/groupadmin/:group_id" component={BasicHeader} />
                <PrivateRoute path="/inviteusertotakecontent" component={BasicHeader} />
                <PrivateRoute path="/notifications" component={BasicHeader} />
                <PrivateRoute path="/invitations" component={BasicHeader} />
                <PrivateRoute path="/myplayedcontents" component={BasicHeader} />
                <PrivateRoute path="/sellerKYC" component={BasicHeader} />
                <PrivateRoute path="/becomeacreator" component={BasicHeader} />
                <PrivateRoute path="/updateProfile" component={BasicHeader} />
                <PrivateRoute path="/salesreport" component={BasicHeader} />
                <PrivateRoute path="/contactus" component={BasicHeader} />
                <PrivateRoute path="/aboutus" component={BasicHeader} />
                <PrivateRoute path="/aboutmemorizeitall" component={BasicHeader} />
                <PrivateRoute path="/contentsdetailsedit/:quizdeckid" component={BasicHeader} />
                <PrivateRoute path="/usertypesandpricing" component={BasicHeader} /> 
                <PrivateRoute path="/pricing" component={BasicHeader} />
                <PrivateRoute path="/campaigns" component={BasicHeader} />
                <PrivateRoute path="/myhome" component={BasicHeader} />
                <PrivateRoute path="/help" component={BasicHeader} />
                <PrivateRoute path="/howitworks" component={BasicHeader} />
                <PrivateRoute path="/suggestions" component={BasicHeader} />
                <PrivateRoute path="/advertise" component={BasicHeader} />
                <PrivateRoute path="/reportabuse" component={BasicHeader} />
                <PrivateRoute path="/vaccancies" component={BasicHeader} />
                <PrivateRoute path="/news" component={BasicHeader} />
                <PrivateRoute path="/calendar" component={BasicHeader} />
                <PrivateRoute path="/fb_deletion_status" component={BasicHeader} />

                    </Switch>
                </BrowserRouter>





             
                      <BrowserRouter forceRefresh= {true}>
                        <Switch>
                        <PrivateRoute exact  path="/" component={MyHomePage}/> 
                        <PrivateRoute path="/dashboard" component={Dashboard}/>
                <PrivateRoute path="/mycredits" component={MyCredits} />
                <PrivateRoute path="/purchasecredits" component={PurchaseCredits} />
                <PrivateRoute path="/managesubscriptions/:type" component={ManageSubscriptions} />
                <PrivateRoute path="/genrelist" component={GenreListingPage} />
                <PrivateRoute path="/genredetails/:genreId/:cat_id?/:parent_cat_id?" component={GenreDetailsPage} />
                <PrivateRoute path="/categorycontents/:cat_id/:parent_cat_id?" component={CategoryContents} />
                <PrivateRoute path="/selectattributes" component={SelectAttributes} />
                <PrivateRoute path="/selectcategories" component={SelectCategories} />
                <PrivateRoute path="/createcontentdeck" component={CreateContentDeck} />
                <PrivateRoute path="/createcontentpage" component={CreateContentPage} />
                <PrivateRoute path="/repeatcontentpage" component={RepeatContentPage} />
                <PrivateRoute path="/mycontents" component={MyContents} />
                <PrivateRoute path="/mycontentsunpublished" component={MyContentsUnpublished} />
                <PrivateRoute path="/mycontentsbuttons" component={MyContentsButtons} />
                <PrivateRoute path="/mycontentsprivate" component={MyContentsPrivate} />
                <PrivateRoute path="/mycontentsgroup" component={MyContentsGroup} />
                <PrivateRoute path="/addquestions/:quizdeckId" component={AddQuestions} />
                <PrivateRoute path="/questionlist/:quizdeckId" component={QuestionList} />
                <PrivateRoute path="/termsandconditions" component={TermsAndConditionsPage} />
                <PrivateRoute path="/creatortermsandconditions" component={CreatorTermsAndConditionsPage} />
                <PrivateRoute path="/privacypolicy" component={PrivacyPolicyPage} />
                <PrivateRoute path="/contentdetails/:quizdeckId" component={ContentDetailPage} />
                <PrivateRoute path="/playcontent/:quizdeckId" component={PlayContentPage} />
                <PrivateRoute path="/repeatplaycontent/:repeatQuizdeckId" component={RepeatPlayContentPage} />
                <PrivateRoute path="/repeatcontentresults/:quizdeckId" component={RepeatContentResults} />
                <PrivateRoute path="/paymentgateway" component={SingleCheckoutPage} />
                <PrivateRoute path="/contentresults/:quizdeckId" component={ContentResults} />
                <PrivateRoute path="/search" component={SearchPage} />
                <PrivateRoute path="/creatorprofile/:profileId" component={CreatorProfile} />
                <PrivateRoute path="/contentsedit/:quizdeckId" component={ContentsEdit} />
                <PrivateRoute path="/contentseditunpublished/:quizdeckId" component={ContentsEditUnpublished} />
                <PrivateRoute path="/editquestion/:questionId" component={EditQuestion} />
                <PrivateRoute path="/myfolders" component={MyFolders} />
                <PrivateRoute path="/myfolderdetails" component={MyFolderDetails} />
                <PrivateRoute path="/addtofolders" component={AddToFolders} />
                <PrivateRoute path="/mygroups" component={MyGroups} />
                <PrivateRoute path="/creategroup" component={CreateGroup} />
                <PrivateRoute path="/groupdetails/:group_id" component={GroupDetails} />
                <PrivateRoute path="/groupnews/:group_id" component={GroupNews} />
                <PrivateRoute path="/groupadmin/:group_id" component={GroupAdmin} />
                <PrivateRoute path="/inviteusertotakecontent" component={InviteUserToTakeContent} />
                <PrivateRoute path="/notifications" component={Notifications} />
                <PrivateRoute path="/invitations" component={Emails} />
                <PrivateRoute path="/myplayedcontents" component={MyPlayedContents} />
                <PrivateRoute path="/sellerKYC" component={SellerKYC} />
                <PrivateRoute path="/becomeacreator" component={BecomeACreator} />
                <PrivateRoute path="/updateProfile" component={UpdateProfile} />
                <PrivateRoute path="/salesreport" component={SalesReport} />
                <PrivateRoute path="/contactus" component={ContactUs} />
                <PrivateRoute path="/aboutus" component={AboutUs} />
                <PrivateRoute path="/aboutmemorizeitall" component={AboutMemorizeitall} />
                <PrivateRoute path="/contentsdetailsedit/:quizdeckid" component={ContentsDetailsEdit} />
                <PrivateRoute path="/usertypesandpricing" component={UserTypesAndPricing} />
                <PrivateRoute path="/pricing" component={Pricing} />
                <PrivateRoute path="/campaigns" component={Campaign} />
                <PrivateRoute path="/myhome" component={MyHomePage} />
                <PrivateRoute path="/help" component={Help} />
                <PrivateRoute path="/howitworks" component={HowItWorks} />
                <PrivateRoute path="/suggestions" component={Suggestions} />
                <PrivateRoute path="/advertise" component={Advertisement} />
                <PrivateRoute path="/reportabuse" component={ReportAbuse} />
                <PrivateRoute path="/vaccancies" component={Vacancies} />
                <PrivateRoute path="/news" component={News} />
                <PrivateRoute path="/calendar" component={Calendar} />
                <PrivateRoute path="/fb_deletion_status" component={FacebookDeletionStatus} />
                <PrivateRoute path="/questions/upgrade" component={AQustionCompatibilityTiny} />
                        </Switch>
                      </BrowserRouter>

                      <BrowserRouter forceRefresh= {true}>
                    <Switch>   
                    <PrivateRoute exact path="/" component={BasicFooter}/> 
                    <PrivateRoute path="/dashboard" component={BasicFooter}/>
                <PrivateRoute path="/mycredits" component={BasicFooter}/>
                <PrivateRoute path="/purchasecredits" component={BasicFooter}/>
                <PrivateRoute path="/managesubscriptions/:type" component={BasicFooter}/>
                <PrivateRoute path="/genrelist" component={BasicFooter}/>
                <PrivateRoute path="/genredetails/:genreId/:cat_id?/:parent_cat_id?" component={BasicFooter}/>
                <PrivateRoute path="/categorycontents/:cat_id/:parent_cat_id?" component={BasicFooter} />
                <PrivateRoute path="/selectattributes" component={BasicFooter} />
                <PrivateRoute path="/selectcategories" component={BasicFooter} />
                <PrivateRoute path="/createcontentdeck" component={BasicFooter} />
                <PrivateRoute path="/createcontentpage" component={BasicFooter} />
                <PrivateRoute path="/repeatcontentpage" component={BasicFooter} />
                <PrivateRoute path="/mycontents" component={BasicFooter} />
                <PrivateRoute path="/mycontentsunpublished" component={BasicFooter} />
                <PrivateRoute path="/mycontentsbuttons" component={BasicFooter} />
                <PrivateRoute path="/mycontentsprivate" component={BasicFooter} />
                <PrivateRoute path="/addquestions/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/questionlist/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/termsandconditions" component={BasicFooter} />
                <PrivateRoute path="/creatortermsandconditions" component={BasicFooter} />
                <PrivateRoute path="/privacypolicy" component={BasicFooter} />
                <PrivateRoute path="/contentdetails/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/playcontent/:quizdeckId" component={ShortFooter} />
                <PrivateRoute path="/repeatplaycontent/:repeatQuizdeckId" component={ShortFooter} />
                <PrivateRoute path="/repeatcontentresults/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/paymentgateway" component={BasicFooter} />
                <PrivateRoute path="/contentresults/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/search" component={BasicFooter} />
                <PrivateRoute path="/creatorprofile/:profileId" component={BasicFooter} />
                <PrivateRoute path="/contentsedit/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/contentseditunpublished/:quizdeckId" component={BasicFooter} />
                <PrivateRoute path="/editquestion/:questionId" component={BasicFooter} />
                <PrivateRoute path="/myfolders" component={BasicFooter} />
                <PrivateRoute path="/myfolderdetails" component={BasicFooter} />
                <PrivateRoute path="/addtofolders" component={BasicFooter} />
                <PrivateRoute path="/mygroups" component={BasicFooter} />
                <PrivateRoute path="/creategroup" component={BasicFooter} />
                <PrivateRoute path="/groupdetails/:group_id" component={BasicFooter} />
                <PrivateRoute path="/groupnews/:group_id" component={BasicFooter} />
                <PrivateRoute path="/groupadmin/:group_id" component={BasicFooter} />
                <PrivateRoute path="/inviteusertotakecontent" component={BasicFooter} />
                <PrivateRoute path="/notifications" component={BasicFooter} />
                <PrivateRoute path="/invitations" component={BasicFooter} />
                <PrivateRoute path="/myplayedcontents" component={BasicFooter} />
                <PrivateRoute path="/sellerKYC" component={BasicFooter} />
                <PrivateRoute path="/becomeacreator" component={BasicFooter} />
                <PrivateRoute path="/updateProfile" component={BasicFooter} />
                <PrivateRoute path="/salesreport" component={BasicFooter} />
                <PrivateRoute path="/contactus" component={BasicFooter} />
                <PrivateRoute path="/aboutus" component={BasicFooter} />
                <PrivateRoute path="/aboutmemorizeitall" component={BasicFooter} />
                <PrivateRoute path="/contentsdetailsedit/:quizdeckid" component={BasicFooter} />
                <PrivateRoute path="/usertypesandpricing" component={BasicFooter} /> 
                <PrivateRoute path="/pricing" component={BasicFooter} />
                <PrivateRoute path="/campaigns" component={BasicFooter} />
                <PrivateRoute path="/myhome" component={BasicFooter} />
                <PrivateRoute path="/help" component={BasicFooter} />
                <PrivateRoute path="/howitworks" component={BasicFooter} />
                <PrivateRoute path="/suggestions" component={BasicFooter} />
                <PrivateRoute path="/advertise" component={BasicFooter} />
                <PrivateRoute path="/reportabuse" component={BasicFooter} />
                <PrivateRoute path="/vaccancies" component={BasicFooter} />
                <PrivateRoute path="/news" component={BasicFooter} />
                <PrivateRoute path="/calendar" component={BasicFooter} />
                <PrivateRoute path="/fb_deletion_status" component={BasicFooter} />
                    </Switch>
                </BrowserRouter>
                    
        </React.Fragment>
    )
}

export default HomePage
