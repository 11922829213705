/* eslint-disable */ 

import React, {useState, useEffect} from 'react'

import andriodIcon from '../images/android-icon.png'
import backToTopLogo from '../images/back-to-top-logo.png'
import androidImg from '../images/android-icon.png'
import macImg from "../images/desk.png"
import footerlogo from '../images/memorize-footer-logo.png'


import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";
import axios from 'axios';
import qs from 'qs';

import "../less/login.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

export function BasicFooter(props) {

    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])


    const user = cookies['user'] //getUser();
    let user_type_id ;
    if(!user) {
        user_type_id = -1
    } else {
        user_type_id = user.user_type_id
    }
    //console.log(user)
    
    console.log("user_type_id", user_type_id)

    const token = cookies['access_token'] //getToken()

    const goToContactUsPage = () => {
        console.log('goToContactUsPage')
        props.history.push("/contactus")
    }

    const goTostartmemorizing = () => {
        if (user_type_id == 8){
            props.history.push('/selectattributes');
        }else{
            axios({
                method: 'post',
                url: '/api/my/played/quiz/recentlyplayed',
                headers:{
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'x-access-token': "Memo "+token,
                    'locale': "en",
                },
                data: qs.stringify({
                    page: '1'
                })
            })
            .then(response => {
                let quizzesList = response.data.data;
                console.log("quizzes", quizzesList)
                props.history.push("/contentdetails/"+response.data.data.quiz_deck_id)
                //setUserSession(token, response.data.user);
            }).catch(error => {
                // removeUserSession();
            });
        }
    }

    const goTousertypesandpricing = () => {
        props.history.push("/usertypesandpricing")
    }

    const goTobecomeacreator = () => {
        props.history.push('/becomeacreator');
    }

    const goTogroupspage = () => {
        props.history.push('/mygroups');
    }

    const goTopricing = () => {
        props.history.push("/pricing")
    }

    const goTocampaigns = () => {
        props.history.push("/campaigns")
    }

    const goToHelpPage = () => {
        props.history.push("/help")
    }

    const goToHowItWorksPage = () => {
        props.history.push("/howitworks")
    }

    const goToSuggestionsPage = () => {
        props.history.push("/suggestions")
    }

    const goToadvertisePage = () => {
        props.history.push("/advertise")
    }

    const goToaboutus = () => {
        props.history.push('/aboutus');
    }
    const goToaboutMemorizeitall = () => {
        props.history.push('/aboutmemorizeitall');
    }

    const goToAbusePage = () => {
        if(token) {
            props.history.push('/reportabuse');
        }
    }

    const goTovacancies = () => {
        props.history.push("/vaccancies")
    }

    const goToNewsPage = () => {
        props.history.push("/news")
    }

    const goToCalendarPage = () => {
        props.history.push("/calendar")
    }

    const goToTermsPage = () => {
        props.history.push("/termsandconditions")
    }
    
    const goToPrivacyPolicyPage = () => {
        props.history.push("/privacypolicy")
    }
    const goToCreatorTermsPage = () => {
        props.history.push("/creatortermsandconditions")
    }
    const goToHomePage = () => {
        if(token) {
            console.log('here')
            props.history.push("/myhome")
        } else {
            props.history.push("/")
        }
    }


    return (
        <footer class="memorize-footer">
        <div class="footer-img">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInLeft">
                        <h3>{t("memorizeall")}</h3>
                        <ul>
                            <li onClick={()=>goToHomePage()}><a href="javascript:void(0)">{t("home")}</a></li>
                            {/* <li onClick={()=>goTostartmemorizing()}><a href="javascript:void(0)">{t("start_memorizing")}</a></li> */}
                            <li onClick={()=>goTousertypesandpricing()}><a href="javascript:void(0)">{t("user_types_and_pricing")}</a></li>
                            {
                                user_type_id == 2 ?
                                (
                                    <React.Fragment>
                                        <li
                                            onClick={()=>goTobecomeacreator()}
                                        ><a href="javascript:void(0)">{t("become_a_creator")}</a></li>
                                    </React.Fragment>
                        
                                )
                                :
                                null
                            }

                        {/* {
                            user_type_id == 2 ? 
                            null
                            :
                            (
                                <li class="nav-item"
                                    onClick={goTogroupspage}
                                >
                                    <a href="javascript:void(0)">{t("group")}</a>
                                </li>
                            )
                        } */}

                            {/* <li onClick={()=>goTopricing()}><a href="javascript:void(0)">{t("pricing")}</a></li> 
                            <li onClick={()=>goTocampaigns()}><a href="javascript:void(0)">{t("campaign")}</a></li> */}
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInLeft">
                        <h3>{t("support")}</h3>
                        <ul>
                            <li onClick={()=>goToContactUsPage()}><a href="javascript:void(0)">{t("contact")} Memorizeitall</a></li>
                            {/* <li onClick={()=>goToHelpPage()}><a href="javascript:void(0)">{t("help")}</a></li> 
                            <li onClick={()=>goToHowItWorksPage()}><a href="javascript:void(0)">{t("how_it_works")}</a></li>*/}
                            <li onClick={()=>goToSuggestionsPage()}><a href="javascript:void(0)">{t("suggestions")}</a></li>
                            <li onClick={()=>goToadvertisePage()}><a href="javascript:void(0)">{t("advertise")}</a></li>
                            { token ? 
                                <li onClick={()=>goToAbusePage()}><a href="javascript:void(0)">{t("report_abuses")}</a></li>
                            : null}
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInRight">
                        <h3>{t("company")}</h3>
                        <ul>
                            
                            {
                                user_type_id == 8? 
                                null
                                :
                                (
                                    <li
                                        onClick={goToaboutMemorizeitall}
                                    ><a href="javascript:void(0)">{t("about_memorizeitall")}</a></li>
                                )

                            }
                            {/* {
                                user_type_id == 8? 
                                null
                                :
                                (
                                    <li
                                        onClick={goToaboutus}
                                    ><a href="javascript:void(0)">About me</a></li>
                                )

                            } 
                            <li onClick={()=>goTovacancies()}><a href="javascript:void(0)">{t("vaccancies")}</a></li>
                            <li onClick={()=>goToNewsPage()}><a href="javascript:void(0)">{t("news")}</a></li>
                            <li onClick={()=>goToCalendarPage()}><a href="javascript:void(0)">{t("calendar")}</a></li>*/}
                            <li onClick={()=>goToTermsPage()}><a href="javascript:void(0)">{t("terms_and_conditions")}</a></li>
                            <li onClick={()=>goToPrivacyPolicyPage()}><a href="javascript:void(0)">{t("privacy_policy")}</a></li>
                            <li onClick={()=>goToCreatorTermsPage()}><a href="javascript:void(0)">Creator {t("terms_and_conditions")}</a></li>
                          </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInRight">
                        <h3>{t("offices")}</h3>
                        <ul>
                            <li>{t("address_line_1")}</li>
                            <li>{t("address_line_2")}</li>
                            <li><a href="mailto:info@memorizeitall.email">info@memorizeitall.email</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-xl-6 position-inherit">
                        {/* <div class="ios-android-download-link animated fadeInLeft">
                            <a href="javascript:void(0)">
                                <span class="icon"><span class="icon-apple"></span></span>
                                <span class="txt">Download on the</span>
                                <span class="store">App Store</span>
                            </a>
                            <a href="javascript:void(0)">
                                <span class="icon"><img src={androidImg} /></span>
                                <span class="txt">Android App on</span>
                                <span class="store">Google Play</span>
                            </a>
                            <a href="javascript:void(0)">
                                <span class="icon"><img src={macImg} /></span>
                                <span class="txt">Download on</span>
                                <span class="store">PC/Mac</span>
                            </a>
                        </div> */}
                        <div class="copy-right animated fadeInRight">
                            &copy; 2020 Memorizeitall - all rights reserved - <a href="#">www.memorizeitall.com</a>
                        </div>

                        {/* <!-- <div class="subscribe animated fadeInRight">
                            <div class="subscribe-in">
                                <input type="text" />
                                <a href="javascript:void(0)" class="subscribe-now-btn">Subscribe now</a>
                            </div>
                        </div> --> */}
                    </div>
                    <div class="col-md-12 col-xl-6 memorize-txt">
                        <div class="memorize-logo">
                            <img src={footerlogo} />
                        </div>
                        <p>Memorize<span>it</span>all</p>
                    </div>
                </div>
            </div>
            <a id="back-to-top" href="#" class="back-to-top-btn animate-top-to-bottom"><img src={backToTopLogo} /></a>
        </div>
    </footer>
        )
}

export default BasicFooter
