/* eslint-disable */

import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import folder from "../images/folder.png";

import { useTranslation } from "react-i18next";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/folder.css";
import "../less/test.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useCookies } from "react-cookie";

function AddToFolders(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  console.log(props);

  const token = cookies["access_token"]; //getToken();

  const quiz_deck_id = props.location.state.quiz_deck_id;

  const [totalPages, setTotalPages] = useState();
  const [folderId, setFolderId] = useState();
  const [folderDetails, setFolderDetails] = useState([]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  const [banners, setBanners] = useState();
  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const showMessageToUser = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/folders/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        parent_folder_id: folderId,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setTotalPages(response.data.total_pages);
        // showMessageToUser(response.data.msg)
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    if (folderId) {
      axios({
        method: "post",
        url: "/api/folders/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          parent_folder_id: folderId,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setTotalPages(response.data.total_pages);
          // showMessageToUser(response.data.msg)
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
  }, [folderId]);

  useEffect(() => {
    if (totalPages == 0) {
      setFolderDetails([]);
    }
    for (let i = 1; i <= totalPages; i++) {
      axios({
        method: "post",
        url: "/api/folders/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          page: i,
          parent_folder_id: folderId,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setFolderDetails((folderDetails) =>
            folderDetails.concat(response.data.data)
          );
          // showMessageToUser(response.data.msg)
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
    setTotalPages(0);
  }, [totalPages]);

  const addContentToFolder = (event, id) => {
    console.log(id);
    axios({
      method: "post",
      url: "/api/folders/quiz/move",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
        folder_id: id,
      }),
    })
      .then((response) => {
        console.log("response", response);
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        // removeUserSession();
      });
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      {/* <BasicHeader
            
            {...props}
        /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("add_to_folders")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("add_to_folders")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img src={banner} class="img_banner"></img> */}
      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      <section class="">
        <div class="container">
          <div class="row" id="owl-carousel">
            {folderDetails.length > 0
              ? folderDetails.map((detail) => (
                  <div
                    class="col-lg-2 col-md-3 col-sm-3 col-6"
                    onClick={() => setFolderId(detail.id)}
                  >
                    <div class="item">
                      <div class="cust_genre">
                        <img src={folder} />
                        <h4>{detail.name}</h4>
                        <a
                          onClick={(e) => addContentToFolder(e, detail.id)}
                          href="javascript:void(0)"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
}

export default AddToFolders;
