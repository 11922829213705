const Reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_CURRENT_MENU":
            return {
                ...state,
                "CURRENT_MENU_ID": action.payload
            }
        case "UPDATE_NOTIFICATION_COUNT":
            return {
                ...state,
                "CURRENT_NOTIFICATION_COUNT": action.payload
            }
        case "UPDATE_INVITATION_COUNT":
            return {
                ...state,
                "CURRENT_INVITATION_COUNT": action.payload
            }   
        default:
            console.log('Reducer default called')
            return state
    }

}

export default Reducer;