/* eslint-disable */ 
import React, {useState, useEffect, useContext} from 'react'

import aboutus from "../images/dashboard/aboutus.png"

import { getToken, removeUserSession, setUserSession, getUser, setUserGlobalLanguage , getUserGlobalLanguage, getDeviceUUID} from '../Utils/Common';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import "../less/counter.css"

import { Context } from '../Reducers/Store'
import { useCookies } from "react-cookie";

function SideNavBar(props) {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const [state, dispatch] = useContext(Context)

    const user = cookies['user'] //getUser();
    console.log(user)
    const user_type_id = user.user_type_id;
    console.log("user_type_id", user_type_id)

    const token = cookies['access_token'] //getToken()

    const goToCreditsPage = () => {
        // console.log("props before", props)
        props.history.push('/mycredits');
        // console.log("props after", props)
    } 

    const goToManageSubscriptionPage = () => {
        props.history.push('/managesubscriptions/' + -1); // -1 foar all user_types
    }

    const gotoAttributesPage = () => {

        axios({
            method: 'post',
            url: '/api/quiz_deck/createcheck',
            headers:{
                'Content-Type' : 'application/x-www-form-urlencoded',
                'x-access-token': "Memo "+token,
                'locale': "en",
            }
        })
        .then(response => {
            console.log("can create content success", response)
            props.history.push('/selectattributes');
            //setUserSession(token, response.data.user);
        }).catch(error => {
            // removeUserSession();
            console.log("can create content error", error.response)
            document.getElementById("freeCheckModal").click();
        });

        
    }

    const gotoCreateContentPage = () => {
        props.history.push('/createcontentpage');
    }

    const gotoMyContents = () => {
        console.log("going to mycontent")
        props.history.push('/mycontentsbuttons');
    }

    const gotoMyPrivateContents= () => {
        console.log("going to my private content")
        props.history.push('/mycontentsprivate');
    }

    const gotoMyGroupContents= () => {
        console.log("going to my group content")
        props.history.push('/mycontentsgroup');
    }

    const aboutusPage = () => {
        props.history.push('/aboutus');
    }

    const logoutSession = () => {
    
        axios({
            method: 'get',
            url: '/api/user/logout/'+ getDeviceUUID(),
            headers:{
                'locale': "en",
                'x-access-token': "Memo "+token,
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            console.log("logout response", response)
        }).catch(error => {
        //   removeUserSession();
        });
        
        //removeUserSession();
        props.history.push('/login');
    }

    const goToGenrePage = () => {
        props.history.push('/genrelist');
    }

    const goToMyFolders = () => {
        props.history.push('/myfolders');
    }

    const goToTermsAndConditionsPage = () => {
        props.history.push('/termsandconditions');
    }

    const goToPrivacyPolicyPage = () => {
        props.history.push('/privacypolicy');
    }

    // const goToMySellerKYC = () => {
    //     props.history.push('/sellerKYC');
    // }

    const goToMyBecomeACreator = () => {
        props.history.push('/becomeacreator');
    }

    const gotoSalesReportPage = () => {
        props.history.push('/salesreport');   
    }

    const goToMyBecomeAPremiumUser = () => {
        props.history.push('/managesubscriptions/' + 3) //3 for premium user
    }
    const showMenuDropdown = () => {
        // if(document.getElementById("dashboard-menu-list").style.display == "block") {
        //     document.getElementById("dashboard-menu-list").style.display =  "none";
        // } else {
        //     document.getElementById("dashboard-menu-list").style.display =  "block";
        // }
        if(!document.getElementById("dashboard-menu-list").classList.contains('show')) {
            document.getElementById("dashboard-menu-list").classList.toggle("show")
            //document.getElementById("dashboard-menu-list").classList.toggle("hide");
        } else {
            document.getElementById("dashboard-menu-list").classList.toggle("show")
            document.getElementById("dashboard-menu-list").classList.toggle("hide");
        }
    }
    const goToHomePage = () => {
        props.history.push('/dashboard');
    }
    const goToUpdateProfile = () => {
        props.history.push('/updateprofile');
    }
    const gotoNotificationsPage = () => {
        props.history.push('/notifications');
    }
    return (
        <React.Fragment>
            <div class="dashboard-menu animated fadeInLeft">
                    <a href="javascript:void(0)" class="dashboard-menu-button" onClick={() => showMenuDropdown()}>Menu</a>
                    <div class="dashboard-menu-list" id="dashboard-menu-list">
                        <ul class="mainmenu">
                            {/* <li><a href="javascript:void(0)"><span class="icon icon-dashboard"></span> <span class="txt">{t('dashboard')}</span></a></li> */}
                            {/* <li onClick={goToGenrePage}><a href="javascript:void(0)"><span class="icon icon-home"></span> <span class="txt">{t('home')}</span></a></li> */}
                            
                            <li class="menuFirstChild"><a href="javascript:void(0)">
                                <span class="outerBg">
                                <span class="icon icon-new-login"></span>
                                </span>
                                 <span class="txt">{user.name}</span></a></li>
                            {/* {
                                user_type_id == 2 || user_type_id == 8? 
                                null
                                :
                                (
                                <li class="folderList"
                                    onClick={goToMyFolders}
                                ><a href="javascript:void(0)"><span class="icon icon-folder1"></span> <span class="txt">{t("my_folders")}</span></a></li>
                                )

                            } */}
                            {/* {
                                user_type_id == 2 ? 
                                null
                                :
                                (
                                <li
                                    onClick={goToMySellerKYC}
                                ><a href="javascript:void(0)"><span class="icon icon-calendar1"></span> <span class="txt">Seller KYC</span></a></li>
                                )

                            } */}
                            
                            {/* <li>
                                <a href="#QuizesMenu" class="submenu-link" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="QuizesMenu"><span class="icon icon-contents"></span> <span class="txt">{t('quizes')}</span></a>
                                <div class="collapse submenu-content" id="QuizesMenu">
                                    <ul class="pl-0">
                                            {
                                                user_type_id == 8? 
                                                null
                                                :
                                                (
                                                    <li onClick={gotoCreateContentPage}><a href="javascript:void(0)"><span class="icon icon-contents "></span> <span class="txt">{t('quizes')}</span></a></li>
                                                )

                                            }
                                        

                                        <li onClick={gotoAttributesPage}><a href="javascript:void(0)"><span class="icon icon-create-new"></span> <span class="txt">{t('createquiz')}</span></a></li>
                                        <li onClick={gotoMyContents}><a href="javascript:void(0)"><span class="icon icon-edit-content"></span> <span class="txt">{t('edit_contents')}</span></a></li>
                                        <li onClick={gotoMyPrivateContents}><a href="javascript:void(0)"><span class="icon icon-private-content"></span> <span class="txt">{t('privatequiz')}</span></a></li>
                                        <li onClick={gotoMyGroupContents}><a href="javascript:void(0)"><span class="icon icon-collaboration"></span> <span class="txt">{t('group_quiz')}</span></a></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li>
                                <a href="#QuizesMenu" class="submenu-link" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="QuizesMenu">
                                    <div class="hoverListItem">
                                    <span class="outerBg">
                                    <span class="icon icon-user2"></span>
                                    </span>
                                     <span class="txt">{t('Profile')}</span>
                                    </div>
                      
                                     </a>
                                <div class="collapse submenu-content" id="QuizesMenu">
                                    <ul class="pl-0">
                                        <li onClick={goToHomePage}><a href="javascript:void(0)">
                                            <div class="hoverListItem">
                                            <span class="outerBg">
                                    <span class="icon icon-user2"></span>
                                    </span>
                                            </div>
                                             <span class="txt">{t('view_profile')}</span></a>
                                             </li>
                                        <li onClick={goToUpdateProfile}>
                                            <a href="javascript:void(0)" class="editProfileItem">
                                        <span class="outerBg">
                                    <span class="icon icon-edit2"></span>
                                    </span>
                                            <span class="txt">{t('editProfile')}</span></a></li>
                                    </ul>
                                </div>
                            </li>
                            <li onClick={gotoNotificationsPage}>
                                <a href="javascript:void(0)" class="d-flex">
                                    <span class="outerBg">
                                    <span class="icon icon-notification1"></span>
                                    </span>
                                    <span class="txt">{t('notifications')}</span>

                                {
                                    state.CURRENT_NOTIFICATION_COUNT > 0 ?
                                    <span class="ml-2 sidenavNotification">{state.CURRENT_NOTIFICATION_COUNT}</span>
                                        :
                                    null
                                }
                                </a>
                            </li>
                            {
                                user_type_id == 8? 
                                null
                                :
                                (
                                    <li onClick={goToCreditsPage}><a href="javascript:void(0)">
                                        <span class="outerBg">
                                        <span class="icon icon-dollar1"></span>
                                        </span>
                                         <span class="txt">{t('my_credits')}</span></a></li>
                                )

                            }

                            
                            {/* <li><a href="javascript:void(0)"><span class="icon icon-language"></span> <span class="txt">{t('change_language')}</span></a></li>
                            <li><a href="javascript:void(0)"><span class="icon icon-share-app"></span> <span class="txt">{t('share_app')}</span></a></li> */}
                                            {
                                                user_type_id == 8? 
                                                null
                                                :
                                                (
                                                    <li onClick={goToManageSubscriptionPage}><a href="javascript:void(0)">
                                                        <span class="outerBg">
                                                        <span class="icon icon-subscription2"></span> 
                                                        </span>
                                                        <span class="txt" >{t('manage_subscription')}</span></a></li>
                                                )

                                            }
                                            {
                                                user_type_id == 2 || user_type_id == 5 || user_type_id == 3 || user_type_id == 8? 
                                                null
                                                :
                                                (
                                                    <li onClick={gotoSalesReportPage}><a href="javascript:void(0)">
                                                        <span class="outerBg">
                                                        <span class="icon icon-report1"></span> 
                                                        </span> 
                                                        <span class="txt">{t("sales_report")}</span></a></li>
                                                )

                                            }
                            
                            {
                                user_type_id == 2 ?
                                (
                                    <React.Fragment>
                                         <li onClick={goToMyBecomeAPremiumUser}>
                                             <a href="javascript:void(0)">
                                                <span class="outerBg">
                                                        <span class="icon icon-subscription2"></span> 
                                                        </span>
                                                <span class="txt">{t("become_a_premium_member")}</span>
                                              </a>
                                            </li>
                                            <li  onClick={goToMyBecomeACreator}>
                                                <a href="javascript:void(0)">
                                                    <span class="outerBg">
                                                        <span class="icon icon-subscription2"></span> 
                                                        </span>
                                                    <span class="txt">{t("become_a_creator")}</span>
                                                </a>
                                            </li>
                                    </React.Fragment>
          
                                )   
                                : 
                                null
                                
                            }

                            {
                                user_type_id == 3 ?
                                (
                                <React.Fragment>
                                            <li  onClick={goToMyBecomeACreator}>
                                                <a href="javascript:void(0)">
                                                    <span class="outerBg">
                                                        <span class="icon icon-subscription2"></span> 
                                                        </span>
                                                    <span class="txt">{t("become_a_creator")}</span>
                                                </a>
                                            </li> 
                                   
                                </React.Fragment>
                             )   
                             : 
                             null
                             
                         }
                                            {/* {
                                                user_type_id == 8? 
                                                null
                                                :
                                                (
                                                    <li
                                                        onClick={aboutusPage}
                                                    ><a href="javascript:void(0)"><span class="icon icon-calendar1"></span> <span class="txt">{t("aboutus")}</span></a></li>
                                                )

                                            } 
                                            {
                                                user_type_id == 8? 
                                                null
                                                :
                                                (
                                                    <li><a href="javascript:void(0)">
                                                        <span class="outerBg">
                                                        <span class="icon icon-information"></span> 
                                                        </span>
                                                         <span class="txt">{t("howitworks")}</span></a></li>
                                                )

                                            }*/}

                            
                            
                            
                            {/* <li><a href="javascript:void(0)"><span class="icon icon-share"></span> <span class="txt">Share Referal</span></a></li> */}
                            {/* <li onClick={goToTermsAndConditionsPage}><a href="javascript:void(0)"><span class="icon icon-info"></span> <span class="txt">{t('lbl_terms_conditions')}</span></a></li>
                            <li onClick={goToPrivacyPolicyPage}><a href="javascript:void(0)"><span class="icon icon-info "></span> <span class="txt">{t('lbl_privacy_policy')}</span></a></li> */}
                            {/*
                            <li onClick={logoutSession}><a href="javascript:void(0)">
                                                    <span class="outerBg">
                                                        <span class="icon icon-logout"></span> 
                                                        </span>
                                 <span class="txt">{t('sign_out')}</span></a></li>
                                 */}
                        </ul>
                    </div>
                </div>

                <p class="details-play-quiz"
                                id="freeCheckModal" 
                                data-toggle="modal" 
                                data-target="#freeCheck"
                                style={{display:"none"}}
                            >Open Modal</p>

<div class="modal fade add-rate-review-info" id="freeCheck" tabindex="-1" role="dialog" aria-labelledby="freeCheck" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Access rights</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="add-rate-review-info">
                   
                        
                        <p><strong>{t('free_user_cannot_create_5_contents')}</strong></p>
                    </div>
                </div> 
                <div className="modal-footer">
                    <div class="save-cancel-btn">
                            <a 
                                data-dismiss="modal"
                                onClick={goToMyBecomeAPremiumUser}
                                href="javascript:void(0)" class="btn-border blue-bg-btn">
                                    {t('become_a_premium_member')}
                            </a>
                            <a
                                data-dismiss="modal"
                                // onClick={skipReview} 
                                href="javascript:void(0)" class="btn-background blue-bg-btn">
                                    {t('skip')}
                            </a>
                        </div>
                </div>               
            </div>
        </div>
    </div>



        </React.Fragment>
    )
}

export default SideNavBar
