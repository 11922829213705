/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { tHydrate } from "../Utils/HydrateT";

function GenreList(props) {
  //console.log("genrelist",props)
  const { genreList } = props;
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;

  const [banners, setBanners] = useState();

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const goToGenreDetails = (id) => {
    props.history.push("/genredetails/" + id);
  };

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  return (
    <React.Fragment>
      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      {/* <section class="banner_carousel">
            <div class="row">
                    <OwlCarousel
                                    className="owl-carousel logo_slider"
                                    // id="owl-carousel"
                                    items="1"
                                    autoplay
                                    nav
                                    dots
                                    loop
                                    margin={5}
                                    nav
                                >
                                {
                                    banners && banners.length > 0 ?
                                    (
                                        banners.map(banner => (
                                            banner.banner_type == 2 ? 
                                            (
                                                <a class="item" href={banner.link}><img src={BANNER_PATH + banner.image} /></a>
                                            )
                                            :
                                            null
                                            
                                        ))
                                        
                                    )
                                    :
                                    null
                                }
                            
                    </OwlCarousel>
                            
                    </div>
                
            </section> */}

      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance3">
            <div class="text-center">
              <h2 style={{ color: "#294177", fontWeight: "600" }}>
                {t("content_search_title")}
              </h2>
              <h5 style={{ color: "#294177" }}>
                {tHydrate("content_search_description_1")}
              </h5>
              <h5 style={{ color: "#294177" }}>
                {t("content_search_description_2")}
              </h5>
            </div>
            <div class="d-flex flex-wrap align-items-end folder-all cust_link">
              {/* <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5> */}
            </div>
          </div>

          <div class="d-flex performance2">
            <div class="text-left">
              <h3 style={{ color: "#294177", fontWeight: "600" }}>
                {t("search_genre")}
              </h3>
            </div>
            <div class="d-flex flex-wrap align-items-end folder-all cust_link">
              {/* <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5> */}
            </div>
          </div>

          {/* <OwlCarousel
                            className="owl-theme owl-carousel"
                            id="owl-carousel"
                            items="8"
                            autoplay
                            nav
                            dots
                            loop
                            margin={10}
                            nav
                        > */}
          <div id="genres" class="row justify-content-center">
            {genreList
              ? genreList.length > 0
                ? genreList.map((eachGenre) => (
                    <div
                      class="item"
                      onClick={() => goToGenreDetails(eachGenre.id)}
                    >
                      <div class="cust_genre">
                        <img src={GENER_PATH + eachGenre.image} />
                        <h4
                          dangerouslySetInnerHTML={{ __html: eachGenre.name }}
                        ></h4>
                      </div>
                    </div>
                  ))
                : null
              : null}
          </div>
          {/* </OwlCarousel> */}
        </div>
      </section>
    </React.Fragment>
  );
}

export default GenreList;
