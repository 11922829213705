/* eslint-disable */

import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import "../less/login.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

export function ShortFooter(props) {

    const { t } = useTranslation()

    return (
        <footer class="memorize-footer py-4 memorize-footer-mobile">
            <div class="memorize-txt text-center">
                <p>Memorize<span>it</span>all</p>
            </div>
        </footer>
    )
}

export default ShortFooter
