import React, { Suspense } from 'react';
import Store from './Reducers/Store';
import { BrowserRouter} from 'react-router-dom';

import './App.css';


import { CookiesProvider } from 'react-cookie';
import firebase from 'firebase'
import WebSocketProvider from './Utils/WebSocket';
import RouterEventsProvider from './Utils/RouterEvents';
import AppPublicPrivateRoutes from './Components/AppPublicPrivateRoutes';


function App(props) {

  const firebaseConfig = {
    apiKey: "AIzaSyDuHiByIUDnBexaAHmMn0n0SzlUKS7XOro",
    authDomain: "memorizeitall-ba135.firebaseapp.com",
    databaseURL: "https://memorizeitall-ba135.firebaseio.com",
    projectId: "memorizeitall-ba135",
    storageBucket: "memorizeitall-ba135.appspot.com",
    messagingSenderId: "87062783534",
    appId: "1:87062783534:web:6861cd7937411b49ece1bf",
    measurementId: "G-CRDZXQVX7L"
  };

  firebase.initializeApp(firebaseConfig)


  
  return (
    <Suspense fallback="loading">
      <div className="App">
        {/* <HomePage /> 
        render= {() => <Redirect  to={{  pathname: "/myhome"   }} /> }
        */}
        <CookiesProvider>
          <Store>
            <WebSocketProvider>
              <BrowserRouter forceRefresh={true} >
                <RouterEventsProvider>
                  <AppPublicPrivateRoutes {...props} />
                </RouterEventsProvider>
              </BrowserRouter>
            </WebSocketProvider>
          </Store>
        </CookiesProvider>
      </div>
    </Suspense>
  );
}

export default App;
