/* eslint-disable */ 
import React, {useState, useEffect} from 'react'
import axios from 'axios';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";

function CreatorTermsAndConditionsPage(props) {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const [termsAndConditions, setTermsAndConditions] = useState()
    const token = cookies['access_token']
    const globalLang = cookies['global_language'] //JSON.parse(getUserGlobalLanguage());
    let globalLangCode,globalLangId
    if (!globalLang) {
        globalLangCode = i18next.language
    } else {
        globalLangCode = globalLang.lang_code;
        globalLangId =  globalLang.id;
    }
    //let globalLangId = globalLang.id;

    useEffect(() => {
        

        axios({
            method: 'get',
            url: '/api/creatorTermsandConditions/get',
            headers:{
                // 'Content-Type' : 'application/x-www-form-urlencoded',
                'x-access-token': "Memo "+token,
                'locale': globalLangCode,
            }
            })
            .then(response => {
            console.log("terms", response.data.data)
            setTermsAndConditions(response.data.data[0].description)
            // setUserSession(token, response.data.user);
            }).catch(error => {
            // removeUserSession();
            });
    }, [])

    return (
        <React.Fragment>
        {/* <BasicHeader 
            {...props}
        />  */}

<div class="my-credits-wrapper">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-8 col-md-6 col-lg-5">
                    <h3 class="animated fadeInLeft">{t('Creator_Terms_and_conditions')}</h3>  
                </div>
            </div>  
        </div>
    </div>
        {
                termsAndConditions 
                ?
                (
                    // <PrivacyPolicyContent 
                    //     // text = {privacyPolicy}
                    //     dangerouslySetInnerHTML={privacyPolicy}
                    // /> 
                    <section class="gener_gap">
                    <div class="container">
                        
                        <div class="col-lg-6 col-md-8 col-sm-12 cust_center">
                          
                        <div
                        dangerouslySetInnerHTML={{__html: termsAndConditions}} 
                    />
                             
                           
                        </div>
            
                        
            
                    </div>
                </section>
                )
                :
                null
            }  
        {/* <BasicFooter /> */}
    </React.Fragment>
    )
}

export default CreatorTermsAndConditionsPage
