/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { getUser, getToken, removeUserSession, setUserSession, getUserGlobalLanguage } from '../Utils/Common';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import "../less/my-credits.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

import "../less/test.css"
import "../less/pricing.css"
import i18next from 'i18next';

function UserTypesAndPricingComponent(props) {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <section class="pricingDetails">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 sub-table">
              <div class="priceTable">
                <h2 class="type">{t("pricing_free_user")}</h2>
                <div class="rateCard">
                  <h2>{t("currency_for_free_user")} {t("currency_symbol")}</h2>
                  <p>{t("price_per_month")}</p>
                </div>
                <div class="description">
                  <p><span>{t("price_free_user_feature")}</span></p>
                  <p><span>{t("price_free_feature_1")}</span></p>
                  <p><span>{t("price_free_feature_2")}</span></p>
                  <p><span>{t("price_free_feature_3")}</span></p>
                  <p><span>{t("price_free_feature_4")}</span></p>
                  <p><span>{t("price_free_feature_5")}</span></p>
                  <p><span>{t("price_free_feature_6")}</span></p>
                  <p><span>{t("price_free_feature_7")}</span></p>
                  <p><span>{t("price_free_feature_8")}</span></p>
                  {/* <a href="javascript:void(0)" class="btn btnBuy">Buy Now</a> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sub-table">
              <div class="priceTable active">
                <h2 class="type">{t("pricing_pro_user")}</h2>
                <div class="rateCard">
                  <h2>{t("currency_for_pro_user")} {t("currency_symbol")} {t("currency_for_pro_user_added_txt")}</h2>
                  <p>{t("price_per_month")}</p>
                </div>
                <div class="description">
                  <p><span>{t("price_pro_user_feature")}</span></p>
                  <p><span>{t("price_pro_user_feature_line_1")}</span></p>
                  <p><span>{t("price_pro_feature_1")}</span></p>
                  <p><span>{t("price_pro_feature_2")}</span></p>
                  <p><span>{t("price_pro_feature_3")}</span></p>
                  <p><span>{t("price_pro_feature_4")}</span></p>
                  <p><span>{t("price_pro_feature_5")}</span></p>
                  {/* <a href="javascript:void(0)" class="btn btnBuy">Buy Now</a> */}
                </div>
                <div>
                  <span className="icon icon-idea"></span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 sub-table">
              <div class="priceTable">
                <h2 class="type">{t("pricing_creator_user")}</h2>
                <div class="rateCard">
                  <h2>{t("currency_for_creator")}  {t("currency_symbol")} {t("currency_for_creator_added_txt")}</h2>
                  <p>{t("price_per_month")}</p>
                </div>
                <div class="description">
                  <p><span>{t("price_creator_user_feature")}</span></p>
                  <p><span>{t("price_creator_user_feature_line_1")}</span></p>
                  <p><span>{t("price_creator_feature_1")}</span></p>
                  <p><span>{t("price_creator_feature_2")}</span></p>
                  <p><span>{t("price_creator_feature_3")}</span></p>
                  {/*<p><span>{t("price_creator_feature_4")}</span></p> */}
                  {/* <a href="javascript:void(0)" class="btn btnBuy">Buy Now</a> */}
                </div>
                <div>
                  <span className="icon icon-subscription1"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment>
  )
}

export default UserTypesAndPricingComponent
