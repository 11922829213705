/* eslint-disable */ 
import React from 'react'
import { useTranslation } from 'react-i18next';
import "../less/dashboard.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

function Help(props) {

    const { t } = useTranslation()

    const goToDashboard = () => {
        props.history.push('/dashboard');
    }

    return (
        <React.Fragment>
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-6 col-lg-6">
                            <h2 class="animated fadeInLeft">Help</h2>
                        </div>
                        <div class="col-sm-7 col-md-6 col-lg-6">
                            <div class="breadcrumb-item animated fadeInRight">
                                <ul>
                                    <li onClick={goToDashboard}><a href="javascript:void(0)"><span class="icon-home1"></span> {t('home')}</a></li>
                                    <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)" class="active">Help</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="genre_list_img_banner"></div>
        </React.Fragment>
    )
}

export default Help
