/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import axios from "axios";
import qs from "qs";

import { useTranslation } from "react-i18next";

import { useCookies } from "react-cookie";
import { DebounceInput } from "react-debounce-input";
import { Context } from "../Reducers/Store";
import ContentComponent from "./ContentComponent";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";

function SearchPage(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const user = cookies["user"]; //getUser();
  const [state, dispatch] = useContext(Context);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  const [checkedParams, setCheckedParams] = useState([1]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [globalSearchResult, setGlobalSearchResult] = useState([]);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState({});
  const [contentResults, setContentResults] = useState([]);
  const [genreResults, setGenreResults] = useState([]);
  const [categoryResults, setCategoryResults] = useState([]);
  const [creatorResults, setCreatorResults] = useState([]);

  //For Contents Pagination.
  const [totalContentResultsPages, setTotalContentResultsPages] =
    useState(null);
  const [totalContentResults, setTotalContentResults] = useState(null);
  const [currentContentResults, setCurrentContentResults] = useState(1);

  //For Genres Pagination
  const [totalGenreResultsPages, setTotalGenreResultsPages] = useState(null);
  const [totalGenreResults, setTotalGenreResults] = useState(null);
  const [currentGenreResults, setCurrentGenreResults] = useState(1);

  //Category
  const [totalCategoryResultsPages, setTotalCategoryResultsPages] =
    useState(null);
  const [totalCategoryResults, setTotalCategoryResults] = useState(null);
  const [currentCategoryResults, setCurrentCategoryResults] = useState(1);

  //CreatorResults
  const [totalCreatorResultsPages, setTotalCreatorResultsPages] =
    useState(null);
  const [totalCreatorResults, setTotalCreatorResults] = useState(null);
  const [currentCreatorResults, setCurrentCreatorResults] = useState(1);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");
  let cancel = "";

  const [banners, setBanners] = useState();
  let gqs_cancelToken, ggs_cancelToken, gcats_cancelToken, gcres_cancelToken;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const onContentResultsPagesChanged = (data) => {
    setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchContentResults(data.currentPage);
    setCurrentContentResults(data.currentPage);
  };
  const onGenreResultsPageChanged = (data) => {
    setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchGenreResults(data.currentPage);
    setCurrentGenreResults(data.currentPage);
  };

  const onCategoryResultsPageChanged = (data) => {
    setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchCategoryResults(data.currentPage);
    setCurrentCategoryResults(data.currentPage);
  };

  const onCreatorResultsPageChanged = (data) => {
    setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchCreatorResults(data.currentPage);
    setCurrentCreatorResults(data.currentPage);
  };
  const searchParamsChange = (e, id) => {
    let checkedValue = e.target.checked;
    if (checkedValue === true) {
      setCheckedParams((checkedParams) => checkedParams.concat(id));
    } else {
      let index = checkedParams.indexOf(id);
      let tempArray = [...checkedParams];
      tempArray.splice(index, 1);
      setCheckedParams(tempArray);
    }
  };

  useEffect(() => {
    console.log(checkedParams);
  }, [checkedParams]);
  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_search_page" });
  }, []);

  const fetchContentResults = async (currentPage = 1) => {
    if (typeof gqs_cancelToken != typeof undefined) {
      gqs_cancelToken.cancel("Operation canceled due to new request.");
    }

    if (checkedParams.includes(1) && query) {
      console.log("fetching quizzes");
      gqs_cancelToken = axios.CancelToken.source();

      axios({
        method: "post",
        url: "/api/global/quiz/search",
        cancelToken: gqs_cancelToken.token,
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          term: query,
          page: currentPage.toString(),
        }),
      })
        .then((response) => {
          console.log("search response", response.data);
          //setResults(response.data)

          setContentResults(response.data.data);
          setTotalContentResultsPages(response.data.total_pages);
          setTotalContentResults(response.data.total);
          setCurrentContentResults(currentPage);
          setLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error) || error) {
            setLoading(false);
            setMessage("Failed to fetch results.Please check network");
          }
          // removeUserSession();
        });
    } else {
      setContentResults([]);
      setTotalContentResultsPages(0);
      setTotalContentResults(0);
      setCurrentContentResults(1);
    }
  };
  const fetchGenreResults = async (currentPage = 1) => {
    if (typeof ggs_cancelToken != typeof undefined) {
      ggs_cancelToken.cancel("Operation canceled due to new request.");
    }
    if (checkedParams.includes(2) && query) {
      console.log("fetching genre");
      ggs_cancelToken = axios.CancelToken.source();
      axios({
        method: "post",
        url: "/api/global/genre/search",
        cancelToken: ggs_cancelToken.token,
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          term: query,
          page: currentPage.toString(),
        }),
      })
        .then((response) => {
          console.log("search response", response.data);
          //setResults(response.data)
          setGenreResults(response.data.data);
          setTotalGenreResultsPages(response.data.total_pages);
          setTotalGenreResults(response.data.total);
          setCurrentGenreResults(currentPage);
          setLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error) || error) {
            setLoading(false);
            setMessage("Failed to fetch results.Please check network");
          }
          // removeUserSession();
        });
    } else {
      setGenreResults([]);
      setTotalGenreResultsPages(0);
      setTotalGenreResults(0);
      setCurrentGenreResults(1);
    }
  };
  const fetchCategoryResults = async (currentPage = 1) => {
    if (typeof gcats_cancelToken != typeof undefined) {
      gcats_cancelToken.cancel("Operation canceled due to new request.");
    }
    if (checkedParams.includes(3) && query) {
      console.log("fetching category");
      gcats_cancelToken = axios.CancelToken.source();
      axios({
        method: "post",
        url: "/api/global/category/search",
        cancelToken: gcats_cancelToken.token,
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          term: query,
          page: currentPage.toString(),
        }),
      })
        .then((response) => {
          console.log("search response", response.data.data);
          //setResults(response.data)
          setCategoryResults(response.data.data);
          setTotalCategoryResultsPages(response.data.total_pages);
          setTotalCategoryResults(response.data.total);
          setCurrentCategoryResults(currentPage);
          setLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error) || error) {
            setLoading(false);
            setMessage("Failed to fetch results.Please check network");
          }
          // removeUserSession();
        });
    } else {
      setCategoryResults([]);
      setTotalCategoryResultsPages(0);
      setTotalCategoryResults(0);
      setCurrentCategoryResults(1);
    }
  };
  const fetchCreatorResults = async (currentPage = 1) => {
    if (typeof gcres_cancelToken != typeof undefined) {
      gcres_cancelToken.cancel("Operation canceled due to new request.");
    }
    if (checkedParams.includes(4) && query) {
      console.log("fetching creator");
      gcres_cancelToken = axios.CancelToken.source();
      axios({
        method: "post",
        url: "/api/global/creator/search",
        cancelToken: gcres_cancelToken.token,
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          term: query,
          page: currentPage.toString(),
        }),
      })
        .then((response) => {
          console.log("search response", response.data.data);
          //setResults(response.data)
          setCreatorResults(response.data.data);
          setTotalCreatorResultsPages(response.data.total_pages);
          setTotalCreatorResults(response.data.total);
          setCurrentCreatorResults(currentPage);
          setLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error) || error) {
            setLoading(false);
            setMessage("Failed to fetch results.Please check network");
          }
          // removeUserSession();
        });
    } else {
      setCreatorResults([]);
      setTotalCreatorResultsPages(0);
      setTotalCreatorResults(0);
      setCurrentCreatorResults(1);
    }
  };
  const fetchSearchResults = (query) => {
    console.log("adsfsfdsaf");
    fetchContentResults(currentContentResults);
    fetchGenreResults(currentGenreResults);
    fetchCategoryResults(currentCategoryResults);
    fetchCreatorResults(currentCreatorResults);
  };

  useEffect(() => {
    //if(results.data){
    //console.log(results)
    //setContentResults(results.data.quizzes)
    //(results.data.genres)
    //setCategoryResults(results.data.categories)
    //setCreatorResults(results.data.creators)
    //refreshContentOnChecked()
    //}
    fetchSearchResults(query);
  }, [checkedParams]);

  useEffect(() => {
    console.log("changed");
    //console.log(contentResults);
    //console.log(genreResults);
    //console.log(categoryResults);
    //console.log(creatorResults);
  }, [contentResults, genreResults, categoryResults, creatorResults]);

  const handleSearchChange = (event) => {
    const query = event.target.value;

    console.log("query=" + query);

    //Content Reset.
    setTotalContentResultsPages(0);
    setTotalContentResults(0);
    setCurrentContentResults(1);

    //Genre reset
    setTotalGenreResultsPages(0);
    setTotalGenreResults(0);
    setCurrentGenreResults(1);

    //Category
    setTotalCategoryResultsPages(0);
    setTotalCategoryResults(0);
    setCurrentCategoryResults(1);

    //CreatorResults
    setTotalCreatorResultsPages(0);
    setTotalCreatorResults(0);
    setCurrentCreatorResults(1);

    if (!query) {
      console.log(query);
      setResults({});

      setContentResults([]);
      setGenreResults([]);
      setCategoryResults([]);
      setCreatorResults([]);
      setQuery(query);
      setMessage("");
    } else {
      console.log("query", query);
      setQuery(query);
      setLoading(true);
      setMessage("");
    }
  };

  useEffect(() => {
    if (loading == true && query) {
      console.log("calling fetchSearchResults");
      fetchSearchResults(query);
    }
  }, [loading, query]);

  const genreSelected = (id) => {
    props.history.push("/genredetails/" + id);
  };
  const categorySelected = (category) => {
    if (category.parent_cat_id) {
      props.history.push(
        "/genredetails/" +
          category.parent_genre_id +
          "/" +
          category.id +
          "/" +
          category.parent_cat_id
      );
    } else {
      props.history.push(
        "/genredetails/" + category.parent_genre_id + "/" + category.id
      );
    }
  };

  const profileSelected = (profileId) => {
    props.history.push("/creatorprofile/" + profileId);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
         /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("search_here")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("search_here")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      <section class="gener_gap">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 filter_layout">
              <h3>
                <i class="fa fa-filter"></i> {t("refine_your_search")}
              </h3>

              <div
                class="accordion md-accordion"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div class="card">
                  <div class="card-header" role="tab" id="headingOne1">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      <h5 class="mb-0">
                        {t("filter")}
                        <i class="fa fa-minus rotate-icon"></i>
                      </h5>
                    </a>
                  </div>
                  <div
                    id="collapseOne1"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div class="card-body">
                      <div class="scroll-tab">
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" column>
                            <FormControlLabel
                              id="contentParamId"
                              value="Content"
                              control={<Checkbox color="primary" />}
                              label={t("contents")}
                              labelPlacement="start"
                              checked={checkedParams.includes(1) ? true : false}
                              onChange={(e) => searchParamsChange(e, 1)}
                            />
                            <FormControlLabel
                              id="genreParamId"
                              value="Genre"
                              control={<Checkbox color="primary" />}
                              label={t("genere")}
                              labelPlacement="start"
                              checked={checkedParams.includes(2) ? true : false}
                              onChange={(e) => searchParamsChange(e, 2)}
                            />
                            <FormControlLabel
                              id="categoryParamId"
                              value="Category"
                              control={<Checkbox color="primary" />}
                              label={t("category")}
                              labelPlacement="start"
                              checked={checkedParams.includes(3) ? true : false}
                              onChange={(e) => searchParamsChange(e, 3)}
                            />

                            <FormControlLabel
                              id="creatorParamId"
                              value="Creators"
                              control={<Checkbox color="primary" />}
                              label={t("creators")}
                              labelPlacement="start"
                              checked={checkedParams.includes(4) ? true : false}
                              onChange={(e) => searchParamsChange(e, 4)}
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-md-8 col-sm-12">
              <div class="search_tab">
                <div class="search_content">
                  <i class="fa fa-search"></i>
                  <DebounceInput
                    minLength={1}
                    debounceTimeout={500}
                    onChange={(event) => handleSearchChange(event)}
                    placeholder={t("search_here")}
                  />
                  {/* 
                            <input
                              type="text"
                              value={query}
                              name="search"
                              id="search-input"
                              placeholder={t("search_here")}
                              onChange={(e) => handleSearchChange(e)}
                          />
                          */}
                </div>
              </div>
              <div class="dashboard-wrapper">
                <div class="dashboard-content searchcontent">
                  <div class="dashboard-information">
                    <section class="dashboard-section">
                      <div class="preferred-info preferred-genres animated fadeInRight">
                        {contentResults.length > 0 ? (
                          <div class="row mb-4">
                            <div class="col-8">
                              <h3>{t("contents")}</h3>
                            </div>
                          </div>
                        ) : null}

                        <div class="row row-eq-height">
                          {contentResults
                            ? contentResults.map((content) => (
                                <ContentComponent
                                  content={content}
                                  {...props}
                                />
                              ))
                            : null}
                        </div>
                      </div>
                      <div class="text-center pt-4">
                        {totalContentResults > PAGE_LIMIT ? (
                          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                            <Pagination
                              totalRecords={totalContentResults}
                              totalPages={totalContentResultsPages}
                              currentPage={currentContentResults}
                              pageLimit={PAGE_LIMIT}
                              pageNeighbours={1}
                              onPageChanged={onContentResultsPagesChanged}
                            />
                          </div>
                        ) : null}
                        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                      </div>

                      <div class="preferred-info preferred-genres animated fadeInRight">
                        {genreResults.length > 0 ? (
                          <div class="row mb-4">
                            <div class="col-8">
                              <h3>{t("genere")}</h3>
                            </div>
                          </div>
                        ) : null}
                        <div class="row row-eq-height">
                          {genreResults
                            ? genreResults.map((genre) => (
                                <div
                                  class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3"
                                  onClick={() => genreSelected(genre.id)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="preferred-boxs square"
                                  >
                                    <span>
                                      <img
                                        src={GENER_PATH + genre.image}
                                        alt="Img"
                                      />
                                    </span>
                                    <span
                                      class="in-txt"
                                      dangerouslySetInnerHTML={{
                                        __html: genre.name,
                                      }}
                                    ></span>
                                  </a>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div class="text-center pt-4">
                        {totalGenreResults > PAGE_LIMIT ? (
                          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                            <Pagination
                              totalRecords={totalGenreResults}
                              totalPages={totalGenreResultsPages}
                              currentPage={currentGenreResults}
                              pageLimit={PAGE_LIMIT}
                              pageNeighbours={1}
                              onPageChanged={onGenreResultsPageChanged}
                            />
                          </div>
                        ) : null}
                        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                      </div>
                      <div class="preferred-info preferred-genres animated fadeInRight">
                        {categoryResults.length > 0 ? (
                          <div class="row mb-4">
                            <div class="col-8">
                              <h3>{t("category")}</h3>
                            </div>
                          </div>
                        ) : null}
                        <div class="row">
                          {categoryResults
                            ? categoryResults.map((category) => (
                                <div
                                  class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3"
                                  onClick={() => categorySelected(category)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="preferred-boxs circle"
                                  >
                                    <img
                                      src={CATEGORY_PATH + category.image}
                                      alt="Img"
                                    />
                                  </a>
                                  <div
                                    class="in-txt"
                                    dangerouslySetInnerHTML={{
                                      __html: category.name,
                                    }}
                                  ></div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div class="text-center pt-4">
                        {totalCategoryResults > PAGE_LIMIT ? (
                          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                            <Pagination
                              totalRecords={totalCategoryResults}
                              totalPages={totalCategoryResultsPages}
                              currentPage={currentCategoryResults}
                              pageLimit={PAGE_LIMIT}
                              pageNeighbours={1}
                              onPageChanged={onCategoryResultsPageChanged}
                            />
                          </div>
                        ) : null}
                        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                      </div>
                      <div class="preferred-info preferred-genres animated fadeInRight">
                        {creatorResults.length > 0 ? (
                          <div class="row mb-4">
                            <div class="col-8">
                              <h3>{t("creators")}</h3>
                            </div>
                          </div>
                        ) : null}
                        <div class="row">
                          {creatorResults
                            ? creatorResults.map((creator) => (
                                <div
                                  class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3"
                                  onClick={() => profileSelected(creator.id)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="preferred-boxs circle"
                                  >
                                    <img
                                      src={PROFILE_PIC + creator.profile_pic}
                                      alt="Img"
                                    />
                                  </a>
                                  <div class="in-txt">{creator.name}</div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div class="text-center pt-4">
                        {totalCreatorResults > PAGE_LIMIT ? (
                          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                            <Pagination
                              totalRecords={totalCreatorResults}
                              totalPages={totalCreatorResultsPages}
                              currentPage={currentCreatorResults}
                              pageLimit={PAGE_LIMIT}
                              pageNeighbours={1}
                              onPageChanged={onCreatorResultsPageChanged}
                            />
                          </div>
                        ) : null}
                        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default SearchPage;
