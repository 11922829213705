/* eslint-disable */

import qs from "qs";
import React, { useEffect, useRef, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import expImg from "../images/quiz-creation/Explaination.png";
import hintImg from "../images/quiz-creation/idea.png";

import axios from "axios";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/quiz-creation.css";
import AnswerInputOptions from "./AnswerInputOptions";
import AnswerOptions from "./AnswerOptions";

import { useTranslation } from "react-i18next";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import useCustomQuill from "../Hooks/useCustomQuill";
import "../less/quill-editor.css";
import ReactQuillComponent from "./ReactQuillComponent";
//Tiny MCE 5
// import TinyMCE5Component from './TinyMCE5Component'

import { useCookies } from "react-cookie";
import LoadingOverlay from "react-loading-overlay";

function AddQuestions(props) {
  // console.log("props in add questions", props)
  const refs = useRef([]);
  // const refTinyMCE5QuestionComponents = useRef([])
  // const refTinyMCE5HintComponents = useRef([])
  // const refTinyMCE5ExplainComponents = useRef([])

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"];
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  //let defaultLanguage = props.location.state.primaryLanguage
  //let _langs = [];
  //_langs.push(defaultLanguage)
  //_langs = _langs.concat(props.location.state.availableLanguages)
  //console.log("addquestion", props)
  //console.log("languages",_langs)

  const quiz_deck_id = props.match.params.quizdeckId;
  //const defaultLangId = props.location.state.availableLanguages[0].id //defaultLanguage.id;
  //const defaultLangCode = defaultLanguage.code

  const [defaultLangId, setDefaultLangId] = useState(null);
  const [availableLanguages, setAvailableLangauges] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState({}); //useState(props.location.state.availableLanguages[0])
  const [languages, setLanguages] = useState([]);
  const [isPublished, setIsPublished] = useState(0);
  //const isPublished = props.location.state.isPublished;
  //console.log('isPublished',isPublished)
  const [mediaRightsChecked, setMediaRightsChecked] = useState(false);

  const [question, setQuestion] = useState();
  const [hint, setHint] = useState();
  //const [questionfile, setQuestionFile] = useState('');
  const [explanation, setExplanation] = useState();
  const [questionMode, setQuestionMode] = useState(1);
  const [numberOfOptions, setNumberOfOptions] = useState([1]);
  const [latestOption, setLatestOption] = useState(0);
  const [questionCreated, setQuestionCreated] = useState(false);
  const [questionCreating, setQuestionCreating] = useState(0);
  const [answerCreated, setAnswerCreated] = useState(false);
  const [questionUploaded, setQuestionUploaded] = useState(false);
  const [answerUploaded, setAnswerUploaded] = useState(false);
  const [answerMode, setAnswerMode] = useState(2);
  const [saving, setSaving] = useState(false);

  const [questionId, setQuestionId] = useState("");

  let [questionTexts, setQuestionTexts] = useState({});
  let [quizDeckDetails, setQuizDeckDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  let [validLanguagesError, setValidLanguagesError] = useState("");

  const editor = useRef(null);

  const [fetchingFinished, setFetchingFinished] = useState(false);
  let [allQuillRefs] = useCustomQuill(t("type_here"), [
    languages,
    fetchingFinished,
  ]);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const config = {
    bucketName: "memoriz",
    dirName: "uploads/questions" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    //
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  // const ReactS3Client = new S3(config);

  const showMessageToUser = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [questionFiles, setQuestionFiles] = useState([]);
  const onFileUploadChange = (event) => {
    let tempQuestionFiles = [];
    if (questionFiles && questionFiles.length > 0) {
      // console.log('questionFiles.length=', questionFiles.length)
      //Does it exists languageId
      for (let i = 0; i < languages.length; i++) {
        //console.log('element', element)
        if (languages[i].id == currentLanguage.id) {
          // console.log('currentLanguage.id=' + currentLanguage.id)
          // console.log('file=', event.target.files[0])
          tempQuestionFiles.push({
            languageId: currentLanguage.id,
            file: event.target.files[0],
          });
        } else {
          // console.log('copying existing answers edited')
          if (questionFiles.length > 0) {
            for (let j = 0; j < questionFiles.length; j++) {
              if (languages[i].id == questionFiles[j].languageId) {
                console.log("har har");
                tempQuestionFiles.push(questionFiles[j]);
              }
            }
          }
        }
      }
    } else {
      tempQuestionFiles.push({
        languageId: currentLanguage.id,
        file: event.target.files[0],
      });
    }
    // console.log('tempQuestionFiles.length=' + tempQuestionFiles.length)
    setQuestionFiles(tempQuestionFiles);

    if (questionMode == 2) {
      var image = document.getElementById(
        "questionImagePreview_" + currentLanguage.id + "_" + questionId
      );
      image.src = URL.createObjectURL(event.target.files[0]);
    }
  };

  /*    useEffect(()=>{
        let elems = document.getElementsByClassName('ql-editor')
        for (let i=0; i< elems.length; i++) {
            elems[i].classList.add('ql-size-36px') 
            elems[i].classList.add('ql-font-arial')  
        }
    },[]) */

  useEffect(() => {
    console.log("In useEffect");
    setLoading(true);
    axios({
      method: "post",
      url: "/api/quiz_deck/details",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
      data: {
        quiz_deck_id: quiz_deck_id,
      },
    })
      .then((response) => {
        console.log("details of this quiz deck are", response.data);
        setQuizDeckDetails(response.data.details);

        setLoading(false);
        //setMemorizingLoading(false)
      })
      .catch((error) => {
        console.log("===error", error);
        let validLangs = error.response.data[0].validlangs;
        let errorStirng = error.response.data[0].msg;
        let strValid = "";
        if (validLangs) {
          for (let i = 0; i < validLangs.length; i++) {
            if (strValid == "") {
              strValid += validLangs[i].name;
            } else {
              strValid = strValid + " or " + validLangs[i].name;
            }
          }
        }
        if (strValid) {
          errorStirng += ". " + "Change the global language to " + strValid;
          setValidLanguagesError(errorStirng);
        }
        showErrorMessageToUser(errorStirng);
        setLoading(false);
        //setMemorizingLoading(false)
      });
  }, [globalLangCode]);

  useEffect(() => {
    if (!languages.length) return;
    console.log("xxx-langs", languages);
  }, [languages]);

  useEffect(() => {
    if (!quizDeckDetails) return;
    setDefaultLangId(quizDeckDetails.language_id);
    setAvailableLangauges(quizDeckDetails.languages);
    setLanguages(quizDeckDetails.languages);
    setIsPublished(quizDeckDetails.is_published);
    console.log(
      "quizDeckDetails.languages.length",
      quizDeckDetails.languages.length
    );
    for (let i = 0; i < quizDeckDetails.languages.length; i++) {
      console.log("quizDeckDetails.language_id", quizDeckDetails.language_id);
      console.log(
        "quizDeckDetails.languages[i].id",
        quizDeckDetails.languages[i].id
      );
      if (quizDeckDetails.languages[i].id == quizDeckDetails.language_id) {
        setCurrentLanguage(quizDeckDetails.languages[i]);
        console.log("xxx-CurrentLanguage", quizDeckDetails.languages[i]);
      }
    }
    setFetchingFinished(true);
  }, [quizDeckDetails]);

  const changeLanguage = (language) => {
    console.log("this is current lang", language);

    setCurrentLanguage(language);
  };

  // Locking scroll while saving
  useEffect(() => {
    if (saving) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!saving) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [saving]);
  /*
    const getTinyMCE5QuestionTitle = (language) => {
        for(let i=0; i< refTinyMCE5QuestionComponents.current.length; i++) {
            if(refTinyMCE5QuestionComponents.current[i].id == language.id) {
                return refTinyMCE5QuestionComponents.current[i].tmce.getContent()
            }
        }
    }
    const getTinyMCE5QuestionHint = (language) => {
        for(let i=0; i< refTinyMCE5HintComponents.current.length; i++) {
            if(refTinyMCE5HintComponents.current[i].id == language.id) {
                return refTinyMCE5HintComponents.current[i].tmce.getContent()
            }
        }
    }
    const getTinyMCE5QuestionExplain = (language) => {
        for(let i=0; i< refTinyMCE5ExplainComponents.current.length; i++) {
            if(refTinyMCE5ExplainComponents.current[i].id == language.id) {
                return refTinyMCE5ExplainComponents.current[i].tmce.getContent()
            }
        }
    }
    */
  const addQuestionAndAnswer = async () => {
    // console.log('questionCreating', questionCreating)
    if (questionCreating <= 0) {
      setSaving(true);

      const mediaRightsGiven = await isMediaRightsGiven();
      const isOptionGiven = await correctOptions();
      const areAllOptionsUploaded = await uploadOptionsCheck();

      let questionDataSet = [];

      for (let i = 0; i < languages.length; i++) {
        let tempQuestionDataset = {};
        tempQuestionDataset.is_default = 0;
        //tempQuestionDataset.title = questionTexts[languages[i].code]
        // tempQuestionDataset.title = document.getElementById("questionText_" + languages[i].code).value;
        // console.log($("#questionText_" + languages[i].code + 'div.ql-editor'))
        // console.log($("#questionText_" + languages[i].code + 'div.ql-editor').text())
        tempQuestionDataset.title = document.querySelector(
          "#editor" + languages[i].id + " .ql-editor"
        ).innerHTML;
        //tempQuestionDataset.title = allQuillRefs[languages[i].id].quill.root.innerHTML
        //tempQuestionDataset.title = getTinyMCE5QuestionTitle(languages[i])
        console.log("tempQuestionDataset.title", tempQuestionDataset.title);

        /*
                let bugfix = tempQuestionDataset.title
                
                if(!bugfix.includes('<span')) {
                    let index = bugfix.indexOf('<p>')
                    console.log('index', index)
                    if(index > -1) {
                        let indexEnd = bugfix.lastIndexOf('</p>')
                        let data = bugfix.substring(index + '<p>'.length,indexEnd)
                        console.log('data',data )
                        let newBugFreeString = `<p><span class="ql-size-36px">` + data +  `</span></p>`
                        tempQuestionDataset.title = newBugFreeString
                        console.log('tempQuestionDataset.title',newBugFreeString )
                        //<span class="ql-size-36px"></span>
                    }     
                } else if(bugfix.includes('<span')) {
                    let index = bugfix.indexOf('<p>')
                    console.log('index', index)
                    if(index > -1) {
                        let indexSpanStart = bugfix.indexOf('<span')
                        let indexEndP = bugfix.lastIndexOf('</p>')
                        if(indexEndP > indexSpanStart) {
                            console.log('indexSpanStart',indexSpanStart)
                            console.log('indexEndP',indexEndP)
                            let data = bugfix.substring(index + + '<p>'.length, indexSpanStart)
                            let newBugFreeString = bugfix
                            if(data.length > 0) {
                                newBugFreeString = `<p><span class="ql-size-36px ql-font-arial">` +data +  `</span>` + bugfix.substring(indexSpanStart,bugfix.length)
                            }
                            console.log('newBugFreeString',newBugFreeString)
                            tempQuestionDataset.title = newBugFreeString
                        }
                    }
                }
                */

        if (!tempQuestionDataset.title) {
          // console.log('content name blank')
          // setSaving(false)
          // showErrorMessageToUser(t('question_name_required'))
          // return
          continue;
        }
        tempQuestionDataset.hint = document.getElementById(
          "questionHint_" + languages[i].code
        ).value;
        //tempQuestionDataset.hint = getTinyMCE5QuestionHint(languages[i])
        tempQuestionDataset.explanation = document.getElementById(
          "questionExp_" + languages[i].code
        ).value;
        //tempQuestionDataset.explanation = getTinyMCE5QuestionExplain(languages[i])
        console.log("explanation", tempQuestionDataset.explanation);

        tempQuestionDataset.language_id = languages[i].id;
        if (defaultLangId == languages[i].id) {
          tempQuestionDataset.is_default = 1;
        }

        questionDataSet.push(tempQuestionDataset);
      }

      if (
        ((numberOfOptions.length >= 2 && answerMode !== 1) ||
          (numberOfOptions.length == 1 && answerMode === 1)) &&
        isOptionGiven == true &&
        areAllOptionsUploaded == true &&
        questionDataSet.length === languages.length &&
        mediaRightsGiven === true
      ) {
        document.getElementById("errorMessage").style.display = "none";
        document.getElementById("errorMessageAllLanguages").style.display =
          "none";
        //setQuestionCreating(1);

        console.log("before create quest");
        let ques_Id = await createQuestion();
        console.log("ques_id", ques_Id);
        if (ques_Id == null) {
          return;
        }

        let fileUploadPromises = [];

        const afterAnswerUploads = async () => {
          // console.log('afterAnswerUploads ')
          // console.log('ques_Id', ques_Id)

          for (let i = 0; i < languages.length; i++) {
            if (questionFiles.length > 0 && questionMode != 1) {
              for (let j = 0; j < questionFiles.length; j++) {
                if (questionFiles[j].languageId == languages[i].id) {
                  console.log("questionFiles[j].file", questionFiles[j].file);
                  const fileExtension =
                    questionFiles[j].file.name.split(".")[
                      questionFiles[j].file.name.split(".").length - 1
                    ];
                  questionDataSet[i].files = [
                    {
                      file:
                        "question_" +
                        languages[i].code +
                        "_" +
                        ques_Id +
                        "." +
                        fileExtension,
                    },
                  ];
                }
              }
            }
          }

          const resultOptionsArray = await checkOptions(ques_Id);

          console.log("resultOptionsArray", resultOptionsArray);
          console.log("numberOfOptions", numberOfOptions);

          // Question Language Update API CALL
          console.log(questionDataSet, "question data");

          await axios({
            method: "post",
            url: "/api/questions/update/language",
            headers: {
              locale: globalLangCode,
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              quiz_deck_id: quiz_deck_id,
              topic_id: 0,
              question_id: ques_Id,
              question_mode: questionMode,
              dataset: questionDataSet,
            }),
          })
            .then((response) => {
              setQuestionCreated(true);
              // console.log("quiz created response", JSON.stringify(response.data))
              // setUserSession(token, response.data.user);
              showMessageToUser(response.data.msg);
              setSaving(false);
            })
            .catch((error) => {
              setSaving(false);
              // removeUserSession();
              // console.log("error", error)
            });

          //Answer Create API CALL
          await axios({
            method: "post",
            url: "/api/answers/create",
            headers: {
              locale: globalLangCode,
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              quiz_deck_id: quiz_deck_id,
              answer_mode: answerMode,
              question_id: ques_Id,
              option_mode: answerMode,
              options: resultOptionsArray,
            }),
          })
            .then((response) => {
              // console.log("quiz created response", JSON.stringify(response.data))
              // setUserSession(token, response.data.user);
              showMessageToUser(response.data.msg);
              setAnswerCreated(true);
            })
            .catch((error) => {
              setSaving(false);
              // removeUserSession();
              // console.log("error", error)
            });
        };

        const afterQuestionFileUploads = async () => {
          console.log("afterQuestionFileUploads answerMode", answerMode);
          if (answerMode > 2) {
            //Input, text omited
            console.log("calling promise validate on answers");
            Promise.all(
              refs.current.map(async (child, index) => {
                console.log("going to upload");
                // console.log(ques_Id, index)

                await child.validate(ques_Id, index);
              })
            )
              .then(async (data) => {
                setAnswerUploaded(true);
                await afterAnswerUploads();
              })
              .catch((error) => {
                setSaving(false);
                console.log("error in answer validate", error);
              });
          } else {
            // console.log("ONLYYYYYYYYYYYYYYYYYY INPUTTTTTTTTTTT")
            setAnswerUploaded(true);
            await afterAnswerUploads();
          }
        };

        // console.log('questionFiles.length', questionFiles.length)
        // console.log('questionMode', questionMode)
        if (questionFiles.length > 0 && questionMode !== 1) {
          // console.log('questionFiles', questionFiles)
          for (let i = 0; i < languages.length; i++) {
            for (let j = 0; j < questionFiles.length; j++) {
              if (languages[i].id == questionFiles[j].languageId) {
                const newFileName =
                  "question_" + languages[i].code + "_" + ques_Id;
                // console.log('newFileName', newFileName)

                const fd = new FormData();
                fd.append("file", questionFiles[j].file);
                fd.append("filename", newFileName);
                // console.log('questionFile', questionFiles[j].file)
                // For Media Type
                // For Media Type
                if (questionMode == 2) {
                  var media_type = 1; // Image File
                } else if (questionMode == 3) {
                  var media_type = 2; // Audio File
                } else if (questionMode == 4) {
                  var media_type = 3; // Video File
                } else {
                  var media_type = 1; // Text
                }

                fileUploadPromises.push(
                  axios({
                    method: "post",
                    url:
                      "/api/questions/upload/image?filename=" +
                      newFileName +
                      "&media_type=" +
                      media_type,
                    headers: {
                      locale: "en",
                      "x-access-token": "Memo " + token,
                      "Content-Type": "multipart/form-data",
                      //'enctype': "multipart/form-data"
                    },
                    data: fd,
                  })
                );
              }
            }
          }

          Promise.all(fileUploadPromises)
            .then(async () => {
              console.log("Questions uploaded successfully");
              setQuestionUploaded(true);
              await afterQuestionFileUploads();
            })
            .catch((error) => {
              console.log("error", error.response.data[0].msg);
              setQuestionCreating(0);
              showErrorMessageToUser(error.response.data[0].msg);
              setSaving(false);
            });
        } else {
          setQuestionUploaded(true);
          await afterQuestionFileUploads();
        }
      } else if (questionDataSet.length !== languages.length) {
        setSaving(false);
        document.getElementById("errorMessageAllLanguages").style.display =
          "block";
      } else if (answerMode === 1) {
        setSaving(false);
        document.getElementById("errorMessageNoInput").style.display = "block";
        document.getElementById("errorMessage").style.display = "none";
      } else {
        setSaving(false);
        document.getElementById("errorMessage").style.display = "block";
        document.getElementById("errorMessageNoInput").style.display = "none";
      }
    }
  };

  // useEffect(() => {
  //     setQuestionUploaded(true)
  // }, [questionCreated])

  useEffect(() => {
    // console.log('consoled here to check data')
    // console.log('questionCreated=', questionCreated)
    // console.log('answerCreated=', answerCreated)
    // console.log('questionUploaded=', questionUploaded)
    // console.log('answerUploaded=', answerUploaded)
    // console.log('isPublished=', isPublished)
    // if (questionCreated == true && answerCreated == true && questionUploaded == true && answerUploaded == true) {
    //     props.history.push({
    //         pathname: "/questionList/" + props.location.state.quiz_deck_id,
    //         state: {
    //             ...props.location.state,
    //         }
    //     })

    // }
    if (
      questionCreated == true &&
      answerCreated == true &&
      questionUploaded == true &&
      answerUploaded == true &&
      isPublished == 1
    ) {
      props.history.push({
        pathname: "/contentsedit/" + quiz_deck_id,
        state: {
          ...props.location.state,
        },
      });
    }
    if (
      questionCreated == true &&
      answerCreated == true &&
      questionUploaded == true &&
      answerUploaded == true &&
      isPublished == 0
    ) {
      //props.history.push('/mycontentsunpublished');

      props.history.push({
        pathname: "/contentseditunpublished/" + quiz_deck_id,
        state: {
          ...props.location.state,
          primaryLanguageId: defaultLangId,
        },
      });

      //props.history.push('/contentseditunpublished/'+quiz_deck_id);
    }
    if (
      questionCreated == true &&
      answerCreated == true &&
      questionUploaded == true &&
      answerUploaded == true &&
      [null, undefined].includes(isPublished)
    ) {
      //props.history.push('/mycontentsunpublished');

      props.history.push({
        pathname: "/questionList/" + quiz_deck_id,
        state: {
          ...props.location.state,
          primaryLanguageId: defaultLangId,
        },
      });
      //props.history.push('/contentseditunpublished/'+quiz_deck_id);
    }
  }, [questionCreated, answerCreated, questionUploaded, answerUploaded]);

  const makeOptions = () => {
    //    setLatestOption(latestOption+1);
    setNumberOfOptions((numberOfOptions) => {
      console.log("numberOfOptions", numberOfOptions);
      return numberOfOptions.concat(latestOption + 1);
    });
  };

  const createQuestion = () => {
    if (questionId) {
      return questionId;
    }

    //let default_lang_question_name = questionTexts[defaultLangCode]

    let q_id;
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/questions/create",
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          quiz_deck_id: quiz_deck_id,
          question_mode: questionMode,
          question_name: "Add Sample  -- This is default", //default_lang_question_name,
          language_id: defaultLangId,
        }),
      })
        .then((response) => {
          // console.log("quiz created response", response.data.id)
          showMessageToUser(response.data.msg);
          q_id = response.data.id;
          setQuestionId(q_id);
          resolve(q_id);
          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
          console.log("error", error.response);
          setSaving(false);
          showErrorMessageToUser(error.response.data[0].msg);
          q_id = null;
          resolve(q_id);
        });
    });
  };
  useEffect(() => {
    if (validLanguagesError) {
      document.getElementById("errorMessageQuizLanguges").innerHTML =
        validLanguagesError;
      document.getElementById("errorMessageQuizLanguges").style.display =
        "block";
    } else {
      document.getElementById("errorMessageQuizLanguges").style.display =
        "none";
    }
  }, [validLanguagesError]);

  useEffect(() => {
    console.log("questionId=", questionId);
  }, [questionId]);

  useEffect(() => {
    console.log("numberOfOptions=", numberOfOptions);
    setLatestOption(latestOption + 1);
  }, [numberOfOptions]);

  useEffect(() => {
    console.log("answerMode=" + answerMode);
    if (answerMode == 2) {
      numberOfOptions.map((optionNo) => {
        for (let i = 0; i < languages.length; i++) {
          document.getElementById(
            "uploadButton_" + languages[i].id + "_" + optionNo
          ).style.display = "none";
          document.getElementById(
            "answerText_" + languages[i].id + "_" + optionNo
          ).style.display = "block";
          document.getElementById(
            "answerImagePreview_" + languages[i].id + "_" + optionNo
          ).style.display = "none";
        }
      });
    } else if (answerMode === 3) {
      numberOfOptions.map((optionNo) => {
        for (let i = 0; i < languages.length; i++) {
          document.getElementById(
            "answerText_" + languages[i].id + "_" + optionNo
          ).style.display = "none";
          if (currentLanguage.id == languages[i].id) {
            document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            ).style.display = "block";
            document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            ).style.display = "block";
          } else {
            document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            ).style.display = "none";
            document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            ).style.display = "none";
          }
        }
      });
    } else if (answerMode === 1) {
      for (let i = 0; i < languages.length; i++) {
        // console.log(numberOfOptions)
        // console.log(document.getElementById("answerInput_" + languages[i].id + "_" + 1))
        document.getElementById(
          "answerInput_" + languages[i].id + "_" + 1
        ).style.display = "block";
      }
    } else {
      numberOfOptions.map((optionNo) => {
        //document.getElementById("uploadButton"+optionNo).style.display = "block"
        for (let i = 0; i < languages.length; i++) {
          if (currentLanguage.id == languages[i].id) {
            document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            ).style.display = "block";
          } else {
            document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            ).style.display = "none";
          }
          document.getElementById(
            "answerText_" + languages[i].id + "_" + optionNo
          ).style.display = "none";
          document.getElementById(
            "answerImagePreview_" + languages[i].id + "_" + optionNo
          ).style.display = "none";
        }
      });
    }
  }, [currentLanguage, answerMode, numberOfOptions]);
  /*
    useEffect(()=>{
        if (answerMode == 2){
            numberOfOptions.map(optionNo => {
                document.getElementById("uploadButton"+optionNo).style.display = "none"
                for(let i=0; i<languages.length; i++){
                
                        document.getElementById("answerText_"+languages[i].id+"_"+optionNo).style.display = "block"
                        document.getElementById("answerImagePreview"+optionNo).style.display = "none"
                    
                }
            })
        } else if (answerMode === 3){
            numberOfOptions.map(optionNo => {
                document.getElementById("uploadButton"+optionNo).style.display = "block"
                for(let i=0; i<languages.length; i++){
                    document.getElementById("answerText_"+languages[i].id+"_"+optionNo).style.display = "none"
                    document.getElementById("answerImagePreview"+optionNo).style.display = "block"
                }
                
            })
        } else if (answerMode === 1){
           
                
                for(let i=0; i<languages.length; i++){
                    console.log(numberOfOptions)
                    console.log(document.getElementById("answerInput_"+languages[i].id+"_"+1))
                    document.getElementById("answerInput_"+languages[i].id+"_"+1).style.display = "block"
                }
           
            
        } else {
            numberOfOptions.map(optionNo => {
                document.getElementById("uploadButton"+optionNo).style.display = "block"
                for(let i=0; i<languages.length; i++){
                    document.getElementById("answerText_"+languages[i].id+"_"+optionNo).style.display = "none"
                    document.getElementById("answerImagePreview"+optionNo).style.display = "none"
                }
                
            })
        }
    }, [answerMode, numberOfOptions])
    */
  useEffect(() => {
    if (answerMode === 1) {
      setNumberOfOptions([1]);
      setLatestOption(0);
    }
  }, [answerMode]);

  useEffect(() => {
    if (questionMode == 1) {
      //Text
      for (let i = 0; i < languages.length; i++) {
        document.getElementById(
          "questionUploadButton_" + languages[i].id + "_" + questionId
        ).style.display = "none";
        document.getElementById(
          "questionImagePreview_" + languages[i].id + "_" + questionId
        ).style.display = "none";
        // document.getElementById("questionVideoAudioPreview").style.display = "none"
      }
    } else if (questionMode == 2) {
      //Picture
      for (let i = 0; i < languages.length; i++) {
        if (currentLanguage.id == languages[i].id) {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "block";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "block";
          // document.getElementById("questionVideoAudioPreview").style.display = "none"
        } else {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          // document.getElementById("questionVideoAudioPreview").style.display = "none"
        }
      }
    } else {
      //Audio, Video
      for (let i = 0; i < languages.length; i++) {
        if (currentLanguage.id == languages[i].id) {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "block";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          // document.getElementById("questionVideoAudioPreview").style.display = "block"
        } else {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          // document.getElementById("questionVideoAudioPreview").style.display = "none"
        }
      }
    }
  }, [questionMode, currentLanguage]);

  const checkOptions = (ques_Id) => {
    let tempOptionsArray = [];
    // console.log('checkOptions numberOfOptions', numberOfOptions)

    numberOfOptions.map((options) => {
      let tempOptionsObject = {};
      tempOptionsObject.language_id = languages[0].id;
      tempOptionsObject.language_code = languages[0].code;
      if (answerMode === 3 || answerMode === 4 || answerMode === 5) {
        let answerFileName = document.getElementById(
          "uploadButton_" + languages[0].id + "_" + options
        ).value;
        let answerextension = answerFileName.split(".").pop();

        tempOptionsObject.file =
          "answer_" +
          languages[0].code +
          "_" +
          (options - 1) +
          "_" +
          ques_Id +
          "." +
          answerextension;
      } else if (answerMode === 2) {
        tempOptionsObject.text = document.getElementById(
          "answerText_" + languages[0].id + "_" + options
        ).value;
      } else {
        tempOptionsObject.text = document.getElementById(
          "answerInput_" + languages[0].id + "_" + options
        ).value;
      }
      if (document.getElementById("option" + options).checked == true) {
        tempOptionsObject.is_right = 1;
      } else {
        tempOptionsObject.is_right = 0;
      }

      tempOptionsObject.other_lang = [];

      for (let i = 1; i < languages.length; i++) {
        let tempOtherLanguageObj = {};
        tempOtherLanguageObj.language_id = languages[i].id;

        if (answerMode === 3 || answerMode === 4 || answerMode === 5) {
          let answerFileName = document.getElementById(
            "uploadButton_" + languages[i].id + "_" + options
          ).value;
          let answerextension = answerFileName.split(".").pop();

          tempOtherLanguageObj.file =
            "answer_" +
            languages[i].code +
            "_" +
            (options - 1) +
            "_" +
            ques_Id +
            "." +
            answerextension;
          // console.log('tempOtherLanguageObj.file', tempOtherLanguageObj.file)
        } else if (answerMode === 2) {
          // console.log(document.getElementById("answerText_" + languages[i].id + "_" + options).value)
          tempOtherLanguageObj.text = document.getElementById(
            "answerText_" + languages[i].id + "_" + options
          ).value;
        } else {
          // console.log(document.getElementById("answerInput_" + languages[i].id + "_" + options).value)
          tempOtherLanguageObj.text = document.getElementById(
            "answerInput_" + languages[i].id + "_" + options
          ).value;
        }
        if (document.getElementById("option" + options).checked == true) {
          tempOtherLanguageObj.is_right = 1;
        } else {
          tempOtherLanguageObj.is_right = 0;
        }
        tempOptionsObject.other_lang.push(tempOtherLanguageObj);
        // console.log('tempOptionsObject', tempOptionsObject)
      }

      tempOptionsArray.push(tempOptionsObject);
    });
    let sortedTempOptionsArray = [];
    for (let i = 0; i < tempOptionsArray.length; i++) {
      //NOTE: this is for image, autio, video files.
      //      can be changed for text, input also.
      if (answerMode === 3 || answerMode === 4 || answerMode === 5) {
        // console.log('tempOptionsArray[i].file=' + tempOptionsArray[i].file)
        let findPrefix =
          ("answer_" + tempOptionsArray[i].language_code + "_").length - 1;
        let findIndexDigits = tempOptionsArray[i].file.lastIndexOf("_") - 1;
        // console.log('findPrefix=' + findPrefix + " " + 'findIndexDigits=' + findIndexDigits)
        // console.log('Index' + tempOptionsArray[i].file.substr(findPrefix + 1, findIndexDigits - findPrefix))
        let posOfIndex = parseInt(
          tempOptionsArray[i].file.substr(
            findPrefix + 1,
            findIndexDigits - findPrefix
          ),
          10
        );
        sortedTempOptionsArray[posOfIndex] = tempOptionsArray[i];
      }
    }
    if (sortedTempOptionsArray.length > 0) {
      tempOptionsArray = sortedTempOptionsArray;
    }
    /*
        numberOfOptions.map(options => {
            if ((document.getElementById("option" + options).checked) == true){
                console.log("option" + options)

            }  
        })
        */

    return tempOptionsArray;
  };
  /*
    const checkOptions = () => {
        let tempOptionsArray = []
        console.log(numberOfOptions)

        
            numberOfOptions.map(options => {
                let tempOptionsObject = {}
                tempOptionsObject.language_id = languages[0].id
                if(answerMode === 3 || answerMode === 4 || answerMode === 5){
                    let answerFileName = (document.getElementById("uploadButton"+(options)).value)
                    let answerextension = answerFileName.split('.').pop(); 
                    if(answerextension === 'jpg'){
                        answerextension = 'jpeg'
                    } else if (answerextension === 'mp3'){
                        answerextension = 'mpeg'
                    }else if (answerextension === 'jfif'){
                        answerextension = 'jpeg'
                    }
                    tempOptionsObject.file = "answer_" + languages[0].code+"_" + (options-1)+"_"+ questionId+"."+answerextension
                }else if(answerMode === 2){
                    tempOptionsObject.text = document.getElementById("answerText_"+ languages[0].id+"_"+ options).value
                }else{
                    tempOptionsObject.text = document.getElementById("answerInput_"+ languages[0].id+"_"+ options).value
                }
                if ((document.getElementById("option" + options).checked) == true){
                    tempOptionsObject.is_right = 1
                } else {
                    tempOptionsObject.is_right = 0
                }

                tempOptionsObject.other_lang = []

                for(let i=1; i<languages.length; i++){
                    let tempOtherLanguageObj = {}
                    tempOtherLanguageObj.language_id = languages[i].id
                    if(answerMode === 3 || answerMode === 4 || answerMode === 5){
                        let answerFileName = (document.getElementById("uploadButton"+(options)).value)
                        let answerextension = answerFileName.split('.').pop(); 
                        if(answerextension === 'jpg'){
                            answerextension = 'jpeg'
                        } else if (answerextension === 'mp3'){
                            answerextension = 'mpeg'
                        }else if (answerextension === 'jfif'){
                            answerextension = 'jpeg'
                        }
                        tempOtherLanguageObj.file = "answer_" + languages[i].code+"_" + (options-1)+"_"+ questionId+"."+answerextension
                    }else if(answerMode === 2){
                        console.log(document.getElementById("answerText_"+ languages[i].id+"_"+ options).value)
                        tempOtherLanguageObj.text = document.getElementById("answerText_"+ languages[i].id+"_"+ options).value
                    }else{
                        console.log(document.getElementById("answerInput_"+ languages[i].id+"_"+ options).value)
                        tempOtherLanguageObj.text = document.getElementById("answerInput_"+ languages[i].id+"_"+ options).value
                    }
                    if ((document.getElementById("option" + options).checked) == true){
                        tempOtherLanguageObj.is_right = 1
                    } else {
                        tempOtherLanguageObj.is_right = 0
                    }
                    tempOptionsObject.other_lang.push(tempOtherLanguageObj)
                    console.log(tempOptionsObject)
                }
                
                tempOptionsArray.push(tempOptionsObject)
            })
        

        return tempOptionsArray
    }
    */
  const uploadOptionsCheck = () => {
    let value = true;
    for (let i = 0; i < languages.length; i++) {
      if (answerMode !== 1) {
        numberOfOptions.map((options) => {
          if (answerMode === 2) {
            let answertext = document.getElementById(
              "answerText_" + languages[i].id + "_" + options
            ).value;
            if (answertext && value == true) {
              document.getElementById("errorMessageNoText").style.display =
                "none";
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              value = value && true;
            } else {
              document.getElementById("errorMessageNoText").style.display =
                "block";
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              value = value && false;
            }
          } else {
            let answerFileName = document.getElementById(
              "uploadButton_" + languages[i].id + "_" + options
            ).value;
            if (answerFileName && value == true) {
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              document.getElementById("errorMessageNoText").style.display =
                "none";
              value = value && true;
            } else {
              document.getElementById("errorMessageNoUpload").style.display =
                "block";
              document.getElementById("errorMessageNoText").style.display =
                "none";
              value = value && false;
            }
          }
        });
      } else {
        let answerInput = document.getElementById(
          "answerInput_" + languages[i].id + "_" + 1
        ).value;
        if (answerInput && value == true) {
          document.getElementById("errorMessageNoText").style.display = "none";
          document.getElementById("errorMessageNoUpload").style.display =
            "none";
          value = value && true;
        } else {
          document.getElementById("errorMessageNoText").style.display = "block";
          document.getElementById("errorMessageNoUpload").style.display =
            "none";
          value = value && false;
        }
      }
    }
    return value;
  };

  const correctOptions = () => {
    let value = false;
    if (answerMode === 1) {
      if (document.getElementById("option" + 1).checked == true) {
        value = true;
      }
    } else {
      for (let i = 0; i < latestOption; i++) {
        if (document.getElementById("option" + (i + 1)).checked == true) {
          value = true;
        }
      }
    }
    return value;
  };

  const isMediaRightsGiven = () => {
    if (mediaRightsChecked === true) {
      return true;
    }
    showErrorMessageToUser(t("please_accept_media_rights"));
    return false;
  };

  const mediaRightsChanged = (e) => {
    if (e.target.checked) {
      setMediaRightsChecked(true);
    } else {
      setMediaRightsChecked(false);
    }
  };

  const handleQuestionTitle = (languageId, e) => {
    questionTexts[languageId] = e;
    setQuestionTexts(questionTexts);
  };

  return (
    <LoadingOverlay
      active={saving || loading}
      spinner
      text={saving ? "Saving..." : loading ? "Loading..." : ""}
      styles={{
        content: {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <React.Fragment>
        {/* <BasicHeader
                {...props}
            /> */}
        <div class="inner-header">
          <div class="container">
            <div class="row">
              <div class="col-sm-5 col-md-6 col-lg-6">
                <h2 class="animated fadeInLeft">{t("question_creation")}</h2>
              </div>
              <div class="col-sm-7 col-md-6 col-lg-6">
                <div class="breadcrumb-item animated fadeInRight">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <span class="icon-home1"></span>
                        {t("home")}
                      </a>
                    </li>
                    {/* <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)"><span class="icon-home1"></span> Create Content</a></li> */}
                    <li>
                      <span class="icon-next"></span>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active">
                        {t("question_creation")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="quiz-creation-wrapper">
          <div class="container">
            <div class="quiz-creation-tabs">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                  <ul class="nav nav-tabs nav-justified animated fadeInLeft">
                    {languages
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            onClick={() => changeLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              {/* <span class="icon hindi"></span>  */}
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{t(language.name)}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>

              <div class="tab-content">
                <div class="tab-pane container active" id="EnglishTab">
                  <div class="quiz-creation-content">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInLeft">
                        <h4>{t("create_question")}</h4>
                        <h6 class="createQues">{t("your_question")}</h6>
                        <div class="create-question">
                          <div class="question-info">
                            <div class="row">
                              <div class="col-10 col-sm-10">
                                <h5 class="bold">
                                  {t("please_enter_your_question")}
                                </h5>
                              </div>
                              {/* <div class="col-2 col-sm-2 count">4/10</div> */}
                            </div>
                            {languages && fetchingFinished
                              ? languages.map((language, index) => (
                                  <div
                                    class="question-textarea"
                                    style={
                                      language.id == currentLanguage.id
                                        ? null
                                        : { display: "none" }
                                    }
                                  >
                                    {/* <textarea
                                                                            style={language.id == currentLanguage.id ? null : { display: "none" }}
                                                                            placeholder={t("type_here")}
                                                                            id={"questionText_" + language.code}
                                                                        ></textarea> */}
                                    {/*
                                                                        <ReactQuill style={language.id == currentLanguage.id ? null : { display: "none" }} class="form-control"
                                                                            id={"questionText_" + language.code}
                                                                            placeholder={t("type_here")}
                                                                            modules={modules}
                                                                            formats={formats}
                                                                            onChange={e => handleQuestionTitle(language.code, e)} />
                                                                        */}

                                    <ReactQuillComponent
                                      language_id={language.id}
                                    ></ReactQuillComponent>
                                    {/*
                                                                         <TinyMCE5Component key={index}  
                                                                                            ref={instance => refTinyMCE5QuestionComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                            language_code={globalLangCode}
                                                                                            content={''}
                                                                                            placeholder={t("type_here")}/>
                                                                        */}
                                    {/*<div style={language.id == currentLanguage.id ? null : { display: "none" }}>
                                                                            <JoditEditor
                                                                                id={"questionText_" + language.code}
                                                                                ref={editor}
                                                                                config={editorConfig}
                                                                                tabIndex={1}
                                                                                onChange={e => handleQuestionTitle(language.code, e)}
                                                                            /> 
                                                                        </div>*/}
                                  </div>
                                ))
                              : null}
                          </div>
                          <div class="question-info">
                            <h5 class="bold">{t("upload_question")}</h5>
                            <div class="row mt-4">
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(2)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 2
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-image"></span>
                                  <span>{t("img_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(4)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 4
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-video"></span>
                                  <span>{t("vid_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(3)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 3
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-audio"></span>
                                  <span>{t("aud_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(1)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 1
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-text"></span>
                                  <span>{t("text_label")}</span>
                                </a>
                              </div>
                            </div>

                            {languages
                              ? languages.map((language) => (
                                  <>
                                    <input
                                      type="file"
                                      name="file"
                                      onChange={onFileUploadChange}
                                      style={{ display: "none" }}
                                      id={
                                        "questionUploadButton_" +
                                        language.id +
                                        "_" +
                                        questionId
                                      }
                                    />
                                    <img
                                      id={
                                        "questionImagePreview_" +
                                        language.id +
                                        "_" +
                                        questionId
                                      }
                                      width="200px"
                                      style={{ display: "none" }}
                                    />
                                  </>
                                ))
                              : null}

                            {/*
                                                    <input type="file" name="file" 
                                                        id = "questionUploadButton"
                                                        style={{display:"none"}}   
                                                        onChange={onFileUploadChange}
                                                    />

                                                    <img 
                                                        id="questionImagePreview" width="200px" 
                                                        style={{display:"none"}}
                                                    /> 
                                                    */}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-12 col-md-12 col-lg-6 animated fadeInRight"
                        id="rightBlock"
                      >
                        <h4>{t("add_answer")}</h4>
                        <h6 class="yourAns">{t("your_answer")}</h6>
                        <div
                          class="add-option-btn"
                          onClick={makeOptions}
                          style={
                            answerMode === 1
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <a href="javascript:void(0)">
                            <span class="icon icon-plus blue-bg-btn"></span>{" "}
                            <span>{t("add_option")}</span>
                          </a>
                        </div>

                        <div class="selectAns">
                          {t("select_type_of_answer")}
                        </div>

                        <div class="row my-4 justify-content-center typeOfAnsRow">
                          <div
                            class="col-6 col-sm-2 typeOfAns"
                            onClick={() => setAnswerMode(3)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"3"}
                              class={
                                answerMode == 3
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-image"></span>
                              <span>{t("img_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2 typeOfAns"
                            onClick={() => setAnswerMode(5)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"5"}
                              class={
                                answerMode == 5
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-video"></span>
                              <span>{t("vid_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2 typeOfAns"
                            onClick={() => setAnswerMode(4)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"4"}
                              class={
                                answerMode == 4
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-audio"></span>
                              <span>{t("aud_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2 typeOfAns"
                            onClick={() => setAnswerMode(2)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"2"}
                              class={
                                answerMode == 2
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-text"></span>
                              <span>{t("text_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2 typeOfAns"
                            onClick={() => setAnswerMode(1)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"2"}
                              class={
                                answerMode == 1
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-edit1"></span>
                              <span>{t("input_label")}</span>
                            </a>
                          </div>
                        </div>

                        {numberOfOptions.length > 0 && answerMode !== 1
                          ? numberOfOptions.map((optionNo, index) => (
                              <AnswerOptions
                                optionNo={optionNo}
                                answerMode={answerMode}
                                ref={(ins) => (refs.current[index] = ins)}
                                languages={languages}
                                currentLanguage={currentLanguage}
                              />
                            ))
                          : null}

                        {numberOfOptions.length > 0 && answerMode === 1 ? (
                          <AnswerInputOptions
                            answerMode={answerMode}
                            languages={languages}
                            currentLanguage={currentLanguage}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    id="errorMessage"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("min_2_options_should_be_given")}
                  </div>
                  <div
                    id="errorMessageNoInput"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_two_input_and_correct_option")}
                  </div>
                  <div
                    id="errorMessageNoUpload"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("upload_file_for_all_options")}
                  </div>
                  <div
                    id="errorMessageNoText"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_text_for_all_options")}
                  </div>
                  <div
                    id="errorMessageAllLanguages"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_question_name_for_all_languages")}
                  </div>
                  <div
                    id="errorMessageQuizLanguges"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  ></div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" />}
                        label={t("media_rights")}
                        labelPlacement="end"
                        checked={mediaRightsChecked}
                        onChange={(e) => mediaRightsChanged(e)}
                      />
                    </FormGroup>
                  </FormControl>

                  <div class="hint-explanation">
                    <div class="row m-0">
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInLeft">
                        <div class="hint-explanation-in">
                          <div class="icon">
                            <img src={hintImg} />
                          </div>
                          <div>
                            <h4>{t("enter_hint")}</h4>
                            {languages
                              ? languages.map((language, index) => (
                                  <textarea
                                    style={
                                      language.id == currentLanguage.id
                                        ? null
                                        : { display: "none" }
                                    }
                                    placeholder={t("hints")}
                                    id={"questionHint_" + language.code}
                                  ></textarea>
                                  /*
                                                                     <div style={language.id == currentLanguage.id ? null : { display: "none" }}
                                                                         id={"questionHint_" + language.code}>
                                                                        <TinyMCE5Component key={index}  
                                                                                            ref={instance => refTinyMCE5HintComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                            language_code={globalLangCode}
                                                                                            content={''}
                                                                                            placeholder={t("hints")}/>
                                                                    </div>
                                                                    */
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-sm-2 col-md-2 col-lg-2 qc-border animated fadeInDown"></div> */}
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInRight">
                        <div class="hint-explanation-in">
                          <div class="icon">
                            <img src={expImg} />
                          </div>
                          <div>
                            <h4>{t("add_explanation")}</h4>
                            {languages
                              ? languages.map((language, index) => (
                                  <textarea
                                    style={
                                      language.id == currentLanguage.id
                                        ? null
                                        : { display: "none" }
                                    }
                                    placeholder={t("explanation")}
                                    id={"questionExp_" + language.code}
                                  ></textarea>
                                  /*
                                                                    <div style={language.id == currentLanguage.id ? null : { display: "none" }}
                                                                                    id={"questionExp_" + language.code}>
                                                                                <TinyMCE5Component key={index}  
                                                                                       ref={instance => refTinyMCE5ExplainComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                       language_code={globalLangCode}
                                                                                       content={''}
                                                                                       placeholder={t("explanation")}/>
                                                                    </div>
                                                                    */
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="qc-bottom-btns animated fadeInRight">
              {/* <a onClick={addMoreQuestionAndAnswer} href="javascript:void(0)" class="btn-background">Add more</a> */}
              <a
                onClick={addQuestionAndAnswer}
                href="javascript:void(0)"
                class="btn-border blue-bg-btn"
              >
                {t("complete_and_preview")}
              </a>
            </div>
          </div>
        </div>
        <ToastContainer />

        {/* <BasicFooter /> */}
      </React.Fragment>
    </LoadingOverlay>
  );
}

export default AddQuestions;
