/* eslint-disable */

import React, { useState, useEffect, useImperativeHandle } from 'react'
import S3 from 'react-aws-s3';

import imageImg from "../images/quiz-creation/image.png"
import audioImg from "../images/quiz-creation/audio.png"
import videoImg from "../images/quiz-creation/vedio.png"
import textImg from "../images/quiz-creation/text.png"
import axios from 'axios';
import { showMessageToUser, showErrorMessageToUser } from '../Utils/toastr';
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";

const AnswerOptions = React.forwardRef((props, ref) => {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])
    const token = cookies['access_token'] //getToken();
    const { languages, currentLanguage, optionName, optionIdFor, optionNumber, answerTextId, answerMode, answerTypeId, questionId, optionNo } = props

    //let answer_id = 
    // const [answerModeState, setAnswerModeState] = useState(answerMode)

    const [answerFiles, setAnswerFile] = useState([]);

    const config = {
        bucketName: 'memoriz',
        dirName: 'uploads/answers', /* optional */
        region: 'ap-southeast-1',
        accessKeyId: 'AKIAWHED5POKH4CAAJLY',
        secretAccessKey: 'vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE',
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }

    const ReactS3Client = new S3(config);

    const uploadAnswerFileToLocal = (event) => {

        let tempanswerFiles = []
        if (answerFiles && answerFiles.length > 0) {
            console.log('answerFiles.length=', answerFiles.length)
            //Does it exists languageId
            for (let i = 0; i < languages.length; i++) {
                //console.log('element', element)
                if (languages[i].id == currentLanguage.id) {
                    console.log('currentLanguage.id=' + currentLanguage.id)
                    console.log('file=', event.target.files[0])
                    tempanswerFiles.push({
                        languageId: currentLanguage.id,
                        file: event.target.files[0]
                    })
                } else {
                    console.log('copying existing answers edited')
                    if (answerFiles.length > 0) {
                        for (let j = 0; j < answerFiles.length; j++) {
                            if (languages[i].id == answerFiles[j].languageId) {
                                console.log('har har')
                                tempanswerFiles.push(answerFiles[j])
                            }
                        }
                    }
                }
            }
        } else {
            tempanswerFiles.push({
                languageId: currentLanguage.id,
                file: event.target.files[0]
            })
        }
        console.log('tempanswerFiles.length=' + tempanswerFiles.length)
        setAnswerFile(tempanswerFiles)

        //setAnswerFile(event.target.files[0])
        if (answerMode === 3) {
            var image = document.getElementById("answerImagePreview_" + currentLanguage.id + "_" + optionNo);
            image.src = URL.createObjectURL(event.target.files[0]);
        }

    }

    useEffect(() => {
        console.log('answerFiles=', answerFiles)
    }, [answerFiles])

    const CustomException = (message) => {
        this.msg = message
    }
    useImperativeHandle(ref, () => ({

        validate(questionId, optionNumber) {
            return new Promise((resolve, reject) => {
                console.log(questionId, optionNumber)
                if (answerFiles && answerFiles.length > 0 && answerMode !== 2) {
                    console.log("I'm clicked");
                    let fileUploadPromises = []
                    for (let i = 0; i < languages.length; i++) {
                        for (let j = 0; j < answerFiles.length; j++) {
                            if (languages[i].id == answerFiles[j].languageId) {
                                const newFileName = "answer_" + languages[i].code + "_" + optionNumber + "_" + questionId;
                                console.log('newFileName', newFileName)


                                //answerMode
                                const fd = new FormData()
                                fd.append('file', answerFiles[j].file)
                                fd.append('filename', newFileName)
                                console.log('answerFile', answerFiles[j].file)
                                // For Media Type
                                if (answerMode == 3) {
                                    var media_type = 1; // Image File
                                } else if (answerMode == 4) {
                                    var media_type = 2;  // Audio File
                                } else if (answerMode == 5) {
                                    var media_type = 3;  // Video File
                                } else {
                                    var media_type = 0; // Text
                                }
                                fileUploadPromises.push(
                                    axios({
                                        method: 'post',
                                        url: '/api/answers/upload/image?filename=' + newFileName + '&media_type=' + media_type,
                                        headers: {
                                            'locale': "en",
                                            'x-access-token': "Memo " + token,
                                            'Content-Type': 'multipart/form-data',
                                            //'enctype': "multipart/form-data"
                                        },
                                        data: fd

                                    })
                                )
                            }
                        }
                    }
                    Promise.all(fileUploadPromises)
                        .then(() => {
                            console.log('success uploading answers')
                            resolve()
                        })
                        .catch((error) => {
                            console.log("error", error.response.data[0].msg)
                            showErrorMessageToUser(error.response.data[0].msg);
                            reject(error.response.data[0].msg)
                            //throw new Error(error.response.data[0].msg) 

                        })
                } else {
                    resolve()
                }
            })
        }

    }))


    return (
        <React.Fragment>




            <div class="option-list optionListing">

                <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input"
                        id={"option" + optionNo}
                        name="Option" value="Option"
                    />
                    <label class="custom-control-label"
                        for={"option" + optionNo}
                    >
                        {t("option") + optionNo}
                    </label>
                </div>
                <div class="inner-option">
                    <div class="select-txt">{t("select_this_as_correct_answer")}</div>

                    {
                        languages ?
                            languages.map(language => (
                                <div class="option-input"
                                    style={currentLanguage.id === language.id ? { display: "block" } : { display: "none" }}
                                >
                                    <input type="text"
                                        id={"answerText_" + language.id + "_" + optionNo}
                                        style={{display: "block" ,fontSize: "36px",fontFamily: 'arial'}}
                                    />

                                </div>
                            ))
                            :
                            null
                    }
                    {/* 
                                                      <input type="file" name="file" 
                                                            
                                                           onChange={uploadAnswerFileToLocal}
                                                          style={{display:"none"}}
                                                            id = {"uploadButton"+optionNo}
                                    
                                                        />
                                                        <img 
                                                        id={"answerImagePreview"+optionNo} width="200px" 
                                                        style={{display:"none"}}
                                                        /> 
                                                     */}
                    {
                        languages ?
                            languages.map(language => (

                                <>
                                    <input type="file" name="file"

                                        onChange={uploadAnswerFileToLocal}
                                        style={{ display: "none" }}
                                        id={"uploadButton_" + language.id + '_' + optionNo}

                                    />
                                    <img
                                        id={"answerImagePreview_" + language.id + "_" + optionNo} width="200px"
                                        style={{ display: "none" }}
                                    />
                                </>
                            ))
                            :
                            null
                    }


                </div>
            </div>

        </React.Fragment>
    )
})

export default AnswerOptions
