/* eslint-disable */ 
import React from 'react'

import andriodIcon from '../images/android-icon.png'
import backToTopLogo from '../images/back-to-top-logo.png'
import androidImg from '../images/android-icon.png'
import macImg from "../images/desk.png"
import footerlogo from '../images/memorize-footer-logo.png'
import { useTranslation } from 'react-i18next';

import "../less/login.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

export function LoginFooter(props) {
    const { t } = useTranslation()
    const goTousertypesandpricing = () => {
        props.history.push("/usertypesandpricing")
    }
    return (
        <footer class="memorize-footer login-memorize-footer">
        <div class="footer-img">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInLeft">
                        <h3>{t("memorizeall")}</h3>
                        <ul>
                            <li><a href="javascript:void(0)">{t("home")}</a></li>
                            <li onClick={()=>goTousertypesandpricing()}><a href="javascript:void(0)">{t("user_types_and_pricing")}</a></li>
                            {/* <li><a href="javascript:void(0)">Start memorizing</a></li> 
                            <li><a href="javascript:void(0)">Become creator</a></li>
                            <li><a href="javascript:void(0)">Groups</a></li>
                            <li><a href="javascript:void(0)">Pricing</a></li>
                            <li><a href="javascript:void(0)">Campaigns</a></li>*/}
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInLeft">
                        <h3>Support</h3>
                        <ul>
                            {/* <li><a href="javascript:void(0)">Help</a></li> */}
                            <li><a href="javascript:void(0)">{t("contact")} Memorizeitall</a></li>
                            {/* <li><a href="javascript:void(0)">How it works / Q&A</a></li> */}
                            <li><a href="javascript:void(0)">{t("suggestions")}</a></li>
                            <li><a href="javascript:void(0)">{t("advertise")}</a></li>
                            <li><a href="javascript:void(0)">{t("report_abuses")}</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInRight">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="javascript:void(0)">{t("about_memorizeitall")}</a></li>
                            {/* <li><a href="javascript:void(0)">Vacancies</a></li>
                            <li><a href="javascript:void(0)">News</a></li>
                            <li><a href="javascript:void(0)">Calendar</a></li> Privacy policy*/}
                            <li><a href="javascript:void(0)">{t("terms_and_conditions")}</a></li>
                            <li><a href="javascript:void(0)">{t("privacy_policy")}</a></li>
                            <li><a href="javascript:void(0)">Creator {t("terms_and_conditions")}</a></li>
                          </ul>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 animated fadeInRight">
                        <h3>{t("offices")}</h3>
                        <ul>
                            <li>{t("address_line_1")}</li>
                            <li>{t("address_line_2")}</li>
                            <li><a href="mailto:info@memorizeitall.email">info@memorizeitall.email</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-xl-6 position-inherit">
                        <div class="ios-android-download-link animated fadeInLeft">
                            <a href="javascript:void(0)">
                                <span class="icon"><span class="icon-apple"></span></span>
                                <span class="txt">Download on the</span>
                                <span class="store">App Store</span>
                            </a>
                            <a href="javascript:void(0)">
                                <span class="icon"><img src={androidImg} /></span>
                                <span class="txt">Android App on</span>
                                <span class="store">Google Play</span>
                            </a>
                            <a href="javascript:void(0)">
                                <span class="icon"><img src={macImg} /></span>
                                <span class="txt">Download on</span>
                                <span class="store">PC/Mac</span>
                            </a>
                        </div>
                        <div class="copy-right animated fadeInRight">
                            &copy; 2020 Memorizeitall - all rights reserved - <a href="#">www.memorizeitall.com</a>
                        </div>
                        {/* <!-- <div class="subscribe animated fadeInRight">
                            <div class="subscribe-in">
                                <input type="text" />
                                <a href="javascript:void(0)" class="subscribe-now-btn">Subscribe now</a>
                            </div>
                        </div> --> */}
                    </div>
                    <div class="col-md-12 col-xl-6 memorize-txt">
                        <div class="memorize-logo">
                            <img src={footerlogo} />
                        </div>
                        <p>Memorize<span>it</span>all</p>
                    </div>
                </div>
            </div>
            <a id="back-to-top" href="#" class="back-to-top-btn animate-top-to-bottom"><img src={backToTopLogo} /></a>
        </div>
    </footer>
        )
}

export default LoginFooter
