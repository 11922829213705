/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";

import dashlogo from "../images/dash-logo.png";
import Pagination from "./Pagination";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/groups.css";
import "../less/icon-font.css";
import "../less/invite.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

function GroupNews(props) {
  console.log("GroupNews.props", props);
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  const PAGE_LIMIT = 10;

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const group_id = props.match.params.group_id;
  //const group = props.location.state.group
  //const group_creatorId = group.created_by

  const user = cookies["user"]; //getUser();
  const user_id = user.id;

  const editor = useRef(null);
  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);

  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [organization, setOrganization] = useState("");
  //const [profile_pic, setProfile_pic] = useState("");
  const [profile_pic_filename, setProfile_pic_filename] = useState("");
  //const [profilePicUploaded, setProfilePicUploaded] = useState(0)
  const [open_close, setOpenClose] = useState(0); //Close = 0
  const [owner_name, setOwnerName] = useState("");

  const [groupAdminList, setGroupAdminList] = useState("");
  const [toAddAdminEMail, setToAddAdminEMail] = useState("");

  const [news, setNews] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  const [selectedNewsForDetails, setSelectedNewsForDetails] = useState(null);

  const [banners, setBanners] = useState();

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fetchAdministrators = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/administrator/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        group_id: group_id,
      }),
    })
      .then((response) => {
        let group_admin_list = response.data.data;
        console.log("/api/groups/ response.data", group_admin_list);
        setGroupAdminList(group_admin_list);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/groups/" + group_id,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        let group_details = response.data.data;
        console.log("/api/groups/ response.data", response.data.data);
        setGroupName(group_details.group_name);
        setGroupDesc(group_details.description);
        setOrganization(group_details.organization);
        setProfile_pic_filename(group_details.profile_pic);
        setOpenClose(group_details.open_close);
        setOwnerName(group_details.owner_name);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user_id,
      }),
    })
      .then((response) => {
        console.log("advertisement", response);
        setAdLength(response.data.total);
      })
      .catch((error) => {
        // removeUserSession();
      });

    fetchAdministrators();

    //window.addEventListener("beforeunload", onUnload);
    //return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
  useEffect(() => {
    if (adLength > 0) {
      setAdv([]);
      for (let i = 0; i < 2; i++) {
        let randomPosition = getRandomInt(0, adLength);
        axios({
          method: "post",
          url: "/api/advertisements/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            position: randomPosition,
            size: 1,
            user_id: user_id,
          }),
        })
          .then((response) => {
            console.log("advertisement", response);
            setAdv((ad) => ad.concat(response.data.data));
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [adLength]);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const fetchNews = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/news/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: group_id,
        page: currentPage,
      }),
    })
      .then((response) => {
        console.log("/groups/news/list response.data", response.data);
        setNews(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        console.log("ffffffff");
      })
      .catch((error) => {
        console.log("error--", error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchNews(data.currentPage);
    setCurrent(data.currentPage);
  };
  useEffect(() => {
    fetchNews();
  }, [props.group_id]);

  const onUnload = (e) => {
    console.log("groupName", groupName);
    console.log("groupDesc", groupDesc);
  };

  const onDetailNewsClick = (item, index) => {
    console.log("onDetailNewsClick", item);
    setSelectedNewsForDetails(item);
    document.getElementById("id-news-detail-modal" + index).click();
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}
      <div class="container">
        <div class="groupDetailContent groupNewsPage">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <div class="shadowPanelInner groupDetailBox">
                <div class="groupDetailHeader d-flex align-items-center  position-relative justify-content-between">
                  <div class="mb-4 w-100">
                    <div class="detailLogoBox d-flex align-items-center pl-75px">
                      <div class="groupDetailLogo">
                        <img
                          src={GROUPS_PATH + profile_pic_filename}
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="ml-2">
                        <h5 class="groupNameText d-block">{groupName}</h5>
                        <h5 class="organisationText mb-0">{organization}</h5>
                      </div>
                    </div>
                  </div>

                  <div class="groupDetailIcons d-flex mb-4">
                    <a href="javascript:void(0)" class="gpDetailIcon">
                      <span class="icon icon-notification2"></span>
                    </a>
                    <div class="gpDetailIcon ml-2">
                      <span
                        class={
                          open_close ? "icon icon-unlock" : "icon icon-lock"
                        }
                      ></span>
                    </div>
                  </div>
                </div>

                <div class="groupDetailDescriptionBox">
                  <div class="groupAdminBox mb-3">
                    <h6>{t("group_administrator")}</h6>
                    <div class="groupAdminList">
                      {groupAdminList &&
                        groupAdminList.map((admin, index) => (
                          <div class="mb-1">
                            <span>{index + 1}</span> -{" "}
                            <span>Mr. {admin.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div class="descBox">
                    <h6>{t("group_desc")}:</h6>
                    <div
                      class="groupDescription"
                      dangerouslySetInnerHTML={
                        groupDesc ? { __html: groupDesc } : null
                      }
                    ></div>
                  </div>
                </div>
              </div>
              <div class="advertisement-slider mt-3 mb-0">
                <div class="row">
                  {adv && adv.length > 0
                    ? adv.map((ad) => (
                        <div className="col-md-6 col-sm-12">
                          <a class="item" target="_blank" href={ad.link}>
                            <img
                              src={ADVERTISEMENT_PATH + ad.image}
                              className="mb-2"
                            />
                          </a>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  {news
                    ? news.length > 0
                      ? news.map((item, index) => (
                          <div class="newsAppetizerList mt-2">
                            <div class="newsAppetizerBox position-relative">
                              <div class="newsDetails mb-4">
                                <div class="newsPostDetails">
                                  <div class="font-weight-bold mb-1">
                                    <span class="d-inline-block pr-1">
                                      {t("date")}:
                                    </span>
                                    <span>{item.formated_created_at}</span>
                                  </div>
                                  <div class="font-weight-bold mb-1">
                                    <span class="d-inline-block pr-1">
                                      {t("posted_by")}:
                                    </span>
                                    <span>Mr.{item.news_created_by}</span>
                                  </div>
                                </div>
                                <div class="memorizeBrandIcon">
                                  <img src={dashlogo} alt="" width="60"></img>
                                </div>
                              </div>

                              <div class="newsInfo">
                                <a href="javascript:void(0)">
                                  <p
                                    dangerouslySetInnerHTML={
                                      item.description
                                        ? { __html: item.description }
                                        : null
                                    }
                                    class="newsContent"
                                    data-toggle="modal"
                                    data-backdrop="static"
                                    data-target="#groupNewsModal"
                                    id={"id-news-detail-modal" + index}
                                    onClick={() =>
                                      onDetailNewsClick(item, index)
                                    }
                                  ></p>
                                </a>

                                {/* <div>
                                                                <span class="d-block">1.ABC</span>
                                                                <span class="d-block">2.DEF</span>
                                                                <span class="d-block">3.GHI</span>
                                                            </div>
                                                            <div class="mb-2">Interested can call 000-1234-5678 or email info@abc.com</div>
                                                            <div>Futher information avail a futher information available.Futher information avail a futher information available.Futher information avail a futher information available.</div> */}
                              </div>
                              {/**
                                                        <div class="newsInfo">
                                                            <p dangerouslySetInnerHTML = { item.description ? {__html: item.description }: null }></p>
                                                            <div>
                                                                <span class="d-block">1.ABC</span>
                                                                <span class="d-block">2.DEF</span>
                                                                <span class="d-block">3.GHI</span>
                                                            </div>
                                                            <div class="mb-2">Interested can call 000-1234-5678 or email info@abc.com</div>
                                                            <div>Futher information avail a futher information available.Futher information avail a futher information available.Futher information avail a futher information available.</div>
                                                        </div>
                                                         */}
                            </div>
                          </div>
                        ))
                      : null
                    : null}
                </div>
              </div>
              <div class="text-center pt-4">
                {total > PAGE_LIMIT ? (
                  <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                    <Pagination
                      totalRecords={total}
                      totalPages={totalPages}
                      currentPage={current}
                      pageLimit={PAGE_LIMIT}
                      pageNeighbours={1}
                      onPageChanged={onPageChanged}
                    />
                  </div>
                ) : null}
                {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="groupNewsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("news_in_detail")}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              dangerouslySetInnerHTML={
                selectedNewsForDetails
                  ? { __html: selectedNewsForDetails.description }
                  : null
              }
            ></div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <BasicFooter /> */}
      <ToastContainer />
    </React.Fragment>
  );
}

export default GroupNews;
