/* eslint-disable */

import React, { useEffect, useState } from "react";

import axios from "axios";
import qs from "qs";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";

function GroupRequestJoinComponent(props) {
  console.log("GroupRequestJoinComponent.props", props);

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const user = cookies["user"]; //getUser();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const [requestList, setRequestList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    fetchRequestList();
  }, [props.group_id]);

  const fetchRequestList = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/open/request/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        page: currentPage,
      }),
    })
      .then((response) => {
        console.log("/groups/open/request/list response", response.data.data);
        setRequestList(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  const onPageChanged = (data) => {
    setLoading(true);
    setRequestList(data.currentPage);
    setCurrent(data.currentPage);
  };

  const deleteInvitationRequest = (item) => {
    axios({
      method: "post",
      url: "/api/groups/open/request/delete",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        user_id: item.user_id,
      }),
    })
      .then((response) => {
        fetchRequestList();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  const acceptInvitation = (item) => {
    axios({
      method: "post",
      url: "/api/groups/open/request/accept",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        user_id: item.user_id,
      }),
    })
      .then((response) => {
        fetchRequestList();
        props.onRefreshMembers();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  return (
    <React.Fragment>
      <div class="requestJoinPanel mb-2 border-box">
        <div class="position-relative mb-2 header">
          <h6 class="blueThemeText panelHeading">{t("request_to_join")}</h6>
        </div>
        {requestList &&
          requestList.map((item) => (
            <div class="requestList">
              <div class="memberBox position-relative mb-2">
                <div class="memberDetail">
                  <div class="photo">
                    <img src={PROFILE_PIC + item.profile_pic} alt="" />
                  </div>
                  <div class="info ml-2">
                    <span class="d-block mb-1">{item.name}</span>
                    <span class="mailId">{item.email_id}</span>
                  </div>
                </div>
                <div class="actionBtns d-flex">
                  <a
                    href="javascript:void(0)"
                    class="actionIcon acceptRequest"
                    onClick={() => acceptInvitation(item)}
                  >
                    <span class="icon icon-tick1 text-white"></span>
                  </a>

                  <a
                    href="javascript:void(0)"
                    class="actionIcon noAccess ml-5px"
                    onClick={() => deleteInvitationRequest(item)}
                  >
                    <span class="icon icon-close1"></span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        <div class="text-center pt-2">
          {total > PAGE_LIMIT ? (
            <div className="d-flex flex-row py-2 align-items-center justify-content-center">
              <Pagination
                totalRecords={total}
                totalPages={totalPages}
                currentPage={current}
                pageLimit={PAGE_LIMIT}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            </div>
          ) : null}
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}

export default GroupRequestJoinComponent;
