/* eslint-disable */ 
import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';

import "../less/my-credits.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"

import "../less/test.css"
import "../less/pricing.css"
import i18next from 'i18next';

function VerifyEmail(props) {

    return (
        <React.Fragment>
            <section>
                <div class="container">
                    <div class="verify-email">
                        <p>{props.location.state.msg} </p>
                        <p>{props.location.state.msg2} </p>
                        <div class="verify-icons">
                            <span class="icon icon-mail1"></span>
                            <span class="icon icon-arrow"></span>
                            <span class="icon icon-inbox"></span>
                            <span class="icon icon-arrow"></span>
                            <span class="icon icon-hand"></span>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default VerifyEmail