/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import ContentComponent from "./ContentComponent";
import Creators from "./Creators";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/seller.css";
import "../less/test.css";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import downloadpng from "../images/seller_icon/download.png";
import questionpng from "../images/seller_icon/question.png";
import starpng from "../images/seller_icon/star.png";

function CreatorProfile(props) {
  const seller_id = props.match.params.profileId;
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const PAGE_LIMIT = 6;
  const token = cookies["access_token"];
  const user = cookies["user"]; //getUser()
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [creatorDetails, setCreatorDetails] = useState();
  const [creatorGenres, setCreatorGenres] = useState();
  const [creatorCategories, setCreatorCategories] = useState();
  const [creatorRatings, setCreatorRatings] = useState();
  const [creatorContents, setCreatorContents] = useState();
  const [totalCreatorContentsResults, setTotalCreatorContentsResults] =
    useState(null);
  const [totalCreatorContentsPages, setTotalCreatorContentsPages] =
    useState(null);
  const [currentCreatorContentsPage, setCurrentCreatorContentsPage] =
    useState(1);

  const [creatorSimilarCreators, setCreatorSimilarCreators] = useState();
  const [review, setReview] = useState();
  const [ratingStars, setRatingStars] = useState(0);
  const [reviewAdded, setReviewAdded] = useState(0);

  const [banners, setBanners] = useState();
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/details",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
        user_id: user.id,
      }),
    })
      .then((response) => {
        console.log("creator details", response.data.user);
        setCreatorDetails(response.data.user);
      })
      .catch((error) => {});

    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/genres",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
      }),
    })
      .then((response) => {
        setCreatorGenres(response.data.data.preffered_genres);
      })
      .catch((error) => {});

    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/categories",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
      }),
    })
      .then((response) => {
        setCreatorCategories(response.data.data.preffered_categories);
      })
      .catch((error) => {});

    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/ratings",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
        page: 1,
      }),
    })
      .then((response) => {
        console.log("ratings response", response.data);
        setCreatorRatings(response.data.data);
      })
      .catch((error) => {});

    fetchCreatorContents();
  }, []);

  const submitReviewForThisCreator = async () => {
    await axios({
      method: "post",
      url: "/api/quiz/review/create",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        rating_for: 2,
        to_user_id: seller_id,
        review: review,
        rating: ratingStars,
      }),
    })
      .then((response) => {
        console.log("response after submiting review", response);
        showMessageToUser(response.data.msg);
        setReviewAdded(1);
      })
      .catch((error) => {
        console.log(error.response);
        // removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
        setReviewAdded(1);
      });
  };

  useEffect(() => {
    console.log("callled refresh");
    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/ratings",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
        page: 1,
      }),
    })
      .then((response) => {
        console.log("ratings response", response.data);
        setCreatorRatings(response.data.data);
      })
      .catch((error) => {});
    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/creators/details",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
        user_id: user.id,
      }),
    })
      .then((response) => {
        console.log("creator details", response.data.user);
        setCreatorDetails(response.data.user);
      })
      .catch((error) => {});
  }, [reviewAdded]);

  useEffect(() => {
    console.log(creatorSimilarCreators);
  }, [
    creatorDetails,
    creatorContents,
    creatorGenres,
    creatorRatings,
    creatorSimilarCreators,
    creatorCategories,
  ]);

  const fetchCreatorContents = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/creators/quizzes",
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        seller_id: seller_id,
        user_id: user.id,
        page: currentPage.toString(),
        limit: PAGE_LIMIT,
      }),
    })
      .then((response) => {
        setCreatorContents(response.data.data);
        setTotalCreatorContentsPages(response.data.total_pages);
        setTotalCreatorContentsResults(response.data.total);
        setCurrentCreatorContentsPage(currentPage);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };

  const onCreatorContentsPageChanged = (data) => {
    fetchCreatorContents(data.currentPage);
    setCurrentCreatorContentsPage(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("seller_profile")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("seller_profile")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      {creatorDetails &&
      creatorGenres &&
      creatorRatings &&
      creatorCategories &&
      (reviewAdded == 1 || reviewAdded == 0) ? (
        <section class="gener_gap">
          <div class="container">
            <div class="row">
              {creatorDetails ? (
                <div class="col-md-5">
                  <div class="row profile-creators">
                    <div class="col-lg-12 col-xl-12 mb-4">
                      <div class="m-fold-001 sell-pro-003">
                        <div class="col-md-12">
                          <div className="row">
                            <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                              <div class="creator-profile creator-pro-001">
                                <img
                                  src={PROFILE_PIC + creatorDetails.profile_pic}
                                  width="100%"
                                />
                                <h5>{creatorDetails.name}</h5>
                              </div>
                            </div>
                            <div class="col-md-8 p-3">
                              <div class="creator-text">
                                <div class="d-flex flex-row">
                                  <div class="p-1">
                                    <img src={questionpng} alt="" />
                                  </div>
                                  <div class="p-2">
                                    <p class="mb-0 quiz-cr">
                                      {creatorDetails.total_quizzes}{" "}
                                      {t("quiz_created")}
                                    </p>
                                  </div>
                                </div>
                                <div class="d-flex flex-row">
                                  <div class="p-1">
                                    <img src={downloadpng} alt="" />
                                  </div>
                                  <div class="p-2">
                                    <p class="mb-0 quiz-cr">
                                      {creatorDetails.downloads}{" "}
                                      {t("quizDownloads")}
                                    </p>
                                  </div>
                                </div>
                                <div class="d-flex flex-row">
                                  <div class="p-1">
                                    <img src={starpng} alt="" />
                                  </div>
                                  <div class="p-2">
                                    <p class="mb-0 quiz-cr">
                                      <span>
                                        {creatorDetails.ratings > 0
                                          ? (function star() {
                                              let stars = [];
                                              for (
                                                let i = 0;
                                                i < creatorDetails.ratings;
                                                i++
                                              ) {
                                                stars.push(
                                                  <i
                                                    class="fa fa-star"
                                                    aria-hidden="true"
                                                  ></i>
                                                );
                                              }
                                              return stars;
                                            })()
                                          : null}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="seller-edit">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 mb-3">
                      <div class="about-seller">
                        <div class="d-flex">
                          <div class="p-1 mr-auto">
                            <h5>{t("aboutme")}</h5>
                          </div>
                          <div class="p-1">
                            <div class="selling-reviews">
                              <p>
                                {creatorDetails.reviews} {t("reviews")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="seller-text">
                          <p>{creatorDetails.about_us}</p>
                        </div>

                        <div class="seller-lang">
                          <h5 class="mt-4 mb-4">{t("prefered_languages")}</h5>

                          <div class="seller-prefer col-md-12">
                            <div class="row">
                              {creatorDetails.preffered_languages.map(
                                (language) => (
                                  <div class="col-lg-3 col-md-6 col-6 d-flex justify-content-center align-items-center flex-column">
                                    <div class="seller-hind">
                                      <img src={COUNTRY_FLAG + language.flag} />
                                    </div>
                                    <p class="mt-3">{t(language.lang_name)}</p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {creatorGenres || creatorCategories ? (
                <div class="col-md-7">
                  <div class="seller-genres-detail">
                    <div class="seller-tab">
                      <ul
                        class="nav nav-justified"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            <h5 class="mb-0">
                              {t("gener")}(
                              {creatorGenres ? creatorGenres.length : 0})
                            </h5>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            <h5 class="mb-0">
                              {t("category")}(
                              {creatorCategories ? creatorCategories.length : 0}
                              )
                            </h5>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="pills-contact-tab"
                            data-toggle="pill"
                            href="#pills-contact"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            <h5 class="mb-0">{t("rating")}</h5>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div class="genres-sell col-md-12">
                          <div class="row">
                            {creatorGenres
                              ? creatorGenres.map((genre) => (
                                  <div class="seller-cate col-xl-3 col-lg-3 col-md-4">
                                    <div class="seller-collection">
                                      <img
                                        src={GENER_PATH + genre.image}
                                        alt="#"
                                      />
                                    </div>
                                    <p class="mt-3">{genre.name}</p>
                                  </div>
                                ))
                              : "No Preferred Genres Available"}
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div class="genres-sell col-md-12">
                          <div class="row">
                            {creatorCategories
                              ? creatorCategories.map((category) => (
                                  <div class="seller-cate col-xl-3 col-lg-3 col-md-4">
                                    <div class="seller-collection">
                                      <img
                                        src={CATEGORY_PATH + category.image}
                                        alt="#"
                                      />
                                    </div>
                                    <p class="mt-3">{category.name}</p>
                                  </div>
                                ))
                              : "No Preferred Genres Available"}
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div class="reviews-listing">
                          {creatorRatings.length > 0
                            ? creatorRatings.map((creatorRating) => (
                                <div class="review-list">
                                  <div class="user-img">
                                    <img
                                      src={
                                        PROFILE_PIC +
                                        creatorRating.from_user_profile_pic
                                      }
                                    />
                                  </div>
                                  <div>
                                    <div>{creatorRating.from_user_name}</div>
                                    <div class="stars">
                                      {creatorRating.rating > 0
                                        ? (function star() {
                                            let stars = [];
                                            for (
                                              let i = 1;
                                              i <= creatorRating.rating;
                                              i++
                                            ) {
                                              stars.push(
                                                <span
                                                  onClick={() =>
                                                    setRatingStars(i)
                                                  }
                                                  class="icon-star active"
                                                ></span>
                                              );
                                            }
                                            return stars;
                                          })()
                                        : null}
                                      {5 - creatorRating.rating > 0
                                        ? (function star() {
                                            let stars = [];
                                            for (
                                              let i = 1;
                                              i <= 5 - creatorRating.rating;
                                              i++
                                            ) {
                                              stars.push(
                                                <span
                                                  onClick={() =>
                                                    setRatingStars(
                                                      creatorRating.rating + i
                                                    )
                                                  }
                                                  class="icon-star"
                                                ></span>
                                              );
                                            }
                                            return stars;
                                          })()
                                        : null}
                                    </div>
                                    <div class="comment">
                                      {creatorRating.review}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : t("no_rating_available")}
                        </div>

                        <div
                          class="quize_listing"
                          data-toggle="modal"
                          data-target="#RateandReview"
                        >
                          <button class="continue blue-bg-btn">
                            {t("add_rating")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      ) : null}

      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance1">
            <div class="mr-auto">
              {creatorContents && creatorContents.length > 0 ? (
                <h3 style={{ color: "#1d5190!important" }}>
                  {creatorDetails ? creatorDetails.name : ""}{" "}
                  {t("created_these_contents")}{" "}
                </h3>
              ) : null}
            </div>
            <div class="d-flex flex-wrap align-items-end folder-all cust_link">
              {/* <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5> */}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="row">
                {creatorContents
                  ? creatorContents.map((content) => (
                      <ContentComponent content={content} {...props} />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="nav-direction">
        {totalCreatorContentsResults > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={totalCreatorContentsResults}
              totalPages={totalCreatorContentsPages}
              currentPage={currentCreatorContentsPage}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onCreatorContentsPageChanged}
            />
          </div>
        ) : null}
      </div>

      {/* <section class="gener_gap">
    <div class="my-credits-wrapper">
        <div class="container">
            <div class="purchase-credits-info">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <a href="javascript:void(0)" class="signup-points">
                            <span class="icon"><span class="icon-diamond"></span></span>
                            <span>Sign Up for paid membership to get FLAT 10% Off on all Quizes and Downloads.</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section> */}

      <Creators fetchType={3} seller_id={seller_id} {...props} />

      <div
        class="modal fade add-rate-review-info"
        id="RateandReview"
        tabindex="-1"
        role="dialog"
        aria-labelledby="RateandReview"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("add_rate_and_review")}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <div class="stars">
                  {ratingStars > 0
                    ? (function star() {
                        let stars = [];
                        for (let i = 1; i <= ratingStars; i++) {
                          stars.push(
                            <span
                              onClick={() => setRatingStars(i)}
                              class="icon-star active"
                            ></span>
                          );
                        }
                        return stars;
                      })()
                    : null}
                  {5 - ratingStars > 0
                    ? (function star() {
                        let stars = [];
                        for (let i = 1; i <= 5 - ratingStars; i++) {
                          stars.push(
                            <span
                              onClick={() => setRatingStars(ratingStars + i)}
                              class="icon-star"
                            ></span>
                          );
                        }
                        return stars;
                      })()
                    : null}
                </div>
                <div>
                  <textarea
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    placeholder={t("add_review")}
                  ></textarea>
                </div>
                <div class="save-cancel-btn">
                  <a
                    data-dismiss="modal"
                    onClick={submitReviewForThisCreator}
                    href="javascript:void(0)"
                    class="btn-border blue-bg-btn"
                  >
                    {t("submit")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default CreatorProfile;
