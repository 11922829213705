/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import calendarImg from "../images/icon/calendar.png";
import userIcon from "../images/icon/user1.png";
import { Context } from "../Reducers/Store";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/quill-editor.css";
import "../less/releway-font.css";

import { useCookies } from "react-cookie";
import "../less/seller.css";
import "../less/test.css";

function ContentDetailPage(props) {
  console.log("props of this content details page ", props);
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const quiz_deck_id = props.match.params.quizdeckId;
  const token = cookies["access_token"]; //getToken();
  const [quizDeckDetails, setQuizDeckDetails] = useState();
  const [descriptionVisibilty, setDescriptionVisibility] = useState(true);
  const [showAllLanguages, setShowAllLanguages] = useState(false);
  const [quizTakenId, setQuizTakenId] = useState();
  const [quizTakenErrorMsg, setQuizTakenErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [memorizingLoading, setMemorizingLoading] = useState(false);
  const [ratingStars, setRatingStars] = useState(0);
  const [openGroupRequest, setOpenGroupRequest] = useState(false);
  const [contentQuestionsReset, setContentQuestionsReset] = useState(0); //can't reset

  const { t } = useTranslation();

  const user = cookies["user"]; //getUser();
  console.log(user);
  const user_id = user.id;
  const user_type_id = user.user_type_id;
  console.log("user_type_id", user_type_id);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  console.log("globallang", globalLang);
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  const [creatorRatings, setCreatorRatings] = useState();

  const [statusofthisquizDeck, setStatusofthisdeck] = useState();
  const [previuoslyQuizTakenId, setPreviouslyQuizTakenId] = useState();

  let languageNames = [];
  if (quizDeckDetails) {
    quizDeckDetails.languages.map((language) => {
      languageNames.push(t(language.name));
    });
  }

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const profileSelected = (profileId) => {
    props.history.push("/creatorprofile/" + profileId);
  };

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_played_content" });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "post",
      url: "/api/quiz/play/lateststatus",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        console.log("status of this quiz deck are", response.data);
        setStatusofthisdeck(response.data.status);
        if (response.data.quiz_taken_id) {
          setPreviouslyQuizTakenId(response.data.quiz_taken_id);
        }
        setLoading(false);
        setMemorizingLoading(false);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        setLoading(false);
        setMemorizingLoading(false);
      });

    axios({
      method: "post",
      url: "/api/quiz_deck/details",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token, //getToken(),
        "Content-Type": "application/json",
      },
      data: {
        quiz_deck_id: quiz_deck_id,
      },
    })
      .then((response) => {
        console.log("details of this quiz deck are", response.data);
        setQuizDeckDetails(response.data.details);
        setLoading(false);
        setMemorizingLoading(false);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
        setLoading(false);
        setMemorizingLoading(false);
      });

    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user.id,
      }),
    })
      .then((response) => {
        console.log("advertisement", response);
        setAdLength(response.data.total);
        // setAdv(response.data.data)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    fetchQuestionsResetStatus();
  }, []);

  const fetchQuestionsResetStatus = () => {
    axios({
      method: "post",
      url: "/api/my/quiz/question/reset/status",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        setContentQuestionsReset(response.data.status);
        console.log(
          "status of this quiz deck are",
          JSON.stringify(response.data)
        );
        console.log("status of this quiz deck are", response.data);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  useEffect(() => {
    if (adLength > 0) {
      setAdv([]);
      for (let i = 0; i < 2; i++) {
        let randomPosition = getRandomInt(0, adLength);
        axios({
          method: "post",
          url: "/api/advertisements/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            position: randomPosition,
            size: 1,
            user_id: user.id,
          }),
        })
          .then((response) => {
            console.log("advertisement", response);
            setAdv((ad) => ad.concat(response.data.data));
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [adLength]);

  useEffect(() => {
    if (quizDeckDetails) {
      axios({
        method: "post",
        url: "/api/creators/details",
        headers: {
          locale: globalLangCode,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          seller_id: quizDeckDetails.created_by,
        }),
      })
        .then((response) => {
          console.log("creator response", response);
          setCreatorRatings(response.data.user.ratings);
          setLoading(false);
          setMemorizingLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setMemorizingLoading(false);
        });
    }
  }, [quizDeckDetails]);

  useEffect(() => {
    if (creatorRatings >= 0) {
      console.log("creator ratings", creatorRatings);
    }
  }, [creatorRatings]);

  const getDate = (dateInISO) => {
    let date = new Date(dateInISO);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    return dt + "-" + month + "-" + year;
  };

  const getTime = (dateInISO) => {
    let date = new Date(dateInISO);
    let hour = date.getHours();
    let minute = date.getMinutes();
    return hour + ":" + minute;
  };

  const toggleDeckDescription = () => {
    setDescriptionVisibility(!descriptionVisibilty);
  };

  const showAllLanguagesFunction = () => {
    if (showAllLanguages == true) {
      setShowAllLanguages(false);
    } else {
      setShowAllLanguages(true);
    }
  };

  const startMemorizingforfree = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/playcheck",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: "en",
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        console.log("can memorize content success", response);
        props.history.push({
          pathname: "/playcontent/" + quiz_deck_id,
          state: {
            ...props.location.state,
            status: 1,
          },
        });
      })
      .catch((error) => {
        console.log("can memorize content error", error.response);
        document.getElementById("modalOpenButtonCDP").click();
      });
  };

  /*
    const startMemorizingforfree = () => {
      props.history.push({
          pathname: '/playcontent/'+ quiz_deck_id,
          state: {
              ...props.location.state,
              status : 1
          }
      })
    }
    */
  const goToMyBecomeAPremiumUser = () => {
    props.history.push("/managesubscriptions/" + 3); //3 for premium user
  };

  /*
    const resumeMemorizingforfree = () => {
        props.history.push({
            pathname: '/playcontent/'+ quiz_deck_id,
            state: {
                ...props.location.state,
                status : 3,
                quiz_taken_id:previuoslyQuizTakenId
            }
        })

    }
    */

  const startMemorizingbypurchasing = () => {
    setMemorizingLoading(true);
    //const token = getToken();

    axios({
      method: "post",
      url: "/api/quiz_deck/purchase",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        console.log("response after buying quiz", response);
        if (response.data.status && response.data.status === 1) {
          showMessageToUser(response.data.msg);
          startMemorizingforfree();
        } else {
          showMessageToUser(response.data.msg);
          setMemorizingLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data[0].msg);
        setMemorizingLoading(false);
        showErrorMessageToUser(error.response.data[0].msg);
        // removeUserSession();
      });
  };

  useEffect(() => {
    if (quizTakenId) {
      setMemorizingLoading(false);
      props.history.push({
        pathname: "/playcontent/" + quiz_deck_id,
        state: {
          ...props.location.state,
          quiz_taken_id: quizTakenId,
        },
      });
    }
  }, [quizTakenId]);

  const goToDashboard = () => {
    props.history.push("/dashboard");
  };

  const goToAddToFolders = () => {
    props.history.push({
      pathname: "/addtofolders",
      state: {
        quiz_deck_id: quiz_deck_id,
      },
    });
  };
  const resetContentsQuestions = () => {
    axios({
      method: "post",
      url: "/api/my/quiz/question/reset",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
      }),
    })
      .then((response) => {
        fetchQuestionsResetStatus();
      })
      .catch((error) => {});
  };
  const goToInviteUsersToTakeContent = () => {
    props.history.push({
      pathname: "/inviteusertotakecontent",
      state: {
        quiz_deck_id: quiz_deck_id,
      },
    });
  };

  const goToReportAbuseAndViolations = () => {
    props.history.push({
      pathname: "/reportabuse",
      state: {
        quiz_deck_id: quiz_deck_id,
        creator_name: quizDeckDetails.user_name,
      },
    });
  };

  const fetchOpenGroupRequest = (group_id) => {
    axios({
      method: "post",
      url: "/api/groups/open/request/status",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        group_id: group_id,
      }),
    })
      .then((response) => {
        console.log("response /open/request/status", response);
        setOpenGroupRequest(response.data.status ? true : false);
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        //showErrorMessageToUser(error.response.data.msg)
      });
  };
  const becomeMemberOfGroup = () => {
    console.log("quizDeckDetails.open_close", quizDeckDetails.open_close);
    console.log("quizDeckDetails.group_id", quizDeckDetails.group_id);
    console.log("user_id", user_id);

    axios({
      method: "post",
      url: "/api/groups/open/requesttoadd",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        group_id: quizDeckDetails.group_id,
      }),
    })
      .then((response) => {
        console.log("response groups/open/requesttoadd", response);
        showMessageToUser(response.data.msg);
        setOpenGroupRequest(true);
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  useEffect(() => {
    if (!quizDeckDetails || !quizDeckDetails.group_id) return;
    console.log("in useEffect");
    fetchOpenGroupRequest(quizDeckDetails.group_id);
  }, [quizDeckDetails, openGroupRequest]);

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <ToastContainer />

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("contentDetails")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("contentDetails")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {quizDeckDetails && creatorRatings >= 0 && loading === false ? (
        <div class="my-credits-wrapper">
          <div class="container">
            <div class="quiz-detail-page">
              <div class="row">
                <div class="col-lg-8 col-md-12">
                  <div class="purchase detail-purchase col-md-12 mx-auto">
                    <div class="row">
                      <div class="col-md-12 p-0">
                        <div class="firstfull-credit credit-member p-3">
                          <div class="detail-name firstfull-credit-001">
                            <p
                              style={{ fontSize: "30px" }}
                              class="text-white mb-0"
                              dangerouslySetInnerHTML={{
                                __html: quizDeckDetails.name,
                              }}
                            ></p>
                            {quizDeckDetails.is_official_quiz === 1 ? (
                              <span className="icon icon-verified"></span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(quizDeckDetails &&
                    memorizingLoading === false &&
                    (statusofthisquizDeck == 0 || statusofthisquizDeck == 1) &&
                    quizDeckDetails.group_id == null) ||
                  (quizDeckDetails.group_id &&
                    quizDeckDetails.is_member_of_group) ? (
                    <div class="d-flex justify-content-center mt-4 resp-btn">
                      {quizDeckDetails.amount > 0 &&
                      quizDeckDetails.is_buyed == 0 ? (
                        <div
                          class="cmplt-preview"
                          style={{ margin: "5px" }}
                          onClick={startMemorizingbypurchasing}
                        >
                          <a href="javascript:void(0)">
                            <p class="details-play-quiz">
                              Start memorizing for {quizDeckDetails.amount}{" "}
                              credits
                            </p>
                          </a>
                        </div>
                      ) : statusofthisquizDeck == 0 ? (
                        <div
                          class="cmplt-preview"
                          style={{ margin: "5px" }}
                          onClick={startMemorizingforfree}
                        >
                          <a href="javascript:void(0)">
                            <p class="details-play-quiz">
                              {t("start_memorizing_for_free")}
                            </p>
                          </a>
                        </div>
                      ) : (
                        <div
                          class="cmplt-preview"
                          style={{ margin: "5px" }}
                          onClick={startMemorizingforfree}
                        >
                          <a href="javascript:void(0)">
                            <p class="details-play-quiz">
                              {t("resume_memorizing")}
                            </p>
                          </a>
                        </div>
                      )}
                      {contentQuestionsReset ? (
                        <div
                          class="cmplt-preview"
                          style={{ margin: "5px" }}
                          onClick={resetContentsQuestions}
                        >
                          <a href="javascript:void(0)">
                            <p class="details-play-quiz">
                              {t("reset_content_questions")}
                            </p>
                          </a>
                        </div>
                      ) : null}
                      {user_type_id == 2 ? null : (
                        <div
                          class="cmplt-preview"
                          style={{ margin: "5px" }}
                          onClick={goToAddToFolders}
                        >
                          <a href="javascript:void(0)">
                            <p class="details-play-quiz">
                              {t("add_to_folders_in_details")}
                            </p>
                          </a>
                        </div>
                      )}

                      <div
                        class="cmplt-preview"
                        style={{ margin: "5px" }}
                        onClick={goToInviteUsersToTakeContent}
                      >
                        <a href="javascript:void(0)">
                          <p class="details-play-quiz">{t("inviteUsers")}</p>
                        </a>
                      </div>
                      <div
                        class="cmplt-preview"
                        style={{ margin: "5px" }}
                        onClick={goToReportAbuseAndViolations}
                      >
                        <a href="javascript:void(0)">
                          <p class="content-violation">
                            {<span class="icon icon-exclamation"></span>}
                          </p>
                        </a>
                      </div>

                      <div class="quiz-extra-info-008 mb-3 mobileView">
                        <div class="review-section">
                          <div class="row align-items-center">
                            {quizDeckDetails.languages.length > 0
                              ? (function language() {
                                  let languages = [];
                                  console.log("adsfadsf");
                                  languages = quizDeckDetails.languages.map(
                                    (language, index) => {
                                      switch (language.name) {
                                        case "English":
                                          return (
                                            <img
                                              key={index.toString()}
                                              src={`${awsImageUrl}/uploads/languages/usa.png`}
                                            />
                                          );
                                          break;
                                        case "Japanese":
                                          return (
                                            <img
                                              key={index.toString()}
                                              src={`${awsImageUrl}/uploads/languages/japan.png`}
                                            />
                                          );
                                          break;
                                        case "Chinese":
                                          return (
                                            <img
                                              key={index.toString()}
                                              src={`${awsImageUrl}/uploads/languages/china.png`}
                                            />
                                          );
                                          break;
                                        case "Hindi":
                                          return (
                                            <img
                                              key={index.toString()}
                                              src={`${awsImageUrl}/uploads/languages/india.png`}
                                            />
                                          );
                                          break;
                                      }
                                      //languages.push(t(language.name))
                                    }
                                  );
                                  return (
                                    <div className="col-md-6 language-img pr-0">
                                      {" "}
                                      {languages}{" "}
                                    </div>
                                  );
                                })()
                              : null}
                            {/* 
                                            <div class="col-md-6 language-img">
                                                <img src={`${awsImageUrl}/uploads/languages/japan.png"/>
                                                <img src={`${awsImageUrl}/uploads/languages/india.png"/>
                                                <img src={`${awsImageUrl}/uploads/languages/usa.png"/>                                                                  
                                            </div>
                                            */}
                            {/* <div class="col-md-6 user-content">*/}
                            {quizDeckDetails.types.length > 0
                              ? (function contentType() {
                                  let types = [];
                                  console.log("-------------------");
                                  types = quizDeckDetails.types.map(
                                    (typeo, index) => {
                                      console.log("type", typeo);
                                      switch (typeo.trim()) {
                                        case "Text":
                                          console.log("adding");
                                          return (
                                            <span
                                              key={index.toString()}
                                              class="icon icon-text"
                                            ></span>
                                          );
                                          break;
                                        case "Picture":
                                          return (
                                            <span
                                              key={index.toString()}
                                              class="icon icon-images1"
                                            ></span>
                                          );
                                          break;
                                        case "Audio":
                                          return (
                                            <span
                                              key={index.toString()}
                                              class="icon icon-audio1"
                                            ></span>
                                          );
                                          break;
                                        case "Video":
                                          return (
                                            <span
                                              key={index.toString()}
                                              class="icon icon-youtube"
                                            ></span>
                                          );
                                          break;
                                      }
                                      //languages.push(t(language.name))
                                    }
                                  );
                                  console.log(
                                    "quizDeckDetails.complexity_id",
                                    quizDeckDetails.complexity_id
                                  );
                                  switch (
                                    quizDeckDetails.complexity_id.toString()
                                  ) {
                                    case "1":
                                      console.log("adding");
                                      types.push(
                                        <span className="icon icon-expert"></span>
                                      );
                                      break;
                                    case "2":
                                      types.push(
                                        <span className="icon icon-intermediate"></span>
                                      );
                                      break;
                                    case "3":
                                      types.push(
                                        <span className="icon icon-beginner"></span>
                                      );
                                      break;
                                    case "4":
                                      types.push(
                                        <span className="icon icon-number1"></span>
                                      );
                                      break;
                                    case "5":
                                      types.push(
                                        <span className="icon icon-number1"></span>
                                      );
                                      break;
                                    case "6":
                                      types.push(
                                        <span className="icon icon-number2"></span>
                                      );
                                      break;
                                    case "7":
                                      types.push(
                                        <span className="icon icon-number2"></span>
                                      );
                                      break;
                                    case "8":
                                      types.push(
                                        <span className="icon icon-number3"></span>
                                      );
                                      break;
                                    case "9":
                                      types.push(
                                        <span className="icon icon-number4"></span>
                                      );
                                      break;
                                    case "10":
                                      types.push(
                                        <span className="icon icon-number5"></span>
                                      );
                                      break;
                                    case "11":
                                      types.push(
                                        <span className="icon icon-number6"></span>
                                      );
                                      break;
                                    case "12":
                                      types.push(
                                        <span className="icon icon-number7"></span>
                                      );
                                      break;
                                    case "13":
                                      types.push(
                                        <span className="icon icon-number8"></span>
                                      );
                                      break;
                                    case "14":
                                      types.push(
                                        <span className="icon icon-number9"></span>
                                      );
                                      break;
                                    case "15":
                                      types.push(
                                        <span className="icon icon-number1"></span>
                                      );
                                      types.push(
                                        <span className="icon icon-number0"></span>
                                      );
                                      break;
                                  }
                                  console.log("types.length", types.length);
                                  //types.push(<span class="icon icon-intermediate"></span>)
                                  //types.push(<span class="icon icon-number8"></span>)
                                  return (
                                    <div className="col-md-6 user-content">
                                      {" "}
                                      {types}{" "}
                                    </div>
                                  );
                                })()
                              : null}

                            {Math.round(quizDeckDetails.rating) > 0
                              ? (function star() {
                                  let stars = [];

                                  //
                                  let currentRating = Math.round(
                                    quizDeckDetails.rating
                                  );

                                  for (let i = 0; i < currentRating; i++) {
                                    stars.push(
                                      <span class="icon icon-star"></span>
                                    );
                                  }
                                  for (let j = currentRating; j < 5; j++) {
                                    stars.push(
                                      <span class="icon icon-star-border"></span>
                                    );
                                  }

                                  stars.push(
                                    <small>
                                      <a
                                        data-toggle="modal"
                                        data-target="#showReviews"
                                        href="javascript:void(0)"
                                      >
                                        {quizDeckDetails.reviews}
                                      </a>
                                    </small>
                                  );
                                  return (
                                    <div className="col-md-7 review-user">
                                      {stars}
                                    </div>
                                  );
                                })()
                              : null}
                            <div class="col-md-5 questions-section ml-auto">
                              <div class="d-flex content-right">
                                <span class=" icon icon-question2"></span>
                                <b class="count">
                                  {quizDeckDetails.questioncount}
                                </b>
                                <span class=" icon icon-download5"></span>
                                <b>
                                  {quizDeckDetails.quiz_taken
                                    ? quizDeckDetails.quiz_taken
                                    : 0}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <p class="mb-0">English , Chinese , Japanese &amp; <span class="detail-more"><a href="">+1 more</a></span></p> */}
                        {/* <div class="col-md-4">
                            <div class="quiz-extra-info-008 mb-3">
                                <div class="d-flex mb-2">
                                    <div class="p-2">
                                        <span className=" icon icon-language"></span>
                                    </div>
                                    <div class="p-2">
                                        
                                        <p class="mb-0">
                                            {
                                                languageNames.length < 4 ?
                                                (languageNames.join(", "))
                                                :
                                                (
                                                    showAllLanguages==false ? 
                                                    (
                                                        <React.Fragment>
                                                            {languageNames.slice(0,3).join(", ")}
                                                        <span 
                                                        class="detail-more"
                                                        onClick={showAllLanguagesFunction}     
                                                    >
                                                        <a href="#">+1 more</a>
                                                    </span>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    (
                                                        languageNames.join(", ")
                                                    )
                                                ) 
                                            }
                                            
                                        </p>
                                    </div>
                                </div> */}

                        {/* <img src={targetImg} alt="" /> */}
                        {/* <p class="mb-0">Pictures , Audio , Video , Text based Q&amp;A</p> */}
                        {/* <div class="d-flex mb-2">
                                    <div class="p-2">
                                        
                                        <span className="icon icon-target"></span>
                                    </div>
                                    <div class="p-2">
                                        
                                        {
                                            quizDeckDetails.types.length > 0 ?
                                            (
                                                function language  () {
                                                    let types = [];

                                                    quizDeckDetails.types.map(type => (
                                                        types.push(t(type.trim()))
                                                    ))
                                                    return <p class="mb-0">{types.join(', ')}</p>
                                                }()
                                            )
                                            :
                                            null
                                        }      
                                    </div>
                                </div> */}
                        {/* <div class="d-flex mb-2">
                                    <div class="p-2">
                                        <span className=" icon icon-download"></span>
                                    </div>
                                    <div class="p-2">
                                        <p class="mb-0">{quizDeckDetails.quiz_taken} {t("quizTaken")}</p>
                                    </div>
                                </div> */}
                        {/* <div class="d-flex mb-2">
                                    <div class="p-2 de-rat">
                                        <span className=" icon icon-star"></span>
                                    </div>

                                    <div class="p-2">
                                        <p class="mb-0">
                                            <span>
                                               {
                                                    quizDeckDetails.rating >= 0 
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            for (let i=1; i<=quizDeckDetails.rating; i++){
                                                                stars.push(<i class="fa fa-star" aria-hidden="true"></i>)
                                                            }
                                                            return stars
                                                        }()
                                                    )
                                                    : null
                                                }
                                                {
                                                    quizDeckDetails.rating >= 0 && quizDeckDetails.rating % 1 !== 0
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            stars.push(<i class="fa fa-star-half-o" aria-hidden="true"></i>)
                                                            return stars
                                                        }()
                                                    )
                                                    : null
                                                }
                                                {
                                                    quizDeckDetails.rating<5
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            for (let i=1; i<=5-(quizDeckDetails.rating); i++){
                                                                stars.push(<i class="fa fa-star-o" aria-hidden="true"></i>)
                                                            }
                                                            return stars
                                                        }()
                                                    )
                                                    :null
                                                }

                                                
                                                <a data-toggle="modal" data-target="#showReviews" href="javascript:void(0)">({quizDeckDetails.reviews} {t("reviews")})</a>
                                            </span>
                                         </p>
                                    </div>
                                </div> */}
                      </div>
                    </div>
                  ) : quizDeckDetails.group_id &&
                    !quizDeckDetails.is_member_of_group &&
                    !openGroupRequest ? (
                    <div class="d-flex justify-content-center mt-4 resp-btn">
                      <div
                        class="cmplt-preview"
                        style={{ margin: "5px" }}
                        onClick={becomeMemberOfGroup}
                      >
                        <a href="javascript:void(0)">
                          <p class="details-play-quiz">{t("become_member")}</p>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div class="d-flex justify-content-center mt-4 resp-btn">
                      <div class="cmplt-preview" style={{ margin: "5px" }}>
                        <a href="javascript:void(0)">
                          <p class="details-play-quiz">
                            {t("your_invitation_request_is_pending")}
                          </p>
                        </a>
                      </div>
                    </div>
                  )}

                  <div class="advertisement-slider">
                    <div class="row">
                      {adv && adv.length > 0
                        ? adv.map((ad) => (
                            <div class="col-md-6 col-sm-12">
                              <a class="item" target="_blank" href={ad.link}>
                                <img
                                  src={ADVERTISEMENT_PATH + ad.image}
                                  className="mb-2"
                                />
                              </a>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>

                  <div class="quiz-details-creation">
                    <div class="profile-creators">
                      {/* <a href="#" class="details_icon"><i class="fa fa-ellipsis-v"></i></a> */}
                      <div class="col-lg-12 col-xl-12 mb-4 p-0">
                        <div class="m-fold-001 sell-pro-003 content-profile-creator">
                          <div class="col-md-12">
                            <div className="row">
                              <div class="m-fold-pic col-xl-3 col-lg-12 col-md-3 p-0">
                                <div class="creator-profile creator-pro-001">
                                  <img
                                    href="#"
                                    onClick={() =>
                                      profileSelected(
                                        quizDeckDetails.created_by
                                      )
                                    }
                                    src={
                                      PROFILE_PIC + quizDeckDetails.profile_pic
                                    }
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div class="col-xl-4 col-lg-6 col-md-4 q-create-by p-0 col-6">
                                <div class="creator-text">
                                  <div class="d-flex flex-row align-items-center">
                                    <div class="creator-text-padding">
                                      <img src={userIcon} alt="" />
                                    </div>
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr pl-1">
                                        ({t("CreatedBy")}:{" "}
                                        <a
                                          href="#"
                                          onClick={() =>
                                            profileSelected(
                                              quizDeckDetails.created_by
                                            )
                                          }
                                        >
                                          {quizDeckDetails.created_by}
                                        </a>
                                        )
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row">
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            profileSelected(
                                              quizDeckDetails.created_by
                                            )
                                          }
                                        >
                                          {quizDeckDetails.user_name}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row">
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr">
                                        {creatorRatings >= 0 ? (
                                          <span>
                                            {creatorRatings >= 0
                                              ? (function star() {
                                                  let stars = [];
                                                  for (
                                                    let i = 1;
                                                    i <= creatorRatings;
                                                    i++
                                                  ) {
                                                    stars.push(
                                                      <i
                                                        class="fa fa-star"
                                                        aria-hidden="true"
                                                      ></i>
                                                    );
                                                  }
                                                  return stars;
                                                })()
                                              : null}
                                            {creatorRatings >= 0 &&
                                            creatorRatings % 1 !== 0
                                              ? (function star() {
                                                  let stars = [];
                                                  stars.push(
                                                    <i
                                                      class="fa fa-star-half-o"
                                                      aria-hidden="true"
                                                    ></i>
                                                  );
                                                  return stars;
                                                })()
                                              : null}
                                            {creatorRatings < 5
                                              ? (function star() {
                                                  let stars = [];
                                                  for (
                                                    let i = 1;
                                                    i <= 5 - creatorRatings;
                                                    i++
                                                  ) {
                                                    stars.push(
                                                      <i
                                                        class="fa fa-star-o"
                                                        aria-hidden="true"
                                                      ></i>
                                                    );
                                                  }
                                                  return stars;
                                                })()
                                              : null}
                                          </span>
                                        ) : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-5 col-lg-6 col-md-5 q-create-by p-0 col-6">
                                <div class="creator-text">
                                  <div class="d-flex flex-row">
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr">
                                        ({t("content_id")}: {quizDeckDetails.id}
                                        )
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row align-items-center">
                                    <div class="creator-text-padding">
                                      <img src={calendarImg} alt="" />
                                    </div>
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr pl-1">
                                        {t("last_updated")}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row">
                                    <div class="creator-text-padding">
                                      <p class="mb-0 quiz-cr">
                                        {getDate(quizDeckDetails.updated_at)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {
                                                quizDeckDetails.description
                                                ?
                                                (
                                                    <div class="detail-profile-arro d-flex align-items-center justify-content-center" id="detail-profile-arro"
                                                            onClick={toggleDeckDescription}
                                                    >
                                                            <img src={arrowImg} alt="" />
                                                    </div>
                                                )
                                                :
                                                null
                                            } */}
                    </div>
                  </div>

                  {quizDeckDetails.description ? (
                    <div
                      class="quiz-details-text col-md-12 p-0"
                      id="deckDescription"
                    >
                      <div class="detail-001-text">
                        <p
                          dangerouslySetInnerHTML={
                            descriptionVisibilty
                              ? { __html: quizDeckDetails.description }
                              : null
                          }
                        ></p>
                      </div>
                    </div>
                  ) : null}

                  {/* <div class="purchase detail-purchase col-md-12 mx-auto">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="firstfull-credit credit-member p-3">
                                                    <div class="d-flex flex-row">
                                                        <div class="p-2 ml-2 member-ship">
                                                            <img src={diamondImg} alt="" />
                                                        </div>
                                                        <div class="p-2 d-flex align-items-center firstfull-credit-001">
                                                            <p class="text-white mb-0">{t("sign_up_for_paid_memebership_to_get_flat_1_off_on_all_quizes_and_downloads")}</p>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                    </div> */}

                  {/* <img src={complexityImg} alt="#" /> */}
                  {/* <img src={expImg} alt="#" /> */}
                  {/* <div class="detail-genres mt-5 col-md-12">
                                        <div class="detail-song">
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div class="bollywood-content extreum-cont">
                                                        <span className="icon icon-question1"></span>
                                                        <h5 class="mb-0 ">
                                                            # {t("question")}
                                                        </h5>
                                                        <h4 class="mt-3 det-num-quiz">{quizDeckDetails.questions.length}</h4>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div class="bollywood-content extreum-cont">
                                                    
                                                        <span className="icon icon-bulb"></span>
                                                        <h5 class="mb-0">
                                                            {t("hints")}
                                                        </h5>
                                                        <h4 class="mt-3 detail-availa-002">
                                                            {
                                                                quizDeckDetails.hints_available == 0 
                                                                ?  
                                                                (
                                                                    t("not_available")
                                                                )
                                                                :t("available")
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                                

                                                
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div class="bollywood-content extreum-cont">
                                                        
                                                        <span className="icon icon-chart"></span>
                                                        <h5 class="mb-0">
                                                            {t("Complex")}
                                                        </h5>
                                                        <h4 class="mt-3 details-expertaion">{quizDeckDetails.complexity_name}</h4>
                                                    </div>
                                                </div>
                
                                            </div>
                                        </div> 
                                    </div> */}
                </div>

                <div class="col-md-12 col-lg-4">
                  <div class="quiz-extra-info-008 desktopView mb-3">
                    <div class="review-section">
                      <div class="row align-items-center">
                        {quizDeckDetails.languages.length > 0
                          ? (function language() {
                              let languages = [];
                              console.log("adsfadsf");
                              languages = quizDeckDetails.languages.map(
                                (language, index) => {
                                  switch (language.name) {
                                    case "English":
                                      return (
                                        <img
                                          key={index.toString()}
                                          src={`${awsImageUrl}/uploads/languages/usa.png`}
                                        />
                                      );
                                      break;
                                    case "Japanese":
                                      return (
                                        <img
                                          key={index.toString()}
                                          src={`${awsImageUrl}/uploads/languages/japan.png`}
                                        />
                                      );
                                      break;
                                    case "Chinese":
                                      return (
                                        <img
                                          key={index.toString()}
                                          src={`${awsImageUrl}/uploads/languages/china.png`}
                                        />
                                      );
                                      break;
                                    case "Hindi":
                                      return (
                                        <img
                                          key={index.toString()}
                                          src={`${awsImageUrl}/uploads/languages/india.png`}
                                        />
                                      );
                                      break;
                                  }
                                  //languages.push(t(language.name))
                                }
                              );
                              return (
                                <div className="col-md-6 language-img pr-0">
                                  {" "}
                                  {languages}{" "}
                                </div>
                              );
                            })()
                          : null}
                        {/* 
                                            <div class="col-md-6 language-img">
                                                <img src={`${awsImageUrl}/uploads/languages/japan.png"/>
                                                <img src={`${awsImageUrl}/uploads/languages/india.png"/>
                                                <img src={`${awsImageUrl}/uploads/languages/usa.png"/>                                                                  
                                            </div>
                                            */}
                        {/* <div class="col-md-6 user-content">*/}
                        {quizDeckDetails.types.length > 0
                          ? (function contentType() {
                              let types = [];
                              console.log("-------------------");
                              types = quizDeckDetails.types.map(
                                (typeo, index) => {
                                  console.log("type", typeo);
                                  switch (typeo.trim()) {
                                    case "Text":
                                      console.log("adding");
                                      return (
                                        <span
                                          key={index.toString()}
                                          class="icon icon-text"
                                        ></span>
                                      );
                                      break;
                                    case "Picture":
                                      return (
                                        <span
                                          key={index.toString()}
                                          class="icon icon-images1"
                                        ></span>
                                      );
                                      break;
                                    case "Audio":
                                      return (
                                        <span
                                          key={index.toString()}
                                          class="icon icon-audio1"
                                        ></span>
                                      );
                                      break;
                                    case "Video":
                                      return (
                                        <span
                                          key={index.toString()}
                                          class="icon icon-youtube"
                                        ></span>
                                      );
                                      break;
                                  }
                                  //languages.push(t(language.name))
                                }
                              );
                              console.log(
                                "quizDeckDetails.complexity_id",
                                quizDeckDetails.complexity_id
                              );
                              switch (
                                quizDeckDetails.complexity_id.toString()
                              ) {
                                case "1":
                                  console.log("adding");
                                  types.push(
                                    <span className="icon icon-expert"></span>
                                  );
                                  break;
                                case "2":
                                  types.push(
                                    <span className="icon icon-intermediate"></span>
                                  );
                                  break;
                                case "3":
                                  types.push(
                                    <span className="icon icon-beginner"></span>
                                  );
                                  break;
                                case "4":
                                  types.push(
                                    <span className="icon icon-number1"></span>
                                  );
                                  break;
                                case "5":
                                  types.push(
                                    <span className="icon icon-number1"></span>
                                  );
                                  break;
                                case "6":
                                  types.push(
                                    <span className="icon icon-number2"></span>
                                  );
                                  break;
                                case "7":
                                  types.push(
                                    <span className="icon icon-number2"></span>
                                  );
                                  break;
                                case "8":
                                  types.push(
                                    <span className="icon icon-number3"></span>
                                  );
                                  break;
                                case "9":
                                  types.push(
                                    <span className="icon icon-number4"></span>
                                  );
                                  break;
                                case "10":
                                  types.push(
                                    <span className="icon icon-number5"></span>
                                  );
                                  break;
                                case "11":
                                  types.push(
                                    <span className="icon icon-number6"></span>
                                  );
                                  break;
                                case "12":
                                  types.push(
                                    <span className="icon icon-number7"></span>
                                  );
                                  break;
                                case "13":
                                  types.push(
                                    <span className="icon icon-number8"></span>
                                  );
                                  break;
                                case "14":
                                  types.push(
                                    <span className="icon icon-number9"></span>
                                  );
                                  break;
                                case "15":
                                  types.push(
                                    <span className="icon icon-number1"></span>
                                  );
                                  types.push(
                                    <span className="icon icon-number0"></span>
                                  );
                                  break;
                              }
                              console.log("types.length", types.length);
                              //types.push(<span class="icon icon-intermediate"></span>)
                              //types.push(<span class="icon icon-number8"></span>)
                              return (
                                <div className="col-md-6 user-content">
                                  {" "}
                                  {types}{" "}
                                </div>
                              );
                            })()
                          : null}

                        {Math.round(quizDeckDetails.rating) > 0
                          ? (function star() {
                              let stars = [];

                              //
                              let currentRating = Math.round(
                                quizDeckDetails.rating
                              );

                              for (let i = 0; i < currentRating; i++) {
                                stars.push(
                                  <span class="icon icon-star"></span>
                                );
                              }
                              for (let j = currentRating; j < 5; j++) {
                                stars.push(
                                  <span class="icon icon-star-border"></span>
                                );
                              }

                              stars.push(
                                <small>
                                  <a
                                    data-toggle="modal"
                                    data-target="#showReviews"
                                    href="javascript:void(0)"
                                  >
                                    {quizDeckDetails.reviews}
                                  </a>
                                </small>
                              );
                              return (
                                <div className="col-md-7 review-user">
                                  {stars}
                                </div>
                              );
                            })()
                          : null}
                        <div class="col-md-5 questions-section ml-auto">
                          <div class="d-flex content-right">
                            <span class=" icon icon-question2"></span>
                            <b class="count">{quizDeckDetails.questioncount}</b>
                            <span class=" icon icon-download5"></span>
                            <b>
                              {quizDeckDetails.quiz_taken
                                ? quizDeckDetails.quiz_taken
                                : 0}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <p class="mb-0">English , Chinese , Japanese &amp; <span class="detail-more"><a href="">+1 more</a></span></p> */}
                    {/* <div class="col-md-4">
                            <div class="quiz-extra-info-008 mb-3">
                                <div class="d-flex mb-2">
                                    <div class="p-2">
                                        <span className=" icon icon-language"></span>
                                    </div>
                                    <div class="p-2">
                                        
                                        <p class="mb-0">
                                            {
                                                languageNames.length < 4 ?
                                                (languageNames.join(", "))
                                                :
                                                (
                                                    showAllLanguages==false ? 
                                                    (
                                                        <React.Fragment>
                                                            {languageNames.slice(0,3).join(", ")}
                                                        <span 
                                                        class="detail-more"
                                                        onClick={showAllLanguagesFunction}     
                                                    >
                                                        <a href="#">+1 more</a>
                                                    </span>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    (
                                                        languageNames.join(", ")
                                                    )
                                                ) 
                                            }
                                            
                                        </p>
                                    </div>
                                </div> */}

                    {/* <img src={targetImg} alt="" /> */}
                    {/* <p class="mb-0">Pictures , Audio , Video , Text based Q&amp;A</p> */}
                    {/* <div class="d-flex mb-2">
                                    <div class="p-2">
                                        
                                        <span className="icon icon-target"></span>
                                    </div>
                                    <div class="p-2">
                                        
                                        {
                                            quizDeckDetails.types.length > 0 ?
                                            (
                                                function language  () {
                                                    let types = [];

                                                    quizDeckDetails.types.map(type => (
                                                        types.push(t(type.trim()))
                                                    ))
                                                    return <p class="mb-0">{types.join(', ')}</p>
                                                }()
                                            )
                                            :
                                            null
                                        }      
                                    </div>
                                </div> */}
                    {/* <div class="d-flex mb-2">
                                    <div class="p-2">
                                        <span className=" icon icon-download"></span>
                                    </div>
                                    <div class="p-2">
                                        <p class="mb-0">{quizDeckDetails.quiz_taken} {t("quizTaken")}</p>
                                    </div>
                                </div> */}
                    {/* <div class="d-flex mb-2">
                                    <div class="p-2 de-rat">
                                        <span className=" icon icon-star"></span>
                                    </div>

                                    <div class="p-2">
                                        <p class="mb-0">
                                            <span>
                                               {
                                                    quizDeckDetails.rating >= 0 
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            for (let i=1; i<=quizDeckDetails.rating; i++){
                                                                stars.push(<i class="fa fa-star" aria-hidden="true"></i>)
                                                            }
                                                            return stars
                                                        }()
                                                    )
                                                    : null
                                                }
                                                {
                                                    quizDeckDetails.rating >= 0 && quizDeckDetails.rating % 1 !== 0
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            stars.push(<i class="fa fa-star-half-o" aria-hidden="true"></i>)
                                                            return stars
                                                        }()
                                                    )
                                                    : null
                                                }
                                                {
                                                    quizDeckDetails.rating<5
                                                    ?
                                                    (
                                                        function star  () {
                                                            let stars = [];
                                                            for (let i=1; i<=5-(quizDeckDetails.rating); i++){
                                                                stars.push(<i class="fa fa-star-o" aria-hidden="true"></i>)
                                                            }
                                                            return stars
                                                        }()
                                                    )
                                                    :null
                                                }

                                                
                                                <a data-toggle="modal" data-target="#showReviews" href="javascript:void(0)">({quizDeckDetails.reviews} {t("reviews")})</a>
                                            </span>
                                         </p>
                                    </div>
                                </div> */}
                  </div>

                  <div class="detail-genres">
                    <h5 style={{ color: "#1d5190!important" }}>{t("gener")}</h5>

                    <div class="detail-song">
                      <div class="row">
                        {quizDeckDetails.genres.length > 0
                          ? quizDeckDetails.genres.map((genre) => (
                              <div class="col-xl-4 col-lg-6 col-sm-6 col-6 mb-3">
                                <div class="bollywood-content content-001">
                                  <img src={GENER_PATH + genre.image} alt="#" />
                                  <p
                                    class="mb-0"
                                    dangerouslySetInnerHTML={
                                      genre.name ? { __html: genre.name } : null
                                    }
                                  ></p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>

                  <div class="detail-genres">
                    <h5 style={{ color: "#1d5190!important" }}>
                      {t("category")}
                    </h5>

                    <div class="detail-song">
                      <div class="row">
                        {quizDeckDetails.categories.length > 0
                          ? quizDeckDetails.categories.map((category) => (
                              <div class="col-xl-4 col-lg-6 col-sm-6 col-6 mb-3 d-flex justify-content-center">
                                <div class=" d-flex justify-content-start flex-column align-items-center">
                                  <div class="details-cate-content">
                                    <img
                                      src={CATEGORY_PATH + category.image}
                                      alt="#"
                                    />
                                  </div>
                                  <p
                                    class="mb-0 cate-tex"
                                    dangerouslySetInnerHTML={
                                      category.name
                                        ? { __html: category.name }
                                        : null
                                    }
                                  ></p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>

                  {quizDeckDetails.sub_categories &&
                  quizDeckDetails.sub_categories.length > 0 ? (
                    <div class="detail-genres">
                      <h5 style={{ color: "#1d5190!important" }}>
                        {t("sub_category")}
                      </h5>
                      {quizDeckDetails.sub_categories.map((sub_category) => (
                        <div class="detail-song">
                          <div class="row">
                            <div class="col-xl-4 col-lg-6 col-sm-6 col-6 mb-3 d-flex justify-content-center">
                              <div class="d-flex justify-content-start flex-column align-items-center">
                                <div class="details-cate-content">
                                  <img
                                    src={CATEGORY_PATH + sub_category.image}
                                    alt="#"
                                  />
                                </div>
                                <p
                                  class="mb-0 cate-tex"
                                  dangerouslySetInnerHTML={
                                    sub_category.name
                                      ? { __html: sub_category.name }
                                      : null
                                  }
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <div class="detail-genres">
                    <h5 style={{ color: "#1d5190!important" }}>
                      {t("keyword")}
                    </h5>

                    <div class="detail-song">
                      <div class="row">
                        {quizDeckDetails.keywords.length > 0
                          ? quizDeckDetails.keywords.map((keyword) => (
                              <div class="col-xl-4 col-lg-6 col-sm-6 col-6 mb-3">
                                <div class="bollywood-content content-001">
                                  {/* <img src={GENER_PATH + genre.image} alt="#" /> */}
                                  <p class="mb-0">{keyword.name}</p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="showReviews" class="modal fade" role="dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">{t("reviews")}</h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="reviews-listing">
                      {quizDeckDetails.reviewsText.length > 0
                        ? quizDeckDetails.reviewsText.map((review) => (
                            <div class="review-list">
                              <div class="user-img">
                                <img src={PROFILE_PIC + review.profile_pic} />
                              </div>
                              <div>
                                <div>{review.name}</div>
                                <div class="stars">
                                  {review.rating > 0
                                    ? (function star() {
                                        let stars = [];
                                        for (
                                          let i = 1;
                                          i <= review.rating;
                                          i++
                                        ) {
                                          stars.push(
                                            <span
                                              onClick={() => setRatingStars(i)}
                                              class="icon-star active"
                                            ></span>
                                          );
                                        }
                                        return stars;
                                      })()
                                    : null}
                                  {5 - review.rating > 0
                                    ? (function star() {
                                        let stars = [];
                                        for (
                                          let i = 1;
                                          i <= 5 - review.rating;
                                          i++
                                        ) {
                                          stars.push(
                                            <span
                                              onClick={() =>
                                                setRatingStars(
                                                  review.rating + i
                                                )
                                              }
                                              class="icon-star"
                                            ></span>
                                          );
                                        }
                                        return stars;
                                      })()
                                    : null}
                                </div>
                                <div class="comment">{review.review}</div>
                              </div>
                            </div>
                          ))
                        : "No Ratings Available"}
                    </div>
                  </div>
                  <div class="modal-footer">
                    <a
                      href="javascript:void(0)"
                      class="btn-background blue-bg-btn"
                      data-dismiss="modal"
                    >
                      {t("close")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : // <Loader
      //     style={{position: "fixed", top: "40%", left: "50%", transform: "translate(-50%, -50%)"}}
      //     type="ThreeDots"
      //     color="#00BFFF"
      //     height={100}
      //     width={100}
      // // timeout={3000} //3 secs

      // />
      null}

      {/*Modal Dialog*/}
      <p
        class="details-play-quiz"
        id="modalOpenButtonCDP"
        data-toggle="modal"
        data-target="#freeCheck1"
        style={{ display: "none" }}
      >
        Open Modal
      </p>

      <div
        class="modal fade add-rate-review-info"
        id="freeCheck1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="freeCheck"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Access rights
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <p>
                  <strong>{t("free_user_cannot_memorize_5_contents")}</strong>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div class="save-cancel-btn">
                <a
                  data-dismiss="modal"
                  onClick={goToMyBecomeAPremiumUser}
                  href="javascript:void(0)"
                  class="btn-border blue-bg-btn"
                >
                  {t("become_a_premium_member")}
                </a>
                {/**
                                <a
                                    data-dismiss="modal"
                                    // onClick={skipReview} 
                                    href="javascript:void(0)" class="btn-background blue-bg-btn">
                                    {t('skip')}
                            </a>
                             */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContentDetailPage;
