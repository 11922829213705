/* eslint-disable */ 
import React, { useEffect, useState ,useContext} from 'react';
import axios from 'axios';
import BasicFooter from './BasicFooter';
import BasicHeader from './BasicHeader';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

import "../less/my-credits.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/emails.css"

import "../less/test.css"
import "../less/seller.css"
import Pagination from './Pagination';
import {Context } from '../Reducers/Store'
import { useCookies } from "react-cookie";
//import imgbanner from "../images/banner.png"


function Emails(props) {
    const PAGE_LIMIT = 10;
    
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])
    const user = cookies['user'] //getUser();
    const token = cookies['access_token'] //getToken();

    const [state, dispatch] = useContext(Context)
    const [invitationList, setInvitationList] = useState(null)
    const [toggleRefreshData, setToggleRefreshData] = useState(false)
    // Pagination
    const [totalInvitationPages, setTotalInvitationPages] = useState(null);
    const [totalInvitation, setTotalInvitation] = useState(null);
    const [currentInvitations, setCurrentInvitations] = useState(1);

    useEffect (() => {
        dispatch({type: 'UPDATE_CURRENT_MENU',payload:'id_invite_page'})
    }, []);

    const fetchInvitations = async (currentPage = 1)=> {
        axios({
            method: 'post',
            url: '/api/my/invitation/emails/list',
            headers:{
                'locale': "en",
                'x-access-token': "Memo "+token,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data : qs.stringify({
                page : currentPage,
                limit : PAGE_LIMIT

            })
        })
        .then(response => {
            console.log("response", response.data)
            setInvitationList(response.data)
            setTotalInvitationPages(response.data.total_pages)
            setTotalInvitation(response.data.total);
        }).catch(error => {
        // removeUserSession();
        });
    }
    
    useEffect(()=>{
        fetchInvitations()
    }, [toggleRefreshData])
    
    const onInvitationPageChanged = (data) => {
        //setLoading(true);
        console.log('data.currentPage='+data.currentPage)
        fetchInvitations(data.currentPage);
        setCurrentInvitations(data.currentPage);  
    }

    const acceptInvitation = (email) => {
        console.log('Accept Invitation')
        switch (email.invitation_type) {
            case 5:
                axios({
                    method: 'get',
                    url: '/api/invitation/accept/' + email.invitation_id,
                    headers:{
                        'locale': "en",
                        'x-access-token': "Memo "+token,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then(response => {
                    console.log("response", response.data)
                    setToggleRefreshData(!toggleRefreshData)
                }).catch(error => {
                
                });
            break;
            case 0:
                //Accept the invitation.
                axios({
                    method: 'get',
                    url: '/api/invitation/accept/' + email.invitation_id,
                    headers:{
                        'locale': "en",
                        'x-access-token': "Memo "+token,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then(response => {
                    console.log("response", response.data)
                    //setToggleRefreshData(!toggleRefreshData)
                    //Play the Quiz
                    props.history.push({
                        pathname: '/contentdetails/'+ email.quiz_deck_id,
                        
                    })
                }).catch(error => {
                
                });
        }
    }
    const deleteInvitation = (email) => {
        console.log('Delete Invitation')
        switch (parseInt(email.invitation_type)) {
            case 5:case 0:
                axios({
                    method: 'get',
                    url: '/api/invitation/delete/' + email.invitation_id,
                    headers:{
                        'locale': "en",
                        'x-access-token': "Memo "+token,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then(response => {
                    console.log("response", response.data)
                    setToggleRefreshData(!toggleRefreshData)
                }).catch(error => {
                
                });
            break;
        }
    }

    return (
        <React.Fragment>
            {/* <BasicHeader 
                {...props}
            /> */}
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-6 col-lg-6">
                            <h2 class="animated fadeInLeft">{t("invitation")}</h2>
                        </div>
                        <div class="col-sm-7 col-md-6 col-lg-6">
                            <div class="breadcrumb-item animated fadeInRight">
                                <ul>
                                    <li><a href="javascript:void(0)"><span class="icon-home1"></span> {t("home")}</a></li>
                                    <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)" class="active">{t("invitation")}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-sm-10 col-md-9 col-lg-8">
                            <div class="emails-list-box animated fadeInRight">                              
                                <div id="accordion" class="faq-tabs animated fadeInRight">
                                {
                                    (invitationList && invitationList.data.length > 0) ? invitationList.data.map((email, index) =>(
                                        (email.invitation_type === 5) ?
                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h5 class="mb-0">
                                                        <a href="javascript:void(0)" class="" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne" dangerouslySetInnerHTML={{__html:"You are invited to \""+email.group_data.group_name+ "\""}}></a>
                                                    </h5>
                                                </div>
                                                <div id={"collapse" + index} class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <div class="info">
                                                            <p dangerouslySetInnerHTML = { email.group_data.group_description ? {__html: email.group_data.group_description }: null }></p>
                                                            <p>{t('please_accept_the_invitation')} </p>
                                                        </div>
                                                        <div class="action-btn">
                                                            <a href="javascript:void(0)" class="btn-background" onClick={(e) => {e.preventDefault(); acceptInvitation(email)}}>{t("accept")}</a>
                                                            <a href="javascript:void(0)" class="btn-border" onClick={(e) => {e.preventDefault(); deleteInvitation(email)}}> {t("delete")}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : 
                                            <div class="card">
                                                <div class="card-header" id="headingOne">
                                                    <h5 class="mb-0">
                                                        <a href="javascript:void(0)" class="" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne" dangerouslySetInnerHTML={{__html:"You are invited to Play \""+email.quiz_data.name+ "\""}}></a>
                                                    </h5>
                                                </div>
                                                <div id={"collapse" + index} class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <div class="info">
                                                            {/*<p>{email.quiz_data.description}</p>*/}
                                                            <p dangerouslySetInnerHTML = {email.quiz_data.description ? {__html: email.quiz_data.description} : null}></p>
                                                            <p>If you are interested please accept the invitation</p>
                                                        </div>
                                                        <div class="action-btn">
                                                            <a href="javascript:void(0)" class="btn-background" onClick={(e) => {e.preventDefault(); acceptInvitation(email)}}>{t("see_details")}</a>
                                                            <a href="javascript:void(0)" class="btn-border" onClick={(e) => {e.preventDefault(); deleteInvitation(email)}}>{t("delete")}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )) 
                                        :
                                        null
                                    }
                                    
                                </div>
                            </div>
                            {
                                totalInvitation > PAGE_LIMIT ?
                                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                        <Pagination totalRecords={totalInvitation} totalPages={totalInvitationPages} currentPage={currentInvitations} pageLimit={PAGE_LIMIT} pageNeighbours={1} onPageChanged={onInvitationPageChanged} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <BasicFooter /> */}
        </React.Fragment>
    )
}

export default Emails
