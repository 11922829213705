/* eslint-disable */ 

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function FacebookDeletionStatus(props) {

    const { t } = useTranslation()
    const { search } = useLocation();
    const [user_id, setUser_id] = useState(null)

    useEffect(()=>{
        let query_params = new URLSearchParams(search)
        let userID = query_params.get('id')
        console.log('user id to be deleted', query_params.get('id'))
        setUser_id(userID.substring(userID.indexOf('_')+1,userID.length))
    },[])


    return (
        <React.Fragment>
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h2 class="animated fadeInLeft">Facebook data of user with id {user_id} is deleted Successfully </h2>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FacebookDeletionStatus
