/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/invite.css";
import "../less/kyc-details.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import { useTranslation } from "react-i18next";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../less/quill-editor.css";
//import { formats } from '../Utils/quill-editor';
import { useCookies } from "react-cookie";
import useCustomQuill from "../Hooks/useCustomQuill";
import ReactQuillComponent from "./ReactQuillComponent";

function CreateGroup(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const user = cookies["user"]; //getUser();
  const token = cookies["access_token"]; //getToken();

  //console.log(user)
  const user_type = user.user_type_id;
  const user_name = user.name;
  const user_id = user.id;
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [organization, setOrganization] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [profile_pic_filename, setProfile_pic_filename] = useState("");

  const [open_close, setOpenClose] = useState(0);

  const editor = useRef(null);

  const [languages, setLanguages] = useState([]);
  const [selecetedLanguage, setSelectedLanguage] = useState(1); //English
  const [allQuillRefs] = useCustomQuill(t("group_description"), []);

  const [banners, setBanners] = useState();
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  console.log("allQuillRefs", allQuillRefs);
  const showMessageToUser = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    console.log("Inside useEffect allQuillRefs", allQuillRefs);
  }, [allQuillRefs]);

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const addGroup = () => {
    let localDesc;
    for (let i = 0; i < allQuillRefs.current.length; i++) {
      console.log(
        "thisQuill.root.innerHTML",
        allQuillRefs.current[i].quill.root.innerHTML
      );
      localDesc = allQuillRefs.current[i].quill.root.innerHTM;
    }

    localDesc = document.querySelector(
      "#editor" + "0" + " .ql-editor"
    ).innerHTML;
    //console.log('thisQuill.root.innerHTML',thisQuill.root.innerHTML)

    // localDesc = document.querySelector('#editor' +'0' +' .ql-editor').innerHTML;
    // localDesc = thisQuill.root.innerHTML;

    axios({
      method: "post",
      url: "/api/groups/create",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        language_id: globalLangId,
        group_name: groupName,
        group_description: localDesc,
        organization: organization,
        profile_pic: profile_pic_filename,
        open_close: open_close,
        type_of_payment: 0,
      }),
    })
      .then((response) => {
        console.log("response", response);
        showMessageToUser(response.data.msg);
        props.history.push({
          pathname: "/mygroups",
        });
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onFileUploadChange = (event) => {
    console.log(event.target.files[0]);

    setProfile_pic(event.target.files[0]);
  };

  useEffect(() => {
    console.log("profile_pic", profile_pic);

    if (profile_pic) {
      const fd = new FormData();
      fd.append("file", profile_pic);
      fd.append("filename", profile_pic.name);

      axios({
        method: "post",
        url: "/api/groups/profile/image/upload",
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
          "Content-Type": "multipart/form-data",
          //'enctype': "multipart/form-data"
        },
        data: fd,
      })
        .then((response) => {
          console.log("response.data.data.file_name", response);
          setProfile_pic_filename(response.data.data.file_name);

          console.log("file_name=" + response.data.data.file_name);
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
          console.log("error", error);
        });
    }
  }, [profile_pic]);

  const onChangeOpenCloseGroup = (event) => {
    console.log("event.target", event.target);
    let value = event.target.value;
    console.log("event.target.id", event.target.id);
    console.log("event.target.value", value);
    setOpenClose(open_close == 0 ? 1 : 0);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("create_group")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("create_group")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      {/* 
            <div class="container">
                <div class="row justify-content-center">
                
                    <div class="col-sm-10 col-md-8 col-lg-7">                    
                        <div class="create-group">
                            <div class="create-group-in animated fadeInRight">
                                
                                <div>
                                    <input type="text" placeholder={t("group_name")} 
                                        value={groupName}
                                        onChange={e => setGroupName(e.target.value)}
                                    />
                                </div>
                                <div class="profile-image">
                                    <img src={GROUPS_PATH+profile_pic_filename} alt="Profile Img" />
                                    <div class="edit-profile">
                                        <a href="javascript:void(0)" class="edit-profile">
                                            <span class="icon-camera1"></span>
                                            
                                        </a>
                                        
                                        <input type="file" 
                                            id = "questionUploadButton" 
                                            onChange={onFileUploadChange}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <textarea placeholder={t("organization")} 
                                        value={organization}
                                        onChange={e => setOrganization(e.target.value)}
                                    >

                                    </textarea>
                                </div>
                                <div class="custom-control custom-radio" onChange={onChangeOpenClose}>
                                    <input type="radio" class="custom-control-input" 
                                        id={"option" + 1} 
                                        name="Option" value="1" 
                                        checked =  {open_close == "1"}
                                    />
                                    <label class="custom-control-label" for={"option" + 1}>
                                            {t("open_group")}
                                    </label>
                                    <input type="radio" class="custom-control-input" 
                                        id={"option" + 2} 
                                        name="Option" value="0" 
                                        checked =  {open_close == "0"} 
                                    />
                                    <label class="custom-control-label"  for={"option" + 2} >
                                            {t("close_group")}
                                    </label>
                                </div>
                                <div>
                                    <JoditEditor
                                        id={"group_desc"}
                                        ref={editor}
                                        config={editorConfig}
                                        value={groupDesc}
                                        tabIndex={1}
                                        onChange={e => setGroupDesc(e)}
                                    />
           

                                </div>                            
                            </div>


                            <div class="row justify-content-center mt-5">
                                <div class="col-sm-12 col-md-12 col-lg-4 animated fadeInLeft">
                                    <a href="javascript:void(0)" class="btn-background btn-block blue-bg-btn"
                                        onClick={addGroup}
                                    >{t("create")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    */}
      <div class="container">
        <div class="adminPanel createGroup">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-7">
              <div class="adminPanelInner">
                <div class="groupadminArea position-relative">
                  <div class="adminCreateBox">
                    <div class="mb-4">
                      <input
                        type="text"
                        placeholder={t("group_name")}
                        class="bb-1 blueThemeText"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      ></input>
                    </div>
                    <div class="mb-4">
                      <input
                        type="text"
                        placeholder={t("organization")}
                        class="bb-1 blueThemeText"
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div class="logoUploadArea">
                    <div class="logoUpload position-relative">
                      <input
                        type="file"
                        class="custom-file-input"
                        onChange={onFileUploadChange}
                      ></input>
                      <img
                        src={GROUPS_PATH + profile_pic_filename}
                        alt=""
                        class="groupLogoImg img-fluid"
                      ></img>
                      <div class="logoText">{t("logo")}</div>
                      <div class="cameraIcon">
                        <span class="icon icon-camera2"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="groupStatusArea d-flex justify-content-between">
                  <div class="closedGroup d-flex align-items-center">
                    <span class="closedLock">
                      <span class="icon-lock"></span>
                    </span>
                    <label class="checkContainer ml-2 blueThemeText">
                      {t("close_group")}
                      <input
                        type="radio"
                        name="groupCheck"
                        id="id_close_group"
                        defaultChecked={open_close == 0 ? true : false}
                        onChange={(event) => onChangeOpenCloseGroup(event)}
                      ></input>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="openGroup d-flex align-items-center">
                    <span class="openLock">
                      <span className="icon icon-unlock"></span>
                    </span>
                    <label class="checkContainer ml-2 blueThemeText">
                      {t("open_group")}
                      <input
                        type="radio"
                        name="groupCheck"
                        id="id_open_group"
                        defaultChecked={open_close == 1 ? true : false}
                        onChange={(event) => onChangeOpenCloseGroup(event)}
                      ></input>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="richTextEditor groupAdminRichText">
                  <ReactQuillComponent language_id={0}></ReactQuillComponent>
                  {/*
                                    <ReactQuill class="form-control" id={"group_desc"}
                                        placeholder={t("group_description")}
                                        modules={modules}
                                        formats={formats} />
                                     */}
                </div>
                <div class="createGroupBtn mt-4">
                  <a href="javascript:void(0)" onClick={() => addGroup()}>
                    <span class="icon icon-next"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}

      <ToastContainer />
    </React.Fragment>
  );
}

export default CreateGroup;
