/* eslint-disable */ 
import React from 'react'
import { useTranslation } from 'react-i18next';
import "../less/dashboard.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/news.css"

import newsImg from "../images/news/image.png"
import newsImg1 from "../images/news/image-1.png"
import newsImg2 from "../images/news/image-2.png"
import newsImg3 from "../images/news/image-3.png"
import newsImg4 from "../images/news/image-4.png"
import newsImg5 from "../images/news/image-5.png"
import newsImg6 from "../images/news/image-6.png"
import newsImg7 from "../images/news/image-7.png"
import newsImg8 from "../images/news/image-8.png"


function News(props) {

    const { t } = useTranslation()

    const goToDashboard = () => {
        props.history.push('/dashboard');
    }

    return (
        <React.Fragment>
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-6 col-lg-6">
                            <h2 class="animated fadeInLeft">{t("news")}</h2>
                        </div>
                        <div class="col-sm-7 col-md-6 col-lg-6">
                            <div class="breadcrumb-item animated fadeInRight">
                                <ul>
                                    <li onClick={goToDashboard}><a href="javascript:void(0)"><span class="icon-home1"></span> {t('home')}</a></li>
                                    <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)" class="active">{t("news")}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section class="newsList">
        <div class="container">
            <div class="custList">
                <div class="row">
                    <div class="col-md-7 custWid1">
                        <div class="imgOverlay animated fadeInLeft">
                            <img src={newsImg} /> 
                            <div class="caption">
                                <h4>Weekly news for students: corona cases, poverty, tourism for one</h4>
                                <p>12 Oct 2020</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 custWid2">
                        <div class="imgOverlay animated fadeInRight">
                            <img src={newsImg1} /> 
                            <div class="caption">
                                <h4>Summer 2020 News</h4>
                                <p>12 Oct 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-7">
                        <div class="row gridList">
                            <div class="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center">
                                <img src={newsImg2} class="animated fadeInLeft" />
                            </div>
                            <div class="col-md-12 col-lg-8 col-sm-12 d-flex align-items-center">
                                <div class="description animated fadeInRight">
                                    <h4>NBC news to host trump town hall at came time as ABC news-biden town hall </h4>
                                    <h5>12 Oct 2020: By Admin</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row gridList">
                            <div class="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center">
                                <img src={newsImg8} class="animated fadeInLeft" />
                            </div>
                            <div class="col-md-12 col-lg-8 col-sm-12 d-flex align-items-center">
                                <div class="description animated fadeInRight">
                                    <h4>NBC news to host trump town hall at came time as ABC news-biden town hall </h4>
                                    <h5>12 Oct 2020: By Admin</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row gridList">
                            <div class="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center">
                                <img src={newsImg3} class="animated fadeInLeft" />
                            </div>
                            <div class="col-md-12 col-lg-8 col-sm-12 d-flex align-items-center">
                                <div class="description animated fadeInRight">
                                    <h4>NBC news to host trump town hall at came time as ABC news-biden town hall </h4>
                                    <h5>12 Oct 2020: By Admin</h5>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-lg-4 col-md-5">
                        <div class="owl-carousel owl-theme animated fadeInRight" id="owl-carousel4">
                            <div class="item">
                                <div class="cust_genre">
                                    <img src={newsImg4} />
                                    <h3>Weekly news for students: corona cases, poverty, tourism for one</h3>
                                    <p class="dateBy">12 Oct 2020: By Admin</p>
                                    <p class="cusText">Covid-19 is ensuring that this Halloween will be very different, but that didn't stop Tumblr users from thinking about Oct. 31 as early as July 1....</p>
                                    <a href="javascript:void(0)" class="rdMr">Read more</a>
                                </div>
                            </div>
                            <div class="item">
                                <div class="cust_genre">
                                    <img src={newsImg4} />
                                    <h3>Weekly news for students: corona cases, poverty, tourism for one</h3>
                                    <p class="dateBy">12 Oct 2020: By Admin</p>
                                    <p class="cusText">Covid-19 is ensuring that this Halloween will be very different, but that didn't stop Tumblr users from thinking about Oct. 31 as early as July 1....</p>
                                    <a href="javascript:void(0)" class="rdMr">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 gridRow animated fadeInLeft">
                        <img src={newsImg5} />
                        <h3>Weekly news for students: corona cases, poverty, tourism for one</h3>
                        <p class="dateBy">12 Oct 2020: By Admin</p>
                        <p class="cusText">Covid-19 is ensuring that this Halloween will be very different, but that didn't stop Tumblr users from thinking about Oct. 31 as early as July 1....</p>
                        <a href="javascript:void(0)" class="rdMr">Read more</a>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 gridRow animated fadeInUp">
                        <img src={newsImg6} />
                        <h3>Weekly news for students: corona cases, poverty, tourism for one</h3>
                        <p class="dateBy">12 Oct 2020: By Admin</p>
                        <p class="cusText">Covid-19 is ensuring that this Halloween will be very different, but that didn't stop Tumblr users from thinking about Oct. 31 as early as July 1....</p>
                        <a href="javascript:void(0)" class="rdMr">Read more</a>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 gridRow animated fadeInRight">
                        <img src={newsImg7} />
                        <h3>Weekly news for students: corona cases, poverty, tourism for one</h3>
                        <p class="dateBy">12 Oct 2020: By Admin</p>
                        <p class="cusText">Covid-19 is ensuring that this Halloween will be very different, but that didn't stop Tumblr users from thinking about Oct. 31 as early as July 1....</p>
                        <a href="javascript:void(0)" class="rdMr">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

        </React.Fragment>
    )
}

export default News
