/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function MyPlayedContents(props) {
  console.log("content props", props);
  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState();
  const [loading, setLoading] = useState(false);

  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      setCurrentLanguage(languages[1]);
    }
  }, [languages]);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      console.log("current language code is", currentLanguage);
      fetchQuizzes();
    }
  }, [currentLanguage]);

  const fetchQuizzes = async () => {
    axios({
      method: "post",
      url: "/api/my/played/quiz/recentlyplayed",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: currentLanguage.lang_code,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        let quizzesList = response.data.data;
        console.log("quizzes", quizzesList);
        setQuizzes(quizzesList);
        setLoading(false);
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const gotoAttributesPage = () => {
    props.history.push("/selectattributes");
  };

  const goToDashboardPage = () => {
    props.history.push("/dashboard");
  };

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">Contents List</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboardPage}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> Home
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      Create Content
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading === false ? (
        <div class="create-content-wrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-10 col-lg-7">
                <div class="create-content-tabs animated fadeInRight">
                  <ul class="nav nav-tabs nav-justified">
                    {languages && currentLanguage
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            onClick={() => setCurrentLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{language.name}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div class="tab-content">
                    <div class="tab-pane container active" id="EnglishTab">
                      <div onClick={gotoAttributesPage} class="add-quiz-btn">
                        <a href="javascript:void(0)">
                          <span class="icon icon-plus blue-bg-btn"></span>{" "}
                          <span>Add Content</span>
                        </a>
                      </div>

                      <div class="create-content-accordion">
                        <div id="accordion" class="accordion">
                          {quizzes && quizzes.length > 0
                            ? quizzes.map((object, i) => (
                                // return <ContentRow obj={object} key={i} index={i} />;
                                <div class="card">
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    href={"#collapse" + i}
                                  >
                                    <div class="row">
                                      <div class="col-sm-9">{object.name}</div>
                                      <div class="col-sm-3 question">
                                        {object.questionCount} question{" "}
                                        <span class="icon-next"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id={"collapse" + i}
                                    class="card-body collapse show"
                                    data-parent="#accordion"
                                  >
                                    <p>{object.description}</p>
                                    <div
                                      class="text-center"
                                      onClick={() =>
                                        goToContentDetailsPage(object.id)
                                      }
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        class="btn-background blue-bg-btn"
                                      >
                                        View Content Detail
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4">
                  {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                  {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="ThreeDots"
          color="#294177"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      )}

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default MyPlayedContents;
