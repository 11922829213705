/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/dashboard.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/kyc-details.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAsyncEffect from "../Hooks/useAsyncEffect";

function UpdateProfile(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const user = cookies["user"]; //getUser();
  //console.log(user)
  const user_type = user.user_type_id;
  const user_name = user.name;
  const user_id = user.id;

  const [profilePic, setProfilePic] = useState();
  const [newProfilePic, setNewProfilePic] = useState();

  const [firstName, setFirstName] = useState(user.name);

  const [desc, setDesc] = useState(user.about_us);

  const [emailID, setEmailId] = useState(user.email_id);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_no);
  const [dialCode, setDialCode] = useState(user.dial_code);
  const [countryId, setCountryId] = useState(user.country_id);

  const [kycScreen, setKYCScreen] = useState(1);

  const [userProfile, setUserProfile] = useState();
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [genreList, setGenreList] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [pagesInGenre, setPagesInGenre] = useState();
  const [currentGenrePages, setCurrentGenrePages] = useState([]);

  //Category
  const [genreCategories, setGenreCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [totalCategoriesPages, setTotalCategoriesPages] = useState(0);
  const [currentCategoriesPages, setCurrentCategoriesPages] = useState([]);

  //Sub Categories
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesTotal, setSubCategoriesTotal] = useState(0);
  const [subCategoriesTotalPages, setSubCategoriesTotalPages] = useState(0);

  const [countriesList, setCountriesList] = useState([]);

  const [profileUpdated, setProfileUpdated] = useState(0);

  const [userPreviousPreferences, setUserPreviousPreferences] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let KYC_PATH = awsImageUrl + "/uploads/kyc/";

  const config = {
    bucketName: "memoriz",
    dirName: "uploads/kyc" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    //
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  const profileImageconfig = {
    bucketName: "memoriz",
    dirName: "uploads/users" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    //
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function compare_name(a, b) {
    // a should come before b in the sorted order
    if (a.name < b.name) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.name > b.name) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  const [choosenDate, setChoosenDate] = useState(new Date());

  const changeDate = (date) => {
    setChoosenDate(date);
  };

  useEffect(() => {
    axios
      .get("/api/user/get/profile", {
        headers: {
          // 'content-type' : 'application/json',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
      })
      .then((response) => {
        console.log("response for user", response);
        setUserProfile(response.data.user);
        setProfilePic(response.data.user.profile_pic);
        let user_data = response.data.user;
        console.log("response", response);
        axios({
          method: "get",
          url: "/api/countries/list",
          headers: {
            locale: globalLangCode,
          },
        })
          .then((response) => {
            console.log("countries=========", response);
            setCountriesList((country) => country.concat(response.data.data));
            setPhoneNumber(user_data.phone_no);
            setDesc(user_data.about_us);
            /*if(user_data.user_type_id == 10 && user_data.kyc_detail_completed == 1) {
                setDialCode(user_data.kyc_bank_details.dial_code)
                setCountryId(user_data.kyc_bank_details.bank_country_code)
            } else*/
            if (!user_data.dial_code) {
              console.log("here Afganistan -----");
              setDialCode(response.data.data[0].dial_code);
              setCountryId(response.data.data[0].id);
            } else {
              console.log("here else");
              setDialCode(user_data.dial_code);
              setCountryId(user_data.country_id);
            }
          })
          .catch((error) => {
            //   removeUserSession();
          });
      })
      .catch((error) => {
        //   removeUserSession();
      });

    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "post",
      url: "/api/genres/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        console.log("genre response", response);
        setPagesInGenre(response.data.total_pages);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "post",
      url: "/api/user/get/my-prefrences",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        preference_type: "4",
      }),
    })
      .then((response) => {
        console.log("preferences response", response.data.data);
        //   setUserSession(token, response.data.user);
        setUserPreviousPreferences(response.data.data);
      })
      .catch((error) => {
        //   removeUserSession();
      });
  }, []);

  useEffect(() => {
    if (userPreviousPreferences) {
      let tempGenreArray = [];
      userPreviousPreferences.preffered_genres.map((genre) => {
        tempGenreArray.push(parseInt(genre.id));
      });
      let tempCategoryArray = [];
      userPreviousPreferences.preffered_categories.map((category) => {
        tempCategoryArray.push(parseInt(category.id));
      });
      let tempSubCategoryArray = [];
      userPreviousPreferences.preffered_sub_categories.map((category) => {
        tempSubCategoryArray.push(parseInt(category.id));
      });
      let tempLangArray = new Set();
      userPreviousPreferences.preffered_languages.map((language) => {
        tempLangArray.add(Number(language.id));
      });
      setSelectedLanguages(Array.from(tempLangArray));
      setSelectedGenres(tempGenreArray);
      setSelectedCategories(tempCategoryArray);
      setSelectedSubCategories(tempSubCategoryArray);
    }
  }, [userPreviousPreferences]);

  useEffect(() => {
    setGenreList([]);
    setCurrentGenrePages([]);
    if (pagesInGenre) {
      for (let i = 1; i <= pagesInGenre; i++) {
        axios({
          method: "post",
          url: "/api/genres/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            page: i,
          }),
        })
          .then((response) => {
            console.log("genre response", response);
            setGenreList((genreList) => genreList.concat(response.data.data));
            // setUserSession(token, response.data.user);
            setCurrentGenrePages((currentGenrePages) =>
              currentGenrePages.concat(i)
            );
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [pagesInGenre]);

  const showCategories = () => {
    setGenreCategories([]);
    // setPagesInCategories([])
    for (let i = 0; i < selectedGenres.length; i++) {
      axios({
        method: "post",
        url: "/api/genres/details/categories",
        headers: {
          locale: globalLangCode,
        },
        data: qs.stringify({
          genre_id: selectedGenres[i],
        }),
      })
        .then((response) => {
          // console.log("genre details response", response)
          setTotalCategoriesPages(
            (totalCategoriesPages) =>
              totalCategoriesPages + response.data.total_pages
          );
          for (let page = 1; page <= response.data.total_pages; page++) {
            axios({
              method: "post",
              url: "/api/genres/details/categories",
              headers: {
                locale: globalLangCode,
              },
              data: qs.stringify({
                genre_id: selectedGenres[i],
                page: page,
              }),
            })
              .then((response) => {
                console.log("genre details response", response);
                setGenreCategories((genreCategories) =>
                  genreCategories.concat(response.data.data)
                );
                // setUserSession(token, response.data.user);
                setCurrentCategoriesPages((currentCategoriesPages) =>
                  currentCategoriesPages.concat(i)
                );
              })
              .catch((error) => {
                // removeUserSession();
              });
          }
          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
  };

  useEffect(() => {
    if (genreList.length > 0) {
      genreList.sort(compare_name);
    }
  }, [genreList]);

  useEffect(() => {
    if (genreCategories.length > 0) {
      genreCategories.sort(compare_name);
    }
  }, [genreCategories]);

  const genreSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedGenres.map((eachGenre) => {
      if (eachGenre !== id) {
        tempArray.push(eachGenre);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedGenres(tempArray);
  };
  const subCategoriesSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedSubCategories.map((eachCategory) => {
      if (eachCategory !== id) {
        tempArray.push(eachCategory);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    console.log("tempArray", tempArray);
    setSelectedSubCategories(tempArray);
  };
  const categoriesSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedCategories.map((eachCategory) => {
      if (eachCategory !== id) {
        tempArray.push(eachCategory);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedCategories(tempArray);
  };
  useEffect(() => {
    if (!selectedCategories.length) return;
    axios({
      method: "post",
      url: "/api/subcategories/list/catids",
      headers: {
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        category_ids: selectedCategories.join(","),
        page: 1,
      }),
    }).then((responseSubCat) => {
      setSubCategoriesTotal(responseSubCat.data.total);
      setSubCategoriesTotalPages(responseSubCat.data.total_pages);
    });
  }, [selectedCategories]);

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setSubCategories([]);
        for (let i = 1; i <= subCategoriesTotalPages; i++) {
          let responseSubCat = yield axios({
            method: "post",
            url: "/api/subcategories/list/catids",
            headers: {
              "x-access-token": "Memo " + token,
              locale: globalLangCode,
            },
            data: qs.stringify(
              {
                category_ids: selectedCategories.join(","),
                page: i,
              },
              { cancelToken: token1 }
            ),
          });
          //setSubCategoriesTotal(responseSubCat.data.total)
          //setSubCategoriesTotalPages(responseSubCat.data.total_pages)
          setSubCategories((subCategories) =>
            subCategories.concat(responseSubCat.data.data)
          );
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [subCategoriesTotal, subCategoriesTotalPages]
  );

  const selectThisLanguage = (id) => {
    const tempArray = new Set(selectedLanguages);
    tempArray.has(Number(id))
      ? tempArray.delete(Number(id))
      : tempArray.add(Number(id));
    setSelectedLanguages(Array.from(tempArray));
  };

  useEffect(() => {
    console.log(selectedGenres);
    console.log(selectedCategories);
  }, [selectedGenres, selectedCategories]);

  const selectCountry = (id) => {
    for (let i = 0; i < countriesList.length; i++) {
      if (countriesList[i].id == id) {
        setCountryId(id);
        setDialCode(countriesList[i].dial_code);
        return;
      }
    }
  };

  const onFileUploadChange = (event) => {
    console.log(event.target.files[0]);

    setNewProfilePic(event.target.files[0]);
  };

  const [profilePicUploaded, setProfilePicUploaded] = useState(0);

  useEffect(() => {
    const newFileName = "memorize" + user_id;
    /*
    if(newProfilePic){
        const newFileName = "memorize"+user_id;
        const ReactS3Client = new S3(profileImageconfig);
        ReactS3Client
        .uploadFile(newProfilePic, newFileName)
        .then(data => {
            console.log(data) 
            setProfilePicUploaded(1)
        })
        .catch(err => {
            console.error(err)
            setProfilePicUploaded(1)
        })
    }
    if(!newProfilePic){
        setProfilePicUploaded(0)
    }
    */

    console.log("$$$$$$$$", newProfilePic);
    if (newProfilePic) {
      const fd = new FormData();
      fd.append("file", newProfilePic);
      fd.append("filename", newFileName);
      axios({
        method: "post",
        url:
          "/api/user/profile/image/upload?filename=" +
          newFileName +
          "&media_type=" +
          1,
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
          "Content-Type": "multipart/form-data",
          //'enctype': "multipart/form-data"
        },
        data: fd,
      })
        .then((response) => {
          console.log("response.data.data.file_name", response);
          setProfilePic(response.data.data.file_name);
          setProfilePicUploaded(1);
          console.log("file_name=" + response.data.data.file_name);
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
          console.log("error", error);
        });
    }
    if (!newProfilePic) {
      setProfilePicUploaded(0);
    }
  }, [newProfilePic]);

  useEffect(() => {
    if (profilePicUploaded == 1) {
      /*
        const newFileName = "memorize"+user_id;
        let newProfilePicName = newProfilePic.name
        let extension = newProfilePicName.split('.').pop(); 
        */
      //alert('here' + profilePic)
      let extension = profilePic.split(".")[1];
      let newFileName = profilePic.split(".")[0];
      console.log("profilePic", profilePic);
      /*
        if(extension === 'jpg'){
            extension = 'jpeg'
        }else if (extension === 'jfif'){
            extension = 'jpeg'
        }
        */
      axios({
        method: "post",
        url: "/api/user/profile/image/update",
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          profile_pic: newFileName + "." + extension,
        }),
      })
        .then((response) => {
          console.log("profile update", response);
          showMessageToUser(response.data.msg);
          setProfilePic(response.data.data.profile_pic);
          setNewProfilePic();
          //window.location.reload()
        })
        .catch((error) => {
          //   removeUserSession();
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  }, [profilePicUploaded]);

  const setCookieUser = (user) => {
    let exp_time = cookies["expiry_time"];
    console.log("exp_time-outer", exp_time);
    if (!exp_time) {
      let exp = 30 * 24 * 60 * 60;
      console.log("in setCookieUser", exp);
      exp_time = new Date();
      exp_time.setTime(exp_time.getTime() + exp * 1000);
    } else {
      exp_time = new Date(exp_time);
      console.log("exp_time", exp_time);
    }
    setCookie("user", JSON.stringify(user), { path: "/", expires: exp_time });
  };

  const updateProfile = () => {
    setProfileUpdated(0);

    axios({
      method: "post",
      url: "/api/user/profile/update",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        name: firstName,
        //'last_name':lastName,
        dial_code: dialCode,
        country_id: countryId,
        phone_no: phoneNumber,
        about_us: desc,
      }),
    })
      .then((response) => {
        console.log("profile update", response);
        setCookieUser(response.data.data);

        showMessageToUser(response.data.msg);
        setProfileUpdated(1);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  useEffect(() => {
    if (profileUpdated === 1) {
      axios({
        method: "post",
        url: "/api/user/post/my-prefrences",
        headers: {
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          preffered_languages: selectedLanguages.join(","),
          preffered_categories: selectedCategories.join(","),
          preffered_sub_categories: selectedSubCategories.join(","),
          preffered_genres: selectedGenres.join(","),
        }),
      })
        .then((response) => {
          console.log("preferences response", response);
          showMessageToUser(response.data.msg);
          const signup_redirect = localStorage.getItem("signup_redirect");
          if (signup_redirect) {
            localStorage.removeItem("signup_redirect");
            props.history.push(signup_redirect);
          } else {
            props.history.push("/genrelist");
          }
          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  }, [profileUpdated]);

  const setKycandcatgories = (id) => {
    setKYCScreen(id);
    showCategories();
  };

  return (
    <React.Fragment>
      {/* <BasicHeader
                userName = {user_name}
                {...props}
            /> */}

      <ToastContainer />

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("editProfile")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("editProfile")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img src={banner} class="img_banner"></img> */}

      <div class="kyc-details-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <div class="kyc-details-info animated fadeInRight">
                <div id="myWizard">
                  <div class="timeline-wrapper">
                    <ul class="step-progress step-steps">
                      <li
                        class={
                          kycScreen >= 1
                            ? "item active step-line"
                            : "item step-line"
                        }
                        onClick={() => setKYCScreen(1)}
                      >
                        <div class="kyc-menu">
                          <span class="no">1</span>
                          <span>{t("personal_details")}</span>
                        </div>
                      </li>
                      <li
                        class={
                          kycScreen >= 2
                            ? "item active step-change"
                            : "item step-change"
                        }
                        onClick={() => setKYCScreen(2)}
                      >
                        <div class="kyc-menu">
                          <span class="no">2</span>
                          <span>{t("gener")}</span>
                        </div>
                      </li>
                      <li
                        class={
                          kycScreen >= 3
                            ? "item active step-change"
                            : "item step-change"
                        }
                        onClick={() => setKYCScreen(3)}
                      >
                        <div class="kyc-menu">
                          <span class="no">3</span>
                          <span>{t("category")}</span>
                        </div>
                      </li>
                      <li
                        class={
                          kycScreen >= 4
                            ? "item active step-line"
                            : "item step-line"
                        }
                        onClick={() => setKYCScreen(4)}
                      >
                        <div class="kyc-menu">
                          <span class="no">4</span>
                          <span>{t("sub_category")}</span>
                        </div>
                      </li>
                      <li
                        class={
                          kycScreen === 5
                            ? "item active step-change"
                            : "item step-change"
                        }
                        onClick={() => setKYCScreen(5)}
                      >
                        <div class="kyc-menu">
                          <span class="no">5</span>
                          <span>{t("language")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="step-content">
                    {userProfile ? (
                      <div
                        id="step1"
                        style={
                          kycScreen === 1
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div class="edit-profile-img">
                          <div class="profile-image">
                            <img
                              src={PROFILE_PIC + profilePic}
                              alt="Profile Img"
                            />
                            <div class="edit-profile">
                              <a href="javascript:void(0)" class="edit-profile">
                                <span class="icon-camera1"></span>
                              </a>

                              <input
                                type="file"
                                id="questionUploadButton"
                                onChange={onFileUploadChange}
                              />
                            </div>
                          </div>
                          <span class="camera-txt">{t("camera_msg")}</span>
                        </div>

                        <div class="row">
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("namee")}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-12">
                            <input
                              type="text"
                              placeholder={t("profile_desc")}
                              value={desc}
                              onChange={(e) => setDesc(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-6">
                            <select
                              onChange={(e) => selectCountry(e.target.value)}
                            >
                              {countriesList.length > 0
                                ? countriesList.map((country) =>
                                    country.dial_code == dialCode ? (
                                      <option value={country.id} selected>
                                        +{country.dial_code} {country.name}
                                      </option>
                                    ) : (
                                      <option value={country.id}>
                                        +{country.dial_code} {country.name}
                                      </option>
                                    )
                                  )
                                : null}
                            </select>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={
                                user_type == 10
                                  ? t("ph")
                                  : t("ph") + t("ph_not_required")
                              }
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder="Email Id(primary email)"
                              value={emailID}
                              // onChange={e => setEmailId(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setKYCScreen(2)}
                        >
                          {/*
                                             <a href="javascript:void(0)" class="btn-background">{t("next_in_attributes_page")}</a> */}
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            <span class="icon-next"></span>
                          </a>
                        </div>
                      </div>
                    ) : null}

                    <div
                      id="step2"
                      style={
                        kycScreen === 2
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <p>{t("profile_genre_heading")}</p>

                        <div class="choose-genre">
                          {/*<h5 class="mb-3">{t("choose_preferred_genre")}</h5> */}
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {genreList &&
                              currentGenrePages.length === pagesInGenre &&
                              userPreviousPreferences
                                ? genreList.length > 0
                                  ? genreList.map((eachGenre) => (
                                      <div
                                        class="col-sm-6 mb-3"
                                        onClick={() =>
                                          genreSelected(
                                            eachGenre.id,
                                            eachGenre.name
                                          )
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={
                                            selectedGenres.includes(
                                              eachGenre.id
                                            )
                                              ? "preferred-boxs active"
                                              : "preferred-boxs"
                                          }
                                        >
                                          <span class="icon">
                                            <img
                                              src={GENER_PATH + eachGenre.image}
                                            />
                                          </span>
                                          <span
                                            class="in-txt"
                                            dangerouslySetInnerHTML={{
                                              __html: eachGenre.name,
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setKycandcatgories(3)}
                        >
                          {/* <a href="javascript:void(0)" class="btn-background">{t("next_in_attributes_page")}</a> */}
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            <span class="icon-next"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="step3"
                      style={
                        kycScreen === 3
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <p>{t("profile_category_heading")}</p>

                        <div class="choose-genre">
                          {/*<<h5 class="mb-3">{t("choose_preferred_category")}</h5>*/}
                          {/* <strong 
                                                // onClick={showCategories}>
                                                Show Categorie>
                                            </strong> */}
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {genreCategories &&
                              currentCategoriesPages.length ==
                                totalCategoriesPages
                                ? genreCategories.length > 0
                                  ? genreCategories.map((category) => (
                                      <div
                                        class="col-sm-6 mb-3"
                                        onClick={() =>
                                          categoriesSelected(
                                            category.id,
                                            category.name
                                          )
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={
                                            selectedCategories.includes(
                                              category.id
                                            )
                                              ? "preferred-boxs active"
                                              : "preferred-boxs"
                                          }
                                        >
                                          <span class="icon">
                                            <img
                                              src={
                                                CATEGORY_PATH + category.image
                                              }
                                            />
                                          </span>
                                          <span class="in-txt">
                                            {category.name}
                                          </span>
                                        </a>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setKYCScreen(4)}
                        >
                          {/* <a href="javascript:void(0)" class="btn-background">{t("next_in_attributes_page")}</a> */}
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            <span class="icon-next"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="step4"
                      style={
                        kycScreen === 4
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <p>{t("profile_sub_category_heading")}</p>

                        <div class="choose-genre">
                          {/*<<h5 class="mb-3">{t("choose_preferred_category")}</h5>*/}
                          {/* <strong 
                                                // onClick={showCategories}>
                                                Show Categorie>
                                            </strong> */}
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {subCategories
                                ? subCategories.length > 0 ||
                                  selectedSubCategories.length > 0
                                  ? subCategories.map((category) => (
                                      <div
                                        class="col-sm-6 mb-3"
                                        onClick={() =>
                                          subCategoriesSelected(
                                            category.id,
                                            category.name
                                          )
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={
                                            selectedSubCategories.includes(
                                              category.id
                                            )
                                              ? "preferred-boxs active"
                                              : "preferred-boxs"
                                          }
                                        >
                                          <span class="icon">
                                            <img
                                              src={
                                                CATEGORY_PATH + category.image
                                              }
                                            />
                                          </span>
                                          <span class="in-txt">
                                            {category.name}
                                          </span>
                                        </a>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setKYCScreen(5)}
                        >
                          {/* <a href="javascript:void(0)" class="btn-background">{t("next_in_attributes_page")}</a> */}
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            <span class="icon-next"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="step5"
                      style={
                        kycScreen === 5
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <p>{t("profile_language_heading")}</p>

                        {languages.length > 0 ? (
                          <div class="choose-language">
                            {/*<<h5 class="mb-3">{t("choose_Language")}</h5> */}
                            <div class="row">
                              {languages.map((language) => (
                                <div
                                  class="col-6 col-sm-4"
                                  onClick={() =>
                                    selectThisLanguage(language.id)
                                  }
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className={
                                      selectedLanguages.includes(
                                        Number(language.id)
                                      )
                                        ? "language active"
                                        : "language"
                                    }
                                  >
                                    <img
                                      className="icon"
                                      src={COUNTRY_FLAG + language.flag}
                                    />{" "}
                                    <div>{t(language.name)}</div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}

                        <div class="kyc-bottom-btn" onClick={updateProfile}>
                          {/* <a href="javascript:void(0)" class="btn-background">{t("next_in_attributes_page")}</a> */}
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            <span class="icon-next"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default UpdateProfile;
