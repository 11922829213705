
/* eslint-disable */

import React from 'react'
import { useCookies } from 'react-cookie'
import { BrowserRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import PrivateRoute from '../Utils/PrivateRoute'
import PublicRoute from '../Utils/PublicRoute'
import Login from './Login';
import LinkedInPopupHandler from './LinkedInPopupHandler'
import LoginHeader from './LoginHeader';
import MyHomePage from './MyHomePage';
import VerifyEmail from './VerifyEmail';
import UserTypesAndPricing from './UserTypesAndPricing';
import ContactUs from './ContactUs';
import Suggestions from './Suggestions';
import Advertisement from './Advertisement';
import AboutMemorizeitall from './AboutMemorizeitall';
import FacebookDeletionStatus from './FacebookDeletionStatus';
import BasicFooter from './BasicFooter';
import HomePage from './HomePage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import CreatorTermsAndConditionsPage from './CreatorTermsAndConditionsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { useTranslation } from 'react-i18next';

function AppPublicPrivateRoutes(props) {
  
  const [cookies, setCookie] = useCookies(['access_token', 'user'])
  console.log('access_token', cookies['access_token'])
  console.log('user---', cookies['user'])

  
  const { t } = useTranslation()
  return (
    <Switch>
      <PublicRoute path="/login/:redirect_type?/:email_id?/:data?" component={Login} />
      <Route path="/signin-linkedin" component={LinkedInPopupHandler} />
      {
        cookies['access_token'] && cookies['user'] ?
          //user ?
          (
            <React.Fragment>
              <HomePage
                {...props}
              />

            </React.Fragment>
          )
          :
          (
            <React.Fragment>
              <BrowserRouter forceRefresh={true}>
                <Switch>
                  <Route exact path="/" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route exact path="/verifyemail" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/usertypesandpricing" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/contactus" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/suggestions" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/advertise" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  {/* <Route path="/reportabuse" render= {(props) => <LoginHeader {...props} fromLandingPage={true}/>} /> */}
                  <Route path="/aboutmemorizeitall" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/termsandconditions" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/creatortermsandconditions" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/privacypolicy" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                  <Route path="/fb_deletion_status" render={(props) => <LoginHeader {...props} fromLandingPage={true} />} />
                </Switch>
              </BrowserRouter>
              <BrowserRouter forceRefresh={true}>
                <Switch>
                  <Route exact path="/" component={MyHomePage} />
                  <Route exact path="/verifyemail" component={VerifyEmail} />
                  <Route path="/usertypesandpricing" component={UserTypesAndPricing} />
                  <Route path="/contactus" component={ContactUs} />
                  <Route path="/suggestions" component={Suggestions} />
                  <Route path="/advertise" component={Advertisement} />
                  {/*<Route path="/reportabuse" component={ReportAbuse} /> */}
                  <Route path="/aboutmemorizeitall" component={AboutMemorizeitall} />
                  <Route path="/termsandconditions" component={TermsAndConditionsPage} />
                  <Route path="/creatortermsandconditions" component={CreatorTermsAndConditionsPage} />
                  <Route path="/privacypolicy" component={PrivacyPolicyPage} />
                  <Route path="/fb_deletion_status" component={FacebookDeletionStatus} />
                </Switch>
              </BrowserRouter>
              <BrowserRouter forceRefresh={true}>
                <Switch>
                  <Route exact path="/" component={BasicFooter} />
                  <Route exact path="/verifyemail" component={BasicFooter} />
                  <Route path="/usertypesandpricing" component={BasicFooter} />
                  <Route path="/contactus" component={BasicFooter} />
                  <Route path="/suggestions" component={BasicFooter} />
                  <Route path="/advertise" component={BasicFooter} />
                  {/* <Route path="/reportabuse" component={BasicFooter} /> */}
                  <Route path="/aboutmemorizeitall" component={BasicFooter} />
                  <Route path="/termsandconditions" component={BasicFooter} />
                  <Route path="/creatortermsandconditions" component={BasicFooter} />
                  <Route path="/privacypolicy" component={BasicFooter} />
                  <Route path="/fb_deletion_status" component={BasicFooter} />
                </Switch>
              </BrowserRouter>
            </React.Fragment>
          )
      }

    </Switch>
  );
}

export default AppPublicPrivateRoutes;