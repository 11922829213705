import React from "react";
import { Trans, useTranslation } from "react-i18next";

export function tHydrate(translationKey, noTranslation = false) {
  return (
    <HydrateT translationKey={translationKey} noTranslation={noTranslation} />
  );
}

const CONTAINER = "container";
const SPECIAL = "special";

function HydrateT({ translationKey, noTranslation = false }) {
  const { t } = useTranslation();

  const translateWithFormatting = (key) => {
    let text = noTranslation ? key : t(key);
    // Automatically replace "MemorizeItAll" with formatted components
    return text.replace(
      /MemorizeItAll/gi,
      `<${CONTAINER}>Memorize<${SPECIAL}>it</${SPECIAL}>all</${CONTAINER}>`
    );
  };

  return (
    <Trans
      components={{
        [CONTAINER]: (
          <span
            style={{ fontFamily: "century-gothic-bold", color: "#294177" }}
          />
        ),
        [SPECIAL]: <span style={{ color: "#a5cc31" }} />,
      }}
    >
      {translateWithFormatting(translationKey)}
    </Trans>
  );
}
