import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";

const useCustomQuill = (placeholder, deps = []) => {
    
    const Clipboard = Quill.import('modules/clipboard')
    const Delta = Quill.import('delta')
    const refsOfQuill = useRef([])
    // In the component to create single instance initialazation.
    const gotRef = useRef(false)
    useEffect(() => {
        const initialize = () => {
            try {
                if (!gotRef.current) {

                    console.log('initializing useCustomQuill')
                    // Add fonts to whitelist
                    let Font = Quill.import('formats/font');
                    // We do not add Sans Serif since it is the default
                    Font.whitelist = ['arial', 'serif', 'sansserif', 'georgia', 'helvetica', 'monospace'];
                    Quill.register(Font, true);


                    let FontAttributor = Quill.import('attributors/class/font');
                    FontAttributor.whitelist = ['arial', 'serif', 'sansserif', 'georgia', 'helvetica', 'monospace']
                    Quill.register(FontAttributor, true);

                    let Size = Quill.import('formats/size');
                    //Size.whitelist = ['small', false, 'large', 'huge'];
                    Size.whitelist = ['8px', '9px', '10px', '11px', '14px', '16px', '18px', "20px",
                        "22px", "24px", "26px", "28px", "36px", "48px", "72px"];
                    Quill.register(Size, true);

                    let AlignStyle = Quill.import('attributors/style/align')
                    AlignStyle.whitelist = ['right', 'center', 'justify']
                    Quill.register(AlignStyle, true);
                    gotRef.current = true
                }

                //var ColorClass = Quill.import('attributors/class/color');
                //Quill.register(ColorClass, true);

                //var SizeStyle = Quill.import('attributors/style/size');
                //SizeStyle.whitelist = ['small', false, 'large', 'huge'];
                //Quill.register(SizeStyle, true);
                console.log('useCustomQuill->deps', deps)
                
                if (deps.length == 0 || 
                    deps.length == 1 && deps[0] == true) {
                    let configs = {
                        theme: 'snow',
                        modules: {
                            'toolbar': { container: '#toolbar' + 0 },
                            //'image-tooltip': true,

                        },
                        placeholder: placeholder
                    };
                    console.log('before')
                    let quill = new Quill('#editor' + 0, configs);
                    console.log('after')
                    refsOfQuill.current[0] = { id: 0, quill: quill }
                    
                } else {
                    if (deps.length > 1 && deps[1] == true) {
                        let languages = deps[0]
                        for (let i = 0; i < languages.length; i++) {
                            let configs = {
                                theme: 'snow',
                                modules: {
                                    'toolbar': { container: '#toolbar' + languages[i].id },
                                    //'image-tooltip': true,

                                },
                                placeholder: placeholder
                            };
                            let quill = new Quill('#editor' + languages[i].id, configs);
                            refsOfQuill.current[i] = { id: languages[i].id, quill: quill }
                            

                        }
                    }

                }
                

            } catch (error) { console.log(error) }
        }


        initialize()

        // Cleanup function that will be called on
        // 1. Unmount
        return () => {
            console.log('useCustomQuill unmount')
            console.log('deps', deps)
        };
    }, deps)

    return [refsOfQuill]
}

export default useCustomQuill