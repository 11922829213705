/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Pagination from "./Pagination";

function Creators(props) {
  //console.log("creators props" , props)
  //const {creators} = props
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken()

  const PAGE_LIMIT = 6;
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  const [clickedViewAll, setClickedViewAll] = useState(0);

  const [fetchTypeURL, setFetchTypeURL] = useState("");

  const [creators, setCreators] = useState([]);
  const [totalCreatorsPages, setTotalCreatorsPages] = useState(0);
  const [totalCreatorsResults, setTotalCreatorsResults] = useState(0);
  const [currentCreatorsPage, setCurrentCreatorsPage] = useState(0);

  const profileSelected = (profileId) => {
    props.history.push("/creatorprofile/" + profileId);
  };
  const handelViewAll = () => {
    setClickedViewAll(1);
    props.onViewAllCreators();
  };

  useEffect(() => {
    console.log("props.fetchType", props.fetchType);
    switch (props.fetchType) {
      case 1: //GenreListingPage
        setFetchTypeURL("/api/my/suggested/creators/list");
        fetchSuggestedCreators();
        break;
      case 2: //GenreDetailsPage
        setFetchTypeURL("/api/genres/details/creators/listing");
        fetchGenreCreators();
        break;
      case 3: //CreatorProfile
        setFetchTypeURL("/api/creators/similier/creators");
        fetchSimilarCreators();
        break;
    }
  }, [props.fetchType]);

  const fetchSimilarCreators = async (currentPage = 1) => {
    console.log("in fetchSimilarCreators");
    axios({
      method: "post",
      url: fetchTypeURL,
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        seller_id: props.seller_id,
        limit: PAGE_LIMIT,
      }),
    })
      .then((response) => {
        console.log("response.data----", response.data);
        setCreators(response.data.data);
        setTotalCreatorsPages(response.data.total_pages);
        setTotalCreatorsResults(response.data.total);
        setCurrentCreatorsPage(currentPage);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };

  const fetchGenreCreators = async (currentPage = 1) => {
    console.log("in fetchGenreCreators");
    axios({
      method: "post",
      url: fetchTypeURL,
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        genre_id: props.genreId,
        limit: PAGE_LIMIT,
      }),
    })
      .then((response) => {
        console.log("response.data----", response.data);
        setCreators(response.data.data);
        setTotalCreatorsPages(response.data.total_pages);
        setTotalCreatorsResults(response.data.total);
        setCurrentCreatorsPage(currentPage);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };

  const fetchSuggestedCreators = async (currentPage = 1) => {
    console.log("in fetchSuggestedCreators");
    axios({
      method: "post",
      url: fetchTypeURL,
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT,
      }),
    })
      .then((response) => {
        console.log("response.data----", response.data);
        setCreators(response.data.data);
        setTotalCreatorsPages(response.data.total_pages);
        setTotalCreatorsResults(response.data.total);
        setCurrentCreatorsPage(currentPage);
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };

  useEffect(() => {
    console.log("in use effect", fetchTypeURL);
    switch (props.fetchType) {
      case 1:
        fetchSuggestedCreators();
        break;
      case 2:
        fetchGenreCreators();
        break;
      case 3:
        fetchSimilarCreators();
        break;
    }
  }, [fetchTypeURL]);

  const onCreatorsPageChanged = (data) => {
    switch (props.fetchType) {
      case 1:
        fetchSuggestedCreators(data.currentPage);
        break;
      case 2:
        fetchGenreCreators(data.currentPage);
        break;
      case 3:
        fetchSimilarCreators(data.currentPage);
        break;
    }
    setCurrentCreatorsPage(data.currentPage);
  };

  return (
    <React.Fragment>
      <section class="more-folder">
        <div class="container">
          <div class="more-fol-data mx-auto">
            <div class="mb-5 text-center">
              <div class="text-left">
                <h3 style={{ color: "#294177", fontWeight: "600" }}>
                  {" "}
                  {t("creators")}
                </h3>
              </div>
              <div class="d-flex flex-wrap align-items-end folder-all cust_link">
                {/* <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5> */}
              </div>
            </div>

            <div class="or-folder">
              <div class="row">
                {creators
                  ? creators.length > 0
                    ? creators.map((creator) => (
                        <div
                          class="col-lg-6 col-xl-4 col-md-6 mb-3"
                          onClick={() => profileSelected(creator.id)}
                        >
                          <div class="m-fold-001">
                            <div class="">
                              <div class="creator-profile creator-profile-img">
                                <img
                                  src={PROFILE_PIC + creator.profile_pic}
                                  width="100%"
                                />
                              </div>
                              <div class="">
                                <div class="creator-text creator-content">
                                  <div class="d-flex flex-row">
                                    <div class="pl-2">
                                      <h5
                                        style={{ color: "#1d5190!important" }}
                                      >
                                        {creator.name}
                                      </h5>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row">
                                    <div class="p-2">
                                      <span className="icon icon-images"></span>
                                    </div>
                                    <div class="p-2">
                                      <h5 class="mb-0 quiz-sell1">
                                        {creator.total_quizzes}{" "}
                                        {t("quiz_created")}
                                      </h5>
                                    </div>
                                  </div>
                                  {/* <div class="d-flex flex-row">
                                          <div class="p-2">
                                             <img src={puzzleImg} alt="" />
                                          </div>
                                          <div class="p-2">
                                             <h5 class="mb-0 quiz-sell quiz-sell1">
                                                   Travel , Reading & <span class="more">+1 more</span> 
                                             </h5>
                                          </div>
                                       </div>
                                       <div class="d-flex flex-row">
                                          <div class="p-2">
                                             <img src={medalImg} alt="" />
                                          </div>
                                          <div class="p-2">
                                             <h5 class="mb-0 quiz-sell quiz-sell1">
                                                   For Pro & <span class="more">+1 more</span>
                                             </h5>
                                          </div>
                                       </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null
                  : null}
              </div>
            </div>
            <div class="nav-direction">
              {totalCreatorsResults > PAGE_LIMIT ? (
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                  <Pagination
                    totalRecords={totalCreatorsResults}
                    totalPages={totalCreatorsPages}
                    currentPage={currentCreatorsPage}
                    pageLimit={PAGE_LIMIT}
                    pageNeighbours={1}
                    onPageChanged={onCreatorsPageChanged}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Creators;
