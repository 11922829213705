import React, { createContext, useContext } from "react";
// import Reducer from "./Reducer"
import { useCookies } from "react-cookie";
import { io } from "socket.io-client";
import { Context } from "../Reducers/Store";

let socket;
const ws = {
  // emitters
  connectSocket: (token) => {
    console.log("connecting socket...");
    socket = io(
      process.env.REACT_APP_SERVER_IP + ":" + process.env.REACT_APP_SERVER_PORT,
      {
        path: "/sockets",
        auth: {
          token: "Memo " + token, //+ getToken()
        },
      }
    );
  },
  getNotificationCount: () => {
    socket.emit("fetchNotificationCount");
  },
  getInvitaionCount: () => {
    socket.emit("fetchInvitationCount");
  },

  // listeners
  onConnected: null,
  onConnectError: null,
  onDisconnect: null,
  onNotificationCountSent: null,
  onNewNotification: null,
  onNewInvitation: null,
  onInvitationCount: null,
};

const exposedFunctions = [
  ws.connectSocket,
  ws.getNotificationCount,
  ws.getInvitaionCount,
];

const WebSocketProvider = ({ children }) => {
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  if (!socket) {
    console.log("socket calling ws.connectSocket()", cookies["access_token"]);
    ws.connectSocket(cookies["access_token"]);
  }
  if (!ws.onConnected) {
    ws.onConnected = socket.on("connected", (msg) => {
      console.log("socket connected", msg);
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        payload: msg.notification_count,
      });
      dispatch({
        type: "UPDATE_INVITATION_COUNT",
        payload: msg.invitation_count,
      });
    });
  }
  if (!ws.onConnectError) {
    ws.onConnectError = socket.on("connect_error", (socket) => {
      console.log("socket on error", socket);
    });
  }
  if (!ws.onDisconnect) {
    ws.onDisconnect = socket.on("disconnect", (socket) => {
      console.log("socket disconnected", socket);
    });
  }
  if (!ws.onNewNotification) {
    ws.onNewNotification = socket.on("notification", (msg) => {
      console.log("socket new notification arrived", msg);
      ws.getNotificationCount();
    });
  }
  if (!ws.onNotificationCountSent) {
    ws.onNotificationCountSent = socket.on("notifcationCount", (msg) => {
      console.log("socket notifcationCount", msg);
      dispatch({
        type: "UPDATE_NOTIFICATION_COUNT",
        payload: msg.notification_count,
      });
    });
  }
  if (!ws.onNewInvitation) {
    ws.onNewInvitation = socket.on("invitation", (msg) => {
      console.log("socket new invitation arrived", msg);
      ws.getInvitaionCount();
    });
  }
  if (!ws.onInvitationCount) {
    ws.onInvitationCount = socket.on("invitationCount", (msg) => {
      console.log("socket on invitationCount", msg);
      dispatch({
        type: "UPDATE_INVITATION_COUNT",
        payload: msg.invitation_count,
      });
    });
  }
  return (
    <WebSocketContext.Provider value={exposedFunctions}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const WebSocketContext = createContext(exposedFunctions);

export default WebSocketProvider;
