/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";

import qs from "qs";

import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
import "../less/test.css";
// import "../less/owl.carousel.min.css"
import "../less/kyc-details.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SellerKYC(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const user = cookies["user"]; //getUser();
  //console.log(user)
  const user_type = user.user_type_id;
  const user_name = user.first_name;
  const user_id = user.id;

  const [kycScreen, setKYCScreen] = useState(1);

  const [userProfile, setUserProfile] = useState();
  const [profilePic, setProfilePic] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  //---------- Japan specific -----------------------
  const [lastNameKana, setLastNameKana] = useState();
  const [firstNameKana, setFirstNameKana] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [countryCity, setCountryCity] = useState();
  const [countryState, setCountryState] = useState();
  const [countryTown, setCountryTown] = useState();

  //---------------------------------------------------
  const [citizenship, setCitizenship] = useState();

  const [mycountry, setMyCountry] = useState();
  const [zipCode, setZipCode] = useState();
  const [identityProofDocFile, setIdentityProofDocFile] = useState();
  const [emailID, setEmailId] = useState();
  const [additionalEmailID, setAdditionalEmailId] = useState();
  const [dialCode, setDialCode] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [alternativeNumber, setAlternativeNumber] = useState();
  const [accountOwnerName, setAccountOwnerName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [bankName, setBankName] = useState();
  const [iban, setIBAN] = useState();
  const [bankAddress, setBankAddress] = useState();
  const [bankCountry, setBankCountry] = useState();
  const [bankZipCode, setBankZipCode] = useState();
  const [profileFirstName, setProfileFirstName] = useState();
  const [profileDesc, setProfileDesc] = useState();
  const [branchNo, setBranchNo] = useState();
  const [branchName, setBranchName] = useState();
  const [swiftNo, setSwiftNo] = useState();
  const [identityDocfilename, setIdentityDocFileName] = useState("");
  const [identityDocfileID, setIdentityDocFileID] = useState("");

  const [dob, setDOB] = useState(new Date());

  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [genreList, setGenreList] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [pagesInGenre, setPagesInGenre] = useState();
  const [currentGenrePages, setCurrentGenrePages] = useState([]);

  const [genreCategories, setGenreCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [totalCategoriesPages, setTotalCategoriesPages] = useState(0);
  const [currentCategoriesPages, setCurrentCategoriesPages] = useState([]);

  const [kycSubmitted, setKycSubmitted] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [countryid, setCountryId] = useState();

  const [countryCode, setCountryCode] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let KYC_PATH = awsImageUrl + "/uploads/kyc/";

  const config = {
    bucketName: "memoriz",
    dirName: "uploads/kyc" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    //
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function compare_name(a, b) {
    // a should come before b in the sorted order
    if (a.name < b.name) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.name > b.name) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  //const [choosenDate, setChoosenDate] = useState(new Date())

  const changeDate = (date) => {
    setDOB(date);
  };

  useEffect(() => {
    axios
      .get("/api/user/get/profile", {
        headers: {
          // 'content-type' : 'application/json',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
      })
      .then((response) => {
        console.log("response for user", response);
        setUserProfile(response.data.user);
        console.log("response", response);
      })
      .catch((error) => {
        //   removeUserSession();
      });

    axios({
      method: "get",
      url: "/api/countries/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        //console.log("countries", response)
        setCountriesList((country) => country.concat(response.data.data));
      })
      .catch((error) => {
        //   removeUserSession();
      });

    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        //console.log("languages", response.data.data)
        setLanguages(response.data.data);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "post",
      url: "/api/genres/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        //console.log("genre response", response)
        setPagesInGenre(response.data.total_pages);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    if (countriesList.length > 0 && userProfile) {
      console.log("userProfile.country_id", userProfile.country_id);
      if (!userProfile.country_id) {
        setMyCountry(countriesList[0].name);
        setBankCountry(countriesList[0].name);
        setDialCode(countriesList[0].dial_code);
      } else {
        //console.log('ddddddd')
        //let country = _.find(countriesList, { 'id': userProfile.country_id})
        for (let i = 0; i < countriesList.length; i++) {
          //console.log('name',countriesList[i].name)
          if (countriesList[i].id == userProfile.country_id) {
            setMyCountry(countriesList[i].name);
            setBankCountry(countriesList[i].name);
            setDialCode(countriesList[i].dial_code);
            return;
          }
        }
      }
    }
  }, [countriesList, userProfile]);

  useEffect(() => {
    setGenreList([]);
    setCurrentGenrePages([]);
    if (pagesInGenre) {
      for (let i = 1; i <= pagesInGenre; i++) {
        axios({
          method: "post",
          url: "/api/genres/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            page: i,
          }),
        })
          .then((response) => {
            console.log("genre response", response);
            setGenreList((genreList) => genreList.concat(response.data.data));
            // setUserSession(token, response.data.user);
            setCurrentGenrePages((currentGenrePages) =>
              currentGenrePages.concat(i)
            );
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [pagesInGenre]);

  const showCategories = () => {
    setGenreCategories([]);
    // setPagesInCategories([])
    for (let i = 0; i < selectedGenres.length; i++) {
      axios({
        method: "post",
        url: "/api/genres/details/categories",
        headers: {
          locale: globalLangCode,
        },
        data: qs.stringify({
          genre_id: selectedGenres[i],
        }),
      })
        .then((response) => {
          // console.log("genre details response", response)
          setTotalCategoriesPages(
            (totalCategoriesPages) =>
              totalCategoriesPages + response.data.total_pages
          );
          for (let page = 1; page <= response.data.total_pages; page++) {
            axios({
              method: "post",
              url: "/api/genres/details/categories",
              headers: {
                locale: globalLangCode,
              },
              data: qs.stringify({
                genre_id: selectedGenres[i],
                page: page,
              }),
            })
              .then((response) => {
                console.log("genre details response", response);
                setGenreCategories((genreCategories) =>
                  genreCategories.concat(response.data.data)
                );
                // setUserSession(token, response.data.user);
                setCurrentCategoriesPages((currentCategoriesPages) =>
                  currentCategoriesPages.concat(i)
                );
              })
              .catch((error) => {
                // removeUserSession();
              });
          }
          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
  };

  useEffect(() => {
    if (genreList.length > 0) {
      genreList.sort(compare_name);
    }
  }, [genreList]);

  const genreSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedGenres.map((eachGenre) => {
      if (eachGenre !== id) {
        tempArray.push(eachGenre);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedGenres(tempArray);
  };

  const categoriesSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedCategories.map((eachCategory) => {
      if (eachCategory !== id) {
        tempArray.push(eachCategory);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedCategories(tempArray);
  };

  useEffect(() => {
    showCategories();
  }, [selectedGenres]);

  useEffect(() => {
    if (userProfile) {
      console.log(userProfile);
      console.log("userProfile.user_type_id", userProfile.user_type_id);

      if (
        userProfile.kyc_detail_completed == 0 &&
        userProfile.kyc_bank_details
      ) {
        setAccountNumber(userProfile.kyc_bank_details.account_number);
        setAccountOwnerName(userProfile.kyc_bank_details.account_owner_name);
        setAdditionalEmailId(userProfile.kyc_bank_details.additional_email_id);
        setAddressLine1(userProfile.kyc_bank_details.address_line_1);
        setAddressLine2(userProfile.kyc_bank_details.address_line_2);
        setCountryCity(userProfile.kyc_bank_details.city);
        setCountryState(userProfile.kyc_bank_details.state);
        setCountryTown(userProfile.kyc_bank_details.town);
        setAlternativeNumber(
          userProfile.kyc_bank_details.alternate_phone_number
        );
        setBankAddress(userProfile.kyc_bank_details.bank_address);
        setBankCountry(userProfile.kyc_bank_details.bank_country);
        setDOB(userProfile.kyc_bank_details.dob);
        setBankName(userProfile.kyc_bank_details.bank_name);
        setBankZipCode(userProfile.kyc_bank_details.bank_zip_code);
        setBranchName(userProfile.kyc_bank_details.branch_name);
        setBranchNo(userProfile.kyc_bank_details.branch_no);
        setCitizenship(userProfile.kyc_bank_details.citizenship);
        setIBAN(userProfile.kyc_bank_details.iban);
        setIdentityDocFileName(userProfile.kyc_bank_details.indentity_doc);
        setProfileFirstName(userProfile.kyc_bank_details.profile_name);
        setProfileDesc(userProfile.kyc_bank_details.profile_desc);
        setSwiftNo(userProfile.kyc_bank_details.swift_code);
        setZipCode(userProfile.kyc_bank_details.zip_code);

        //Japan specific
        setFirstNameKana(userProfile.kyc_bank_details.first_name_kana);
        setLastNameKana(userProfile.kyc_bank_details.last_name_kana);
      }
      setCountryId(userProfile.country_id);
      setDialCode(userProfile.dial_code);

      setFirstName(userProfile.first_name);
      setLastName(userProfile.last_name);
      setEmailId(userProfile.email_id);
      setPhoneNumber(userProfile.phone_no);
      setProfileFirstName(userProfile.first_name);
      //setProfileDesc(userProfile.about_us)
      setProfilePic(userProfile.profile_pic);
    }
  }, [userProfile]);
  const onFileUploadChange = (event) => {
    console.log(event.target.files[0]);
    /*
    let fileName;
    if(event.target.files[0]){
        fileName = "kyc_document_"+user_id
        const ReactS3Client = new S3(config);
        ReactS3Client
        .uploadFile(event.target.files[0], fileName)
        .then(data => {
            console.log(data)
            setIdentityDocFileName(fileName)
        }).catch(err => {
            console.error(err)
        })
    }
    */
    if (event.target.files[0]) {
      let newFileName = "kyc_document_" + user_id;
      const fd = new FormData();
      fd.append("file", event.target.files[0]);
      fd.append("filename", newFileName);
      axios({
        method: "post",
        url:
          "/api/user/identityproof/image/upload/stripe?filename=" +
          newFileName +
          "&media_type=" +
          1,
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
          "Content-Type": "multipart/form-data",
          //'enctype': "multipart/form-data"
        },
        data: fd,
      })
        .then((responseOuter) => {
          console.log("file_name=" + responseOuter.data.data.file_name);
          console.log("Stripe file_id=" + responseOuter.data.data.file_id);
          axios({
            method: "post",
            url:
              "/api/user/identityproof/image/upload?filename=" +
              newFileName +
              "&media_type=" +
              1,
            headers: {
              locale: "en",
              "x-access-token": "Memo " + token,
              "Content-Type": "multipart/form-data",
              //'enctype': "multipart/form-data"
            },
            data: fd,
          })
            .then((response) => {
              console.log("response.data.data.file_name", response);
              setIdentityDocFileName(response.data.data.file_name);
              setIdentityDocFileID(responseOuter.data.data.file_id);
              console.log("file_name=" + response.data.data.file_name);
            })
            .catch((error) => {
              showErrorMessageToUser(error.response.data[0].msg);
              console.log("error", error.response);
            });
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
          console.log("error", error.response);
        });
    }
  };
  const onFileUploadChangeOld = (event) => {
    console.log(event.target.files[0]);
    /*
    let fileName;
    if(event.target.files[0]){
        fileName = "kyc_document_"+user_id
        const ReactS3Client = new S3(config);
        ReactS3Client
        .uploadFile(event.target.files[0], fileName)
        .then(data => {
            console.log(data)
            setIdentityDocFileName(fileName)
        }).catch(err => {
            console.error(err)
        })
    }
    */
    if (event.target.files[0]) {
      let newFileName = "kyc_document_" + user_id;
      const fd = new FormData();
      fd.append("file", event.target.files[0]);
      fd.append("filename", newFileName);
      axios({
        method: "post",
        url:
          "/api/user/identityproof/image/upload?filename=" +
          newFileName +
          "&media_type=" +
          1,
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
          "Content-Type": "multipart/form-data",
          //'enctype': "multipart/form-data"
        },
        data: fd,
      })
        .then((response) => {
          console.log("response.data.data.file_name", response);
          setIdentityDocFileName(response.data.data.file_name);
          console.log("file_name=" + response.data.data.file_name);
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
          console.log("error", error.response);
        });
    }
  };

  const setCountry = (id) => {
    console.log("in setCountry id", id);
    setCountryId(id);
    let countries = countriesList.filter((country) => {
      if (country.id == id) {
        return true;
      }
      return false;
    });
    setBankCountry(countries[0].name);
    setMyCountry(countries[0].name);
    setDialCode(countries[0].dial_code);
    console.log("country.length", countries[0].name);
  };

  const selectThisLanguage = (id) => {
    let tempArray = [];
    let alreadySelected = false;
    selectedLanguages.map((eachLanguage) => {
      if (eachLanguage !== id) {
        tempArray.push(eachLanguage);
      } else {
        alreadySelected = true;
      }
    });
    if (alreadySelected == false) {
      tempArray.push(id);
    }
    setSelectedLanguages(tempArray);
  };

  useEffect(() => {
    console.log(selectedGenres);
    console.log(selectedCategories);
  }, [selectedGenres, selectedCategories]);

  const goToTermsAndConditionsPage = () => {
    window.open("/creatortermsandconditions", "");
    return false;
    //props.history.push('/termsandconditions');
  };

  const submitKYC = () => {
    console.log("IdentityDocfilename", identityDocfilename);

    if (
      document.getElementById("customCheck1").checked &&
      document.getElementById("customCheck2").checked
    ) {
      console.log("phoneNumber", phoneNumber);
      axios({
        method: "post",
        url: "/api/user/bank-details",
        headers: {
          // 'Content-Type' : 'application/x-www-form-urlencoded',
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          first_name_kana: firstNameKana,
          last_name_kana: lastNameKana,
          citizenship: citizenship,
          dob: dob,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          city: countryCity,
          state: countryState,
          town: countryTown,
          zip_code: zipCode,
          identity_doc: identityDocfilename,
          identity_doc_stripe_file_id: identityDocfileID,
          account_owner_name: accountOwnerName,
          account_number: accountNumber,
          bank_name: bankName,
          iban: iban,
          bank_address: bankAddress,
          bank_country: bankCountry,
          bank_country_code: countryid,
          branch_name: branchName,
          branch_no: branchNo,
          swift_code: swiftNo,
          bank_zip_code: bankZipCode,
          profile_name: profileFirstName,
          profile_desc: profileDesc,
          additional_email_id: additionalEmailID,
          alternate_phone_number: alternativeNumber,

          //user profile specific updates.
          first_name: firstName,
          last_name: lastName,
          country_id: countryid,
          dial_code: dialCode,
          phone_no: phoneNumber,
        }),
      })
        .then((response) => {
          console.log("kyc response", response);
          showMessageToUser(response.data.msg);
          setKycSubmitted(1);
        })
        .catch((error) => {
          //   removeUserSession();
          showErrorMessageToUser(error.response.data[0].msg);
        });
    } else {
      showErrorMessageToUser("Mark all the check boxes");
    }
  };

  useEffect(() => {
    if (kycSubmitted === 1) {
      axios({
        method: "post",
        url: "/api/user/post/my-prefrences",
        headers: {
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          preffered_languages: selectedLanguages.join(","),
          preffered_categories: selectedCategories.join(","),
          preffered_genres: selectedGenres.join(","),
        }),
      })
        .then((response) => {
          console.log("preferences response", response);
          showMessageToUser(response.data.msg);
          //props.history.push('/dashboard')
          props.history.push("/managesubscriptions/" + 10); // 10 for creators.
          // setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  }, [kycSubmitted]);

  useEffect(() => {
    if (userProfile && mycountry) {
      console.log("hereddd");
    }
  }, [userProfile, mycountry]);

  return (
    <React.Fragment>
      {/* <BasicHeader
                userName = {user_name}
                {...props}
            /> */}

      <ToastContainer />

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("creator_kyc")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("creator_kyc")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <img src={banner} class="img_banner"></img> */}

      <div class="kyc-details-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-10">
              <div class="kyc-details-info animated fadeInRight">
                <div id="myWizard">
                  <div class="timeline-wrapper">
                    <ul class="step-progress step-steps">
                      <li
                        class={kycScreen >= 1 ? "item active" : "item"}
                        onClick={() => setKYCScreen(1)}
                      >
                        <div class="kyc-menu">
                          <span class="no">1</span>
                          <span>{t("personal_details")}</span>
                        </div>
                      </li>
                      <li
                        class={kycScreen >= 2 ? "item active" : "item"}
                        onClick={() => setKYCScreen(2)}
                      >
                        <div class="kyc-menu">
                          <span class="no">2</span>
                          <span>{t("bank_details")}</span>
                        </div>
                      </li>
                      <li
                        class={kycScreen === 3 ? "item active" : "item"}
                        onClick={() => setKYCScreen(3)}
                      >
                        <div class="kyc-menu">
                          <span class="no">3</span>
                          <span>{t("profile_details")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="step-content">
                    {userProfile && mycountry ? (
                      <div
                        id="step1"
                        style={
                          kycScreen === 1
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div class="row">
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("first_name")}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("last_name")}
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          {dialCode == 81 ? (
                            <React.Fragment>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  placeholder={t("first_name_kana")}
                                  value={firstNameKana}
                                  onChange={(e) =>
                                    setFirstNameKana(e.target.value)
                                  }
                                />
                              </div>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  placeholder={t("last_name_kana")}
                                  value={lastNameKana}
                                  onChange={(e) =>
                                    setLastNameKana(e.target.value)
                                  }
                                />
                              </div>
                            </React.Fragment>
                          ) : null}
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("citizenship")}
                              value={citizenship}
                              onChange={(e) => setCitizenship(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("zip_code")}
                              value={zipCode}
                              onChange={(e) => setZipCode(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-4">
                            <input
                              type="text"
                              placeholder={t("creator_state")}
                              value={countryState}
                              onChange={(e) => setCountryState(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-4">
                            <input
                              type="text"
                              placeholder={t("creator_city")}
                              value={countryCity}
                              onChange={(e) => setCountryCity(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-4">
                            <input
                              type="text"
                              placeholder={t("creator_town")}
                              value={countryTown}
                              onChange={(e) => setCountryTown(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-5">
                            <input
                              type="text"
                              placeholder={t("creator_address_line_1")}
                              value={addressLine1}
                              onChange={(e) => setAddressLine1(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-7">
                            <input
                              type="text"
                              placeholder={t("creator_address_line_2")}
                              value={addressLine2}
                              onChange={(e) => setAddressLine2(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-6">
                            {/* <select onChange={e=>setCountry(e.target.value)}>
                                                {
                                                        countriesList.length > 0 ?
                                                        (
                                                            countriesList.map(country => (
                                                                country.id == countryCode ? 
                                                                <option value={country.name} selected>{country.name}</option>
                                                                :
                                                                <option value={country.name}>{country.name}</option>
                                                            ))
                                                        )
                                                        :
                                                        null
                                                    }
                                                    
                                                </select> */}

                            {mycountry ? (
                              <input
                                type="text"
                                placeholder="Country"
                                value={mycountry}
                                // onChange={e => set(e.target.value)}
                              />
                            ) : null}
                          </div>
                          <div class="col-sm-6">
                            {/* <input type="text" placeholder="Date of Birth" 
                                                    value={choosenDate}
                                                >
                                                
                                                </input> */}

                            <DatePicker
                              onChange={changeDate}
                              value={dob}
                              maxDate={new Date()}
                            />

                            <strong style={{ margin: "10px" }}>
                              {t("birthday")}
                            </strong>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-sm-4 pt-2">
                            {t("unique_identity_proof_document")}
                          </div>
                          <div class="col-sm-8">
                            <div class="custom-file kyc-file">
                              {/* <input type="file" class="custom-file-input" id="inputGroupFile02" /> */}
                              <input
                                type="file"
                                name="file"
                                // class="custom-fikle-input"
                                id="inputGroupFile02"
                                onChange={onFileUploadChange}
                              />
                              {identityDocfilename ? (
                                <p>{identityDocfilename}</p>
                              ) : null}
                              {/* <label class="custom-file-label" for="inputGroupFile02">Choose file</label> */}
                            </div>
                          </div>
                        </div>
                        <div class="light-color mt-3">
                          {t("any_document_of_proof")}
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder="Email Id(primary email)"
                              value={emailID}
                              // onChange={e => setEmailId(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("additional_email")}
                              value={additionalEmailID}
                              onChange={(e) =>
                                setAdditionalEmailId(e.target.value)
                              }
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder="Phone Number"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("alternative_number")}
                              value={alternativeNumber}
                              onChange={(e) =>
                                setAlternativeNumber(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setKYCScreen(2)}
                        >
                          <p class="details-play-quiz details-play-quiz-kyc p-relative">
                            <span class="rightArrow">›</span>
                          </p>
                          {/*
                                            <a href="javascript:void(0)" class="btn-background blue-bg-btn">
                                                {t("Next")}
                                            </a>
                                            */}
                        </div>
                      </div>
                    ) : null}

                    <div
                      id="step2"
                      style={
                        kycScreen === 2
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <input
                            type="text"
                            placeholder={t("account_owner_name")}
                            value={accountOwnerName}
                            onChange={(e) =>
                              setAccountOwnerName(e.target.value)
                            }
                          />
                        </div>
                        <div class="col-sm-12">
                          <input
                            type="text"
                            placeholder={t("account_number")}
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            placeholder={t("bank_name")}
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            placeholder={t("iban")}
                            value={iban}
                            onChange={(e) => setIBAN(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-12">
                          <input
                            type="text"
                            placeholder={t("branch_name")}
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            placeholder={t("branch_no")}
                            value={branchNo}
                            onChange={(e) => setBranchNo(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            placeholder={t("swift_number")}
                            value={swiftNo}
                            onChange={(e) => setSwiftNo(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-12">
                          <input
                            type="text"
                            placeholder={t("bank_address")}
                            value={bankAddress}
                            onChange={(e) => setBankAddress(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <select onChange={(e) => setCountry(e.target.value)}>
                            {countriesList.length > 0
                              ? countriesList.map((country) =>
                                  country.id == countryid ? (
                                    <option value={country.id} selected>
                                      {country.name}
                                    </option>
                                  ) : (
                                    <option value={country.id}>
                                      {country.name}
                                    </option>
                                  )
                                )
                              : null}
                          </select>
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            placeholder={t("bank_zip_code")}
                            value={bankZipCode}
                            onChange={(e) => setBankZipCode(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            {t("all_the_details_filled_by_me_is_correct")}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck2"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck2"
                          >
                            {t("accept_terms_and_creators_by_memorizeitall")}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={goToTermsAndConditionsPage}
                              style={{ color: "blue" }}
                            >
                              {" "}
                              see details
                            </a>
                          </label>
                        </div>
                      </div>
                      <div
                        class="kyc-bottom-btn"
                        onClick={() => setKYCScreen(3)}
                      >
                        <p class="details-play-quiz details-play-quiz-kyc p-relative">
                          <span class="rightArrow">›</span>
                        </p>
                        {/* <a href="javascript:void(0)" class="btn-background blue-bg-btn">{t("Next")}</a> */}
                      </div>
                    </div>

                    <div
                      id="step3"
                      style={
                        kycScreen === 3
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <div class="row align-items-center mb-3">
                          <div class="col-sm-3">
                            <div class="user-img">
                              <img src={PROFILE_PIC + profilePic} />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              placeholder={t("first_name")}
                              value={profileFirstName}
                              onChange={(e) =>
                                setProfileFirstName(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <textarea
                            placeholder={t("description")}
                            value={profileDesc}
                            onChange={(e) => setProfileDesc(e.target.value)}
                          ></textarea>
                        </div>

                        {languages.length > 0 ? (
                          <div class="choose-language">
                            <h5 class="mb-3">
                              {t("choose_preferred_language")}
                            </h5>
                            <div class="row">
                              {languages.map((language) => (
                                <div
                                  class="col-6 col-sm-4"
                                  onClick={() =>
                                    selectThisLanguage(language.id)
                                  }
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className={
                                      selectedLanguages.includes(language.id)
                                        ? "language active"
                                        : "language"
                                    }
                                  >
                                    <img
                                      className="icon"
                                      src={COUNTRY_FLAG + language.flag}
                                    />{" "}
                                    <span>{t(language.name)}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}

                        <div class="choose-genre">
                          <h5 class="mb-3">{t("choose_genres")}</h5>
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {genreList &&
                              currentGenrePages.length === pagesInGenre
                                ? genreList.length > 0
                                  ? genreList.map((eachGenre) => (
                                      <div
                                        class="col-sm-6 mb-3"
                                        onClick={() =>
                                          genreSelected(
                                            eachGenre.id,
                                            eachGenre.name
                                          )
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={
                                            selectedGenres.includes(
                                              eachGenre.id
                                            )
                                              ? "preferred-boxs active"
                                              : "preferred-boxs"
                                          }
                                        >
                                          <span class="icon">
                                            <img
                                              src={GENER_PATH + eachGenre.image}
                                            />
                                          </span>
                                          <span
                                            class="in-txt"
                                            dangerouslySetInnerHTML={{
                                              __html: eachGenre.name,
                                            }}
                                          ></span>
                                        </a>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>

                        <div class="choose-genre">
                          <h5 class="mb-3">{t("choose_category")}</h5>
                          {/*<strong onClick={showCategories}>{t("show_categories")}</strong> */}
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {genreCategories &&
                              currentCategoriesPages.length ==
                                totalCategoriesPages
                                ? genreCategories.length > 0
                                  ? genreCategories.map((category) => (
                                      <div
                                        class="col-sm-6 mb-3"
                                        onClick={() =>
                                          categoriesSelected(
                                            category.id,
                                            category.name
                                          )
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={
                                            selectedCategories.includes(
                                              category.id
                                            )
                                              ? "preferred-boxs active"
                                              : "preferred-boxs"
                                          }
                                        >
                                          <span class="icon">
                                            <img
                                              src={
                                                CATEGORY_PATH + category.image
                                              }
                                            />
                                          </span>
                                          <span class="in-txt">
                                            {category.name}
                                          </span>
                                        </a>
                                      </div>
                                    ))
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>

                        <div class="kyc-bottom-btn" onClick={submitKYC}>
                          <p class="details-play-quiz details-play-quiz-kyc p-relative">
                            <span class="rightArrow">›</span>
                          </p>
                          {/* <a href="javascript:void(0)" class="btn-background blue-bg-btn">{t("register")}</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default SellerKYC;
