import React, { createContext, useContext, useEffect } from 'react'
import { Context } from '../Reducers/Store'
import { useLocation } from 'react-router-dom';

const RouterEventsProvider = ({ children }) => {
    const location = useLocation();

    const [state, dispatch] = useContext(Context)       // Store

    useEffect(() => {
        console.log('route has been changed',location.pathname);
        // TODO. Update the highlighted icon in header based on this changed route.
    },[location.pathname]);

    return (
        <RouterEventsContext.Provider>
            {children}
        </RouterEventsContext.Provider>
    )
}

export const RouterEventsContext = createContext()

export default RouterEventsProvider