/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import qs from "qs";

import { useCookies } from "react-cookie";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import useCustomQuill from "../Hooks/useCustomQuill";
import "../less/quill-editor.css";
import ReactQuillComponent from "./ReactQuillComponent";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/groups.css";
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";

function GroupNewsComponent(props) {
  //console.log('GroupNewsComponent.props', props)

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const user = cookies["user"]; //getUser();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [organization, setOrganization] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [profile_pic_filename, setProfile_pic_filename] = useState("");
  const [profilePicUploaded, setProfilePicUploaded] = useState(0);
  const [open_close, setOpenClose] = useState(0); //Close = 0
  const [owner_name, setOwnerName] = useState("");

  const [news, setNews] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const [newsDesc, setNewsDescription] = useState("");
  const editor = useRef(null);

  const [modalShown, setModalShown] = useState(false);
  const paramsLanguages = [{ id: 999 }];
  const [allQuillRefs] = useCustomQuill(t("enter_group_news_text"), [
    paramsLanguages,
    modalShown,
  ]);

  useEffect(() => {
    console.log("allQuillRefs.current.length", allQuillRefs.current.length);
    console.log("allQuillRefs.current", allQuillRefs.current);
  }, [allQuillRefs.current]);

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  /*
    useEffect(()=>{
        console.log('Inside useEffect allQuillRefs', allQuillRefs)
    },[allQuillRefs])
    */

  const fetchNews = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/news/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        group_id: props.group_id,
        term: query,
        page: currentPage,
      }),
    })
      .then((response) => {
        let data = response.data.data;
        console.log("/api/groups/news/list response.data", response.data.data);
        setNews(data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchNews(data.currentPage);
    setCurrent(data.currentPage);
  };
  const setNewsDescQuell = (value) => {
    setNewsDescription(value);
  };
  useEffect(() => {
    axios({
      method: "get",
      url: "/api/groups/" + props.group_id,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        let group_details = response.data.data;
        console.log("/api/groups/ response.data", response.data.data);
        setGroupName(group_details.group_name);
        setGroupDesc(group_details.description);
        setOrganization(group_details.organization);
        setProfile_pic(group_details.profile_pic);
        setProfile_pic_filename(group_details.profile_pic);
        setOpenClose(group_details.open_close);
        setOwnerName(group_details.owner_name);
      })
      .catch((error) => {});
    fetchNews();
  }, []);

  const handleSearchChange = (event) => {
    const search_query = event.target.value;

    console.log("search_query=" + search_query);

    if (!search_query) {
      //Members Reset.
      setTotalPages(0);
      setTotal(0);
      setNews([]);
      setQuery(search_query);
    } else {
      setQuery(search_query);
      setLoading(true);
    }
  };

  const onNewsDelete = (element) => {
    console.log("onNewsDelete called");
    //
    axios({
      method: "post",
      url: "/api/groups/news/delete",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        group_id: props.group_id,
        id: element.id,
      }),
    })
      .then((response) => {
        fetchNews();
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  useEffect(() => {
    if (loading == true) {
      console.log("calling fetchNews...");
      fetchNews();
    }
  }, [loading, query]);

  const onNewsClick = () => {
    document.getElementById("id-newsUserModal").click();
    setTimeout(() => {
      setModalShown(true);
    }, 1000);
  };

  const onAddNewsClick = () => {
    let editor_news_desc = document.querySelector(
      "#editor" + 999 + " .ql-editor"
    ).innerHTML;
    //let new_news = document.getElementById('id-news-to-add').value.trim()
    //console.log('new_news', new_news)
    axios({
      method: "post",
      url: "/api/groups/news/create",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        description: editor_news_desc,
      }),
    })
      .then((response) => {
        document.getElementById("id-close-newsModal").click();
        setModalShown(false);
        fetchNews();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  return (
    <React.Fragment>
      <div class="newsPanel memberNewsPanel">
        <div class="position-relative mb-2 header">
          <h6 class="blueThemeText panelHeading">{t("group_news")}</h6>
          <div class="d-flex align-items-center searchBox">
            <div class="position-relative">
              <a
                class="actionIcon inviteUserAction  mr-1 cursor-pointer"
                data-toggle="modal"
                data-backdrop="static"
                data-target="#newsModal"
                id="id-newsUserModal"
                onClick={() => onNewsClick()}
              >
                <span class="icon icon-plus"></span>
              </a>
            </div>
            <div class="search_content">
              {/* <i class="fa fa-search"></i> */}
              <DebounceInput
                minLength={1}
                debounceTimeout={500}
                className="inputBox border-box w-100"
                onChange={(event) => handleSearchChange(event)}
                placeholder={t("search_here")}
              />
              {/* 
                            <input
                              type="text"
                              value={query}
                              name="search"
                              id="search-input"
                              placeholder={t("search_here")}
                              onChange={(e) => handleSearchChange(e)}
                          />
                          */}
            </div>
          </div>
        </div>
        <div class="newsList">
          {news
            ? news.map((element) => (
                <div class="newsBox  position-relative mb-2">
                  <div class="info blueThemeText">
                    <span class="date">{element.formated_created_at}</span> -{" "}
                    <span
                      dangerouslySetInnerHTML={
                        element.description
                          ? { __html: element.description }
                          : null
                      }
                      className="dynamicText"
                    ></span>
                  </div>
                  <a
                    href="javascript:void(0)"
                    class="closeIcon actionIcon"
                    onClick={(event) => onNewsDelete(element)}
                    title={t("delete_news_from_group")}
                  >
                    <span class="icon icon-close1"></span>
                  </a>
                </div>
              ))
            : null}
        </div>
        <div class="text-center pt-4 memberNewsPaging">
          {total > PAGE_LIMIT ? (
            <div className="d-flex flex-row py-4 align-items-center justify-content-center">
              <Pagination
                totalRecords={total}
                totalPages={totalPages}
                currentPage={current}
                pageLimit={PAGE_LIMIT}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div class="modal fade" role="dialog" id="newsModal" data-dismiss="modal">
        <div className="modal-dialog">
          <div class="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">{t("add_news")}</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="id-close-newsModal"
              >
                <span className="icon icon-close1"></span>
              </button>
            </div>
            {/* <div className='modal-body newsModalBody'>
                            <div class="inviteUserPanel">
                                <div class="shadowPanelInner">
                                    <div class="inviteUserHeading position-relative d-flex">
                                        <div class="pl-70px d-flex align-items-center mb-3">
                                            <span class="inviteUserLogo">
                                                <img src={GROUPS_PATH + profile_pic_filename} alt=""
                                                    class="img-fluid" />
                                            </span>
                                            <h5 class="blueThemeText fw-600">{groupName}</h5>
                                        </div>
                                        <div class="inviteUserBox">
                                            <a href="javascript:void(0)" class="inviteUser hoverIcon">
                                                <span class="icon icon-add-user"></span>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="organisationField mb-4">
                                        <input type="text" placeholder={t('organization')}
                                            class="w-100"
                                            value={organization} />
                                    </div>
                                    <div class="inviteUserText">
                                        <span class="d-block mb-4 blueThemeText fw-600">
                                            {t('description')}
                                        </span>
                                        <div class="position-relative">
                                            <ReactQuill class="form-control" id={"id-news-to-add"}
                                                //placeholder={t("group_description")}
                                                modules={modules}
                                                formats={formats}
                                                value={newsDesc}
                                                onChange={setNewsDescQuell} />
                                            
                                            <div className='addNewsBtn'>
                                                <a href="javascript:void(0)" class="addCloseIcon hoverIcon"
                                                    onClick={() => onAddNewsClick()}>
                                                    <span class="icon icon-plus"></span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}
            <div class="modal-body newsModalBody">
              <div class="createNews">
                {/* <h3 class="groupAdminHeading mb-3">Create new group news</h3> */}
                <div class="adminPanelInner createNewsPanel">
                  <div class="d-flex">
                    <div class="logoUploadArea">
                      <div class="logoUpload position-relative">
                        <input type="file" class="custom-file-input" />
                        <img
                          src={GROUPS_PATH + profile_pic_filename}
                          alt=""
                          class="groupLogoImg img-fluid"
                        />
                        <div class="logoText">Logo</div>
                        <div class="cameraIcon">
                          <span class="icon icon-camera2"></span>
                        </div>
                      </div>
                    </div>
                    <div class="groupadminArea position-relative ">
                      <div class="adminCreateBox">
                        <div>
                          <input
                            type="text"
                            placeholder="Group Name"
                            class="bb-1 blueThemeText"
                            value={groupName}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder={t("organization")}
                            class="bb-1 blueThemeText"
                            value={organization}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="richTextEditor mt-4 ">
                    <ReactQuillComponent
                      language_id={999}
                    ></ReactQuillComponent>
                    {/* 
                                        <ReactQuill class="form-control" id={"id-news-to-add"}
                                            placeholder={t("enter_group_news_text")}
                                            modules={modules}
                                            formats={formats}
                                            value={newsDesc}
                                            onChange={setNewsDescQuell} />
                                        */}
                  </div>
                  <div class="createGroupBtn mt-4">
                    <a
                      href="javascript:void(0)"
                      onClick={() => onAddNewsClick()}
                    >
                      <span class="icon icon-next"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
}

export default GroupNewsComponent;
