/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import ContentComponent from "./ContentComponent";

function SearchedQuizzesInFolder(props) {
  const quizzesDetails = props.quizzesDetails;
  console.log(quizzesDetails);
  const { t } = useTranslation();
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };

  return (
    <React.Fragment>
      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance1">
            <div class="mr-auto">
              <h3 style={{ color: "#1d5190!important" }}>{t("contents")}</h3>
            </div>
            {/* <div class="d-flex flex-wrap align-items-end folder-all cust_link">
                                    <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5>
                                    
                                </div> */}
          </div>
          <div class="main-content">
            <div className="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                  {quizzesDetails
                    ? quizzesDetails.length > 0
                      ? quizzesDetails.map((content) => (
                          <ContentComponent content={content} {...props} />
                        ))
                      : null
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SearchedQuizzesInFolder;
