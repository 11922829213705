/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getToken, removeUserSession, setUserSession } from '../Utils/Common';
import axios from 'axios'
// import "../images/favicon.ico"
import Pagination from './Pagination';
import creditsImage from '../images/my-credits/credit.png'
import minusImage from '../images/my-credits/minus.png'
import "../less/my-credits.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import qs from 'qs';

const PAGE_LIMIT = 10;
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";

import LoginFooter from './LoginFooter';
import LoginHeader from './LoginHeader';
import BasicHeader from './BasicHeader';

function MyCredits(props) {
    console.log("credits props", props)
    const [creditsData, setCreditsData] = useState(null)
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const token = cookies['access_token'] //getToken()
    const [totalPages, setTotalPages] = useState(null);
    const [total, setTotal] = useState(null);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(false)

    const fetchCreditsHistory = async (currentPage = 1) => {
        
        axios({
            method: 'post',
            url: '/api/credits/history/list',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'locale': 'en',
                'x-access-token': "Memo " + token
            },
            data: qs.stringify({
                page: currentPage.toString(),
                limit: PAGE_LIMIT.toString()
            })
        })
        .then(response => {
           
            console.log('response', response.data)
            setCreditsData(response.data)
            setTotalPages(response.data.total_pages);
            setTotal(response.data.total);
        }).catch(error => {
            //   removeUserSession();
            //window.location.reload(false);
        });
    }
    const onPageChanged = data => {
        setLoading(true);
        fetchCreditsHistory(data.currentPage);
        setCurrent(data.currentPage);
    }

    useEffect(() => {
        fetchCreditsHistory()
    }, [])

    const purchaseCreditsNavigation = () => {
        props.history.push('/purchasecredits')
    }

    const getDate = (dateInISO) => {
        let date = new Date(dateInISO);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        return (dt + "-" + month + "-" + year)
    }

    const getTime = (dateInISO) => {
        let date = new Date(dateInISO);
        let hour = date.getHours();
        let minute = date.getMinutes();
        return (hour + ":" + minute)
    }

    const goToDashboard = () => {
        props.history.push('/dashboard')
    }


    return (
        <React.Fragment>
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-6 col-lg-6">
                            <h2 class="animated fadeInLeft">{t('my_credits')}</h2>
                        </div>
                        <div class="col-sm-7 col-md-6 col-lg-6">
                            <div class="breadcrumb-item animated fadeInRight">
                                <ul>
                                    <li onClick={goToDashboard}><a href="javascript:void(0)"><span class="icon-home1"></span> {t("home")}</a></li>
                                    <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)" class="active">{t('dashboard')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="my-credits-wrapper">
                <div class="container mb-5">
                    <div class="row justify-content-center">
                        <div class="col-sm-8 col-md-6 col-lg-5">
                            <h3 class="animated fadeInLeft">{t('availablecredits')}</h3>
                            <div class="credits-available-box animated fadeInLeft">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div class="credit-icon btn-block"><span class="icon icon-dollar"></span></div>
                                    <div class="credit-txt">
                                        {/* <div>{t('YourBalance')}</div> */}
                                        <div class="points">{creditsData ? (creditsData.wallet_amount + ' ') : null}</div>
                                    </div>
                                </div>

                                <div class="more-credits-btn">
                                    <a href="javascript:void(0)" class="blueBgBtn" onClick={purchaseCreditsNavigation}>{t('BuyMoreCredits')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="creditTableBox mx-auto animated fadeInRight">
                        <table class="table saleCreditTable">
                            <thead>
                                <tr>
                                    <th>{t("date")}</th>
                                    <th>{t("purchase_history")}</th>
                                    <th className="text-right">{t("transaction")}</th>
                                    <th className="text-right">{t("by_balance")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                creditsData ? 
                                    (
                            creditsData.data.map(creditsHistory => (
                                creditsHistory.trxn_type == 1 ?
                                <tr>
                                    <td data-label="Date">
                                        <span class="d-inline-block pr-1">{ getDate(creditsHistory.created_at)}</span>
                                        <span>{ getTime(creditsHistory.created_at)}</span>
                                    </td>
                                    <td data-label="Purchase History">
                                        <span class="d-block mb-2">{t('credits_purchased')}:</span>
                                        <span class="d-inline-block pr-1">{t('invoice_no')}:</span>
                                        {
                                            (
                                                function render() {
                                                    let response = creditsHistory.payment_gateway_response
                                                    response = JSON.parse(response)
                                                    if(response.object == 'payment_intent') {
                                                        return (
                                                            <a target="_blank" href={response.charges.data[0].receipt_url} >
                                                                <span>{creditsHistory.trxn_id}</span> 
                                                            </a>
                                                        )
                                                    } else {
                                                        return (
                                                            <span>Not Avalilable</span> 
                                                        )
                                                    }
                                                }()
                                            )

                                        }
                                        
                                    </td>
                                    <td data-label="Transaction" className="text-right transactionBalanceNum">
                                        {creditsHistory.credits}
                                    </td>
                                    <td data-label="Balance" className="text-right transactionBalanceNum">{creditsHistory.updated_balance}</td>
                                </tr>
                              : 
                              <tr>
                                    <td data-label="Date">
                                        <span class="d-inline-block pr-1">{ getDate(creditsHistory.created_at)}</span>
                                        <span>{ getTime(creditsHistory.created_at)}</span>
                                    </td>
                                    <td data-label="Purchase History">
                                        <span class="d-block mb-2">{t('contents_purchased')}:</span>
                                        <div class="purchaseBox mb-2">
                                            <div class="contentBox">
                                                <span class="d-inline-block pr-1">{t('content_id')}:</span>
                                                <span>{creditsHistory.quiz_deck_id}</span>
                                            </div>

                                            <div class="creatorBox">
                                                <span class="d-inline-block pr-1">{t('creator_id')}:</span>
                                                <span>{creditsHistory.creator_id}</span>
                                            </div>
                                        </div>

                                        <span class="d-inline-block pr-1">Title:</span>
                                        <span>{creditsHistory.name}</span>
                                    </td>
                                    <td data-label="Transaction" className="text-right transactionBalanceNum">
                                        -{creditsHistory.credits}
                                    </td>
                                    <td data-label="Balance" className="text-right transactionBalanceNum">{creditsHistory.updated_balance}</td>
                                </tr>
                            ))) : null }
                            </tbody>
                        </table>
                        <div class="text-center pt-4">
                            {
                                total > PAGE_LIMIT ?
                                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                        <Pagination totalRecords={total} totalPages={totalPages} currentPage={current} pageLimit={PAGE_LIMIT} pageNeighbours={1} onPageChanged={onPageChanged} />
                                    </div>
                                    :
                                    null
                            }
                                            
                            </div>
                    </div>
                    {/* <hr class="animated fadeInLeft" />
                    <div class="credits-history-info animated fadeInRight">
                        <h3>{t('CreditsHistory')}</h3>
                        {
                            creditsData ?
                                (
                                    creditsData.data.map(creditsHistory => (
                                        <div class="credits-history-list">
                                            <div class="pm-icon"><img src={minusImage} /></div>
                                            <div class="row">
                                                <div class="col-sm-7 col-md-8 col-lg-8">
                                                    <div class="head">{t('lbl_credits_purchased')}</div>
                                                    <div class="balance-points">{t('updated_balance')} - <span class="number">{creditsHistory.updated_balance}</span></div>
                                                </div>
                                                <div class="col-sm-5 col-md-4 col-lg-4 time-and-points">
                                                    <div class="date-time"><span>{getDate(creditsHistory.created_at)}</span> <span>{getTime(creditsHistory.created_at)}</span></div>
                                                    <div>
                                                        <div class="ch-icon"><span class="icon icon-dollar"></span></div>
                                                        <div class="points-txt">
                                                            <div class="ch-points">{creditsHistory.credits}</div>
                                                            <div>{t('lbl_credits')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                                :
                                null
                        }
                    </div> */}
                </div>
            </div>
        </React.Fragment>

    )
}

export default MyCredits;
