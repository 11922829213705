/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/quiz.css";
import "../less/repeat-content.css";
import "../less/test.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RepeatPlayContentPage(props) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"];

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  //const previousStatus = props.location.state.status
  const repeat_quiz_deck_id = props.match.params.repeatQuizdeckId;

  const [currentStatus, setCurrentStatus] = useState();
  const [currentRepeatQuizTakenId, setCurrentRepeatQuizTakenId] = useState();
  const [originalRepeatQuizTakenId, setOriginalRepeatQuizTakenId] = useState();
  const [questionsDeletedCount, setQuestionDeletedCount] = useState(0);

  const [repeatQuizTakenId, setRepeatQuizTakenId] = useState();

  let localCorrectAnswers = 0;
  let localWrongAnswers = 0;
  let localSkippedAnswers = 0;

  let previouslySubmittedAnswers = [];
  let previousanswerdataset = [];

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let QUESTIONS_PATH = awsImageUrl + "/uploads/questions/";
  let ANSWERS_PATH = awsImageUrl + "/uploads/answers/";
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  const initialTime = 100000;

  const [questionList, setQuestionList] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [currentQuestionNo, setCurrentQuestionNo] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [currentSelectedAnswerIndex, setCurrentSelectedAnswerIndex] =
    useState();
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState();
  const [inputAnswer, setInputAnswer] = useState();
  const [inputCorrectAnswer, setInputCorrectAnswer] = useState();
  const [currentQuestionSelectedAnswer, setCurrentQuestionSelectedAnswer] =
    useState();
  const [currentQuestionCorrectAnswer, setCurrentQuestionCorrectAnswer] =
    useState();
  const [currentQuesAnswerOptionMode, setCurrentQuesAnswerOptionMode] =
    useState();
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [questionsOrComparision, setQuestionsorComparision] = useState(0);
  const [selectedAnswerObject, setSelectedAnswerObject] = useState();
  const [correctAnswerObject, setCorrectAnswerObject] = useState();
  const [totalCorrectAnswers, setTotalCorrectAnswers] =
    useState(localCorrectAnswers);
  const [totalWrongAnswers, setTotalWrongAnswers] = useState(localWrongAnswers);
  const [totalSkippedAnswers, setTotalSkippedAnswers] =
    useState(localSkippedAnswers);
  const [answerResultsAfterSubmission, setAnswerResultsAfterSubmission] =
    useState(previousanswerdataset);
  const [isQuizFullyCompleted, setIsQuizFullyCompleted] = useState(false);
  const [wholeResult, setWholeResult] = useState([]);
  const [ratingStars, setRatingStars] = useState(0);
  const [review, setReview] = useState();

  const [isReviewedFromBefore, setIsReviewedFromBefore] = useState(null);

  const [globalPreviousAnswers, setGlobalPreviousAnswers] = useState([]);
  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);
  const [quizDeckTitle, setQuizDeckTitle] = useState();
  const [quiz_deck_id, setQuizDeckId] = useState();
  const [deleteStateToggle, setDeleteStateToggle] = useState(false);

  const user = cookies["user"]; //getUser();
  const user_id = user.id;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/repeat-quiz/play/lateststatus",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({ repeat_quiz_deck_id }),
    })
      .then((response) => {
        console.log(
          "status of this quiz deck are",
          JSON.stringify(response.data)
        );
        console.log("status of this quiz deck are", response.data);
        if (response.data.repeat_quiz_taken_id) {
          setCurrentRepeatQuizTakenId(response.data.repeat_quiz_taken_id);
        }
        if (response.data.quizDetailTitle) {
          setQuizDeckTitle(response.data.quizDetailTitle);
        }
        if (response.data.quiz_deck_id) {
          setQuizDeckId(response.data.quiz_deck_id);
        }
        setCurrentStatus(response.data.status);
        // setLoading(false)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    if (currentStatus == 0 && !currentRepeatQuizTakenId) {
      axios({
        method: "post",
        url: "/api/repeat-quiz/play/change/status",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          language_id: globalLangId,
          repeat_quiz_deck_id: repeat_quiz_deck_id,
          status: 1,
        }),
      })
        .then((response) => {
          console.log("response from quiz status api", response);
          if (response.data.repeat_quiz_taken_id) {
            setOriginalRepeatQuizTakenId(response.data.repeat_quiz_taken_id);
          }
        })
        .catch((error) => {
          console.log(error.response.data[0].msg);
          showErrorMessageToUser(error.response.data[0].msg);
        });
    } else if (currentStatus == 1 && currentRepeatQuizTakenId) {
      axios({
        method: "post",
        url: "/api/repeat-quiz/play/change/status",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          language_id: globalLangId,
          repeat_quiz_deck_id: repeat_quiz_deck_id,
          status: 3,
          repeat_quiz_taken_id: currentRepeatQuizTakenId,
        }),
      })
        .then((response) => {
          console.log("response from quiz status api", response);
          if (response.data.submitted_answers) {
            previouslySubmittedAnswers = response.data.submitted_answers;
            console.log(
              "previouslySubmittedAnswers",
              previouslySubmittedAnswers
            );
            setGlobalPreviousAnswers(previouslySubmittedAnswers);
          }
          if (response.data.repeat_quiz_taken_id) {
            setOriginalRepeatQuizTakenId(response.data.repeat_quiz_taken_id);
          }
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
        });
    }
  }, [currentStatus, currentRepeatQuizTakenId]);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user_id,
      }),
    })
      .then((response) => {
        setAdLength(response.data.total);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (adLength > 0) {
      setAdv([]);
      for (let i = 0; i < 2; i++) {
        let randomPosition = getRandomInt(0, adLength - 1);
        axios({
          method: "post",
          url: "/api/advertisements/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            position: randomPosition,
            size: 1,
            user_id: user.id,
          }),
        })
          .then((response) => {
            setAdv((ad) => ad.concat(response.data.data));
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [adLength]);

  useEffect(() => {
    if (originalRepeatQuizTakenId && globalPreviousAnswers) {
      console.log("getting question list ...");
      setQuestionList([]);
      axios({
        method: "post",
        url: "/api/repeat-quiz/play/questions/list",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
        data: qs.stringify({
          language_id: globalLangId,
          repeat_quiz_deck_id: repeat_quiz_deck_id,
          repeat_quiz_taken_id: originalRepeatQuizTakenId,
        }),
      })
        .then((response) => {
          console.log("response from quiz question list api", response);
          let tempQuestionArray = [];
          if (globalPreviousAnswers.length == response.data.data.length) {
            setIsQuizFullyCompleted(true);
          }
          for (let i = 0; i < response.data.data.length; i++) {
            let questionfound = 0;
            if (globalPreviousAnswers.length > 0) {
              for (let j = 0; j < globalPreviousAnswers.length; j++) {
                // matching
                if (
                  response.data.data[i].id ==
                  globalPreviousAnswers[j].question_id
                ) {
                  questionfound = 1;
                  break;
                } else {
                  // not matching
                  questionfound = 0;
                }
              }
            }
            if (questionfound == 0) {
              tempQuestionArray.push(response.data.data[i]);
            }
          }
          setQuestionList(tempQuestionArray);

          if (globalPreviousAnswers.length > 0) {
            for (let i = 0; i < globalPreviousAnswers.length; i++) {
              if (
                globalPreviousAnswers[i].selected_options ==
                globalPreviousAnswers[i].right_options
              ) {
                localCorrectAnswers++;
              } else if (
                globalPreviousAnswers[i].selected_options &&
                globalPreviousAnswers[i].selected_options !=
                  globalPreviousAnswers[i].right_options
              ) {
                localWrongAnswers++;
              } else if (!globalPreviousAnswers[i].selected_options) {
                localSkippedAnswers++;
              }
              previousanswerdataset.push({
                question_id: parseInt(globalPreviousAnswers[i].question_id),
                id: parseInt(globalPreviousAnswers[i].answer_id),
              });
            }
            setTotalCorrectAnswers(localCorrectAnswers);
            setTotalWrongAnswers(localWrongAnswers);
            setTotalSkippedAnswers(localSkippedAnswers);
            setAnswerResultsAfterSubmission(previousanswerdataset);
          }
        })
        .catch((error) => {
          console.log(error);
          // removeUserSession();
        });
    }
  }, [originalRepeatQuizTakenId, globalPreviousAnswers]);

  const { t } = useTranslation();

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [valueRetrieved, setValueRetrieved] = useState(false);

  useEffect(() => {
    if (questionList) {
      console.log("questionList.length", questionList.length);
      setNumberOfQuestions(questionList.length);
    }
  }, [questionList]);

  useEffect(() => {
    if (numberOfQuestions && deleteStateToggle == false) {
      setCurrentQuestionNo(0);
    }
    if (deleteStateToggle) {
      setDeleteStateToggle(false);
    }
  }, [numberOfQuestions]);

  useEffect(() => {
    if (currentQuestionNo >= 0 && questionList && questionList.length > 0) {
      if (questionList.length - 1 < currentQuestionNo) {
        console.log("questionList", questionList);
        console.log("currentQuestionNo", currentQuestionNo);
        console.log("numberOfQuestions", numberOfQuestions);
        setCurrentQuestionNo(questionList.length - 1);
        console.log("Next currentQuestionNo", currentQuestionNo);
        setNumberOfQuestions(questionList.length);
        setIsQuizFullyCompleted(true);
        return;
      }
      if (questionsOrComparision == 1) {
        setQuestionsorComparision(0);
      }
      setCurrentSelectedAnswerIndex();
      setCurrentQuestionSelectedAnswer();
      setSelectedAnswerObject();
      setInputCorrectAnswer();
      setInputAnswer();
      setProgressBarWidth(
        Math.round(100 * ((currentQuestionNo + 1) / numberOfQuestions))
      );
      setCurrentQuestionId(questionList[currentQuestionNo].id);
      setCurrentQuestion(questionList[currentQuestionNo]);
      setCurrentQuesAnswerOptionMode(
        questionList[currentQuestionNo].ans_mode_id
      );
    } else if (
      questionList &&
      questionList.length == 0 &&
      currentQuestionNo == 0
    ) {
      console.log("--questionList.length", questionList.length);
      console.log("--currentQuestionNo", currentQuestionNo);

      goRepeatContentPage();
    }
  }, [currentQuestionNo, questionList]);
  const goRepeatContentPage = () => {
    props.history.push("/repeatcontentpage");
  };
  useEffect(() => {
    if (currentQuestion) {
      setCurrentSelectedAnswerIndex();
      // document.getElementById("correctAnswerMessage").style.display = "none"
      for (let i = 0; i < currentQuestion.answers.length; i++) {
        if (currentQuestion.answers[i].is_right_answer === 1) {
          setCurrentQuestionCorrectAnswer(currentQuestion.answers[i].id);
          setCorrectAnswerObject(currentQuestion.answers[i]);
          setCorrectAnswerIndex(i + 1);
          setInputCorrectAnswer(currentQuestion.answers[i].name);
        }
      }
    }
  }, [currentQuestion]);

  const markThisAsAnswer = (answerId, index, answerObject) => {
    console.log(answerObject);
    if (currentSelectedAnswerIndex == index) {
      setSelectedAnswerObject();
      setCurrentSelectedAnswerIndex();
      setCurrentQuestionSelectedAnswer();
    } else {
      setSelectedAnswerObject(answerObject);
      setCurrentSelectedAnswerIndex(index);
      setCurrentQuestionSelectedAnswer(answerId);
    }
  };

  const deleteCurrentQuestionFromRepeat = async () => {
    await axios({
      method: "post",
      url: "/api/my/repeat/quizzes/questions/delete",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: quiz_deck_id,
        question_id: currentQuestionId,
      }),
    })
      .then((response) => {
        console.log("response my delete repeat question", response);
        console.log("currentQuestionId", currentQuestionId);
        let newQuestionList = [];
        for (let i = 0; i < questionList.length; i++) {
          if (currentQuestionId !== questionList[i].id) {
            newQuestionList.push(questionList[i]);
          }
        }
        console.log("newQuestionList", newQuestionList);
        setQuestionDeletedCount(questionsDeletedCount + 1);
        setDeleteStateToggle(true);
        setQuestionList(newQuestionList);

        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error.response);
        // removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const submitCurrentQuestionAndAnswer = () => {
    let input;
    if (currentQuesAnswerOptionMode === "1") {
      input = inputAnswer;
    }
    const toBeSubmittedAnswerDataset = {
      repeat_quiz_taken_id: originalRepeatQuizTakenId,
      language_id: 1,
      question_id: currentQuestionId,
      repeat_quiz_deck_id: repeat_quiz_deck_id,
      answer_mode: currentQuesAnswerOptionMode,
      points: 0,
      option_mode: currentQuesAnswerOptionMode,
      right_options: currentQuestionCorrectAnswer,
      topic_id: 0,
      status: 2,
    };
    if (currentQuesAnswerOptionMode == 1) {
      console.log("inside 1");
      if (inputAnswer && inputAnswer === inputCorrectAnswer) {
        console.log("inside 1 right");
        toBeSubmittedAnswerDataset.selected_options =
          currentQuestionCorrectAnswer;
        console.log(toBeSubmittedAnswerDataset);
      } else if (inputAnswer && inputAnswer !== inputCorrectAnswer) {
        console.log("inside 1 wrong");
        toBeSubmittedAnswerDataset.selected_options = "00000000";
      }
    } else {
      console.log("outside 1");
      toBeSubmittedAnswerDataset.selected_options =
        currentQuestionSelectedAnswer;
    }

    if (
      toBeSubmittedAnswerDataset.selected_options ===
        toBeSubmittedAnswerDataset.right_options ||
      inputAnswer === inputCorrectAnswer
    ) {
      setTotalCorrectAnswers(totalCorrectAnswers + 1);
      // alert ("Correct Answer")
      // document.getElementById("correctAnswerMessage").style.display = "block"
    } else if (
      selectedAnswerObject === undefined &&
      inputAnswer !== undefined &&
      inputAnswer !== inputCorrectAnswer
    ) {
      console.log(selectedAnswerObject, inputAnswer, inputCorrectAnswer);
      setTotalWrongAnswers(totalWrongAnswers + 1);
      // alert ("Wrong Answer")
    } else if (selectedAnswerObject !== undefined) {
      console.log(selectedAnswerObject, inputAnswer, inputCorrectAnswer);
      setTotalWrongAnswers(totalWrongAnswers + 1);
      // alert ("Wrong Answer")
    } else {
      console.log(selectedAnswerObject, inputAnswer, inputCorrectAnswer);
      setTotalSkippedAnswers(totalSkippedAnswers + 1);
      // alert ("Skipped Answer")
    }

    axios({
      method: "post",
      url: "/api/repeat-quiz/play/submit/answer",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify(toBeSubmittedAnswerDataset),
    })
      .then((response) => {
        console.log("response after submiting the answer", response);
        setAnswerResultsAfterSubmission((answerResultsAfterSubmission) =>
          answerResultsAfterSubmission.concat({
            question_id: currentQuestionId,
            id: response.data.answer_id,
          })
        );
        let localObject = {
          selectedAnswerObject: selectedAnswerObject,
          correctAnswerObject: correctAnswerObject,
          selectedAnswerIndex: currentSelectedAnswerIndex + 1,
          correctAnswerIndex: correctAnswerIndex,
          question: currentQuestion,
        };
        if (currentQuesAnswerOptionMode === "1") {
          localObject.inputAnswer = inputAnswer;
          localObject.inputCorrectAnswer = inputCorrectAnswer;
        }
        setWholeResult((wholeResult) => wholeResult.concat(localObject));
      })
      .catch((error) => {
        console.log(error);
        // removeUserSession();
      });

    if (questionsOrComparision == 0) {
      setQuestionsorComparision(1);
    }
  };

  const moveToNextQuestion = () => {
    console.log("Correct Answers -- >", totalCorrectAnswers);
    console.log("Wrong Answers -- >", totalWrongAnswers);
    console.log("SKipped Answers -- >", totalSkippedAnswers);
    console.log(
      "answerResultsAfterSubmission -->",
      answerResultsAfterSubmission
    );
    console.log(currentQuestionNo);
    if (currentQuestionNo < numberOfQuestions - 1) {
      setCurrentQuestionNo(currentQuestionNo + 1);
    } else if (currentQuestionNo == numberOfQuestions - 1) {
      setIsQuizFullyCompleted(true);
    }
  };

  useEffect(() => {
    console.log(answerResultsAfterSubmission);
  }, [answerResultsAfterSubmission]);

  const submitQuiz = () => {
    console.log("questionsDeletedCount", questionsDeletedCount);
    axios({
      method: "post",
      url: "/api/repeat-quiz/play/quiz/complete",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: JSON.stringify({
        repeat_quiz_deck_id: repeat_quiz_deck_id,
        topic_id: 0,
        repeat_quiz_taken_id: originalRepeatQuizTakenId,
        language_id: globalLangId,
        total_right_answers: totalCorrectAnswers,
        total_wrong_answers: totalWrongAnswers,
        total_skipped_answers: totalSkippedAnswers,
        questions_deleted_count: questionsDeletedCount,
        points: totalCorrectAnswers,
        accuracy: parseInt(
          (totalCorrectAnswers / answerResultsAfterSubmission.length) * 100
        ),
        results: answerResultsAfterSubmission,
      }),
    })
      .then((response) => {
        console.log("response after submiting the total quiz", response);
        //  GO to Result Page
        goToResultsPage();
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const goToResultsPage = () => {
    window.localStorage.setItem(
      "REPEAT_PLAY_CONTENT_PAGE_STATE",
      JSON.stringify({
        ...props.location.state,
        total_questions: questionList.length,
        repeat_quiz_deck_id: repeat_quiz_deck_id,
        repeat_quiz_taken_id: originalRepeatQuizTakenId,
        total_right_answers: totalCorrectAnswers,
        total_wrong_answers: totalWrongAnswers,
        total_skipped_answers: totalSkippedAnswers,
        numberOfQuestions: answerResultsAfterSubmission.length,
        accuracy: parseInt(
          (totalCorrectAnswers / answerResultsAfterSubmission.length) * 100
        ),
        wholeResult: wholeResult,
      })
    );

    props.history.push({
      pathname: "/repeatcontentresults/" + quiz_deck_id,
    });
  };

  return (
    <React.Fragment>
      <div class="inner-header">
        <div class="container">
          <div class="row mb-30px">
            <div class="col-sm-5 col-md-6 col-lg-6">
              {/* <h2 class="animated fadeInLeft">{t("play_flash_quiz")}</h2> */}
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("play_flash_quiz")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {quizDeckTitle ? (
            <div class="memorizeTitle mb-2">
              <span dangerouslySetInnerHTML={{ __html: quizDeckTitle }}></span>
            </div>
          ) : null}
        </div>
      </div>
      <div class="container" style={{ display: "none" }}>
        <div class="row my-2 justify-content-center">
          <div class="col-md-5 col-sm-7">
            <div class="py-3 px-1 blueBg text-white text-center">
              <span class="d-block">Become a sponsor of a</span>
              <span class="d-block mb-2px">Memorizeitall pro account :</span>
              <span class="d-block font-weight-bold">
                Memorizeitall - <span>www.memorizeitall.com</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <section class="gener_gap">
        <div class="container">
          <div class="col-lg-7 col-md-8 col-sm-12 mr-auto">
            <div class="bar_container">
              <div
                class="skills html"
                style={{ width: progressBarWidth + "%" }}
              >
                {progressBarWidth}%
              </div>
            </div>

            {/* <img src={prizeImg} class="prize" /> */}
            <span className="prize icon-cup"></span>

            {/* <Timer
                        initialTime={initialTime}
                        direction="backward"
                        checkpoints={[
                            {
                                time: 0,
                                callback: () => console.log('completed time'),
                            },
                        ]}
                    >
                        {() => (
                            <div class="clock">
                            <div class="min_sec">
                                <span class="num">
                                    <Timer.Minutes />
                                </span>
                                <span>Min</span>
                            </div>
                            <div class="min_sec">
                                <span class="colan">:</span>
                            </div>
                            <div class="min_sec">
                                <span class="num">
                                <Timer.Seconds />
                                </span>
                                <span>Sec</span>
                            </div>
                        </div>
                        )}
                    </Timer> */}

            {currentQuestion ? (
              <React.Fragment>
                <div class="row">
                  <div class="col-md-12 quizContainer">
                    <div class="play-quiz-section">
                      <div class="col-md-9 mr-auto">
                        {isQuizFullyCompleted === false &&
                        (currentQuestion.hint ||
                          currentQuestion.explanation) ? (
                          <div class="row cust_icns justify-content-md-center">
                            {/* 
                                                                <div class="col-md-4 col-4"
                                                                    style={questionsOrComparision == 1 ? { display: "none" } : null}
                                                                >
                                                                    <div class="img">
                                                                        <span className="icon icon-target1"></span>
                                                                    </div>
                                                                    <h6 class="support_link">{t("question")}</h6>
                                                                </div>
                                                                */}
                            {currentQuestion.hint ? (
                              <div class="col-md-4 col-4">
                                <div
                                  class="img"
                                  data-toggle="modal"
                                  data-target="#hint"
                                >
                                  {/* <img src={bulbImg} /> */}
                                  <span className="icon icon-bulb"></span>
                                </div>
                                <h6 class="support_link">{t("hints")}</h6>
                              </div>
                            ) : null}
                            {currentQuestion.explanation ? (
                              <div class="col-md-4 col-4 p-0">
                                <div
                                  class="img"
                                  data-toggle="modal"
                                  data-target="#explanation"
                                >
                                  {/* <img src={jarImg} /> */}
                                  <span className="icon icon-explanation"></span>
                                </div>
                                <h6 class="support_link">{t("explanation")}</h6>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>

                      {isQuizFullyCompleted === false ? (
                        <React.Fragment>
                          {currentQuestion.qus_mode_id == 1 ? (
                            <React.Fragment>
                              <h5 class="quiz_head">
                                <strong>
                                  {t("question")} {currentQuestionNo + 1}:
                                </strong>{" "}
                              </h5>
                              <p
                                class="quiz_head"
                                dangerouslySetInnerHTML={
                                  currentQuestion.name
                                    ? { __html: currentQuestion.name }
                                    : null
                                }
                              ></p>
                            </React.Fragment>
                          ) : null}
                          {currentQuestion.qus_mode_id == 2 ? (
                            <React.Fragment>
                              <h5 class="quiz_head">
                                <strong>
                                  {t("question")} {currentQuestionNo + 1}:
                                </strong>{" "}
                              </h5>
                              <p
                                class="quiz_head"
                                dangerouslySetInnerHTML={
                                  currentQuestion.name
                                    ? { __html: currentQuestion.name }
                                    : null
                                }
                              ></p>

                              <div class="answer-section text-center">
                                <img
                                  src={
                                    QUESTIONS_PATH + currentQuestion.file_path
                                  }
                                ></img>
                              </div>
                            </React.Fragment>
                          ) : null}
                          {currentQuestion.qus_mode_id == 4 ||
                          currentQuestion.qus_mode_id == 3 ? (
                            <React.Fragment>
                              <h5 class="quiz_head">
                                <strong>
                                  {t("question")} {currentQuestionNo + 1}:
                                </strong>{" "}
                              </h5>
                              <p
                                class="quiz_head"
                                dangerouslySetInnerHTML={
                                  currentQuestion.name
                                    ? { __html: currentQuestion.name }
                                    : null
                                }
                              ></p>
                              {currentQuestion.qus_mode_id === "3" ? (
                                <div className="repeatplayaudio">
                                  <ReactPlayer
                                    width="100%"
                                    //height="100%"
                                    url={
                                      QUESTIONS_PATH + currentQuestion.file_path
                                    }
                                    controls={true}
                                    // Disable right click
                                    onContextMenu={(e) => e.preventDefault()}
                                    // Disable download button
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="repeatplayaudio repeatplayaudio1">
                                  <ReactPlayer
                                    width="100%"
                                    //height="100%"
                                    url={
                                      QUESTIONS_PATH + currentQuestion.file_path
                                    }
                                    controls={true}
                                    // Disable right click
                                    onContextMenu={(e) => e.preventDefault()}
                                    // Disable download button
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          ) : null}

                          {questionsOrComparision == 0 ? (
                            <React.Fragment>
                              <h5
                                class="quiz_head"
                                style={
                                  currentQuestion.ans_mode_id === "1"
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <strong>{t("write_your_answer")}</strong>
                              </h5>
                              <h5
                                class="quiz_head"
                                style={
                                  currentQuestion.ans_mode_id !== "1"
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <strong>{t("options")} :</strong>
                              </h5>
                              {currentQuestion.ans_mode_id === "1"
                                ? currentQuestion.answers.map(
                                    (eachAnswer, index) => (
                                      <div class="answer-section">
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span class="answerStatusText">
                                            <textarea
                                              class="form-control"
                                              autoFocus
                                              onKeyPress={(e) => {
                                                e.key === "Enter" &&
                                                  e.preventDefault();
                                              }}
                                              onChange={(e) =>
                                                setInputAnswer(e.target.value)
                                              }
                                            ></textarea>
                                          </span>
                                        </p>
                                      </div>
                                    )
                                  )
                                : null}
                              {currentQuestion.ans_mode_id === "2"
                                ? currentQuestion.answers.map(
                                    (eachAnswer, index) => (
                                      <div
                                        class={
                                          currentSelectedAnswerIndex == index
                                            ? "answer-section active"
                                            : "answer-section"
                                        }
                                        onClick={() =>
                                          markThisAsAnswer(
                                            eachAnswer.id,
                                            index,
                                            eachAnswer
                                          )
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span
                                            class="opt_tetx answerStatusText"
                                            dangerouslySetInnerHTML={{
                                              __html: eachAnswer.name,
                                            }}
                                          ></span>
                                        </p>
                                        {/* <p class="opt_tetx" dangerouslySetInnerHTML={{ __html: eachAnswer.name }}></p> */}
                                      </div>
                                    )
                                  )
                                : null}
                              {currentQuestion.ans_mode_id === "3"
                                ? currentQuestion.answers.map(
                                    (eachAnswer, index) => (
                                      <div
                                        class={
                                          currentSelectedAnswerIndex == index
                                            ? "answer-section active text-center"
                                            : "answer-section text-center"
                                        }
                                        onClick={() =>
                                          markThisAsAnswer(
                                            eachAnswer.id,
                                            index,
                                            eachAnswer
                                          )
                                        }
                                      >
                                        <p>
                                          <span class="answerStatusIcon">
                                            <i class="fa fa-check-circle"></i>
                                          </span>
                                          <span class="answerStatusText">
                                            <img
                                              src={
                                                ANSWERS_PATH +
                                                eachAnswer.file_path
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                    )
                                  )
                                : null}
                              {currentQuestion.ans_mode_id === "4" ||
                              currentQuestion.ans_mode_id === "5"
                                ? currentQuestion.answers.map(
                                    (eachAnswer, index) => (
                                      <div
                                        class={
                                          currentSelectedAnswerIndex == index
                                            ? "answer-section active"
                                            : "answer-section"
                                        }
                                        onClick={() =>
                                          markThisAsAnswer(
                                            eachAnswer.id,
                                            index,
                                            eachAnswer
                                          )
                                        }
                                      >
                                        <p>
                                          <i class="fa fa-check-circle"></i>
                                        </p>
                                        {currentQuestion.ans_mode_id === "4" ? (
                                          <div className="repeatplayaudio">
                                            <ReactPlayer
                                              width="100%"
                                              url={
                                                ANSWERS_PATH +
                                                eachAnswer.file_path
                                              }
                                              controls={true}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="repeatplayaudio repeatplayaudio1">
                                            <ReactPlayer
                                              width="100%"
                                              url={
                                                ANSWERS_PATH +
                                                eachAnswer.file_path
                                              }
                                              controls={true}
                                              // Disable right click
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                              // Disable download button
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: "nodownload",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )
                                : null}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {currentQuestion.ans_mode_id === "1" ? (
                                selectedAnswerObject ||
                                inputAnswer === inputCorrectAnswer ? (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        inputAnswer == inputCorrectAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong"
                                      }
                                    >
                                      <span class="answerStatusIcon">
                                        <i class="fa fa-times-circle"></i>
                                      </span>
                                      <p class="opt_tetx">{inputAnswer}</p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <span class="answerStatusIcon">
                                        <i class="fa fa-check-circle"></i>
                                      </span>
                                      <p
                                        class="opt_tetx"
                                        dangerouslySetInnerHTML={{
                                          __html: inputCorrectAnswer,
                                        }}
                                      ></p>
                                    </div>
                                  </React.Fragment>
                                ) : selectedAnswerObject === undefined &&
                                  inputAnswer !== undefined &&
                                  inputAnswer !== "" &&
                                  inputAnswer !== inputCorrectAnswer ? (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        inputAnswer == inputCorrectAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong"
                                      }
                                    >
                                      <span class="answerStatusIcon">
                                        <i class="fa fa-check-circle"></i>
                                      </span>

                                      <p
                                        class="opt_tetx d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: inputAnswer,
                                        }}
                                      ></p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <span class="answerStatusIcon">
                                        <i class="fa fa-check-circle"></i>
                                      </span>

                                      <p
                                        class="opt_tetx d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: inputCorrectAnswer,
                                        }}
                                      ></p>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong unanswered"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span class="opt_tetx">
                                          {t("unanswered")}
                                        </span>
                                      </p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <span
                                          class="opt_tetx answerStatusText"
                                          dangerouslySetInnerHTML={{
                                            __html: correctAnswerObject.name,
                                          }}
                                        ></span>
                                      </p>
                                      {/* <p class="opt_tetx" ></p> */}
                                    </div>
                                  </React.Fragment>
                                )
                              ) : null}
                              {currentQuestion.ans_mode_id === "2" ? (
                                selectedAnswerObject ? (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span
                                          class="opt_tetx answerStatusText"
                                          dangerouslySetInnerHTML={{
                                            __html: selectedAnswerObject.name,
                                          }}
                                        ></span>
                                      </p>
                                      {/* <p class="opt_tetx" ></p> */}
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <span
                                          class="opt_tetx answerStatusText"
                                          dangerouslySetInnerHTML={{
                                            __html: correctAnswerObject.name,
                                          }}
                                        ></span>
                                      </p>
                                      {/* <p></p> */}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong unanswered"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span class="opt_tetx">
                                          {t("unanswered")}
                                        </span>
                                      </p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <span
                                          class="opt_tetx answerStatusText"
                                          dangerouslySetInnerHTML={{
                                            __html: correctAnswerObject.name,
                                          }}
                                        ></span>
                                      </p>
                                      {/* <p></p> */}
                                    </div>
                                  </React.Fragment>
                                )
                              ) : null}
                              {currentQuestion.ans_mode_id === "3" ? (
                                selectedAnswerObject ? (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right text-center"
                                          : "answer-section wrong text-center"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span class="answerStatusText">
                                          <img
                                            src={
                                              ANSWERS_PATH +
                                              selectedAnswerObject.file_path
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right text-center">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <span class="answerStatusText">
                                          <img
                                            src={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong unanswered"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span class="opt_tetx">
                                          {t("unanswered")}
                                        </span>
                                      </p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right text-center">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                        <span class="answerStatusText">
                                          <img
                                            src={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </React.Fragment>
                                )
                              ) : null}
                              {currentQuestion.ans_mode_id === "4" ||
                              currentQuestion.ans_mode_id === "5" ? (
                                selectedAnswerObject ? (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                      </p>
                                      {currentQuestion.ans_mode_id === "4" ? (
                                        <div className="repeatplayaudio">
                                          <ReactPlayer
                                            width="100%"
                                            url={
                                              ANSWERS_PATH +
                                              selectedAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="repeatplayaudio repeatplayaudio1">
                                          <ReactPlayer
                                            width="100%"
                                            url={
                                              ANSWERS_PATH +
                                              selectedAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                      </p>
                                      {currentQuestion.ans_mode_id === "4" ? (
                                        <div className="repeatplayaudio">
                                          <ReactPlayer
                                            width="100%"
                                            //height="100%"
                                            url={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="repeatplayaudio repeatplayaudio1">
                                          <ReactPlayer
                                            width="100%"
                                            //height="100%"
                                            url={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <p class="your_ans">{t("your_answer")}</p>
                                    <div
                                      class={
                                        currentQuestionCorrectAnswer ==
                                        currentQuestionSelectedAnswer
                                          ? "answer-section right"
                                          : "answer-section wrong unanswered"
                                      }
                                    >
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i
                                            class={
                                              currentQuestionCorrectAnswer ==
                                              currentQuestionSelectedAnswer
                                                ? "fa fa-check-circle"
                                                : "fa fa-times-circle"
                                            }
                                          ></i>
                                        </span>
                                        <span class="opt_tetx">
                                          {t("unanswered")}
                                        </span>
                                      </p>
                                    </div>
                                    <p class="your_ans">
                                      {t("corerct_answer")}
                                    </p>
                                    <div class="answer-section right">
                                      <p>
                                        <span class="answerStatusIcon">
                                          <i class="fa fa-check-circle"></i>
                                        </span>
                                      </p>
                                      {currentQuestion.ans_mode_id === "4" ? (
                                        <div className="repeatplayaudio">
                                          <ReactPlayer
                                            width="100%"
                                            //height="100%"
                                            url={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="repeatplayaudio repeatplayaudio1">
                                          <ReactPlayer
                                            width="100%"
                                            //height="100%"
                                            url={
                                              ANSWERS_PATH +
                                              correctAnswerObject.file_path
                                            }
                                            controls={true}
                                            // Disable right click
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                            // Disable download button
                                            config={{
                                              file: {
                                                attributes: {
                                                  controlsList: "nodownload",
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                )
                              ) : null}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <strong>{t("all_questions_answered")}</strong>
                      )}
                      <div
                        id="correctAnswerMessage"
                        style={{
                          display: "none",
                          backgroundColor: "green",
                          width: "400px",
                        }}
                      >
                        {t("corerct_answer")}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal" id="hint">
                  <div class="modal-dialog">
                    <div class="modal-content cust_modal">
                      <div class="modal-header">
                        <h4 class="modal-title">{t("hints")}</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div class="modal-body">
                        <p>
                          {currentQuestion.hint
                            ? currentQuestion.hint
                            : t("no_hints_available")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal" id="explanation">
                  <div class="modal-dialog">
                    <div class="modal-content cust_modal">
                      <div class="modal-header">
                        <h4 class="modal-title">{t("explanation")}</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div class="modal-body">
                        <p>
                          {currentQuestion.explanation
                            ? currentQuestion.explanation
                            : t("no_exp_available")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : isQuizFullyCompleted === true ? (
              <strong>{t("all_questions_answered")}</strong>
            ) : null}
            <div className="mt-4">
              {adv && adv.length > 0 && questionsOrComparision == 0 ? (
                <div class="row">
                  {adv && adv.length > 0 ? (
                    <div class="col-sm-6 col-12 rightBannerColumn">
                      <a class="item" target="_blank" href={adv[0]["link"]}>
                        <img
                          src={ADVERTISEMENT_PATH + adv[0]["image"]}
                          class="img-fluid"
                        />
                      </a>
                    </div>
                  ) : null}
                  {adv && adv.length > 1 ? (
                    <div class="col-sm-6 col-12 leftBannerColumn">
                      <a class="item" target="_blank" href={adv[1]["link"]}>
                        <img
                          src={ADVERTISEMENT_PATH + adv[1]["image"]}
                          class="img-fluid"
                        />
                      </a>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="row m-0">
              {isQuizFullyCompleted === false ? (
                <div
                  class="d-flex justify-content-center  mt-4  col-lg-7 col-md-8 col-sm-12 mr-auto p-relative p-0"
                  style={{ position: "relative" }}
                >
                  {/* <a href="#"
                                
                            ><i class="fa fa-arrow-left cust_left" style={questionsOrComparision == 1 ? {display:"none"}:null} ></i></a> */}
                  <div class="cmplt-preview">
                    <p
                      class="details-play-quiz"
                      style={{ cursor: "pointer" }}
                      onClick={
                        questionsOrComparision == 0
                          ? submitCurrentQuestionAndAnswer
                          : moveToNextQuestion
                      }
                    >
                      <span class="rightArrow">›</span>
                    </p>

                    {/* <p class="details-play-quiz" style={{ cursor: "pointer" }} 
                                                onClick={ deleteCurrentQuestionFromRepeat} >
                                                Delete Quest.
                                            </p> */}
                  </div>
                  <div
                    class="cmplt-preview repeat-add-btn"
                    style={{
                      display: questionsOrComparision == 1 ? "unset" : "none",
                    }}
                  >
                    <a
                      id="id_repeat_page"
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("delete_repeat_question")}
                      onClick={() => deleteCurrentQuestionFromRepeat()}
                    >
                      {/* <img src={deleteQuestionFromRepeat} alt="#" width ='40%' height='20%' /> */}
                      <span class="icon-refresh-minus"></span>
                    </a>
                  </div>
                  {/* <a href="#"><i class="fa fa-arrow-right cust_right" style={questionsOrComparision == 1 ? {display:"none"}:null}></i></a> */}
                </div>
              ) : (
                <div
                  class="d-flex justify-content-center  mt-4 col-lg-7 col-md-8 col-sm-12 mr-auto p-relative"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    class="cmplt-preview"
                    // onClick={reviewThisQuiz}
                  >
                    {answerResultsAfterSubmission.length ===
                    numberOfQuestions ? (
                      <p
                        class="details-play-quiz p-relative"
                        onClick={() => submitQuiz()}
                      >
                        <span class="rightArrow">›</span>
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default RepeatPlayContentPage;
