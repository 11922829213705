/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { toast } from "react-toastify";
import { Context } from "../Reducers/Store";
import Pagination from "./Pagination";

const PAGE_LIMIT = 10;

import "../less/repeat-content.css";

function RepeatContentPage(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);
  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const showMessageToUser = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_CURRENT_MENU",
      payload: "id_repeat_contents_page",
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      let setCurrent = false;
      for (let i = 0; i < languages.length; i++) {
        if (globalLangId == languages[i].id) {
          setCurrentLanguage(languages[i]);
          setCurrent = true;
        }
      }
      if (!setCurrent) {
        setCurrentLanguage(languages[0]);
      }
    }
  }, [languages]);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      fetchQuizzes();
    }
  }, [currentLanguage]);

  const fetchQuizzes = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/my/repeat/quizzes/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: currentLanguage.lang_code,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        console.log(response);
        let quizzesList = response.data.data;
        setQuizzes(quizzesList);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setLoading(false);
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const goToDashboardPage = () => {
    props.history.push("/dashboard");
  };

  const goToRepeatContentDetailsPage = (repeatquizdeckid) => {
    props.history.push("/repeatplaycontent/" + repeatquizdeckid);
  };

  const onPageChanged = (data) => {
    setLoading(true);
    fetchQuizzes(data.currentPage);
    setCurrent(data.currentPage);
  };

  const deleteRepeatContent = async (object) => {
    console.log("object", object);
    await axios({
      method: "post",
      url: "/api/my/repeat/quizzes/delete",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        repeat_quiz_deck_id: object.repeat_quiz_deck_id,
      }),
    })
      .then((response) => {
        console.log("response my delete repeat question", response);
        showMessageToUser(response.data.msg);
        fetchQuizzes(current);
      })
      .catch((error) => {
        console.log(error.response);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("repeat_contents_list")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboardPage}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("repeat_contents_list")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading === false ? (
        <div class="create-content-wrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-10 col-lg-7">
                <div class="create-content-tabs animated fadeInRight">
                  <ul class="nav nav-tabs nav-justified">
                    {languages && currentLanguage
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            key={language.id}
                            onClick={() => setCurrentLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{t(language.name)}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div class="tab-content">
                    <div class="tab-pane container active" id="EnglishTab">
                      <div class="create-content-accordion">
                        <div id="accordion" class="accordion">
                          {quizzes && quizzes.length > 0
                            ? quizzes.map((object, i) => (
                                // return <ContentRow obj={object} key={i} index={i} />;
                                <div class="card" key={i}>
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    href={"#collapse" + i}
                                  >
                                    <div class="row">
                                      <div
                                        class="col-sm-9"
                                        dangerouslySetInnerHTML={{
                                          __html: object.name,
                                        }}
                                      ></div>
                                      <div class="col-sm-3 d-flex align-items-center">
                                        <span class=" icon icon-question2 reverse-question2-icon"></span>
                                        <b class="count question-count">
                                          {object.questionCount}
                                        </b>
                                        {/* {t("question")} 
                                                                                    <span class="icon-next"></span> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id={"collapse" + i}
                                    class="card-body collapse show"
                                    data-parent="#accordion"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={
                                        object.description
                                          ? { __html: object.description }
                                          : null
                                      }
                                    ></p>
                                    <div class="text-right repeat-action-btns">
                                      <a
                                        id="id_repeat_page"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          goToRepeatContentDetailsPage(
                                            object.repeat_quiz_deck_id
                                          )
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("repeat")}
                                      >
                                        {/* <img src={repeatContentImg} alt="#" style={{maxWidth: "10%"}}/> */}
                                        <span class="icon-refresh"></span>
                                      </a>
                                      <a
                                        id="id_repeat_page"
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          deleteRepeatContent(object)
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("delete_repeat_content")}
                                      >
                                        {/* <img src={deleteRepeatContentImg} alt="#" style={{maxWidth: "10%"}}/>*/}
                                        <span class="icon-refresh-multiple"></span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4">
                  {total > PAGE_LIMIT ? (
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination
                        totalRecords={total}
                        totalPages={totalPages}
                        currentPage={current}
                        pageLimit={PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      )}
    </React.Fragment>
  );
}

export default RepeatContentPage;
