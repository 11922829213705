/* eslint-disable */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import qs from "qs";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Context } from "../Reducers/Store";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/invite.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
import "../less/repeat-content.css";

function MyGroups(props) {
  const PAGE_LIMIT = 10;

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);
  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;

  const [myGroups, setMyGroups] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  const [banners, setBanners] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_my_groups" });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);

        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });

    fetchMyGroups();
  }, []);

  const goToCreateGroupPage = () => {
    props.history.push({
      pathname: "/creategroup",
    });
  };

  const goToGroupDetailsPage = (group) => {
    props.history.push({
      pathname: "/groupdetails/" + group.group_id,
      state: {
        group: group,
      },
    });
  };

  const gotoGroupAdminPage = (group) => {
    props.history.push({
      pathname: "/groupadmin/" + group.group_id,
      state: {
        group: group,
      },
    });
  };

  const fetchMyGroups = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        console.log(response);
        let groupsList = response.data.data;
        setMyGroups(groupsList);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((error) => {});
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchMyGroups(data.currentPage);
    setCurrent(data.currentPage);
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("group")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("group")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      <div>
        <div class="container">
          <div class="groupPanel">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-9 col-lg-8">
                <div class="groupListing animated fadeInRight">
                  <div class="groupAdminHeaderArea d-flex justify-content-between align-items-center">
                    <h4 class="blueThemeText groupListingHeader fw-600">
                      {t("group_administration")}
                    </h4>
                    <div class="createGroupBtn">
                      <button onClick={() => goToCreateGroupPage()}>
                        {t("create_group")}
                      </button>
                    </div>
                  </div>
                  <div class="mt-4 groupAppetizerList">
                    {myGroups.length > 0
                      ? myGroups.map((group) => (
                          <div class="groupAppetizerBox position-relative">
                            <div class="groupDesc">
                              <h4 class="groupName">{group.name}</h4>
                              <div class="mb-1">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: group.description,
                                  }}
                                ></span>
                              </div>
                            </div>
                            <div class="groupStatus">
                              <div class="d-flex">
                                {[1, 2].includes(group.is_admin) ? (
                                  <div class="d-flex align-items-end">
                                    <a
                                      href="javascript:void(0)"
                                      class="settingIcon"
                                      onClick={() => gotoGroupAdminPage(group)}
                                    >
                                      <span class="icon icon-m-settings"></span>
                                    </a>
                                  </div>
                                ) : null}

                                <div class="statusLogo ml-1">
                                  <span
                                    class="groupLogo"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => goToGroupDetailsPage(group)}
                                  >
                                    <img
                                      src={GROUPS_PATH + group.profile_pic}
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </span>
                                  <div class="groupStatusIcon">
                                    <span
                                      class={
                                        group.open_close
                                          ? "icon icon-unlock"
                                          : "icon icon-lock"
                                      }
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div class="text-center pt-4">
                  {total > PAGE_LIMIT ? (
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination
                        totalRecords={total}
                        totalPages={totalPages}
                        currentPage={current}
                        pageLimit={PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default MyGroups;
