/* eslint-disable */

import React from "react";
//import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useCookies } from "react-cookie";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/login.css";
import "../less/releway-font.css";
let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

function LanguagesComponent(props) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const setCookieLoginLanguage = (loginLanguage) => {
    let exp_time = cookies["expiry_time"];
    if (!exp_time) {
      let exp = 30 * 24 * 60 * 60;
      console.log("LanguagesComponent/setCookieLoginLanguage", exp);
      exp_time = new Date();
      exp_time.setTime(exp_time.getTime() + exp * 1000);
    }
    console.log("setting login_language in cookie", loginLanguage);
    setCookie("login_language", JSON.stringify(loginLanguage), {
      path: "/",
      exp_time,
    });
  };
  function setLanguage(language) {
    //setUserLoginLanguage(language)
    setCookieLoginLanguage(language);

    //const loginLanguage = JSON.parse(getUserLoginLanguage())
    console.log("language.lang_code", cookies["login_language"].lang_code);

    i18next.changeLanguage(language.lang_code).then((t) => {
      console.log(t("app_name"));
    });
    console.log("our i18", i18next);

    props.onSelectLanguage(language);
  }
  return (
    <ul className="dropdown-menu dropdown-menu-right arrow_top">
      {props.languages && props.languages.length > 0
        ? props.languages.map((language, i) => {
            return (
              <li key={i}>
                <a
                  onClick={() => setLanguage(language)}
                  href="javascript:void(0)"
                >
                  <img
                    height="30%"
                    width="30%"
                    src={COUNTRY_FLAG + language.flag}
                    alt="Img"
                  />
                  <span>{language.name}</span>
                </a>
              </li>
            );
          })
        : null}
    </ul>
  );
}

export default LanguagesComponent;
