/* eslint-disable */ 
import React, { useEffect, useState ,useContext,useRef} from 'react';
import axios from 'axios';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import '../less/quill-editor.css';
import { modules, formats } from '../Utils/quill-editor';
import ReactQuillComponent from './ReactQuillComponent';
import useCustomQuill from '../Hooks/useCustomQuill';
import toPlaintext from 'quill-delta-to-plaintext';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from "react-cookie";

function AQustionCompatibilityTiny() {
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const token = cookies['access_token'] //getToken();
    const [questionList, setQuestionList] = useState([])
    const [fetchingFinished, setFetchingFinished] = useState(false)
    const qullRefs = useRef([])
    const [allQuillRefs] = useCustomQuill("add_content",[questionList, fetchingFinished])

    const showMessageToUser = (msg) => {
        toast(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const showErrorMessageToUser = (msg) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    useEffect(()=>{
        axios({
            method: 'get',
            url: '/api/tinymce/toconvert/questions/list',
            headers: {
                'locale': 'en',
                'x-access-token': "Memo " + token,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                setQuestionList(response.data.data)
                setFetchingFinished(true)
            }).catch(error => {
               
                console.log("error", error)
            });
    },[])

    useEffect(() => {
        if(!allQuillRefs || (allQuillRefs && allQuillRefs.current.length == 0)) return
        console.log("allQuillRefs.current.length",allQuillRefs.current.length)
       if(!fetchingFinished) return
        for (let i = 0; i < questionList.length; i++) {
            for(let j=0; j < allQuillRefs.current.length; j++) {
                if(questionList[i].id == allQuillRefs.current[j].id) {
                    console.log("questionLangDetails[i]",questionList[i])
       
                    allQuillRefs.current[j].quill.root.innerHTML = questionList[i].name
                }
            }
        }
        
    },[allQuillRefs,fetchingFinished])
    
    const [dataTranslated, setDataTranslated] = useState([])
    const getQullData = () =>{
        let data = []
        for(let i=0; i < allQuillRefs.current.length; i++) {
            data.push({id: allQuillRefs.current[i].id, questionTrans: toPlaintext(allQuillRefs.current[i].quill.getContents())})
            
        }
        setDataTranslated(data)
        console.log('data', data)
    }
    useEffect(()=>{
        axios({
            method: 'post',
            url: '/api/tinymce/converted/questions',
            headers: {
                'locale': 'en',
                'x-access-token': "Memo " + token,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(
                {
                    "dataset": dataTranslated
                })
            })
            .then(response => {
               
                showMessageToUser(response.data.msg)
                
            }).catch(error => {
                
                console.log("error", error)
            });

    },[dataTranslated])

    return (
        <React.Fragment>
            {questionList && questionList.length &&
              questionList.map((item,index)=>
         
                <ReactQuillComponent language_id={item.id}>

                </ReactQuillComponent>
                )}
                <a onClick={()=>getQullData()}> CLICK HERE</a>
        </React.Fragment>
    )
}
     {/* 
                <ReactQuill class="form-control" id={"QUES_" + item.id}
                    placeholder={"edit_content"}
                    modules={modules}
                    formats={formats}
                    value={item.name} 
                    ref={input=> qullRefs.current[index] = input}/> 
                )
                */}
export default AQustionCompatibilityTiny
