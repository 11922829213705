import React, {createContext, useReducer } from 'react'
import Reducer from "./Reducer"

const initialState = {
    'CURRENT_MENU_ID' : '',
    'CURRENT_NOTIFICATION_COUNT': 0,
    'CURRENT_INVITATION_COUNT': 0
}

const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer,initialState)

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store