/* eslint-disable */

import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import { useTranslation } from "react-i18next";
import "../less/create-content.css";
import { showErrorMessageToUser, showMessageToUser } from "../Utils/toastr";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import { useCookies } from "react-cookie";
import useCustomQuill from "../Hooks/useCustomQuill";
import "../less/quill-editor.css";
import ReactQuillComponent from "./ReactQuillComponent";

function CreateContentDeck(props) {
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const refs = useRef([]);
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"];
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  console.log("content deck", props);
  //let defaultLanguage = props.location.state.availableLanguages[0]
  let genreIdsArray = props.location.state.selectedGenres;
  let categoryIdsArray = props.location.state.selectedCategories;
  let subCategoriesIdsArray = props.location.state.selectedSubCategories;
  let attributesIdsArray = props.location.state.selectedAttributes;
  let selectedKeywordIdsArray = props.location.state.selectedKeywords;
  let amount;
  if (props.location.state.amount) {
    amount = props.location.state.amount;
  }
  let group_id;
  if (props.location.state.groupId) {
    group_id = props.location.state.groupId;
  }

  console.log(
    "props.location.state.availableLanguages",
    props.location.state.availableLanguages
  );
  let _langs = [];
  //_langs.push(defaultLanguage)
  let availableLanguages = props.location.state.availableLanguages;
  _langs = _langs.concat(props.location.state.availableLanguages);
  console.log(_langs);

  const [languages, setLanguages] = useState(_langs);
  const [translatedLangs, setTranslatedLangs] = useState(null);

  const [contentId, setContentId] = useState();
  const defaultLangId = props.location.state.primaryLanguage.id;
  const [languageDataset, setLanguageDataset] = useState([]);

  const [currentLanguage, setCurrentLanguage] = useState(
    props.location.state.primaryLanguage
  );

  const [fetchingFinished, setFetchingFinished] = useState(false);
  const [allQuillRefs] = useCustomQuill(t("add_content_description"), [
    languages,
    fetchingFinished,
  ]);

  useEffect(() => {
    console.log("languages.length", languages.length);
    setFetchingFinished(true);
  }, [languages]);

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        let data = response.data.data;
        let datatoset = [];
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < languages.length; j++) {
            if (data[i].id == languages[j].id) {
              datatoset.push(data[i]);
            }
          }
        }
        console.log("datatoset", datatoset);
        setTranslatedLangs(datatoset);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, [globalLangId, languages]);

  const goToCreateContentPage = async () => {
    let availableLangIds = [];
    for (let i = 0; i < availableLanguages.length; i++) {
      // NOTE: 1 since 0 is default language
      if (
        props.location.state.primaryLanguage.id !== availableLanguages[i].id
      ) {
        availableLangIds.push(availableLanguages[i].id);
      }
    }
    // if(content && description){
    // document.getElementById("errorMessage").style.display = "none"
    const isAllLangPresent = await checkDataSet();
    if (isAllLangPresent === 1) {
      let dataSet = {
        // 'categories_ids' : props.location.state.selectedGenres[0],
        categories_ids: categoryIdsArray.join(","),
        genre_ids: genreIdsArray.join(","),
        quiz_deck_name: "Default Deck Name",
        quiz_deck_desc: "Default Description",
        default_lang_id: defaultLangId,
        attributes_ids: attributesIdsArray.join(","),
        quiz_deck_mode: props.location.state.quizMode,
        quiz_deck_type: props.location.state.quizType,
        complexity: props.location.state.complexity,
      };
      if (amount) {
        dataSet.amount = amount;
      }
      if (group_id) {
        dataSet.group_id = group_id;
      }
      if (subCategoriesIdsArray.length > 0) {
        dataSet.sub_categories_ids = subCategoriesIdsArray.join(",");
      }
      if (availableLangIds.length > 0) {
        dataSet.available_lang_ids = availableLangIds.join(",");
      }
      if (selectedKeywordIdsArray.length > 0) {
        dataSet.keywords_ids = selectedKeywordIdsArray.join(",");
      }
      axios({
        method: "post",
        url: "/api/quiz_deck/create",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify(dataSet),
      })
        .then((response) => {
          console.log("content response", response.data);
          showMessageToUser(response.data.msg);
          if (response.data.data) {
            setContentId(response.data.data.id);
          }
        })
        .catch((error) => {
          // removeUserSession();
          console.log("error", error.response);
          showErrorMessageToUser(error.response.data.msg);
        });
    }
  };

  useEffect(() => {
    if (contentId) {
      (async function updateLanguages() {
        console.log("going to make dataset");
        await makeDataset();
      })();
    }
  }, [contentId]);

  const makeDataset = async () => {
    setLanguageDataset([]);
    for (let i = 0; i < languages.length; i++) {
      document.getElementById("errorMessage_" + (i + 1)).style.display = "none";
      let localName = document.getElementById("contentName_" + (i + 1)).value;
      let localDesc = document.querySelector(
        "#editor" + languages[i].id + " .ql-editor"
      ).innerHTML; //document.getElementById("contentDesc_"+(i+1)).value

      let localIsDefault = 0;
      if (defaultLangId === languages[i].id) {
        localIsDefault = 1;
      }
      if (localName !== "" && localDesc !== "") {
        setLanguageDataset((languageDataset) =>
          languageDataset.concat({
            quiz_deck_name: localName,
            quiz_deck_desc: localDesc,
            language_id: languages[i].id,
            is_default: localIsDefault,
          })
        );
      } else {
        document.getElementById("errorMessage_" + (i + 1)).style.display =
          "block";
      }
    }
    console.log(languageDataset);
  };

  const checkDataSet = async () => {
    for (let i = 0; i < languages.length; i++) {
      document.getElementById("errorMessage_" + (i + 1)).style.display = "none";
      let localName = document.getElementById("contentName_" + (i + 1)).value;
      let localDesc = document.querySelector(
        "#editor" + languages[i].id + " .ql-editor"
      ).innerHTML; //document.getElementById("contentDesc_"+(i+1)).value
      if (localName !== "" && localDesc !== "") {
      } else {
        document.getElementById("errorMessage_" + (i + 1)).style.display =
          "block";
        return 0;
      }
    }
    return 1;
  };

  useEffect(() => {
    console.log(languageDataset);
    console.log(languages);

    if (
      languageDataset.length > 0 &&
      languageDataset.length === languages.length
    ) {
      axios({
        method: "post",
        url: "/api/quiz_deck/update/language",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          quiz_deck_id: contentId,
          dataset: languageDataset,
        }),
      })
        .then((response) => {
          console.log("language updated for deck", response.data);
          showMessageToUser(response.data.msg);
        })
        .catch((error) => {
          // removeUserSession();
          console.log("error", error);
        });

      axios({
        method: "post",
        url: "/api/quiz_deck/update/question/type",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          quiz_deck_id: contentId,
          question_type_id: 1,
        }),
      })
        .then((response) => {
          console.log("quiz created response", response.data);
          showMessageToUser(response.data.msg);
          // setUserSession(token, response.data.user);
          props.history.push({
            pathname: "/questionList/" + contentId,
            state: {
              ...props.location.state,
              quiz_deck_id: response.data.data.id,
              languages: languages,
            },
          });
        })
        .catch((error) => {
          // removeUserSession();
          console.log("error", error);
        });
    }
  }, [languageDataset]);

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  const goToDashboard = () => {
    props.history.push("/dashboard");
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("create_content")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("create_content")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-content-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-7">
              <div class="create-content-tabs animated fadeInRight">
                <ul class="nav nav-tabs nav-justified">
                  {translatedLangs
                    ? translatedLangs.map((language) => (
                        <li
                          class="nav-item"
                          onClick={() => changeLanguage(language)}
                        >
                          <a
                            href=""
                            data-toggle="tab"
                            class={
                              language.id == currentLanguage.id
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {/* <span class="icon hindi"></span>  */}
                            <img src={COUNTRY_FLAG + language.flag} />
                            <span>{t(language.name)}</span>
                          </a>
                        </li>
                      ))
                    : null}
                </ul>

                {/* <!-- Tab panes --> */}

                {languages
                  ? languages.map((language, index) => (
                      <React.Fragment>
                        <div
                          class="tab-content"
                          style={
                            currentLanguage.id == language.id
                              ? null
                              : { display: "none" }
                          }
                        >
                          <div
                            class="tab-pane container active"
                            id="EnglishTab"
                          >
                            <div class="create-topic-box">
                              <div class="header">
                                {t("add_content_title_description")}
                              </div>
                              <div class="inner-box">
                                <div class="mb-4">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder={t("add_content_title")}
                                    id={"contentName_" + (index + 1)}
                                  />
                                </div>
                                <div>
                                  <ReactQuillComponent
                                    language_id={language.id}
                                  ></ReactQuillComponent>
                                  {/*
                                                                        <ReactQuill class="form-control" id={"contentDesc_" + questionNo}
                                                                            placeholder={t("add_content_description")}
                                                                            modules={modules}
                                                                            formats={formats} />
                                                                        */}
                                  {/* <textarea class="form-control" placeholder={t("add_content_description")}
                                                                            id={"contentDesc_" + questionNo}
                                                                        ></textarea> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* 
                                                    <CreateContentDeckForm
                                                        language = {language}
                                                        currentLanguage = {currentLanguage}
                                                        questionNo = {index+1}
                                                    />
                                                    */}
                        <div
                          id={"errorMessage_" + (index + 1)}
                          style={{
                            display: "none",
                            backgroundColor: "red",
                            width: "400px",
                          }}
                        >
                          {t("enter_content_for_all")}
                        </div>
                      </React.Fragment>
                    ))
                  : null}
                <div
                  id={"noContentCreation"}
                  style={{
                    display: "none",
                    backgroundColor: "red",
                    width: "100%",
                  }}
                ></div>
              </div>
              <div
                class="text-center pt-4 add-qa"
                onClick={goToCreateContentPage}
              >
                <a href="javascript:void(0)" class="btn-background blue-bg-btn">
                  {t("continue_to_add_qna")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default CreateContentDeck;
