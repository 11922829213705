/* eslint-disable */ 
import React, {useState, useEffect} from 'react'
import axios from 'axios'

import { useTranslation } from 'react-i18next';

import {loadStripe} from '@stripe/stripe-js';
import { useStripe, Elements } from '@stripe/react-stripe-js';

import i18next from 'i18next';
import { useCookies } from "react-cookie";

function SingleCheckoutPage(props) {
    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const [errorThis, setErrorThis] = useState(null)
    let [stripePromise, setStripePromise] = useState(null);
    let [serverStripeKey, setServerStripeKey] = useState(null);
    const [stripe_customer_id, setStripeCustomerID] = useState(false)
    const [stripeSession, setStripeSession] = useState(null)
    

    const globalLang = cookies['global_language'] //JSON.parse(getUserGlobalLanguage());
    let globalLangCode = globalLang.lang_code;
    let globalLangId = globalLang.id;
    const token =  cookies['access_token'] //getToken()

    useEffect(() => {
        axios({
            method: 'get',
            url: '/api/stripe/keys',
            headers:{
                'locale': "en",
                'x-access-token': "Memo "+token,
                'Content-Type': 'application/json',
            }        
        })
        .then(response => {
            //console.log("stripekeys fetched ", JSON.stringify(response.data))
            //setUserSession(token, response.data.user);
            //console.log(response.data.data[0].publishable)
            let lstripePromise = loadStripe(response.data.data[0].publishable)
            setServerStripeKey(response.data.data[0].publishable);
            setStripePromise(lstripePromise)
        }).catch(error => {
            //removeUserSession();
            console.log("error", error)
        });
      },[])

      useEffect(() => {
    
        // Step 2: Create PaymentIntent over Stripe API
        axios({
            method: 'post',
            url: '/api/stripe/customer/create',
            headers:{
                'locale': "en",
                'x-access-token': "Memo "+token,
                'Content-Type': 'application/json',
            },
            //data: paymentIntentData
          })
          .then(response => {
              console.log('customer create' , response.data.data.stripe_customer_id)
              setStripeCustomerID(response.data.data.stripe_customer_id)
          })
          .catch((err) => {
              console.log(err.response)
              if(err.response && err.response.data && err.response.data.msg) {
                setErrorThis(err.response.data.msg);    //LIVE mode
              } else if (err.response && err.response.data && err.response.data.message) {
                setErrorThis( err.response.data.message); //Test mode
              }
          });
      }, []);

      useEffect(() => {
        console.log('here')
        if(serverStripeKey && stripe_customer_id && stripePromise && props.location.state.id !== 0) {
            let user = cookies['user'] //getUser();
            console.log('user.id=' , user.id)
            console.log('planId=' , props.location.state.id)
            let paymentIntentData
            switch (props.location.state.plantype) {
                case "CreditPlan":
                    paymentIntentData = { /*amount: amount, 
                        currency: currencyCode,*/
                        locale: i18next.language,
                        stripe_customer_id: stripe_customer_id,
                        metadata: {planid: props.location.state.id, userid:user.id ,plantype: 'CreditPlan'},
                        successURL: '/mycredits',
                        cancelURL: '/mycredits'
                        };
                    break;
                case "SubscriptionPlan":
                    paymentIntentData = { /*amount: amount, 
                        currency: currencyCode,*/
                        locale: i18next.language,
                        stripe_customer_id: stripe_customer_id,
                        metadata: {id: props.location.state.id, userid:user.id ,plantype: 'SubscriptionPlan'},
                        successURL: '/managesubscriptions/' + -1,
                        cancelURL: '/managesubscriptions/' + -1
                      };
                    break;
            }

            
            // Step 2: Create PaymentIntent over Stripe API
            axios({
                method: 'post',
                url: '/api/stripe/single/session-checkout',
                headers:{
                    'locale': "en",
                    'x-access-token': "Memo "+token,
                    'Content-Type': 'application/json',
                },
                data: paymentIntentData
            })
            .then(response => {
                console.log('session_id' + response.data)
                setStripeSession(response.data.session_id);
            })
            .catch((err) => {
                console.log('err',err.response.data[0].msg)
                setErrorThis(err.response.data[0].msg.raw.message)
                //err.response.data[0].msg
                //setError(err.message);
            });
            return () => {
                console.log("This will be setStripeSession on unmount");
            }
      }
    }, [serverStripeKey,stripe_customer_id,stripePromise])

    useEffect( () => {
        console.log('here')
        console.log('stripeSession' , stripeSession)
        if(stripeSession) {
        //const stripe = await stripePromise;
        //let stripe = Stripe(serverStripeKey);
        const stripe = window.Stripe(serverStripeKey)
        //window.Stripe
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as argument here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: stripeSession
          }).then(function (result) {
              console.log('adsfasdfsdafadsf')
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        }
          return () => {
            console.log("This will be logged on unmount");
          }
    }, [stripeSession])

    return (
        <React.Fragment>
             {/* <BasicHeader 
                {...props}
            /> */}
            { errorThis ? <h1>{errorThis}    </h1> : null}
            {/* <BasicFooter /> */}
        </React.Fragment>
    )

    }

    export default SingleCheckoutPage