/* eslint-disable */ 


import React from 'react'
import banner from "../images/banner.png"
import { useTranslation } from 'react-i18next';
import "../less/dashboard.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/about-us.css"
import aboutMePng from "../images/about/about.png"
import qualificationpng from "../images/about/qualification.png"
import thankyou from "../images/thank-you.jpg"



function AboutUs() {

    return (
        <React.Fragment>
              <div class="about-us-banner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7 col-sm-8">
                    <div class="animated fadeInLeft txt-info">
                        <h3>Hello, I am</h3>
                        <h2>KIM B.C. PEDERSEN</h2>
                        <p>General Manager/<br></br>International Business Development Director</p>
                        <a href="javascript:void(0)" class="get-in-touch-btn">Get in touch</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-4 person-img">
                    <img src={aboutMePng} class="animated fadeInRight" />
                </div>
            </div>
            <div class="down-nav"><a href="#abt_sec">|</a></div>
        </div>
    </div>
    
    <section class="about-section" id="abt_sec">
        <div class="container animated fadeInRight">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 aboutDesc text-center mr-auto ">
                    <h5>A short story</h5>
                    <h2>About Me</h2>
                    <p>With an upbringing in Japan, more than 40 years relation to Japan. native Japanese language, cultural & business skills, more than 20 years of business development experience. Kim Pedersen has a unique skillset and experiences understanding the Japanese & Asian markets. For a foreign capital company wishing to enter or expand on the Asian markets in record time, Kim Pedersen is one of the best candidates.</p>
                    <h4><i>KIM B.C. PEDERSEN</i></h4>
                </div>
            </div>
        </div>
        <div class="container about-point">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="quality animated fadeInLeft">
                        <div class="icon">
                            <span class="icon-leadership"></span>
                        </div>
                        <h3>20+</h3>
                        <p>LEADER & MANAGEMENT<br></br> EXPERIENCES</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="quality animated fadeInDown">
                        <div class="icon">
                            <span class="icon-eduction"></span>
                        </div>
                        <h3>10+</h3>
                        <p>CORE COMPETENCIES<br></br>KNOWLEDGE </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="quality animated fadeInRight">
                        <div class="icon">
                            <span class="icon-industry"></span>
                        </div>
                        <h3>15+</h3>
                        <p>INDUSTRY / NETWORK<br></br>EXPERIENCE </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="qualification">
        <div class="container">
            <div class="qualification-box">
                <div class="row grid-content">
                    <div class="col-lg-3 col-md-4 col-sm-12 animated fadeInLeft">
                        <div class="qualification-img">
                            <img src={qualificationpng} />
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8 col-sm-12 animated fadeInRight">
                        <h2>Qualification</h2>
                        <div class="qualification-text">
                            <span class="icon icon-graduation"></span>
                            <div>
                                <div>The Danish Export Institute. International Marketing and Export Diploma</div>
                                <div class="year">(1997 -1998)</div>
                            </div>
                        </div>
                        <div class="qualification-text">
                            <span class="icon icon-graduation"></span>
                            <div>
                                <div>Aarhus University. Japanese Language Bachelor Degree Course (completed first 2 years)</div>
                                <div class="year">(1994 -1996)</div>
                            </div>
                        </div>
                        <div class="qualification-text">
                            <span class="icon icon-graduation"></span>
                            <div>
                                <div>Arhus Business School, HH (Higher Commercial Examination)</div>
                                <div class="year">(1983 -1986)</div>
                            </div>
                        </div>
                        <div class="qualification-text">
                            <span class="icon icon-graduation"></span>
                            <div>
                                <div>Attended Kane. Shogakko (Japanese Primary School)</div>
                                <div class="year">(1974 - 1981)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="career-info">
        <div class="container">
            <div class="text-center animated fadeInDown">
                <h2>Career</h2>
                <p>Professionally simplify team driven architectures vis-a-vis standardized infrastructures. Credibly myocardinate premier e-services via business action items. Intrinsicly administrate sticky. </p>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInLeft bor-right">
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInRight">
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                    <div class="career-list">
                        <span class="icon icon-suitcase"></span>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="graduation">Business Consultant</div>
                                <div>Europe/Japan Expert</div>
                            </div>
                            <div class="col-sm-5 year">(2017 - present)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="get-in-touch">
        <div class="container">
            <div class="text-center animated fadeInDown">
                <h2>Get in Touch</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 ">
                    <a href="https://www.memorizeitall.com/" class="contact-details animated fadeInLeft">
                        <span class="icon icon-globe"></span>
                        <span>www.memorizeitall.com</span>
                    </a>                   
                </div>
                <div class="col-lg-4">
                    <div class="contact-details animated fadeInDown">
                        <span class="icon icon-call"></span>
                        <div>0120-4340093</div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <a href="mailto:info@japanexpert.eu" class="contact-details animated fadeInRight">
                        <span class="icon icon-email1"></span>
                        <span>info@japanexpert.eu</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
        </React.Fragment>
    )
}

export default AboutUs
