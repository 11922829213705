/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/invite.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

function InviteUserToTakeContent(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();

  console.log(props);

  const contentId = props.location.state.quiz_deck_id;

  const [searchTerm, setSearchTerm] = useState();
  const [toBeInvitedUsers, setToBeInvitedUsers] = useState([]);
  const [banners, setBanners] = useState();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const addToTakeContentInviteList = () => {
    if (searchTerm.length > 0) {
      setToBeInvitedUsers((users) =>
        users.concat({
          email_id: searchTerm,
        })
      );
    }
  };

  const removeThisUserFromToBeInvitedList = (user) => {
    setToBeInvitedUsers([]);
    for (let i = 0; i < toBeInvitedUsers.length; i++) {
      if (toBeInvitedUsers[i].email_id !== user.email_id) {
        setToBeInvitedUsers((users) =>
          users.concat({
            email_id: toBeInvitedUsers[i].email_id,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (toBeInvitedUsers.length > 0) {
      console.log(toBeInvitedUsers);
    }
  }, [toBeInvitedUsers]);

  const addMembers = () => {
    let dataObj = [];
    for (let i = 0; i < toBeInvitedUsers.length; i++) {
      dataObj.push({
        email_id: toBeInvitedUsers[i].email_id,
      });
    }
    axios({
      method: "post",
      url: "/api/quiz_deck/invite/users/userswithemails",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        quiz_deck_id: contentId,
        data: dataObj,
      }),
    })
      .then((response) => {
        showMessageToUser(response.data.msg);
        // setMemberAdded(true)
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data[0].msg);
      });
    setSearchTerm("");
    setToBeInvitedUsers([]);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("inviteUsers")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("inviteUsers")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="banner_image">
            </div> */}
      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}
      <div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-7">
              <div class="group-profile animated fadeInRight">
                <div class="row"></div>
                <div class="invite-info" id="collapseInvite">
                  <h2>{t("enter_user_email_id")}</h2>
                  <div class="search-control">
                    <a
                      onClick={addToTakeContentInviteList}
                      href="javascript:void(0)"
                      class="search-icon icon-plus"
                    ></a>
                    <input
                      type="text"
                      placeholder={t("email")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <h2>{t("users_to_be_invited")}</h2>

                  <div class="invite-listing">
                    {toBeInvitedUsers.length > 0
                      ? toBeInvitedUsers.map((user) => (
                          <div class="invite-list-box">
                            <div class="user-img">
                              {/* <img src={PROFILE_PIC + user.profile_pic} /> */}
                            </div>
                            <div class="user-name">
                              {/* <div>{user.first_name}</div> */}
                              <div>{user.email_id}</div>
                            </div>
                            <a
                              onClick={() =>
                                removeThisUserFromToBeInvitedList(user)
                              }
                              href="javascript:void(0)"
                              class="links remove"
                            >
                              <span class="icon-trash-bin"></span>
                              <span>{t("delete")}</span>
                            </a>
                          </div>
                        ))
                      : t("no_users")}

                    <div class="row justify-content-center invite-btn">
                      <div
                        class="col-sm-12 col-md-12 col-lg-4"
                        onClick={addMembers}
                      >
                        <a
                          href="javascript:void(0)"
                          class="btn-background btn-block blue-bg-btn"
                        >
                          {t("invite_txt")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
      <ToastContainer />
    </React.Fragment>
  );
}

export default InviteUserToTakeContent;
