/* eslint-disable */

import axios from "axios";
import i18next from "i18next";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../Reducers/Store";
// import "../images/favicon.ico"

import { useCookies } from "react-cookie";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/dashboard.css";
import "../less/icon-font.css";
import "../less/releway-font.css";
import SideNavBar from "./SideNavBar";

function Dashboard(props) {
  console.log("dashboard props", props);
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "expiry_time",
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);
  console.log(t);
  console.log("lang+", i18next.language);
  const [userProfile, setUserProfile] = useState();
  const [userPreferences, setUserPreferences] = useState();
  const [genrePrefenceLength, setGenrePreferenceLength] = useState(4);
  const [categoriesPrefenceLength, setCategoriesPreferenceLength] = useState(4);
  const [languagePrefenceLength, setLanguagePreferenceLength] = useState(4);
  const [userType, setUserType] = useState();

  const token = cookies["access_token"];
  const user = cookies["user"]; //getUser();
  console.log(user);
  const user_type_id = user.user_type_id;
  console.log("user_type_id", user_type_id);
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_profile" });
  }, []);

  const setCookieUser = (user) => {
    let exp_time = cookies["expiry_time"];
    if (!exp_time) {
      let exp = 30 * 24 * 60 * 60;
      console.log("in setCookieUser", exp);
      exp_time = new Date();
      exp_time.setTime(exp_time.getTime() + exp * 1000);
    } else {
      exp_time = new Date(exp_time);
      console.log("exp_time", exp_time);
    }
    setCookie("user", JSON.stringify(user), { path: "/", expires: exp_time });
  };
  useEffect(() => {
    console.log(token);

    axios
      .get("/api/user/get/profile", {
        headers: {
          // 'content-type' : 'application/json',
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
      })
      .then((response) => {
        console.log("response for user", response);
        setCookieUser(response.data.user);

        setUserProfile(response.data.user);
        console.log("response", response);
      })
      .catch((error) => {});

    axios({
      method: "post",
      // url: 'http://localhost:8000/api/user/get/my-prefrences',
      url: "/api/user/get/my-prefrences",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        preference_type: "4",
      }),
    })
      .then((response) => {
        // console.log("preferences response", response.data.data)
        //   setUserSession(token, response.data.user);
        setUserPreferences(response.data.data);
      })
      .catch((error) => {
        //   removeUserSession();
      });
  }, []);

  useEffect(() => {
    console.log("userProfile outside", userProfile);
    if (userProfile) {
      console.log("userProfile inside", userProfile);
      if (userProfile.user_type_id == "1") {
        setUserType("Admin");
      } else if (userProfile.user_type_id == "2") {
        setUserType(t("free"));
      } else if (userProfile.user_type_id == "5") {
        setUserType(t("group"));
      } else if (userProfile.user_type_id == "4") {
        setUserType("Seller");
      } else if (userProfile.user_type_id == "7") {
        setUserType("Dummy User");
      } else if (userProfile.user_type_id == "6") {
        setUserType("Test");
      } else if (userProfile.user_type_id == "8") {
        setUserType("Official Content Creators");
      } else if (userProfile.user_type_id == "9") {
        setUserType("Ad and banners manager");
      } else if (userProfile.user_type_id == "3") {
        setUserType(t("pro"));
      } else if (userProfile.user_type_id == "10") {
        setUserType(t("creator"));
      }
    }
  }, [userProfile]);

  const showAllPreferedGenres = () => {
    setGenrePreferenceLength(userPreferences.preffered_genres.length);
  };
  const showAllPreferedCategories = () => {
    setCategoriesPreferenceLength(userPreferences.preffered_categories.length);
  };
  const showAllPreferedLanguages = () => {
    setLanguagePreferenceLength(userPreferences.preffered_languages.length);
  };
  const showDefaultPreferedGenres = () => {
    setGenrePreferenceLength(4);
  };
  const showDefaultPreferedCategories = () => {
    setCategoriesPreferenceLength(4);
  };
  const showDefaultPreferedLanguages = () => {
    setLanguagePreferenceLength(4);
  };

  const genreSelected = (id) => {
    props.history.push("/genredetails/" + id);
  };

  return (
    <React.Fragment>
      <div class="dashboard-wrapper">
        {/* <div class="container">
            <h2 class="animated fadeInLeft">{t('dashboard')}</h2>
        </div> */}
        <div class="container">
          <div class="dashboard-content">
            <SideNavBar {...props} />
            <div class="dashboard-information">
              {/* <!-- Begin Dashboard Tab Section --> */}
              <section class="dashboard-section">
                {userProfile ? (
                  <div class="profile-box animated fadeInRight">
                    <div class="topside">
                      <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          {/* <h3>{t('Welcome')}</h3> */}
                          <div class="user-name">{userProfile.name} </div>
                          <div class="info-icon">
                            <span class="icon icon-phone"></span>
                            <span>
                              +{userProfile.dial_code} {userProfile.phone_no}
                            </span>
                          </div>
                          <div class="info-icon">
                            <span class="icon icon-user-email"></span>
                            <span class="email">{userProfile.email_id}</span>
                          </div>
                          <div class="info-icon">
                            <span class="icon icon-user"></span>
                            <span>{userProfile.id}</span>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                          <div class="profile-btns">
                            {userType ? (
                              <a href="javascript:void(0)">{userType}</a>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-3 profileImgCol">
                          <div class="profile-image">
                            <img
                              src={PROFILE_PIC + userProfile.profile_pic}
                              alt="Profile Img"
                            />
                            {/* <a href="javascript:void(0)" class="edit-profile"><span class="icon-camera1"></span></a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bottomsite">
                      <div class="row">
                        <div class="col-4 b-boxs">
                          <div class="b-icon">
                            <span class="icon">
                              <span class="icon-images"></span>
                            </span>
                          </div>
                          <div class="number">{userProfile.total_quizzes}</div>
                          <div class="pr-1 pl-1">{t("quizes")}</div>
                        </div>
                        <div class="col-4 b-boxs">
                          <div class="b-icon">
                            <span class="icon dollar">
                              <span class="icon-dollar"></span>
                            </span>
                          </div>
                          <div class="number">{userProfile.wallet_amount}</div>
                          <div class="pr-1 pl-1">{t("CreditsAvailable")}</div>
                        </div>
                        {/*
                                    <div class="col-4 b-boxs">
                                        <div class="b-icon">
                                            <span class="icon"><span class="icon-refer"></span></span>
                                        </div>
                                        <div class="number">{userProfile.referral_count}</div>
                                        <div class="pr-1 pl-1">{t("my_referral")}</div>
                                    </div>
                                     */}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <div class="chart-info animated fadeInRight">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="chart-boxs">Accuracy</div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="chart-boxs">Accuracy</div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="chart-boxs">Accuracy</div>
                                </div>
                            </div>
                        </div> */}
                {/* <div className="preferred-info preferred-genres animated fadeInRight">
                        <div className="row mb-4">
                                <div class="col-8">
                                    <h3>{t('description')}</h3>
                                </div>
                            </div>
                        </div> */}

                {userProfile ? (
                  <div class="profile-box animated fadeInRight">
                    <div class="topside">
                      <div class="row align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="desc">{userProfile.about_us}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  "N/A"
                )}

                {user_type_id == 8 ? null : (
                  <React.Fragment>
                    <div class="preferred-info preferred-genres animated fadeInRight">
                      <div class="mb-4">
                        <h3>{t("prefered_languages")}</h3>
                      </div>
                      <div class="row">
                        {userPreferences
                          ? userPreferences.preffered_languages.length > 0
                            ? userPreferences.preffered_languages.map(
                                (language) => (
                                  <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                    <a
                                      href="javascript:void(0)"
                                      class="preferred-boxs circle"
                                    >
                                      <img
                                        src={COUNTRY_FLAG + language.flag}
                                        alt="Img"
                                      />
                                    </a>
                                    <div class="in-txt">{t(language.name)}</div>
                                  </div>
                                )
                              )
                            : null
                          : null}
                      </div>
                    </div>
                    <div class="preferred-info preferred-genres animated fadeInRight">
                      <div class="preferred-geners">
                        <h3>{t("prefered_genres")}</h3>
                        {/* <div class="col-4 view-all-btn"
                                                // onClick={showAllPreferedLanguages}
                                >
                                                <a href="javascript:void(0)">Edit <span class="icon-arrow-right"></span></a>
                                </div> */}
                      </div>
                      <div class="row row-eq-height">
                        {userPreferences
                          ? userPreferences.preffered_genres.length > 0
                            ? userPreferences.preffered_genres.map((genre) => (
                                <div
                                  class="col-6 col-sm-4 col-md-6 col-lg-3"
                                  onClick={() => genreSelected(genre.id)}
                                  style={{ marginTop: "20px" }}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="preferred-boxs square"
                                  >
                                    <span>
                                      <img
                                        src={GENER_PATH + genre.image}
                                        alt="Img"
                                      />
                                    </span>
                                    <span
                                      class="in-txt"
                                      dangerouslySetInnerHTML={{
                                        __html: genre.name,
                                      }}
                                    ></span>
                                  </a>
                                </div>
                              ))
                            : null
                          : null}
                      </div>
                    </div>

                    <div class="preferred-info preferred-genres animated fadeInRight">
                      <div class="preferred-geners">
                        <h3>{t("prefered_categories")}</h3>
                        {/* <div class="col-4 view-all-btn"
                                                // onClick={showAllPreferedLanguages}
                                >
                                                <a href="javascript:void(0)">Edit <span class="icon-arrow-right"></span></a>
                                </div> */}
                      </div>
                      <div
                        class="row"
                        style={{
                          rowGap: "20px",
                        }}
                      >
                        {userPreferences
                          ? userPreferences.preffered_categories.length > 0
                            ? userPreferences.preffered_categories.map(
                                (category) => (
                                  <div
                                    class="col-6 col-sm-4 col-md-3 col-lg-3"
                                    onClick={() =>
                                      genreSelected(category.parent_genre_id)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={CATEGORY_PATH + category.image}
                                        alt="Img"
                                        width={100}
                                        height={100}
                                      />
                                    </div>
                                    <div>{category.name}</div>
                                  </div>
                                )
                              )
                            : null
                          : null}
                      </div>
                    </div>
                    <div class="preferred-info preferred-genres animated fadeInRight">
                      <div class="preferred-geners">
                        <h3>{t("prefered_sub_categories")}</h3>
                        {/* <div class="col-4 view-all-btn"
                                                // onClick={showAllPreferedLanguages}
                                >
                                                <a href="javascript:void(0)">Edit <span class="icon-arrow-right"></span></a>
                                </div> */}
                      </div>
                      <div
                        class="row"
                        style={{
                          rowGap: "20px",
                        }}
                      >
                        {userPreferences
                          ? userPreferences.preffered_sub_categories.length > 0
                            ? userPreferences.preffered_sub_categories.map(
                                (sub_category) => (
                                  <div
                                    class="col-6 col-sm-4 col-md-3 col-lg-3"
                                    onClick={() =>
                                      genreSelected(
                                        sub_category.parent_genre_id
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={CATEGORY_PATH + sub_category.image}
                                        alt="Img"
                                        width={100}
                                        height={100}
                                      />
                                    </div>
                                    <div>{sub_category.name}</div>
                                  </div>
                                )
                              )
                            : null
                          : null}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </section>
              {/* <!-- End Dashboard Tab Section --> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
