/* eslint-disable */ 

import React, { useState} from 'react'
import { useTranslation } from 'react-i18next';



function AnswerInputOptions (props) {
  const { t } = useTranslation()

    const {languages, currentLanguage, optionName, optionIdFor, optionNumber, answerTextId, answerMode, answerTypeId, questionId, optionNo} = props




    return (
        <React.Fragment>
            



                                    <div class="option-list">
                                        
                                                <div class="custom-control custom-radio">
                                                      <input type="radio" class="custom-control-input" 
                                                        id={"option" + 1} 
                                                        name="Option" value="Option"  
                                                      />
                                                      <label class="custom-control-label" 
                                                      for={"option" + 1}
                                                      >
                                                          {t("option") + 1}
                                                      </label>
                                                  </div>
                                                  <div class="inner-option">
                                                      <div class="select-txt">{t("select_this_as_correct_answer")}</div>
                                                      
                                                      {
                                                              languages ? 
                                                              languages.map(language => (
                                                                <div class="option-input"
                                                                   style = {currentLanguage.id === language.id ? {display:"block"}:{display:"none"}}
                                                                >
                                                                <input type="text"
                                                                    style={{display:"block",fontSize: "36px",fontFamily: 'arial'}}
                                                                    id={"answerInput_"+language.id+"_"+ 1} 
                                                                />
                                          
                                                                </div>
                                                              ))
                                                              :
                                                              null
                                                    }
                                                      <input type="file" name="file" 
                                                            
                                    
                                                          style={{display:"none"}}
                                                            id = {"uploadButton"+1}
                                    
                                                        />
                                                        <img 
                                                        id={"answerImagePreview"+1} width="200px" 
                                                        style={{display:"none"}}
                                                    /> 
                                                  </div>
                                              </div>
                                    
        </React.Fragment>
    )
}

export default AnswerInputOptions
