const editorConfig = {
    readonly: false,
    toolbar: true,
    textIcons: false,
    spellcheck: true,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    buttons: [
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'ul',
        'ol', '|',
        'font',
        'fontsize',
        'paragraph', '|',
    ],
    removeButtons: [
        'source',
        'link',
        'video',
        'eraser',
        'copyformat',
        'image',
        'colors',
        'table'
    ],
}

module.exports = {
    editorConfig
}