/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCustomQuill from "../Hooks/useCustomQuill";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/groups.css";
import "../less/icon-font.css";
import "../less/invite.css";
import "../less/my-credits.css";
import "../less/quill-editor.css";
import "../less/releway-font.css";
import ReactQuillComponent from "./ReactQuillComponent";

import GroupContentComponent from "./GroupContentComponent";
import GroupMemberComponent from "./GroupMemberComponent";
import GroupNewsComponent from "./GroupNewsComponent";
import GroupRequestJoinComponent from "./GroupRequestJoinComponent";

function GroupAdmin(props) {
  //console.log('GroupAdmin.props', props)
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const token = cookies["access_token"]; //getToken();
  const group_id = props.match.params.group_id;
  const group = props.location.state.group;
  const group_creatorId = group.created_by;

  const user = cookies["user"]; //getUser();
  const user_id = user.id;

  const editor = useRef(null);

  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [organization, setOrganization] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [profile_pic_filename, setProfile_pic_filename] = useState("");
  const [profilePicUploaded, setProfilePicUploaded] = useState(0);
  const [open_close, setOpenClose] = useState(0); //Close = 0
  const [owner_name, setOwnerName] = useState("");
  const [super_admin_name, setSuperAdminName] = useState("");
  const [can_change_super_admin, setCanChangeSuperAdmin] = useState(0);
  const [owner_id, setOwnerID] = useState("");

  const [groupAdminList, setGroupAdminList] = useState("");
  const [toAddAdminEMail, setToAddAdminEMail] = useState("");
  const [toAddSuperAdminEMail, setToAddSuperAdminEMail] = useState("");

  const [refreshMembers, setRefreshMembers] = useState(0);
  const [allQuillRefs] = useCustomQuill(t("group_description"), [true]);

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fetchAdministrators = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/administrator/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        group_id: group_id,
      }),
    })
      .then((response) => {
        let group_admin_list = response.data.data;
        console.log(
          "/api/groups/administrator/list response.data",
          group_admin_list
        );
        setGroupAdminList(group_admin_list);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (allQuillRefs && groupDesc) {
      console.log("allQuillRefs.current", allQuillRefs.current);
      for (let i = 0; i < allQuillRefs.current.length; i++) {
        allQuillRefs.current[i].quill.root.innerHTML = groupDesc;
      }
    }
  }, [groupDesc, allQuillRefs]);

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/groups/" + group_id,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        let group_details = response.data.data;
        console.log("/api/groups/ response.data", response.data.data);
        setGroupName(group_details.group_name);
        setGroupDesc(group_details.description);
        setOrganization(group_details.organization);
        setProfile_pic_filename(group_details.profile_pic);
        setOpenClose(group_details.open_close);
        setOwnerName(group_details.owner_name);
        setOwnerID(group_details.owner_id);
        setSuperAdminName(group_details.super_admin_name);
        setCanChangeSuperAdmin(group_details.can_change_super_admin);
      })
      .catch((error) => {});

    fetchAdministrators();

    //window.addEventListener("beforeunload", onUnload);
    //return () => window.removeEventListener("beforeunload", onUnload);
  }, []);

  const setGroupDescQuell = (value) => {
    setGroupDesc(value);
  };
  const onUnload = (e) => {
    console.log("groupName", groupName);
    console.log("groupDesc", groupDesc);
  };
  useEffect(() => {
    console.log("profile_pic", profile_pic);

    if (profile_pic) {
      const fd = new FormData();
      fd.append("file", profile_pic);
      fd.append("filename", profile_pic.name);

      axios({
        method: "post",
        url: "/api/groups/profile/image/upload",
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
          "Content-Type": "multipart/form-data",
        },
        data: fd,
      })
        .then((response) => {
          console.log("response.data.data.file_name", response);
          setProfile_pic_filename(response.data.data.file_name);
          console.log("file_name=" + response.data.data.file_name);
        })
        .catch((error) => {
          showErrorMessageToUser(error.response.data[0].msg);
          console.log("error", error);
        });
    }
  }, [profile_pic]);

  const onFileUploadChange = (event) => {
    console.log(event.target.files[0]);
    setProfile_pic(event.target.files[0]);
  };

  const gotoMyGroupPage = (group) => {
    console.log("profile_pic_filename", profile_pic_filename);

    let localDesc = document.querySelector(
      "#editor0" + " .ql-editor"
    ).innerHTML;
    //let localDesc = thisQuill.root.innerHTML
    //Save the changes of group
    axios({
      method: "post",
      url: "/api/groups/update",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: group_id,
        group_name: groupName,
        group_description: localDesc,
        organization: organization,
        profile_pic: profile_pic_filename,
      }),
    })
      .then((response) => {
        console.log("response", response);
        showMessageToUser(response.data.msg);
        props.history.push({
          pathname: "/mygroups",
        });
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onAddAdministrator = () => {
    axios({
      method: "post",
      url: "/api/groups/administrator/create",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: group_id,
        email_id: toAddAdminEMail,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setToAddAdminEMail("");
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onDeleteAdministrator = (admin) => {
    axios({
      method: "post",
      url: "/api/groups/administrator/delete",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: admin.group_id,
        user_id: admin.user_id,
      }),
    })
      .then((response) => {
        console.log("response", response);
        showMessageToUser(response.data.msg);
        fetchAdministrators();
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onChangeSuperAdministrator = () => {
    document.getElementById("id-groupadmin-inviteUserModal").click();
  };

  const onInviteUserClick = (evt) => {
    //let email = document.getElementById('email_to_invite').value.trim()
    console.log("email", toAddSuperAdminEMail);
    axios({
      method: "post",
      url: "/api/groups/invite/userswithemail",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: group_id,
        email: toAddSuperAdminEMail,
        invited_to_become: 2, //super admin
      }),
    })
      .then((response) => {
        document.getElementById("id-groupadmin-close-inviteUserModal").click();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  const onRefreshMembers = () => {
    setRefreshMembers(!refreshMembers);
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}

      <div class="container">
        <div class="adminPanel">
          <h3 class="groupAdminHeading mb-3">
            {t("group_administration_panel")}
          </h3>
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-8">
              <div class="adminPanelInner animated fadeInRight">
                <div class="groupadminArea">
                  <div class="adminCreateBox">
                    {/*
                                        <div class="groupAdminBox d-flex position-relative">
                                            <label for="" class="blueThemeText d-inline-block pr-2 mb-1">{t('group_administrator')}</label>
                                            <input type="text" class="w-100 blueThemeText" value={super_admin_name}></input>
                                            <a href="javascript:void(0)" class="addCloseIcon"
                                                data-toggle="modal" data-backdrop="static"
                                                data-target="#groupAdmininviteUserModal"
                                                id="id-groupadmin-inviteUserModal"
                                                onClick={() => onChangeSuperAdministrator()}
                                                style={can_change_super_admin == 1 ? { display: "block" } : { display: "none" }}>
                                                <span class="icon  icon-plus"></span>
                                            </a>
                                        </div>
                                        */}

                    <div class="groupAdminBox d-flex position-relative">
                      <label
                        for=""
                        class="blueThemeText d-inline-block pr-2 mb-1"
                      >
                        {t("group_owner")}
                      </label>
                      <div class="position-relative w-100">
                        <label
                          for=""
                          class="blueThemeText d-inline-block pr-2 mb-1"
                        >
                          {owner_name}
                        </label>
                      </div>
                    </div>

                    {groupAdminList && groupAdminList.length ? (
                      groupAdminList.map((admin) => (
                        <React.Fragment>
                          <div class="groupAdminBox d-flex position-relative">
                            <label
                              for=""
                              class="blueThemeText d-inline-block pr-2 mb-1"
                            >
                              {t("group_administrator")}
                            </label>
                            <div class="position-relative w-100">
                              <input
                                type="text"
                                class="w-100 blueThemeText"
                                value={admin.name}
                              ></input>
                              <a
                                href="javascript:void(0)"
                                class="addCloseIcon"
                                onClick={() => onDeleteAdministrator(admin)}
                                style={
                                  admin.myself == 1
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                              >
                                <span class="icon icon-close1"></span>
                              </a>
                            </div>
                          </div>
                          <div class="groupAdminBox d-flex position-relative">
                            <label
                              for=""
                              class="v-hidden blueThemeText d-inline-block pr-2 mb-1"
                            >
                              {t("group_administrator")}
                            </label>
                            <div class="position-relative w-100">
                              <input
                                type="text"
                                class="w-100 blueThemeText"
                                value={toAddAdminEMail}
                                onChange={(e) =>
                                  setToAddAdminEMail(e.target.value)
                                }
                              ></input>
                              <a
                                href="javascript:void(0)"
                                class="addCloseIcon"
                                onClick={() => onAddAdministrator()}
                              >
                                <span class="icon icon-plus"></span>
                              </a>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    ) : (
                      <div class="groupAdminBox d-flex position-relative">
                        <label
                          for=""
                          class="blueThemeText d-inline-block pr-2 mb-1"
                        >
                          {t("group_administrator")}
                        </label>
                        <div class="position-relative w-100">
                          <input
                            type="text"
                            class="w-100 blueThemeText"
                            value={toAddAdminEMail}
                            onChange={(e) => setToAddAdminEMail(e.target.value)}
                          ></input>
                          <a
                            href="javascript:void(0)"
                            class="addCloseIcon"
                            onClick={() => onAddAdministrator()}
                          >
                            <span class="icon icon-plus"></span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="groupStatusBox">
                    <span
                      class={
                        open_close == 0
                          ? "groupLock closedLock"
                          : "groupLock openLock"
                      }
                    >
                      <span
                        class={
                          open_close == 0
                            ? "icon icon-lock"
                            : "icon icon-unlock"
                        }
                      ></span>
                    </span>
                  </div>
                </div>
                <div class="groupadminArea position-relative">
                  <div class="adminCreateBox">
                    <div class="mb-1">
                      <input
                        type="text"
                        placeholder={t("group_name")}
                        class="bb-1 blueThemeText"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      ></input>
                    </div>
                    <div class="mb-1">
                      <input
                        type="text"
                        placeholder={t("organization")}
                        class="bb-1 blueThemeText"
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div class="d-flex align-items-end logoUploadArea">
                    <div class="logoUpload position-relative">
                      <input
                        type="file"
                        class="custom-file-input"
                        onChange={onFileUploadChange}
                      ></input>
                      <img
                        src={GROUPS_PATH + profile_pic_filename}
                        alt=""
                        class="groupLogoImg img-fluid"
                      ></img>

                      <div class="logoText">Logo</div>
                      <div class="cameraIcon">
                        <span class="icon icon-camera2"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="richTextEditor groupAdminRichText">
                  <ReactQuillComponent language_id={0}></ReactQuillComponent>
                  {/* 
                                    <ReactQuill class="form-control" id={"group_desc"}
                                        placeholder={t("group_description")}
                                        modules={modules}
                                        formats={formats}
                                        value={groupDesc}
                                        onChange={setGroupDescQuell} />
                                    */}
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <GroupMemberComponent
                      group_id={group_id}
                      refreshMembers={refreshMembers}
                    />
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <GroupNewsComponent group_id={group_id} />
                  </div>
                </div>
                {open_close ? (
                  <GroupRequestJoinComponent
                    group_id={group_id}
                    onRefreshMembers={onRefreshMembers}
                  />
                ) : null}
                <GroupContentComponent group_id={group_id} />
              </div>
              <div class="createGroupBtn mt-4 justify-content-center">
                <a href="javascript:void(0)" onClick={() => gotoMyGroupPage()}>
                  <span class="icon icon-next"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        role="dialog"
        id="groupAdmininviteUserModal"
        data-dismiss="modal"
      >
        <div className="modal-dialog">
          <div class="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">{t("invite_user")}</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="id-groupadmin-close-inviteUserModal"
              >
                <span className="icon icon-close1"></span>
              </button>
            </div>
            <div className="modal-body">
              <div class="inviteUserPanel">
                <div class="shadowPanelInner">
                  <div class="inviteUserHeading position-relative d-flex">
                    <div class="pl-70px d-flex align-items-center mb-3">
                      <span class="inviteUserLogo">
                        <img
                          src={GROUPS_PATH + profile_pic_filename}
                          alt=""
                          class="img-fluid"
                        />
                      </span>
                      <h5 class="blueThemeText fw-600">{groupName}</h5>
                    </div>
                    <div class="inviteUserBox">
                      <a href="javascript:void(0)" class="inviteUser hoverIcon">
                        <span class="icon icon-add-user"></span>
                      </a>
                    </div>
                  </div>

                  <div class="organisationField mb-4">
                    <input
                      type="text"
                      placeholder={t("organization")}
                      class="w-100"
                      value={organization}
                    />
                  </div>
                  <div class="inviteUserText">
                    <span class="d-block mb-4 blueThemeText fw-600">
                      {t("invite_the_following_as_super_admin")}
                    </span>
                    <div class="position-relative">
                      <input
                        type="text"
                        placeholder={t("input_email_address")}
                        class="w-100"
                        value={toAddSuperAdminEMail}
                        id="email_to_invite"
                        onChange={(evt) =>
                          setToAddSuperAdminEMail(evt.target.value)
                        }
                      />
                      <a
                        href="javascript:void(0)"
                        class="addCloseIcon hoverIcon"
                        onClick={() => onInviteUserClick()}
                      >
                        <span class="icon icon-plus"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
      <ToastContainer />
    </React.Fragment>
  );
}

export default GroupAdmin;
