/* eslint-disable */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import expImg from "../images/quiz-creation/Explaination.png";
import hintImg from "../images/quiz-creation/idea.png";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import "../less/quiz-creation.css";
import AnswerInputOptions from "./AnswerInputOptions";
import AnswerOptions from "./AnswerOptions";
import EditAnswerInputOptions from "./EditAnswerInputOptions";
import EditAnswerOptions from "./EditAnswerOptions";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import useCustomQuill from "../Hooks/useCustomQuill";
import "../less/quill-editor.css";
import ReactQuillComponent from "./ReactQuillComponent";
//Tiny MCE 5
//import TinyMCE5Component from './TinyMCE5Component'

import useAsyncEffect from "../Hooks/useAsyncEffect";

import { useCookies } from "react-cookie";

function EditQuestion(props) {
  const refs = useRef([]);

  // const refTinyMCE5QuestionComponents = useRef([])
  // const refTinyMCE5HintComponents = useRef([])
  // const refTinyMCE5ExplainComponents = useRef([])

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  let QUESTIONS_PATH = awsImageUrl + "/uploads/questions/";
  let ANSWERS_PATH = awsImageUrl + "/uploads/answers/";
  const config = {
    bucketName: "memoriz",
    dirName: "uploads/questions" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    //
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  const showMessageToUser = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //console.log(props)
  const quiz_deck_id = props.location.state.contentId;
  const questionId = props.match.params.questionId;
  const primaryLanguageId = props.location.state.contentDeckDetails.language_id;
  //const contentDeckDetails = props.location.state.contentDeckDetails;
  const questionDetails = props.location.state.questionDetails;
  const isPublished = props.location.state.isPublished;
  const [questionLangDetails, setQuestionLangDetails] = useState([]);
  const [answerLangDetails, setAnswerLangDetails] = useState([]);
  const [uploadedFilePath, setUploadedFilePath] = useState();

  const [questionUploaded, setQuestionUploaded] = useState(false);
  const [answerUploaded, setAnswerUploaded] = useState(false);
  const [questionfile, setQuestionFile] = useState("");

  const [mediaRightsChecked, setMediaRightsChecked] = useState(true);

  //console.log(questionDetails)
  const question_type = questionDetails.question_type;
  //console.log('question_type = ' + question_type)
  //console.log('*****' , questionDetails)
  const [questionMode, setQuestionMode] = useState(question_type);
  const [answerMode, setAnswerMode] = useState(props.location.state.answerMode);
  //console.log(answerMode)
  const [numberOfOptions, setNumberOfOptions] = useState([]);
  const [latestOption, setLatestOption] = useState(0);
  const [questionCreated, setQuestionCreated] = useState(false);
  const [answerCreated, setAnswerCreated] = useState(false);
  const [questionEditing, setQuestionEditing] = useState(0);
  const [saving, setSaving] = useState(false);
  const [changedQuestionImg, setChangedQuestionImg] = useState();
  const [questionFiles, setQuestionFiles] = useState([]);

  const [contentDeckDetails, setContentDeckDetails] = useState("");

  //let [questionTexts, setQuestionTexts] = useState({})
  const editor = useRef(null);

  useEffect(() => {
    setContentDeckDetails(
      JSON.parse(JSON.stringify(props.location.state.contentDeckDetails))
    );
  }, []);

  let language_id = contentDeckDetails.language_id;
  console.log("contentDeckDetails", contentDeckDetails);
  let langs = [];
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [languages, setLanguages] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  let def_Language;
  const [count, setCount] = useState(0);

  const [fetchingFinished, setFetchingFinished] = useState(false);
  let [allQuillRefs] = useCustomQuill(t("type_here"), [
    questionLangDetails,
    fetchingFinished,
  ]);

  useEffect(() => {
    console.log("fetchingFinished", fetchingFinished);
  }, [fetchingFinished]);

  useEffect(() => {
    if (!contentDeckDetails && !defaultLanguage && !currentLanguage) return;
    setCount((count) => count + 1);
    console.log("count", count);
    for (let i = 0; i < contentDeckDetails.languages.length; i++) {
      if (contentDeckDetails.languages[i].id == language_id) {
        let tempObj = contentDeckDetails.languages[i];
        if (contentDeckDetails.languages[i].id == 1) {
          tempObj.code = "en";
        } else if (contentDeckDetails.languages[i].id == 2) {
          tempObj.code = "zh";
        } else if (contentDeckDetails.languages[i].id == 3) {
          tempObj.code = "hi";
        } else {
          tempObj.code = "ja";
        }
        langs.push(tempObj);
        if (!defaultLanguage) {
          def_Language = contentDeckDetails.languages[i];
          setDefaultLanguage(
            JSON.parse(JSON.stringify(contentDeckDetails.languages[i]))
          );
          setCurrentLanguage(
            JSON.parse(JSON.stringify(contentDeckDetails.languages[i]))
          );
        }
      }
    }
    for (let i = 0; i < contentDeckDetails.languages.length; i++) {
      if (contentDeckDetails.languages[i].id != language_id) {
        let tempObj = contentDeckDetails.languages[i];
        if (contentDeckDetails.languages[i].id == 1) {
          tempObj.code = "en";
        } else if (contentDeckDetails.languages[i].id == 2) {
          tempObj.code = "zh";
        } else if (contentDeckDetails.languages[i].id == 3) {
          tempObj.code = "hi";
        } else {
          tempObj.code = "ja";
        }
        // languages.push(tempObj)
        langs = langs.concat(tempObj);
      }
    }
    if (!languages) {
      setLanguages(JSON.parse(JSON.stringify(langs)));
    }
  }, [contentDeckDetails]);

  console.log("languages", languages);
  console.log("defaultLanguage", defaultLanguage);

  useEffect(() => {
    if (!allQuillRefs || (allQuillRefs && allQuillRefs.current.length == 0))
      return;
    console.log("allQuillRefs.current.length", allQuillRefs.current.length);
    if (!fetchingFinished) return;
    for (let i = 0; i < questionLangDetails.length; i++) {
      for (let j = 0; j < allQuillRefs.current.length; j++) {
        if (questionLangDetails[i].id == allQuillRefs.current[j].id) {
          console.log("questionLangDetails[i]", questionLangDetails[i]);
          console.log(
            "questionLangDetails[i].questionDetails",
            questionLangDetails[i].questionDetails
          );
          allQuillRefs.current[j].quill.root.innerHTML =
            questionLangDetails[i].questionDetails.name;
        }
      }
    }
  }, [allQuillRefs, fetchingFinished]);

  const getTransformedFormat = (content) => {
    console.log("content", content);
    if (content && content.indexOf("<p") == -1) {
      return "<p>" + content + "</p>";
    } else {
      return content;
    }
  };

  useEffect(() => {
    console.log("questionLangDetails", questionLangDetails);
  }, [questionLangDetails]);

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source();
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setFetchingFinished(false); //Destroy all the Quill instances in useCustomQuill hook.
        for (let i = 0; i < languages.length; i++) {
          console.log(languages[i].id);
          let code;
          if (languages[i].id == 1) {
            code = "en";
          } else if (languages[i].id == 2) {
            code = "zh";
          } else if (languages[i].id == 3) {
            code = "hi";
          } else {
            code = "ja";
          }

          const responseQuestionList = yield axios({
            method: "post",
            url: "/api/question/details",
            headers: {
              locale: "en",
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify(
              {
                quiz_deck_id: quiz_deck_id,
                language_id: languages[i].id,
                question_id: questionId,
              },
              { cancelToken: token1 }
            ),
          });

          let questionDetails = responseQuestionList.data.data;
          setQuestionLangDetails((questionLangDetails) =>
            questionLangDetails.concat({
              languageId: languages[i].id,
              id: languages[i].id,
              code: code,
              questionDetails: questionDetails,
            })
          );

          //setQuestionTexts(questionTexts);

          const responseAnswerList = yield axios({
            method: "post",
            url: "/api/answers/list",
            headers: {
              locale: "en",
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify(
              {
                quiz_deck_id: quiz_deck_id,
                language_id: languages[i].id,
                question_id: questionId,
              },
              { cancelToken: token1 }
            ),
          });

          setNumberOfOptions([]);
          console.log(
            "answer response from ",
            languages[i].id,
            responseAnswerList.data.data
          );
          let tempAnswerDetails = responseAnswerList.data.data.answers;
          console.log("before======", tempAnswerDetails);

          console.log("after======", tempAnswerDetails);

          setAnswerLangDetails((answerLangDetails) =>
            answerLangDetails.concat({
              languageId: languages[i].id,
              code: code,
              answerDetails: tempAnswerDetails,
            })
          );
          for (
            let j = 0;
            j < responseAnswerList.data.data.answers.length;
            j++
          ) {
            setNumberOfOptions((numberOfOptions) =>
              numberOfOptions.concat(j + 1)
            );
          }
        }
        setFetchingFinished(true); //Now create the new Quill's for Questions.
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [languages]
  );

  useEffect(() => {
    if (questionLangDetails.length > 0 && answerLangDetails.length > 0) {
      console.log("questionLangDetails", questionLangDetails);
      console.log(answerLangDetails);
      let arr = [];
      for (let j = 0; j < languages.length; j++) {
        for (let i = 0; i < questionLangDetails.length; i++) {
          if (questionLangDetails[i].languageId == languages[j].id) {
            /*
                        if(questionMode !=2) {
                            var rp = document.getElementById("questionReactPlayer_"+ languages[j].id + "_"+ questionId);
                            let url = QUESTIONS_PATH + (questionLangDetails[i].questionDetails.file_path)
                            rp.url =  [url]
                        } else {
                            */
            if (questionLangDetails[i].questionDetails.file_path) {
              var image = document.getElementById(
                "questionImagePreview_" + languages[j].id + "_" + questionId
              );
              image.src =
                QUESTIONS_PATH +
                questionLangDetails[i].questionDetails.file_path;
              arr.push({
                languageId: languages[j].id,
                file_name: questionLangDetails[i].questionDetails.file_path,
              });
            }
            //}
          }
        }
      }
      setQuestionFiles(arr);
    }
  }, [questionLangDetails, answerLangDetails]);

  useEffect(() => {
    console.log("numberOfOptions", numberOfOptions);
    console.log("answerLangDetails", answerLangDetails);
    setLatestOption(numberOfOptions.length);
  }, [numberOfOptions]);

  useEffect(() => {
    if (
      answerLangDetails.length == languages.length &&
      numberOfOptions.length > 0
    ) {
      console.log("answerLangDetails", answerLangDetails);
      if (answerMode == 2) {
        console.log("answerMode", answerMode);
        numberOfOptions.map((optionNo) => {
          for (let i = 0; i < languages.length; i++) {
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "block";
            }
            const upBtn = document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            );
            if (upBtn) {
              upBtn.style.display = "none";
            }
            const ansImgPrev = document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            );
            if (ansImgPrev) {
              ansImgPrev.style.display = "none";
            }
          }
        });
      } else if (answerMode == 3) {
        console.log("answerMode", answerMode);
        numberOfOptions.map((optionNo) => {
          /*
                    const upBtn = document.getElementById("uploadButton"+optionNo);
                    if(upBtn){
                        upBtn.style.display = "block";
                    }*/
          console.log("here");
          for (let i = 0; i < languages.length; i++) {
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "none";
            }
            console.log(
              "languages[" + i + "].id=" + languages[i].id,
              "currentLanguage.id=" + currentLanguage.id
            );
            if (currentLanguage.id == languages[i].id) {
              console.log("languages[i]", languages[i]);
              console.log("uploadButton_" + languages[i].id + "_" + optionNo);
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "block";
              }
              console.log(
                "answerImagePreview_" + languages[i].id + "_" + optionNo
              );
              const ansImgPrev = document.getElementById(
                "answerImagePreview_" + languages[i].id + "_" + optionNo
              );
              if (ansImgPrev) {
                console.log("sdfasdfasdf");
                ansImgPrev.style.display = "block";
              }
            } else {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "none";
              }
              const ansImgPrev = document.getElementById(
                "answerImagePreview_" + languages[i].id + "_" + optionNo
              );
              if (ansImgPrev) {
                ansImgPrev.style.display = "none";
              }
            }
          }
        });
      } else if (answerMode == 1) {
        console.log("answerMode", answerMode);

        for (let i = 0; i < languages.length; i++) {
          console.log(answerLangDetails.length);
          console.log(languages.length);
          console.log("answerInput_" + languages[i].id + "_" + 1);
          const ansInput = document.getElementById(
            "answerInput_" + languages[i].id + "_" + 1
          );
          if (ansInput) {
            ansInput.style.display = "block";
          }
        }
      } else {
        console.log("else answerMode");
        numberOfOptions.map((optionNo) => {
          for (let i = 0; i < languages.length; i++) {
            if (currentLanguage.id == languages[i].id) {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "block";
              }
            }
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "none";
            }
            const ansImgPrev = document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            );
            if (ansImgPrev) {
              ansImgPrev.style.display = "none";
            }
          }
        });
      }
    }
    if (answerLangDetails.length == 0) {
      if (answerMode == 2) {
        numberOfOptions.map((optionNo) => {
          for (let i = 0; i < languages.length; i++) {
            const upBtn = document.getElementById(
              "uploadButton_" + languages[i].id + "_" + optionNo
            );
            if (upBtn) {
              upBtn.style.display = "none";
            }
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "block";
            }
            const ansImgPrev = document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            );
            if (ansImgPrev) {
              ansImgPrev.style.display = "none";
            }
          }
        });
      } else if (answerMode === 3) {
        console.log("answerMode === 3");
        console.log("numberOfOptions.length=" + numberOfOptions.length);
        numberOfOptions.map((optionNo) => {
          for (let i = 0; i < languages.length; i++) {
            console.log(
              "languages[" + i + "].id=" + languages[i].id,
              "currentLanguage.id=" + currentLanguage.id
            );
            if (currentLanguage.id == languages[i].id) {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "block";
              }
              const ansImgPrev = document.getElementById(
                "answerImagePreview_" + languages[i].id + "_" + optionNo
              );
              if (ansImgPrev) {
                ansImgPrev.style.display = "block";
              }
            } else {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "none";
              }
              const ansImgPrev = document.getElementById(
                "answerImagePreview_" + languages[i].id + "_" + optionNo
              );
              if (ansImgPrev) {
                ansImgPrev.style.display = "none";
              }
            }
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "none";
            }
          }
        });
      } else if (answerMode === 1) {
        for (let i = 0; i < languages.length; i++) {
          console.log(numberOfOptions);
          console.log(
            document.getElementById("answerInput_" + languages[i].id + "_" + 1)
          );
          const ansInput = document.getElementById(
            "answerInput_" + languages[i].id + "_" + 1
          );
          if (ansInput) {
            ansInput.style.display = "block";
          }
        }
      } else {
        numberOfOptions.map((optionNo) => {
          for (let i = 0; i < languages.length; i++) {
            if (currentLanguage.id == languages[i].id) {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "block";
              }
            } else {
              const upBtn = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + optionNo
              );
              if (upBtn) {
                upBtn.style.display = "none";
              }
            }
            const ansTxt = document.getElementById(
              "answerText_" + languages[i].id + "_" + optionNo
            );
            if (ansTxt) {
              ansTxt.style.display = "none";
            }
            const ansImgPrev = document.getElementById(
              "answerImagePreview_" + languages[i].id + "_" + optionNo
            );
            if (ansImgPrev) {
              ansImgPrev.style.display = "none";
            }
          }
        });
      }
    }
  }, [currentLanguage, answerMode, numberOfOptions, answerLangDetails]);

  useEffect(() => {
    const addOptionBtn = document.getElementsByClassName("add-option-btn")[0];
    if (answerMode == 1) {
      setNumberOfOptions([1]);
      setLatestOption(0);
      if (addOptionBtn) {
        addOptionBtn.style.display = "none";
      }
    } else if (addOptionBtn) {
      addOptionBtn.style.display = "block";
    }
  }, [answerMode]);

  console.log("languages", languages);
  console.log("currentLanguage", currentLanguage);

  //const defaultLangId = defaultLanguage.id;

  const onFileUploadChange = (event) => {
    let tempQuestionFiles = [];
    if (questionFiles && questionFiles.length > 0) {
      console.log("questionFiles.length=", questionFiles.length);
      //Does it exists languageId
      for (let i = 0; i < languages.length; i++) {
        //console.log('element', element)
        if (languages[i].id == currentLanguage.id) {
          console.log("currentLanguage.id=" + currentLanguage.id);
          console.log("file=", event.target.files[0]);
          tempQuestionFiles.push({
            languageId: currentLanguage.id,
            file: event.target.files[0],
          });
        } else {
          console.log("copying existing answers edited");
          if (questionFiles.length > 0) {
            for (let j = 0; j < questionFiles.length; j++) {
              if (languages[i].id == questionFiles[j].languageId) {
                console.log("har har");
                tempQuestionFiles.push(questionFiles[j]);
              }
            }
          }
        }
      }
    } else {
      tempQuestionFiles.push({
        languageId: currentLanguage.id,
        file: event.target.files[0],
      });
    }
    console.log("tempQuestionFiles.length=" + tempQuestionFiles.length);
    setQuestionFiles(tempQuestionFiles);

    console.log("questionMode", questionMode);
    if (questionMode == 2) {
      // console.log("questionImagePreview_")
      var image = document.getElementById(
        "questionImagePreview_" + currentLanguage.id + "_" + questionId
      );
      image.style.display = "block";
      image.src = URL.createObjectURL(event.target.files[0]);
    }
  };
  /*
    const onFileUploadChange = (event) => {
        console.log(event.target.files[0])
        setQuestionFile(event.target.files[0])
        if(questionMode == 2){
            var image = document.getElementById('questionImagePreview');
            image.src = URL.createObjectURL(event.target.files[0]);
        }
    }
    */

  // Locking scroll while saving
  useEffect(() => {
    if (saving) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!saving) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [saving]);

  const changeLanguage = (language) => {
    console.log("languages", languages);
    setCurrentLanguage(language);
  };

  const setAnswerModeFunction = (mode) => {
    if (answerLangDetails.length > 0) {
      setAnswerLangDetails([]);
      setNumberOfOptions([1]);
      setLatestOption(0);
      setAnswerMode(mode);
    } else {
      setAnswerMode(mode);
    }
  };
  /*
    const getTinyMCE5QuestionTitle = (language) => {
        for(let i=0; i< refTinyMCE5QuestionComponents.current.length; i++) {
            if(refTinyMCE5QuestionComponents.current[i].id == language.id) {
                return refTinyMCE5QuestionComponents.current[i].tmce.getContent()
            }
        }
    }
    const getTinyMCE5QuestionHint = (language) => {
        for(let i=0; i< refTinyMCE5HintComponents.current.length; i++) {
            if(refTinyMCE5HintComponents.current[i].id == language.id) {
                return refTinyMCE5HintComponents.current[i].tmce.getContent()
            }
        }
    }
    const getTinyMCE5QuestionExplain = (language) => {
        for(let i=0; i< refTinyMCE5ExplainComponents.current.length; i++) {
            if(refTinyMCE5ExplainComponents.current[i].id == language.id) {
                return refTinyMCE5ExplainComponents.current[i].tmce.getContent()
            }
        }
    }
    */
  const addQuestionAndAnswer = async () => {
    if (questionEditing <= 0) {
      setSaving(true);
      const mediaRightsGiven = await isMediaRightsGiven();
      const isOptionGiven = await correctOptions();
      let areAllOptionsUploaded;
      if (answerLangDetails.length > 0) {
        areAllOptionsUploaded = true;
      } else {
        areAllOptionsUploaded = await uploadOptionsCheck();
      }
      console.log("isOptionGiven", isOptionGiven);
      console.log("areAllOptionsUploaded", areAllOptionsUploaded);

      let questionDataSet = [];
      console.log("languages", languages);
      for (let i = 0; i < languages.length; i++) {
        console.log(languages[i]);
        let tempQuestionDataset = {};
        tempQuestionDataset.is_default = 0;
        tempQuestionDataset.title = document.querySelector(
          "#editor" + languages[i].id + " .ql-editor"
        ).innerHTML;
        //tempQuestionDataset.title = getTinyMCE5QuestionTitle(languages[i])
        if (tempQuestionDataset.title === "") {
          continue;
        }
        tempQuestionDataset.hint = document.getElementById(
          "questionHint_" + languages[i].code
        ).value;
        //tempQuestionDataset.hint = getTinyMCE5QuestionHint(languages[i])
        console.log("Hint", tempQuestionDataset.hint);

        tempQuestionDataset.explanation = document.getElementById(
          "questionExp_" + languages[i].code
        ).value;
        //tempQuestionDataset.explanation = getTinyMCE5QuestionExplain(languages[i])
        tempQuestionDataset.language_id = languages[i].id;
        if (defaultLanguage.id === languages[i].id) {
          tempQuestionDataset.is_default = 1;
        }

        questionDataSet.push(tempQuestionDataset);
      }

      if (
        ((numberOfOptions.length >= 2 && answerMode !== 1) ||
          (numberOfOptions.length == 1 && answerMode == 1)) &&
        areAllOptionsUploaded == true &&
        questionDataSet.length === languages.length &&
        mediaRightsGiven === true
      ) {
        // if (questionDataSet.length === languages.length) {

        document.getElementById("errorMessage").style.display = "none";
        document.getElementById("errorMessageAllLanguages").style.display =
          "none";
        //setQuestionEditing(1);
        // questionId = await createQuestion()

        console.log(questionId);

        let fileUploadPromises = [];

        const afterAnswerUploads = async () => {
          console.log("afterAnswerUploads ");
          console.log("ques_Id", questionId);

          for (let i = 0; i < languages.length; i++) {
            if (questionFiles.length > 0 && questionMode != 1) {
              for (let j = 0; j < questionFiles.length; j++) {
                if (questionFiles[j].languageId == languages[i].id) {
                  let fileExtension;
                  if (questionFiles[j].file) {
                    console.log("questionFiles[j].file", questionFiles[j].file);
                    fileExtension =
                      questionFiles[j].file.name.split(".")[
                        questionFiles[j].file.name.split(".").length - 1
                      ];
                  } else {
                    fileExtension =
                      questionFiles[j].file_name.split(".")[
                        questionFiles[j].file_name.split(".").length - 1
                      ];
                  }

                  questionDataSet[i].files = [
                    {
                      file:
                        "question_" +
                        languages[i].code +
                        "_" +
                        questionId +
                        "." +
                        fileExtension,
                    },
                  ];
                }
              }
            }
          }

          console.log("questionDataSet", questionDataSet);

          const resultOptionsArray = await checkOptions();
          console.log("resultOptionsArray", resultOptionsArray);
          // console.log(numberOfOptions)

          // Question Language Update API CALL
          await axios({
            method: "post",
            url: "/api/questions/update/language",
            headers: {
              locale: globalLangCode,
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              quiz_deck_id: quiz_deck_id,
              topic_id: 0,
              question_id: questionId,
              question_mode: questionMode,
              dataset: questionDataSet,
            }),
          })
            .then((response) => {
              setQuestionCreated(true);
              console.log(
                "quiz created response",
                JSON.stringify(response.data)
              );
              showMessageToUser(response.data.msg);
              // setUserSession(token, response.data.user);
            })
            .catch((error) => {
              // removeUserSession();
              setSaving(false);
              console.log("error", error);
            });

          await axios({
            method: "post",
            url: "/api/answers/update/language",
            headers: {
              locale: globalLangCode,
              "x-access-token": "Memo " + token,
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              quiz_deck_id: quiz_deck_id,
              answer_mode: answerMode,
              question_id: questionId,
              option_mode: answerMode,
              options: resultOptionsArray,
            }),
          })
            .then((response) => {
              console.log(
                "quiz created response",
                JSON.stringify(response.data)
              );
              // setUserSession(token, response.data.user);
              setAnswerCreated(true);
              showMessageToUser(response.data.msg);
            })
            .catch((error) => {
              // removeUserSession();
              setSaving(false);
              console.log("error", error);
            });
        };

        const afterQuestionFileUploads = async () => {
          console.log("afterQuestionFileUploads answerMode", answerMode);
          if (answerMode > 2) {
            console.log("calling promise validate on answers");
            Promise.all(
              refs.current.map(async (child, index) => {
                console.log("going to upload");
                console.log(questionId, index);
                if (child) {
                  await child.validate(questionId, index);
                } else {
                  return;
                }
              })
            )
              .then(async (data) => {
                setAnswerUploaded(true);
                await afterAnswerUploads();
              })
              .catch((error) => {
                setSaving(false);
                console.log("error in answer validate", error);
              });
          } else {
            console.log("ONLYYYYYYYYYYYYYYYYYY INPUTTTTTTTTTTT");
            setAnswerUploaded(true);
            await afterAnswerUploads();
          }
        };
        console.log("questionFiles.length", questionFiles.length);
        console.log("questionMode", questionMode);
        if (questionFiles.length > 0 && questionMode !== 1) {
          for (let i = 0; i < languages.length; i++) {
            for (let j = 0; j < questionFiles.length; j++) {
              if (languages[i].id == questionFiles[j].languageId) {
                const newFileName =
                  "question_" + languages[i].code + "_" + questionId;
                console.log("newFileName", newFileName);
                if (questionFiles[j].file) {
                  const fd = new FormData();
                  fd.append("file", questionFiles[j].file);
                  fd.append("filename", newFileName);
                  console.log("questionFile", questionFiles[j].file);
                  // For Media Type
                  if (questionMode == 2) {
                    var media_type = 1; // Image File
                  } else if (questionMode == 3) {
                    var media_type = 2; // Audio File
                  } else if (questionMode == 4) {
                    var media_type = 3; // Video File
                  } else {
                    var media_type = 1; // Text
                  }
                  fileUploadPromises.push(
                    axios({
                      method: "post",
                      url:
                        "/api/questions/upload/image?filename=" +
                        newFileName +
                        "&media_type=" +
                        media_type,
                      headers: {
                        locale: "en",
                        "x-access-token": "Memo " + token,
                        "Content-Type": "multipart/form-data",
                        //'enctype': "multipart/form-data"
                      },
                      data: fd,
                    })
                  );
                }
              }
            }
          }

          Promise.all(fileUploadPromises)
            .then(async () => {
              console.log("Questions uploaded successfully");
              setQuestionUploaded(true);
              await afterQuestionFileUploads();
            })
            .catch((error) => {
              console.log("error", error.response.data[0].msg);
              setQuestionEditing(0);
              showErrorMessageToUser(error.response.data[0].msg);
              setSaving(false);
            });
        } else {
          setQuestionUploaded(true);
          await afterQuestionFileUploads();
        }
      } else if (questionDataSet.length !== languages.length) {
        setSaving(false);
        document.getElementById("errorMessageAllLanguages").style.display =
          "block";
      } else if (answerMode == 1) {
        setSaving(false);
        document.getElementById("errorMessageNoInput").style.display = "block";
        document.getElementById("errorMessage").style.display = "none";
      } else {
        setSaving(false);
        document.getElementById("errorMessage").style.display = "block";
        document.getElementById("errorMessageNoInput").style.display = "none";
      }
    }
  };

  // useEffect(() => {
  //     setQuestionUploaded(true)
  // }, questionCreated)

  const isMediaRightsGiven = () => {
    if (mediaRightsChecked === true) {
      return true;
    }
    showErrorMessageToUser(t("please_accept_media_rights"));
    return false;
  };

  const mediaRightsChanged = (e) => {
    if (e.target.checked) {
      setMediaRightsChecked(true);
    } else {
      setMediaRightsChecked(false);
    }
  };

  useEffect(() => {
    console.log("questionCreated=", questionCreated);
    console.log("answerCreated=", answerCreated);
    console.log("questionUploaded=", questionUploaded);
    console.log("answerUploaded=", answerUploaded);

    if (
      questionCreated == true &&
      answerCreated == true &&
      questionUploaded == true &&
      answerUploaded == true &&
      isPublished == 1
    ) {
      props.history.push({
        pathname: "/contentsedit/" + quiz_deck_id,
        state: {
          ...props.location.state,
        },
      });
    }
    if (
      questionCreated == true &&
      answerCreated == true &&
      questionUploaded == true &&
      answerUploaded == true &&
      isPublished == 0
    ) {
      //props.history.push('/mycontentsunpublished');

      props.history.push({
        pathname: "/contentseditunpublished/" + quiz_deck_id,
        state: {
          ...props.location.state,
          primaryLanguageId: primaryLanguageId,
        },
      });
      //props.history.push('/contentseditunpublished/'+quiz_deck_id);
    }
  }, [questionCreated, answerCreated, questionUploaded, answerUploaded]);

  const makeOptions = () => {
    if (answerLangDetails.length > 0) {
      setAnswerLangDetails([]);
      setNumberOfOptions([1]);
      setLatestOption(0);
    } else {
      setNumberOfOptions((numberOfOptions) =>
        numberOfOptions.concat(latestOption + 1)
      );
    }
  };

  const correctOptions = () => {
    let value = false;
    if (answerMode == 1) {
      if (document.getElementById("option" + 1).checked == true) {
        value = true;
      }
    } else {
      for (let i = 0; i < latestOption; i++) {
        if (
          document.getElementById("option" + (i + 1)).checked == true &&
          document.getElementById("option" + (i + 1)).defaultChecked == true
        ) {
          value = true;
        } else if (
          document.getElementById("option" + (i + 1)).checked == true &&
          document.getElementById("option" + (i + 1)).defaultChecked == false
        ) {
          value = true;
        } else if (
          document.getElementById("option" + (i + 1)).defaultChecked == true
        ) {
          let defaultIsThere = true;
          for (let j = 0; j < latestOption; j++) {
            if (document.getElementById("option" + (j + 1)).checked == true) {
              value = true;
              defaultIsThere = false;
            }
          }
          if (defaultIsThere == true) {
            value = true;
          }
        }
      }
    }
    return value;
  };

  const uploadOptionsCheck = () => {
    let value = true;
    for (let i = 0; i < languages.length; i++) {
      if (
        answerMode == 2 ||
        answerMode == 3 ||
        answerMode == 4 ||
        answerMode == 5
      ) {
        numberOfOptions.map((options) => {
          if (answerMode == 2) {
            let answertext = document.getElementById(
              "answerText_" + languages[i].id + "_" + options
            ).value;
            if (answertext && value == true) {
              document.getElementById("errorMessageNoText").style.display =
                "none";
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              value = value && true;
            } else {
              document.getElementById("errorMessageNoText").style.display =
                "block";
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              value = value && false;
            }
          } else {
            let answer_upload_element = document.getElementById(
              "uploadButton" + options
            );

            if (!answer_upload_element) {
              answer_upload_element = document.getElementById(
                "uploadButton_" + languages[i].id + "_" + options
              );
            }
            if (
              answer_upload_element &&
              answer_upload_element.value &&
              value == true
            ) {
              document.getElementById("errorMessageNoUpload").style.display =
                "none";
              document.getElementById("errorMessageNoText").style.display =
                "none";
              value = value && true;
            } else {
              document.getElementById("errorMessageNoUpload").style.display =
                "block";
              document.getElementById("errorMessageNoText").style.display =
                "none";
              value = value && false;
            }
          }
        });
      } else {
        let answerInput = document.getElementById(
          "answerInput_" + languages[i].id + "_" + 1
        ).value;
        if (answerInput && value == true) {
          document.getElementById("errorMessageNoText").style.display = "none";
          document.getElementById("errorMessageNoUpload").style.display =
            "none";
          value = value && true;
        } else {
          document.getElementById("errorMessageNoText").style.display = "block";
          document.getElementById("errorMessageNoUpload").style.display =
            "none";
          value = value && false;
        }
      }
    }
    return value;
  };

  const checkOptions = () => {
    console.log("checkOptions called");
    let tempOptionsArray = [];
    console.log(numberOfOptions);

    numberOfOptions.map((options, index) => {
      let tempOptionsObject = {};
      tempOptionsObject.language_id = languages[0].id;
      let localLanguageId = languages[0].id;
      if (answerMode == 3 || answerMode == 4 || answerMode == 5) {
        let answerFileName;
        if (
          document.getElementById(
            "uploadButton_" + localLanguageId + "_" + options
          ).value
        ) {
          answerFileName = document.getElementById(
            "uploadButton_" + localLanguageId + "_" + options
          ).value;
          //alert(answerFileName)
          console.log("uploadButton answerFileName", answerFileName);
        } else {
          for (let a = 0; a < answerLangDetails.length; a++) {
            if (answerLangDetails[a].languageId == localLanguageId) {
              answerFileName =
                answerLangDetails[a].answerDetails[index].file_path;
              console.log("Existing answerFileName", answerFileName);
            }
          }
        }
        //console.log(answerFileName)
        let answerextension = answerFileName.split(".").pop();

        tempOptionsObject.file =
          "answer_" +
          languages[0].code +
          "_" +
          (options - 1) +
          "_" +
          questionId +
          "." +
          answerextension;
        console.log("tempOptionsObject.file=", tempOptionsObject.file);
      } else if (answerMode == 2) {
        tempOptionsObject.text = document.getElementById(
          "answerText_" + languages[0].id + "_" + options
        ).value;
      } else {
        tempOptionsObject.text = document.getElementById(
          "answerInput_" + languages[0].id + "_" + options
        ).value;
      }
      if (
        document.getElementById("option" + options).checked == true &&
        document.getElementById("option" + options).defaultChecked == true
      ) {
        tempOptionsObject.is_right = 1;
      } else if (
        document.getElementById("option" + options).checked == true &&
        document.getElementById("option" + options).defaultChecked == false
      ) {
        tempOptionsObject.is_right = 1;
      } else if (
        document.getElementById("option" + options).defaultChecked == true
      ) {
        let defaultOption = options;
        let isCheckedThere = false;
        numberOfOptions.map((individualoptions, index) => {
          if (
            document.getElementById("option" + individualoptions).checked ==
            true
          ) {
            isCheckedThere = true;
          }
        });
        if (isCheckedThere == true) {
          //alert('isCheckedThere=0')
          tempOptionsObject.is_right = 0;
        } else {
          //alert('isCheckedThere=1')
          tempOptionsObject.is_right = 1;
        }
      } else {
        tempOptionsObject.is_right = 0;
      }

      tempOptionsObject.other_lang = [];

      for (let i = 1; i < languages.length; i++) {
        let tempOtherLanguageObj = {};
        tempOtherLanguageObj.language_id = languages[i].id;
        if (answerMode == 3 || answerMode == 4 || answerMode == 5) {
          // let answerFileName = (document.getElementById("uploadButton"+(options)).value)
          let answerFileName;
          if (
            document.getElementById(
              "uploadButton_" + languages[i].id + "_" + options
            ).value
          ) {
            answerFileName = document.getElementById(
              "uploadButton_" + languages[i].id + "_" + options
            ).value;
          } else {
            for (let a = 0; a < answerLangDetails.length; a++) {
              if (answerLangDetails[a].languageId == localLanguageId) {
                answerFileName =
                  answerLangDetails[a].answerDetails[index].file_path;
              }
            }
          }

          let answerextension = answerFileName.split(".").pop();

          tempOtherLanguageObj.file =
            "answer_" +
            languages[i].code +
            "_" +
            (options - 1) +
            "_" +
            questionId +
            "." +
            answerextension;
        } else if (answerMode == 2) {
          console.log(
            document.getElementById(
              "answerText_" + languages[i].id + "_" + options
            ).value
          );
          tempOtherLanguageObj.text = document.getElementById(
            "answerText_" + languages[i].id + "_" + options
          ).value;
        } else {
          console.log(
            document.getElementById(
              "answerInput_" + languages[i].id + "_" + options
            ).value
          );
          tempOtherLanguageObj.text = document.getElementById(
            "answerInput_" + languages[i].id + "_" + options
          ).value;
        }
        if (
          document.getElementById("option" + options).checked == true &&
          document.getElementById("option" + options).defaultChecked == true
        ) {
          tempOtherLanguageObj.is_right = 1;
        } else if (
          document.getElementById("option" + options).checked == true &&
          document.getElementById("option" + options).defaultChecked == false
        ) {
          tempOtherLanguageObj.is_right = 1;
        } else if (
          document.getElementById("option" + options).defaultChecked == true
        ) {
          let defaultOption = options;
          let isCheckedThere = false;
          numberOfOptions.map((individualoptions, index) => {
            if (
              document.getElementById("option" + individualoptions).checked ==
              true
            ) {
              isCheckedThere = true;
            }
          });
          if (isCheckedThere == true) {
            tempOtherLanguageObj.is_right = 0;
          } else {
            tempOtherLanguageObj.is_right = 1;
          }
        } else {
          tempOtherLanguageObj.is_right = 0;
        }
        tempOptionsObject.other_lang.push(tempOtherLanguageObj);
        console.log(tempOptionsObject);
      }

      tempOptionsArray.push(tempOptionsObject);
    });
    let sortedTempOptionsArray = [];
    for (let i = 0; i < tempOptionsArray.length; i++) {
      //NOTE: this is for image, autio, video files.
      //      can be changed for text, input also.
      if (answerMode === 3 || answerMode === 4 || answerMode === 5) {
        console.log("tempOptionsArray[i].file=" + tempOptionsArray[i].file);
        let findPrefix =
          ("answer_" + tempOptionsArray[i].language_code + "_").length - 1;
        let findIndexDigits = tempOptionsArray[i].file.lastIndexOf("_") - 1;
        console.log(
          "findPrefix=" +
            findPrefix +
            " " +
            "findIndexDigits=" +
            findIndexDigits
        );
        console.log(
          "Index" +
            tempOptionsArray[i].file.substr(
              findPrefix + 1,
              findIndexDigits - findPrefix
            )
        );
        let posOfIndex = parseInt(
          tempOptionsArray[i].file.substr(
            findPrefix + 1,
            findIndexDigits - findPrefix
          ),
          10
        );
        sortedTempOptionsArray[posOfIndex] = tempOptionsArray[i];
      }
    }
    if (sortedTempOptionsArray.length > 0) {
      tempOptionsArray = sortedTempOptionsArray;
    }

    return tempOptionsArray;
  };

  useEffect(() => {
    if (questionMode == 1) {
      for (let i = 0; i < languages.length; i++) {
        const ques_upload_btn = document.getElementById(
          "questionUploadButton_" + languages[i].id + "_" + questionId
        );
        ques_upload_btn.value = null;
        ques_upload_btn.style.display = "none";

        const ques_img_preview = document.getElementById(
          "questionImagePreview_" + languages[i].id + "_" + questionId
        );
        ques_img_preview.src = null;
        ques_img_preview.style.display = "none";

        // document.getElementById("questionVideoAudioPreview").style.display = "none"
      }
    } else if (questionMode == 2) {
      for (let i = 0; i < languages.length; i++) {
        if (currentLanguage.id == languages[i].id) {
          const ques_upload_btn = document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          );
          ques_upload_btn.value = null;
          ques_upload_btn.style.display = "block";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";

          for (let j = 0; j < questionLangDetails.length; j++) {
            if (
              questionLangDetails[j].languageId == languages[i].id &&
              questionLangDetails[j].questionDetails.file_path
            ) {
              var image = document.getElementById(
                "questionImagePreview_" + languages[i].id + "_" + questionId
              );
              image.src =
                QUESTIONS_PATH +
                questionLangDetails[j].questionDetails.file_path;
              image.style.display = "block";
            }
          }
          // document.getElementById("questionVideoAudioPreview").style.display = "none"
        } else {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          // document.getElementById("questionVideoAudioPreview").style.display = "none"
        }
      }
    } else {
      //Audio, Video
      for (let i = 0; i < languages.length; i++) {
        if (currentLanguage.id == languages[i].id) {
          const ques_upload_btn = document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          );
          ques_upload_btn.value = null;
          ques_upload_btn.style.display = "block";

          const ques_img_preview = document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          );
          ques_img_preview.src = null;
          ques_img_preview.style.display = "none";

          //document.getElementById("questionReactPlayer_"+ languages[i].id + '_' + questionId).style.display = "block"

          /*
                    if(questionMode == 3) { //Audio
                        document.getElementById("questionUploadButton_"+ languages[i].id + '_' + questionId).accept="audio/*" 
                    } else { //video
                        document.getElementById("questionUploadButton_"+ languages[i].id + '_' + questionId).accept="video/*" 
                    }
                    */
        } else {
          document.getElementById(
            "questionUploadButton_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          document.getElementById(
            "questionImagePreview_" + languages[i].id + "_" + questionId
          ).style.display = "none";
          //document.getElementById("questionReactPlayer_"+ languages[i].id + '_' + questionId).style.display = "none"
        }
      }
    }
    /*
        if(changedQuestionImg){
            var image = document.getElementById('questionImgPreview');
            console.log("question is there changed")
            image.src = changedQuestionImg
        }else{
            var image = document.getElementById('questionImgPreview');
            image.src = QUESTIONS_PATH+(questionDetails.file_path);
        }
        */
  }, [questionMode, questionLangDetails, changedQuestionImg, currentLanguage]);

  useEffect(() => {
    console.log("currentLanguage.id", currentLanguage);
  }, [currentLanguage]);

  const createMarkup = (language) => {
    console.log("text", language.questionDetails.name);

    if (language.languageId == currentLanguage.id) {
      return {
        __html:
          `<textarea  
                          style='display:block;' 
                          placeholder='Type Here...' 
                          id='` +
          `questionText_` +
          language.code +
          `'` +
          `>` +
          language.questionDetails.name +
          `</textarea>`,
      };
    } else {
      return {
        __html:
          `<textarea  
                          style='display:none;' 
                          placeholder='Type Here...' 
                          id='` +
          `questionText_` +
          language.code +
          `'` +
          `>` +
          language.questionDetails.name +
          `</textarea>`,
      };
    }
  };

  /*
    const handleQuestionTitle = (languageId, e) => {
        questionTexts[languageId] = e
        setQuestionTexts(questionTexts);
    }
    */
  return (
    <LoadingOverlay
      active={saving}
      spinner
      text="Saving..."
      styles={{
        content: {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <React.Fragment>
        {/* <BasicHeader
                {...props}
            /> */}
        <div class="inner-header">
          <div class="container">
            <div class="row">
              <div class="col-sm-5 col-md-6 col-lg-6">
                <h2 class="animated fadeInLeft">{t("question_edit")}</h2>
              </div>
              <div class="col-sm-7 col-md-6 col-lg-6">
                <div class="breadcrumb-item animated fadeInRight">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <span class="icon-home1"></span> {t("home")}
                      </a>
                    </li>
                    <li>
                      <span class="icon-next"></span>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <span class="active"></span>
                        {t("edit_contents")}
                      </a>
                    </li>
                    <li>
                      <span class="icon-next"></span>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active">
                        {t("question_edit")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="quiz-creation-wrapper">
          <div class="container">
            <div class="quiz-creation-tabs">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                  <ul class="nav nav-tabs nav-justified animated fadeInLeft">
                    {languages && currentLanguage
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            onClick={() => changeLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              {/* <span class="icon hindi"></span>  */}
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{t(language.name)}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>

              <div class="tab-content">
                <div class="tab-pane container active" id="EnglishTab">
                  <div class="quiz-creation-content">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInLeft">
                        <h4>{t("edit_question")}</h4>
                        <h6>{t("your_question")}</h6>
                        <div class="create-question">
                          <div class="question-info">
                            <div class="row">
                              <div class="col-10 col-sm-10">
                                <h5 class="bold">
                                  {t("please_edit_your_question")}
                                </h5>
                              </div>
                              {/* <div class="col-2 col-sm-2 count">4/10</div> */}
                            </div>

                            {questionLangDetails &&
                            currentLanguage &&
                            fetchingFinished
                              ? questionLangDetails.map((language, index) => (
                                  <div
                                    class="question-textarea"
                                    style={
                                      language.languageId == currentLanguage.id
                                        ? null
                                        : { display: "none" }
                                    }
                                  >
                                    {/*
                                                                       <TinyMCE5Component key={index}  
                                                                                            ref={instance => refTinyMCE5QuestionComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                            language_code={globalLangCode}
                                                                                            content={language.questionDetails.name}
                                                                                            placeholder={t("type_here")}/>

                                                                        */}
                                    <ReactQuillComponent
                                      language_id={language.languageId}
                                    ></ReactQuillComponent>

                                    {/* 
                                                                         <ReactQuill
                                                                            style={language.languageId == currentLanguage.id ? null : { display: "none" }}
                                                                            class="form-control"
                                                                            id={"questionText_" + language.code}
                                                                            placeholder={t("type_here")}
                                                                            value={questionTexts[language.code]}
                                                                            modules={modules}
                                                                            formats={formats}
                                                                            onChange={e => handleQuestionTitle(language.code, e)} /> 
                                                                        */}
                                    {/*
                                                                        <div style={language.languageId == currentLanguage.id ? null : { display: "none" }}>
                                                                            <JoditEditor
                                                                                id={"questionText_" + language.code}
                                                                                ref={editor}
                                                                                config={editorConfig}
                                                                                value={questionTexts[language.code]}
                                                                                tabIndex={1}
                                                                                onChange={e => handleQuestionTitle(language.code, e)}
                                                                            />
                                                                        </div>
                                                                        */}

                                    {/**
                                                                    <textarea
                                                                    style={language.languageId == currentLanguage.id ?null:{display:"none"}} 
                                                                        placeholder="Type Here..."
                                                                        id={"questionText_"+language.code}
                                                                        defaultValue={language.questionDetails.name}
                                                                    ></textarea>
                                                                     */}
                                  </div>
                                ))
                              : null}
                          </div>
                          <div class="question-info">
                            <h5 class="bold">{t("upload_question")}</h5>
                            <div class="row mt-4">
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(2)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 2
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-image"></span>
                                  <span>{t("img_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(4)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 4
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-video"></span>
                                  <span>{t("vid_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(3)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 3
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-audio"></span>
                                  <span>{t("aud_label")}</span>
                                </a>
                              </div>
                              <div
                                class="col-6 col-sm-3"
                                onClick={() => setQuestionMode(1)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  class={
                                    questionMode == 1
                                      ? "inner-boxs active"
                                      : "inner-boxs"
                                  }
                                >
                                  <span class="icon icon-text"></span>
                                  <span>{t("text_label")}</span>
                                </a>
                              </div>
                            </div>
                            {/** 
                                                    <input type="file" name="file" 
                                                        id = "questionUploadButton"
                                                        style={{display:"none"}}   
                                                        onChange={onFileUploadChange}
                                                    />
                                                    */}
                            {questionLangDetails
                              ? questionLangDetails.map((language, index) => (
                                  <>
                                    <span
                                      style={
                                        language.languageId ==
                                        currentLanguage.id
                                          ? null
                                          : { display: "none" }
                                      }
                                    >
                                      {questionfile ||
                                      parseInt(
                                        language.questionDetails.question_type
                                      ) !== parseInt(questionMode) ||
                                      (parseInt(
                                        language.questionDetails.question_type
                                      ) === parseInt(questionMode) &&
                                        ![undefined, null, ""].includes(
                                          document.getElementById(
                                            "questionUploadButton_" +
                                              currentLanguage.id +
                                              "_" +
                                              questionId
                                          ).value
                                        ))
                                        ? ""
                                        : language.questionDetails.file_path}
                                    </span>
                                  </>
                                ))
                              : null}

                            {languages
                              ? languages.map((language) => (
                                  <>
                                    <input
                                      type="file"
                                      name="file"
                                      onChange={onFileUploadChange}
                                      style={{ display: "none" }}
                                      id={
                                        "questionUploadButton_" +
                                        language.id +
                                        "_" +
                                        questionId
                                      }
                                    />
                                    <img
                                      id={
                                        "questionImagePreview_" +
                                        language.id +
                                        "_" +
                                        questionId
                                      }
                                      width="200px"
                                      style={{ display: "none" }}
                                    />
                                    <div>
                                      {/* 
                                                                    <ReactPlayer
                                                                        
                                                                        id={"questionReactPlayer_" + language.id+"_"+ questionId}
                                                                        style={{display:"none"}}
                                                                        width="100%"
                                                                        height="100%"
                                                                        url= {''}
                                                                        controls = {true}

                                                                    />
                                                                    */}
                                    </div>
                                  </>
                                ))
                              : null}
                            {/* 
                                                    <img
                                                        id = "questionImgPreview"
                                                        width="200px" 
                                                        style={{display:"none"}}
                                                    />

                                                    <img 
                                                        id="questionImagePreview" width="200px" 
                                                        style={{display:"none"}}
                                                    /> 
                                                    */}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-12 col-md-12 col-lg-6 animated fadeInRight"
                        id="rightBlock"
                      >
                        <h4>{t("add_answer")}</h4>
                        <h6>{t("your_answer")}</h6>
                        <div
                          class="add-option-btn"
                          onClick={makeOptions}
                          style={
                            answerMode === 1
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <a href="javascript:void(0)">
                            <span class="icon icon-plus blue-bg-btn"></span>{" "}
                            <span>{t("add_options")}</span>
                          </a>
                        </div>

                        <div>{t("select_type_of_answer")}</div>

                        <div class="row my-4">
                          <div
                            class="col-6 col-sm-2"
                            onClick={() => setAnswerModeFunction(3)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"3"}
                              class={
                                answerMode == 3
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-image"></span>
                              <span>{t("img_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2"
                            onClick={() => setAnswerModeFunction(5)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"5"}
                              class={
                                answerMode == 5
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-video"></span>
                              <span>{t("vid_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2"
                            onClick={() => setAnswerModeFunction(4)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"4"}
                              class={
                                answerMode == 4
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-audio"></span>
                              <span>{t("aud_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2"
                            onClick={() => setAnswerModeFunction(2)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"2"}
                              class={
                                answerMode == 2
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-text"></span>
                              <span>{t("text_label")}</span>
                            </a>
                          </div>
                          <div
                            class="col-6 col-sm-2"
                            onClick={() => setAnswerModeFunction(1)}
                          >
                            <a
                              href="javascript:void(0)"
                              // id={answerTypeId+"2"}
                              class={
                                answerMode == 1
                                  ? "inner-boxs active"
                                  : "inner-boxs"
                              }
                            >
                              <span class="icon icon-edit1"></span>
                              <span>{t("input_label")}</span>
                            </a>
                          </div>
                        </div>

                        {numberOfOptions.length > 0 &&
                        (answerMode == 2 ||
                          answerMode == 3 ||
                          answerMode == 4 ||
                          answerMode == 5) &&
                        answerLangDetails.length > 0
                          ? numberOfOptions.map((optionNo, index) => (
                              <EditAnswerOptions
                                optionNo={optionNo}
                                answerMode={answerMode}
                                ref={(ins) => (refs.current[index] = ins)}
                                languages={languages}
                                answerLangDetails={answerLangDetails}
                                currentLanguage={currentLanguage}
                              />
                            ))
                          : null}

                        {numberOfOptions.length > 0 &&
                        answerMode == 1 &&
                        answerLangDetails.length > 0 ? (
                          <EditAnswerInputOptions
                            answerMode={answerMode}
                            answerLangDetails={answerLangDetails}
                            languages={languages}
                            currentLanguage={currentLanguage}
                          />
                        ) : null}

                        {numberOfOptions.length > 0 &&
                        answerMode !== 1 &&
                        answerLangDetails.length == 0
                          ? numberOfOptions.map((optionNo, index) => (
                              <AnswerOptions
                                optionNo={optionNo}
                                answerMode={answerMode}
                                ref={(ins) => (refs.current[index] = ins)}
                                languages={languages}
                                currentLanguage={currentLanguage}
                              />
                            ))
                          : null}

                        {numberOfOptions.length > 0 &&
                        answerMode === 1 &&
                        answerLangDetails.length == 0 ? (
                          <AnswerInputOptions
                            answerMode={answerMode}
                            languages={languages}
                            currentLanguage={currentLanguage}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    id="errorMessage"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("min_2_options_should_be_given")}
                  </div>
                  <div
                    id="errorMessageNoInput"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_two_input_and_correct_option")}
                  </div>
                  <div
                    id="errorMessageNoUpload"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("upload_file_for_all_options")}
                  </div>
                  <div
                    id="errorMessageNoText"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_text_for_all_options")}
                  </div>
                  <div
                    id="errorMessageAllLanguages"
                    style={{
                      display: "none",
                      backgroundColor: "red",
                      width: "400px",
                    }}
                  >
                    {t("enter_question_name_for_all_languages")}
                  </div>

                  <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" />}
                        label={t("media_rights")}
                        labelPlacement="end"
                        checked={mediaRightsChecked}
                        onChange={(e) => mediaRightsChanged(e)}
                      />
                    </FormGroup>
                  </FormControl>

                  <div class="hint-explanation">
                    <div class="row m-0">
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInLeft">
                        <div class="hint-explanation-in">
                          <div class="icon">
                            <img src={hintImg} />
                          </div>
                          <div>
                            <h4>{t("enter_hint")}</h4>

                            {questionLangDetails && currentLanguage
                              ? questionLangDetails.map((language, index) => (
                                  <div class="question-textarea">
                                    {/*id={"questionHint_" + language.code}
                                                                         style={language.languageId == currentLanguage.id ? null : { display: "none" }}>
                                                                         */}

                                    <textarea
                                      style={
                                        language.languageId ==
                                        currentLanguage.id
                                          ? null
                                          : { display: "none" }
                                      }
                                      placeholder={t("hints")}
                                      id={"questionHint_" + language.code}
                                      defaultValue={
                                        language.questionDetails.hint
                                      }
                                    ></textarea>
                                    {/* 
                                                                        <TinyMCE5Component key={index}  
                                                                                            ref={instance => refTinyMCE5HintComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                            language_code={globalLangCode}
                                                                                            content={language.questionDetails.hint}
                                                                                            placeholder={t("hints")}/>
                                                                        */}
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 animated fadeInRight">
                        <div class="hint-explanation-in">
                          <div class="icon">
                            <img src={expImg} />
                          </div>
                          <div>
                            <h4>{t("add_explanation")}</h4>
                            {questionLangDetails && currentLanguage
                              ? questionLangDetails.map((language, index) => (
                                  <div class="question-textarea">
                                    {/*id={"questionExp_" + language.code}
                                                                         style={language.languageId == currentLanguage.id ? null : { display: "none" }}
                                                                         >
                                                                         */}
                                    <textarea
                                      style={
                                        language.languageId ==
                                        currentLanguage.id
                                          ? null
                                          : { display: "none" }
                                      }
                                      placeholder={t("explanation")}
                                      id={"questionExp_" + language.code}
                                      defaultValue={
                                        language.questionDetails.explanation
                                      }
                                    ></textarea>

                                    {console.log("language", language)}
                                    {/* 
                                                                        <TinyMCE5Component key={index}  
                                                                                ref={instance => refTinyMCE5ExplainComponents.current[index] = {id: language.id, tmce: instance}} 
                                                                                language_code={globalLangCode}
                                                                                content={language.questionDetails.explanation}
                                                                                placeholder={t("explanation")}/>
                                                                        */}
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="qc-bottom-btns animated fadeInRight">
              {/* <a onClick={addMoreQuestionAndAnswer} href="javascript:void(0)" class="btn-background">Add more</a> */}
              <a
                onClick={() => addQuestionAndAnswer()}
                href="javascript:void(0)"
                class="btn-border blue-bg-btn"
              >
                {t("complete_and_preview")}
              </a>
            </div>
          </div>
        </div>

        <ToastContainer />

        {/* <BasicFooter /> */}
      </React.Fragment>
    </LoadingOverlay>
  );
}

export default EditQuestion;
