import i18next  from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend  from 'i18next-http-backend';
import XHR from "i18next-http-backend"
import LanguageDetector from 'i18next-browser-languagedetector';


//Use to bundle the json files, with webpack.
//https://github.com/atroo/i18next-resource-store-loader/blob/master/README.md
var resBundle = require("i18next-resource-store-loader"+"?include=\\.json$!./assets/i18n/index.js");

const options = {
    order: ['cookie','localStorage', 'navigator'],
    caches: ['cookie','localStorage']
}

i18next 
.use(XHR) // <---- add this
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
.use(initReactI18next)
.init({
    detection: options,
    debug: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    },
    /*
    backend: {
        allowMultiLoading: true,
 
    },*/
    resources: resBundle,
    supportedLngs: ['en','hi','ja','zh'],
    //lng: localLang,
    fallbackLng: 'en', // use en if detected lng is not available   
    react: {
        wait: true,
        useSuspense: true
    }
}, function(err, t) {
    /*
    if(i18next.language = 'enGB') {
        i18next.changeLanguage('en')
    }
    */
    // initialized and ready to go!
    console.log(i18next.t('usd'))
});

export default i18next;