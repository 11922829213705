/* eslint-disable */


import React from 'react'
import banner from "../images/banner.png"
import { useTranslation } from 'react-i18next';
import "../less/dashboard.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/about-us.css"



function ReactQuillComponent(props) {
    console.log('ReactQuillComponent props.size', props.size)
    return (
        <React.Fragment>

            <div id={"toolbar" + props.language_id}>
                <select className="ql-font">
                    {props.font == 'arial' ?
                        <option value="arial" selected>
                            Arial
                        </option> : 
                        <option value="arial" >
                            Arial
                        </option>}
                    <option value="serif">Serif</option>
                    <option value="sansserif">Sans-serif</option>
                    <option value="georgia">Georgia</option>
                    <option value="helvetica">Helvetica</option>
                    <option value="monospace">Monospace</option>
                </select>
                <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                </span>
                {/**
                <select class="ql-size">

                </select>
                 */}
                
                 <select title="size" class="ql-size">
                    <option value="9px" ></option>
                    <option value="10px" ></option> 
                    <option value="11px" ></option>
                    <option value="14px" ></option>
                    <option value="16px"></option>

                    {props.size == "18px" ?
                    <option value="18px" selected></option>
                    : <option value="18px" ></option>}

                    <option value="20px"></option>
                    <option value="22px"></option>
                    <option value="24px"></option>
                    <option value="26px"></option>
                    <option value="28px"></option>

                    {/*
                    {props.size == "36px" ?
                    <option value="36px" selected></option>
                    :<option value="36px"></option>}
                    */}
                    <option value="36px" ></option>
                    <option value="48px"></option>
                    <option value="72px"></option>
                </select>
                
                <span class="ql-formats">
                    <button class="ql-header" value="1">H1</button>
                    <button class="ql-header" value="2">H2</button>
                    <button class="ql-header" value="3">H3</button>
                    <button class="ql-header" value="4">H4</button>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-indent" value="-1"></button>
                    <button class="ql-indent" value="+1"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-direction" value="rtl"></button>
                    <select class="ql-align"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-script" value="sub"></button>
                    <button class="ql-script" value="super"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-blockquote"></button>
                    <button class="ql-code-block"></button>
                    <button class="ql-link"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean"></button>
                </span>
            </div>
            <div id={"editor" + props.language_id}></div>
        </React.Fragment>
    )
}

/*
ReactQuillComponent.propTypes = {
    font: PropTypes.string,
    size:PropTypes.string
};
*/
ReactQuillComponent.defaultProps = {
    font: "arial",
    size: "18px"
}

export default ReactQuillComponent

