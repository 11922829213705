/* eslint-disable */ 

import React from 'react'
import { useTranslation } from 'react-i18next';

function AboutMemorizeitall() {
    const {t} = useTranslation()
    return (
        <React.Fragment>
        {/* <BasicHeader 
            {...props}
        />  */}

<div class="my-credits-wrapper">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-8 col-md-6 col-lg-9">
                    <h3 class="animated fadeInLeft">{t("about_memorizeitall_text")}</h3>  
                </div>
            </div>  
        </div>
    </div>
    <section class="gener_gap">
                    <div class="container">
                        
                        <div class="col-lg-9 col-md-8 col-sm-12 cust_center">
                          
                        <div
                        
                    />
                            <p><strong>{t("memorizeitall_company_info")}</strong></p>
                            <p>{t("memorizeitall_comoany_name")}</p> 
                            <p>{t("address")}: {t("address_line_1")} {t("address_line_2")}</p> 
                            <p>{t("memorizeitall_phone")}</p>
                            <p>{t("memorizeitall_fax")}</p>
                            <p>{t("memorizeitall_web")}</p>
                            <p>{t("memorizeitall_email")}</p>
                            <p>{t("memorizeitall_owner")}</p>
                            <p>{t("memorizeitall_established")}</p>

                            <p><strong>{t("what_is_memorizeitall")}</strong></p>

                            <p>{t("what_is_memorizeitall_1")}</p>  

                            <p>{t("what_is_memorizeitall_2")}</p>  

                            <p>{t("what_is_memorizeitall_3")}</p>  

                            <p>{t("what_is_memorizeitall_4")}</p>  

                            <p>{t("what_is_memorizeitall_5")}</p>  

                            <p>{t("what_is_memorizeitall_6")}</p>  

                            <p>{t("what_is_memorizeitall_7")}</p>  

                            <p>{t("what_is_memorizeitall_8")}</p>  

                            <p>{t("what_is_memorizeitall_9")}</p>  

                            <p>{t("what_is_memorizeitall_10")}</p>  

                            <p>{t("what_is_memorizeitall_11")}</p>  

                            <p>{t("what_is_memorizeitall_12")}</p>  

                            <p><strong>{t("what_is_memorizeitall_history")}</strong></p>

                            <p>{t("what_is_memorizeitall_history_1")}</p> 

                            <p>{t("what_is_memorizeitall_history_2")}</p> 

                            <p>{t("what_is_memorizeitall_history_3")}</p> 

                            <p>{t("what_is_memorizeitall_history_4")}</p> 

                            <p>{t("what_is_memorizeitall_history_5")}</p> 
                           
                        </div>
            
                        
            
                    </div>
                </section>
               
    </React.Fragment>
    )
}

export default AboutMemorizeitall
