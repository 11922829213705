/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/dashboard.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/kyc-details.css";

function ContentsDetailsEdit(props) {
  console.log(props);

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const user = cookies["user"]; //getUser();
  const token = cookies["access_token"];
  console.log(user);
  const user_id = user.id;

  const quizDeckId = props.match.params.quizdeckid;
  const primaryLanguageId = props.location.state.primaryLanguageId;
  const languages = props.location.state.languages;

  const [editScreen, setEditScreen] = useState(1);

  const [contentDetails, setContentDetails] = useState();
  const [contentName, setContentName] = useState();
  const [contentDesc, setContentDesc] = useState();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/quiz_deck/details",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token, //getToken(),
        "Content-Type": "application/json",
      },
      data: {
        quiz_deck_id: quizDeckId,
      },
    })
      .then((response) => {
        console.log("details of this quiz deck are", response.data);
        setContentDetails(response.data.details);
        // setLoading(false)
        // setMemorizingLoading(false)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        // showErrorMessageToUser(error.response.data[0].msg)
        // setLoading(false)
        // setMemorizingLoading(false)
      });
  }, []);

  useEffect(() => {
    if (contentDetails) {
      setContentName(contentDetails.name);
      setContentDesc(contentDetails.description);
    }
  }, [contentDetails]);

  return (
    <React.Fragment>
      <div class="kyc-details-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <div class="kyc-details-info animated fadeInRight">
                <div id="myWizard">
                  <div class="timeline-wrapper">
                    <ul class="step-progress step-steps">
                      <li
                        class={editScreen >= 1 ? "item active" : "item"}
                        onClick={() => setEditScreen(1)}
                      >
                        <div class="kyc-menu">
                          <span class="no">1</span>
                          <span>{t("contentDetails")}</span>
                        </div>
                      </li>
                      <li
                        class={editScreen >= 2 ? "item active" : "item"}
                        onClick={() => setEditScreen(2)}
                      >
                        <div class="kyc-menu">
                          <span class="no">2</span>
                          <span>{t("gener")} </span>
                        </div>
                      </li>
                      <li
                        class={editScreen >= 3 ? "item active" : "item"}
                        onClick={() => setEditScreen(3)}
                      >
                        <div class="kyc-menu">
                          <span class="no">3</span>
                          <span>{t("category")}</span>
                        </div>
                      </li>
                      <li
                        class={editScreen >= 4 ? "item active" : "item"}
                        onClick={() => setEditScreen(4)}
                      >
                        <div class="kyc-menu">
                          <span class="no">4</span>
                          <span>Sub categories</span>
                        </div>
                      </li>
                      <li
                        class={editScreen === 5 ? "item active" : "item"}
                        onClick={() => setEditScreen(5)}
                      >
                        <div class="kyc-menu">
                          <span class="no">5</span>
                          <span>Keywords</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="step-content">
                    {contentDetails ? (
                      <div
                        id="step1"
                        style={
                          editScreen === 1
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div class="row">
                          <div class="col-sm-12">
                            <input
                              type="text"
                              placeholder={t("namee")}
                              value={contentName}
                              onChange={(e) => setContentName(e.target.value)}
                            />
                          </div>

                          <div class="col-sm-12">
                            <input
                              type="text"
                              placeholder={t("description")}
                              value={contentDesc}
                              onChange={(e) => setContentDesc(e.target.value)}
                            />
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setEditScreen(2)}
                        >
                          <a
                            href="javascript:void(0)"
                            class="btn-background  blue-bg-btn"
                          >
                            {t("Next")}
                          </a>
                        </div>
                      </div>
                    ) : null}

                    <div
                      id="step2"
                      style={
                        editScreen === 2
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <div class="choose-genre">
                          <h5 class="mb-3">{t("choose_preferred_genre")}</h5>
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {/* {
                                                    genreList && currentGenrePages.length === pagesInGenre
                                                    ?
                                                    (
                                                        genreList.length>0
                                                        ?
                                                        (
                                                            genreList.map(eachGenre => (
                                                                <div class="col-sm-6 mb-3" onClick={() => genreSelected(eachGenre.id, eachGenre.name)}>
                                                                    <a href="javascript:void(0)" className={selectedGenres.includes(eachGenre.id)?"preferred-boxs active":"preferred-boxs"}>
                                                                        <span class="icon"><img src={(GENER_PATH+eachGenre.image)} /></span>
                                                                        <span class="in-txt" dangerouslySetInnerHTML={{__html:eachGenre.name}}></span>
                                                                    </a>
                                                                </div>
                                                            ))
                                                        )
                                                        : null
                                                    )
                                                    : null
                                                } */}
                            </div>
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          //  onClick={() => setKycandcatgories(3)}
                        >
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            {t("Next")}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="step3"
                      style={
                        editScreen === 3
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        <div class="choose-genre">
                          <h5 class="mb-3">{t("choose_preferred_category")}</h5>
                          {/* <strong 
                                                // onClick={showCategories}>
                                                Show Categorie>
                                            </strong> */}
                          <div class="kyc-overflow">
                            <div class="row row-eq-height">
                              {/* {
                                                    genreCategories && currentCategoriesPages.length == totalCategoriesPages
                                                    ?
                                                    (
                                                        genreCategories.length>0
                                                        ?
                                                        (
                                                            genreCategories.map(category => (
                                                                <div class="col-sm-6 mb-3" onClick={() => categoriesSelected(category.id, category.name)}>
                                                                    <a href="javascript:void(0)" className={selectedCategories.includes(category.id)?"preferred-boxs active":"preferred-boxs"}>
                                                                        <span class="icon"><img src={(CATEGORY_PATH+category.image)} /></span>
                                                                        <span class="in-txt">{category.name}</span>
                                                                    </a>
                                                                </div>
                                                            ))
                                                        )
                                                        : null
                                                    )
                                                    : null
                                                } */}
                            </div>
                          </div>
                        </div>

                        <div
                          class="kyc-bottom-btn"
                          onClick={() => setEditScreen(4)}
                        >
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            {t("Next")}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      id="step4"
                      style={
                        editScreen === 4
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        {/* {
                                            languages.length > 0 ? 
                                            (
                                                <div class="choose-language">
                                                    <h5 class="mb-3">{t("choose_Language")}</h5>
                                                    <div class="row">
                                                        {
                                                            languages.map(language => (
                                                                <div class="col-6 col-sm-4"
                                                                    onClick={() => selectThisLanguage(language.id)}
                                                                >
                                                                    <a href="javascript:void(0)" 
                                                                        className={selectedLanguages.includes(language.id)?"language active":"language"}
                                                                    ><img className="icon" src={(COUNTRY_FLAG+language.flag)} /> <span>{t(language.name)}</span></a>
                                                                </div>
                                
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                       
                                            )
                                            :
                                            null
                                        } */}

                        <div
                          class="kyc-bottom-btn"
                          // onClick={updateProfile}
                        >
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            {t("submit")}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      id="step4"
                      style={
                        editScreen === 4
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div class="profile-selction">
                        {/* {
                                            languages.length > 0 ? 
                                            (
                                                <div class="choose-language">
                                                    <h5 class="mb-3">{t("choose_Language")}</h5>
                                                    <div class="row">
                                                        {
                                                            languages.map(language => (
                                                                <div class="col-6 col-sm-4"
                                                                    onClick={() => selectThisLanguage(language.id)}
                                                                >
                                                                    <a href="javascript:void(0)" 
                                                                        className={selectedLanguages.includes(language.id)?"language active":"language"}
                                                                    ><img className="icon" src={(COUNTRY_FLAG+language.flag)} /> <span>{t(language.name)}</span></a>
                                                                </div>
                                
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                       
                                            )
                                            :
                                            null
                                        } */}

                        <div
                          class="kyc-bottom-btn"
                          // onClick={updateProfile}
                        >
                          <a
                            href="javascript:void(0)"
                            class="btn-background blue-bg-btn"
                          >
                            {t("submit")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContentsDetailsEdit;
