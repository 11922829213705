/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useState } from "react";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "../Hooks/useAsyncEffect";
import "../less/my-credits.css";
import "../less/test.css";

// import  "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"

function GenreDetails(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken()
  console.log("GenreDetails props", props);
  const genreId = props.genreId || props.match.params.genreId;
  const showSubCategory = props.showSubCategory || false;
  console.log("cat_id", props.cat_id);
  console.log("parent_cat_id", props.parent_cat_id);
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [genreDetails, setGenreDetails] = useState([]);
  const [refreshAll, setRefreshAll] = useState(false);

  const [genreCategories, setGenreCategories] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [pagesInCategories, setPagesInCategories] = useState(1);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [subCategoryDetails, setSubCategoryDetails] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesTotal, setSubCategoriesTotal] = useState(0);
  const [subCategoriesTotalPages, setSubCategoriesTotalPages] = useState(0);
  //const { genreDetails, genreCategories } = props
  console.log("genreCategores", genreCategories);
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";

  const setCategorySelected = (id) => {
    props.onCatChange(id);
  };

  const fetchGenre = async () => {
    props.onCatChange(0);
  };

  const goToSubCategoryContentPage = (subCategory) => {
    console.log("goToSubCategoryContentPage subCategory", subCategory);

    props.history.push({
      pathname:
        "/categorycontents/" + subCategory.id + "/" + subCategory.parent_cat_id,
      state: {
        genreId: genreId,
      },
    });
  };
  const goToCategoryContentPage = (cat_id) => {
    console.log("goToCategoryContentPage sub_cat_id", cat_id);
    props.history.push({
      pathname: "/categorycontents/" + cat_id,
      state: {
        genreId: genreId,
      },
    });
  };
  const fetchCategoryDetails = (cat_id) => {
    setCategoryDetails([]);
    axios({
      method: "post",
      url: "/api/categories/details",
      headers: {
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        category_id: cat_id,
        genre_id: genreId,
      }),
    })
      .then((response) => {
        console.log("/categories/details response", response);
        setCategoryDetails((categoryDetails) =>
          categoryDetails.concat(response.data.data)
        );
      })
      .catch((error) => {});

    const mySubCat = props?.match?.params?.cat_id;
    if (mySubCat) {
      axios({
        method: "get",
        url: `/api/subcategories/view/${mySubCat}`,
        headers: {
          "x-access-token": "Memo " + token,
          locale: globalLangCode,
        },
      })
        .then((response) => {
          console.log("/categories/details response", response);
          setSubCategoryDetails(response.data.data);
        })
        .catch((error) => {});
    }
  };

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const cancelTokenSource2 = axios.CancelToken.source();
      const token1 = cancelTokenSource.token;
      const token2 = cancelTokenSource2.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
        cancelTokenSource2.cancel();
      });

      try {
        if (props.parent_cat_id > 0) {
          fetchSubcatgories(props.parent_cat_id);
          fetchCategoryDetails(props.parent_cat_id);
        } else {
          if (props.parent_cat_id == 0 && props.cat_id > 0) {
            fetchSubcatgories(props.cat_id);
            fetchCategoryDetails(props.cat_id);
          }
        }
        //if ((props.cat_id == 0 && props.parent_cat_id == 0)) {
        const response = yield axios({
          method: "post",
          url: "/api/genres/details",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify(
            {
              genre_id: genreId,
            },
            { cancelToken: token1 }
          ),
        });
        console.log("async data", response);
        setGenreDetails([]);
        setGenreDetails((genreDetails) =>
          genreDetails.concat(response.data.data)
        );

        if (props.cat_id == 0) {
          console.log("executed /api/genres/details/categories");
          const responseCat = yield axios({
            method: "post",
            url: "/api/genres/details/categories",
            headers: {
              locale: globalLangCode,
            },
            data: qs.stringify(
              {
                genre_id: genreId,
              },
              { cancelToken: token2 }
            ),
          });
          setCategoryDetails([]);
          setSubCategories([]);
          setPagesInCategories(0);
          setPagesInCategories(responseCat.data.total_pages);
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [props.cat_id, props.parent_cat_id]
  );

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setGenreCategories([]);
        for (let i = 1; i <= pagesInCategories; i++) {
          const responseCat = yield axios({
            method: "post",
            url: "/api/genres/details/categories",
            headers: {
              locale: globalLangCode,
            },
            data: qs.stringify(
              {
                genre_id: genreId,
                page: i,
              },
              { cancelToken: token1 }
            ),
          });

          setGenreCategories((genreCategories) =>
            genreCategories.concat(responseCat.data.data)
          );
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [pagesInCategories]
  );

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setSubCategories([]);
        for (let i = 1; i <= subCategoriesTotalPages; i++) {
          let responseSubCat = yield axios({
            method: "post",
            url: "/api/subcategories/list",
            headers: {
              "x-access-token": "Memo " + token,
              locale: globalLangCode,
            },
            data: qs.stringify(
              {
                category_id: selectedCategoryId,
                page: i,
              },
              { cancelToken: token1 }
            ),
          });
          setSubCategoriesTotal(responseSubCat.data.total);
          setSubCategoriesTotalPages(responseSubCat.data.total_pages);
          setSubCategories((subCategories) =>
            subCategories.concat(responseSubCat.data.data)
          );
          setGenreCategories([]);
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [selectedCategoryId, subCategoriesTotal, subCategoriesTotalPages]
  );

  const fetchSubcatgories = (cat_id) => {
    setSelectedCategoryId(cat_id);
    axios({
      method: "post",
      url: "/api/subcategories/list",
      headers: {
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        category_id: cat_id,
        page: 1,
      }),
    })
      .then((response) => {
        console.log("/subcategories/list response", response);
        setGenreCategories([]);
        setSubCategoriesTotal(response.data.total);
        setSubCategoriesTotalPages(response.data.total_pages);
        setSubCategories((subCategories) =>
          subCategories.concat(response.data.data)
        );
      })
      .catch((error) => {});
  };

  const subCategoryContent = (compressed = true) => {
    return (
      <>
        <h3 style={{ color: "#1d5190!important" }} class="pb-2">
          {genreCategories && genreCategories.length
            ? t("category")
            : t("sub_category")}
        </h3>

        <div class="all-songs col-md-12">
          <div class="again-song">
            <div class="row">
              {genreCategories
                ? genreCategories.map((genreCategory) => (
                    <div
                      class="col-lg-3 col-md-4 col-sm-3 col-6 denra1"
                      onClick={() => setCategorySelected(genreCategory.id)}
                    >
                      <div class="genres-songs">
                        <div class="categoryImgOuter">
                          <img
                            src={CATEGORY_PATH + genreCategory.image}
                            alt="#"
                            class="categoryImg"
                          />
                        </div>
                        <p class="mb-0 bolly-new-song">{genreCategory.name}</p>
                      </div>
                    </div>
                  ))
                : null}
              {subCategories
                ? subCategories.map((subCategory) => (
                    <div
                      class={`${
                        compressed ? "col-lg-6" : "col-lg-3"
                      } col-md-4 col-sm-3 col-6 denra1`}
                      onClick={() => goToSubCategoryContentPage(subCategory)}
                    >
                      <div class="genres-songs">
                        <div class="categoryImgOuter">
                          <img
                            src={CATEGORY_PATH + subCategory.image}
                            alt="#"
                            class="categoryImg"
                          />
                        </div>
                        <p class="mb-0 bolly-new-song">{subCategory.name}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const subCategoryDescription = `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`;

  return (
    <React.Fragment>
      <section class="searching-genres">
        <div class="container-fluid">
          {/* <div class="genres-search col-md-10 mx-auto mar_40">
                     <input type="text" placeholder={t("search_here")} class="form-control" style={{fontFamily:"Arial, FontAwesome"}} />
                </div> */}

          <div class="genres-search-008 col-md-10 mx-auto genre">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 col-lg-5 d-flex  flex-column genreCategoryArea">
                  <h3 style={{ color: "#1d5190!important" }} class="genreTxt">
                    {t("gener")}
                  </h3>

                  {genreDetails
                    ? genreDetails.map((genreDetail) => (
                        <div class="about-genres">
                          <div
                            class="music-parent"
                            onClick={() => fetchGenre(genreDetail.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <div class="music-icon">
                              <img
                                src={GENER_PATH + genreDetail.image}
                                alt="#"
                              />
                            </div>
                          </div>

                          <h3
                            class="bollywood-head-002"
                            dangerouslySetInnerHTML={{
                              __html: genreDetail.name,
                            }}
                          ></h3>
                          <div class="bolly-some-content col-md-12">
                            <div class="row">
                              <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                <div class="song-quiz">
                                  {/* <h5 class='genreHeadingTxt'>{t("quizes")}</h5> */}
                                  <div className="genre-icons">
                                    <span class="icon-images"></span>
                                  </div>
                                  <h3>{genreDetail.total_quizzes}</h3>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                <div class="song-quiz">
                                  {/* <h5 class='genreHeadingTxt'>{t("creators")}</h5> */}
                                  <div className="genre-icons">
                                    <span class="icon-m-create"></span>
                                  </div>
                                  <h3>{genreDetail.sellers}</h3>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                <div class="song-quiz">
                                  {/* <h5 class='genreHeadingTxt'>{t("paid")}</h5> */}
                                  <div className="genre-icons">
                                    <span class="icon-paid3"></span>
                                  </div>
                                  <h3>{genreDetail.paid}</h3>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                <div class="song-quiz">
                                  <div className="genre-icons">
                                    <span class="icon-free3"></span>
                                  </div>
                                  {/* <h5 class='genreHeadingTxt'>{t("free")}</h5> */}
                                  <h3>{genreDetail.free}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}

                  <div className="categoryDiv my-5">
                    <h3
                      style={{ color: "#1d5190!important" }}
                      class="pb-50px mb-4"
                    >
                      {categoryDetails && categoryDetails.length
                        ? t("category")
                        : null}
                    </h3>
                    {categoryDetails
                      ? categoryDetails.map((categoryDetail) => (
                          <div class="about-genres">
                            <div
                              class="music-parent"
                              onClick={() =>
                                goToCategoryContentPage(categoryDetail.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div class="music-icon">
                                <img
                                  src={CATEGORY_PATH + categoryDetail.image}
                                  alt="#"
                                />
                              </div>
                            </div>
                            <h3 class="bollywood-head-002">
                              {categoryDetail.cat_name}
                            </h3>
                            <div class="bolly-some-content col-md-12">
                              <div class="row">
                                <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                  <div class="song-quiz">
                                    {/* <h5 class='genreHeadingTxt'>{t("quizes")}</h5> */}
                                    <div className="genre-icons">
                                      <span class="icon-images"></span>
                                    </div>
                                    <h3>{categoryDetail.total_quizzes}</h3>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                  <div class="song-quiz">
                                    {/* <h5 class='genreHeadingTxt'>{t("creators")}</h5> */}
                                    <div className="genre-icons">
                                      <span class="icon-m-create"></span>
                                    </div>
                                    <h3>{categoryDetail.sellers}</h3>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                  <div class="song-quiz">
                                    {/* <h5 class='genreHeadingTxt'>{t("paid")}</h5> */}
                                    <div className="genre-icons">
                                      <span class="icon-paid3"></span>
                                    </div>
                                    <h3>{categoryDetail.paid}</h3>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-md-3 col-sm-3 col-3 p-0">
                                  <div class="song-quiz">
                                    {/* <h5 class='genreHeadingTxt'>{t("free")}</h5> */}
                                    <div className="genre-icons">
                                      <span class="icon-free3"></span>
                                    </div>
                                    <h3>{categoryDetail.free}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  {showSubCategory ? subCategoryContent(true) : null}
                </div>
                <div
                  class={`col-md-12 col-lg-7 listingArea ${
                    showSubCategory ? "sub-category-container" : ""
                  }`}
                >
                  {!showSubCategory ? (
                    subCategoryContent()
                  ) : (
                    <>
                      <div className="sub-category-title-name mb-5">
                        {subCategoryDetails?.name}
                      </div>
                      <div className="sub-category-details flex-grow-1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: subCategoryDetails?.description,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default GenreDetails;
