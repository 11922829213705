/* eslint-disable */ 

import React , {useEffect, useState} from 'react'

import axios from 'axios';
import qs from 'qs';
import BasicHeader from './BasicHeader';
import BasicFooter from './BasicFooter';
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";

function BecomeACreator(props) {
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])
    
    const user = cookies['user'] //getUser();
    console.log(user)
    const user_type_id = user.user_type_id;
    console.log("user_type_id", user_type_id)
    const token  = cookies['access_token']  //getToken()
    const { t } = useTranslation()

    const [kycstatus, setkycstatus] = useState(0)

    useEffect(() => {
        goToManageSubscriptions();
        /*
        axios({
            method: 'get',
            url: '/api/user/kyc-status',
            headers:{
                'x-access-token': "Memo "+token,
                'locale': "en",
            }
        })
        .then(response => {
            console.log("kyc status response", response)
            setkycstatus(response.data.kyc_status)
            if(response.data.kyc_status == 1) { //Submitted the KYC redirect to subs. page.
                goToManageSubscriptions();
            }
        }).catch(error => {

        });
        */
    }, [])



    const goToMySellerKYC = () => {
        props.history.push('/sellerKYC');
    }

   const goToManageSubscriptions = () => {
       props.history.push('/managesubscriptions/' + 10) //10 for Creator
   }

    return (
       <React.Fragment>
           {/* <BasicHeader 
                {...props}
           /> */}
           <div class="inner-header">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 col-md-6 col-lg-6">
                    <h2 class="animated fadeInLeft">{t("become_a_creator")}</h2>
                </div>
                <div class="col-sm-7 col-md-6 col-lg-6">
                    <div class="breadcrumb-item animated fadeInRight">
                        <ul>
                            <li><a href="javascript:void(0)"><span class="icon-home1"></span>{t("home")}</a></li>
                            <li><span class="icon-next"></span></li>
                            <li><a href="javascript:void(0)" class="active">{t("become_a_creator")}</a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="create-content-wrapper">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                   
                    <div class="text-center pt-4 add-qa">
                        {
                            kycstatus== 0 ?
                                <a onClick={goToMySellerKYC} href="javascript:void(0)" class="btn-border mr-2 blue-bg-btn">{t("creator_kyc")}</a>
                            
                            :
                                <a onClick={goToManageSubscriptions} href="javascript:void(0)" class="btn-border mr-2 blue-bg-btn">{t('manage_subscription')}</a>
                        
                        }
                        { /* <a href="javascript:void(0)" class="btn-border mr-2">KYC Completed</a> */ }
                       
                        
                        {/* <a onClick={publishTheQuizDeck} href="javascript:void(0)" class="btn-background">Publish now</a> */}
                    </div>

                </div>
            </div>
        </div>
    </div>
    
    {/* <BasicFooter /> */}
       </React.Fragment>
    )
}

export default BecomeACreator
