/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import ContentComponent from "./ContentComponent";

import "../less/quill-editor.css";
import "../less/seller.css";
import "../less/test.css";

function TopQuizzes(props) {
  console.log("topquizlist", props);
  const { topQuizList } = props;
  const { t } = useTranslation();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  return (
    <React.Fragment>
      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance1">
            <div class="text-left">
              <h3 style={{ color: "#294177", fontWeight: "600" }}>
                {t("top_performing_quizes")}
              </h3>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="row">
                {topQuizList
                  ? topQuizList.length > 0
                    ? topQuizList.map((content) => (
                        <ContentComponent content={content} {...props} />
                      ))
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default TopQuizzes;
