/* eslint-disable */

import React from "react";

import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import { useCookies } from "react-cookie";
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";
import { tHydrate } from "../Utils/HydrateT";

function ContentComponent(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken();

  const user = cookies["user"]; //getUser();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;

  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        /> */}

      <div class="col-lg-6 col-md-12 quiz_details">
        <div class="top-performer">
          <div>
            <div
              id={"showReviews" + props.content.id}
              class="modal fade"
              role="dialog"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">{t("reviews")}</h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="reviews-listing">
                      {props.content.reviewsText.length > 0
                        ? props.content.reviewsText.map((review) => (
                            <div class="review-list">
                              <div class="user-img">
                                <img src={PROFILE_PIC + review.profile_pic} />
                              </div>
                              <div>
                                <div>{review.name}</div>
                                <div class="stars">
                                  {review.rating > 0
                                    ? (function star() {
                                        let stars = [];
                                        for (
                                          let i = 1;
                                          i <= review.rating;
                                          i++
                                        ) {
                                          stars.push(
                                            <span
                                              onClick={() => setRatingStars(i)}
                                              class="icon-star active"
                                            ></span>
                                          );
                                        }
                                        return stars;
                                      })()
                                    : null}
                                </div>
                                <div class="comment">{review.review}</div>
                              </div>
                            </div>
                          ))
                        : "No Ratings Available"}
                    </div>
                  </div>
                  <div class="modal-footer">
                    <a
                      href="javascript:void(0)"
                      class="btn-background blue-bg-btn"
                      data-dismiss="modal"
                    >
                      {t("close")}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="border box_quize">
              <div class="head_pro">
                <div class="profile_pick">
                  <img
                    src={PROFILE_PIC + props.content.user_profile_pic}
                    width="100%"
                  />
                </div>
                <div class="create_by">
                  <h6 style={{ color: "#132D5B" }}>
                    {tHydrate(props.content.user_name)}
                  </h6>
                </div>
                <div className="icons">
                  {props.content.amount > 0 ? (
                    <span className="paid-text">paid</span>
                  ) : (
                    <span className="paid-text">free</span>
                  )}
                  {props.content.is_official_quiz == 1 ? (
                    <span className="icon icon-verified"></span>
                  ) : null}

                  {props.content.group_id ? (
                    <div class="d-inline-block contentLockBox">
                      <span class="contentGroupLock ml-5px closedLock">
                        <span
                          class={
                            props.content.open_close
                              ? "icon icon-unlock"
                              : "icon icon-lock"
                          }
                        ></span>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div class="review-section">
                <div class="row align-items-center">
                  {/* <div class="col-md-6 language-img"> */}
                  {props.content.languages.length > 0
                    ? (function language() {
                        let languages = [];
                        //console.log('adsfadsf')
                        languages = props.content.languages.map(
                          (language, index) => {
                            switch (language.name) {
                              case "English":
                                return (
                                  <img
                                    key={index.toString()}
                                    src={`${awsImageUrl}/uploads/languages/usa.png`}
                                  />
                                );
                                break;
                              case "Japanese":
                                return (
                                  <img
                                    key={index.toString()}
                                    src={`${awsImageUrl}/uploads/languages/japan.png`}
                                  />
                                );
                                break;
                              case "Chinese":
                                return (
                                  <img
                                    key={index.toString()}
                                    src={`${awsImageUrl}/uploads/languages/china.png`}
                                  />
                                );
                                break;
                              case "Hindi":
                                return (
                                  <img
                                    key={index.toString()}
                                    src={`${awsImageUrl}/uploads/languages/india.png`}
                                  />
                                );
                                break;
                            }
                            //languages.push(t(language.name))
                          }
                        );
                        return (
                          <div className="col-md-6 language-img">
                            {" "}
                            {languages}{" "}
                          </div>
                        );
                      })()
                    : null}
                  {/*
                                <img src="https://memoriz.s3-ap-southeast-1.amazonaws.com/uploads/languages/japan.png"/>
                                <img src="https://memoriz.s3-ap-southeast-1.amazonaws.com/uploads/languages/india.png"/>
                                <img src="https://memoriz.s3-ap-southeast-1.amazonaws.com/uploads/languages/usa.png"/>                                                                  
                            
                        
                                
                            </div>
                            */}

                  {/* <div class="col-md-6 user-content">*/}
                  {props.content.types.length > 0
                    ? (function contentType() {
                        let types = [];
                        //console.log('-------------------')
                        types = props.content.types.map((typeo, index) => {
                          //console.log('type', typeo)
                          switch (typeo.trim()) {
                            case "Text":
                              //console.log('adding')
                              return <span class="icon icon-text"></span>;
                              break;
                            case "Picture":
                              return (
                                <span className="icon icon-images1"></span>
                              );
                              break;
                            case "Audio":
                              return <span className="icon icon-audio1"></span>;
                              break;
                            case "Video":
                              return (
                                <span className="icon icon-youtube"></span>
                              );
                              break;
                          }
                          //languages.push(t(language.name))
                        });
                        /* 
                                    1	"Expert"
                                    2	"Intermediate"
                                    3	"Beginner"
                                    4	"1st kyu"
                                    5	"Pre 1st kyu"
                                    6	"2nd kyu"
                                    7	"Pre 2nd kyu"
                                    8	"3rd kyu"
                                    9	"4th kyu"
                                    10	"5th kyu"
                                    11	"6th kyu"
                                    12	"7th kyu"
                                    13	"8th kyu"
                                    14	"9th kyu"
                                    15	"10th kyu"
                                    */
                        switch (props.content.complexity_id) {
                          case "1":
                            //console.log('adding')
                            types.push(
                              <span className="icon icon-expert"></span>
                            );
                            break;
                          case "2":
                            types.push(
                              <span className="icon icon-intermediate"></span>
                            );
                            break;
                          case "3":
                            types.push(
                              <span className="icon icon-beginner"></span>
                            );
                            break;
                          case "4":
                            types.push(
                              <span className="icon icon-number1"></span>
                            );
                            break;
                          case "5":
                            types.push(
                              <span className="icon icon-number1"></span>
                            );
                            break;
                          case "6":
                            types.push(
                              <span className="icon icon-number2"></span>
                            );
                            break;
                          case "7":
                            types.push(
                              <span className="icon icon-number2"></span>
                            );
                            break;
                          case "8":
                            types.push(
                              <span className="icon icon-number3"></span>
                            );
                            break;
                          case "9":
                            types.push(
                              <span className="icon icon-number4"></span>
                            );
                            break;
                          case "10":
                            types.push(
                              <span className="icon icon-number5"></span>
                            );
                            break;
                          case "11":
                            types.push(
                              <span className="icon icon-number6"></span>
                            );
                            break;
                          case "12":
                            types.push(
                              <span className="icon icon-number7"></span>
                            );
                            break;
                          case "13":
                            types.push(
                              <span className="icon icon-number8"></span>
                            );
                            break;
                          case "14":
                            types.push(
                              <span className="icon icon-number9"></span>
                            );
                            break;
                          case "15":
                            types.push(
                              <span className="icon icon-number1"></span>
                            );
                            types.push(
                              <span className="icon icon-number0"></span>
                            );
                            break;
                        }

                        //console.log('types.length',types.length)
                        //types.push(<span className="icon icon-intermediate"></span>)
                        //types.push(<span className="icon icon-number8"></span>)
                        return (
                          <div className="col-md-6 user-content"> {types} </div>
                        );
                      })()
                    : null}

                  {/*</div>*/}
                  {/*<div class="col-md-6 review-user"> */}
                  {Math.round(props.content.rating) > 0
                    ? (function star() {
                        let stars = [];

                        //
                        let currentRating = Math.round(props.content.rating);

                        for (let i = 0; i < currentRating; i++) {
                          stars.push(<span class="icon icon-star"></span>);
                        }
                        for (let j = currentRating; j < 5; j++) {
                          stars.push(
                            <span class="icon icon-star-border"></span>
                          );
                        }
                        stars.push(
                          <small>
                            <a
                              data-toggle="modal"
                              data-target={"#showReviews" + props.content.id}
                              href="javascript:void(0)"
                            >
                              {props.content.reviews}
                            </a>
                          </small>
                        );
                        return (
                          <div className="col-md-6 review-user">{stars}</div>
                        );
                      })()
                    : null}

                  {/* </div> */}
                  <div class="col-md-6 questions-section">
                    <div class="d-flex content-right">
                      <span class=" icon icon-question2"></span>
                      <b class="count">{props.content.questioncount}</b>
                      <span class=" icon icon-download5"></span>
                      <b>
                        {props.content.quiz_taken
                          ? props.content.quiz_taken
                          : 0}{" "}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="row">
                  {/* <div class="col-md-3 pr-0">
                        <div class="profile_pick">
                        <img src="images/profile1.png" width="100%" />
                        </div>
                    </div> */}
                  {/* <div class="col-md-5 pr-0">
                        <div class="create_by">
                        <p>Created by</p>
                        <h6>john Doe smith</h6>
                        </div>
                    </div> */}
                  {/* <img src={languagesImg} /> */}
                  {/* <p>English , Chinese, Japanese & <a href="#">+1 more</a></p> */}
                  {/*<div className="col-md-6">
                    <div class="quizes_boxlist_new">
                        
                        <span className=" icon icon-language"></span>
                        
                        
                            {
                                content.languages.length > 0 
                                ?
                                (
                                    function language  () {
                                        let languages = [];

                                        content.languages.map(language => (
                                        languages.push(t(language.name))
                                        ))
                                        return <p>{languages.join(', ')}</p>
                                    }()
                                )
                                : null
                            }
                            
                    </div> 
                    
                    </div> */}
                  {/* <img src={picturesImg} /> */}

                  {/*<div className="col-md-6">
                    <div class="quizes_boxlist_new">
                        
                        <span className=" icon icon-target"></span>
                        <p>
                            {content.types.join(',')} based Q&A
                        </p>                                
                    </div> 
                    </div> */}

                  {/* <img src={smileyImg} /> */}
                  {/* <div className="col-md-6">
                    <div class="quizes_boxlist_new">
                            
                            <span className=" icon icon-smile"></span>
                            <p class="str_img">
                            {
                                content.rating > 0 
                                ?
                                (
                                    function star  () {
                                        let stars = [];
                                        for (let i=0; i<content.rating; i++){
                                            stars.push(<img src={starsImg} />)
                                        }
                                        return stars
                                    }()
                                )
                                : null
                            }
                                

                                <br></br>({content.reviews} {t("reviews")})
                            </p>
                        </div> 
                    
                    </div> */}
                  {/*
                    <div className="col-md-6">
                        <div class="quizes_boxlist_new">
                        
                        <span className=" icon icon-info"></span>
                        <p>For Pro & <a href="#">+1 more</a></p>      
                        </div> 
                    </div>*/}

                  {/* <img src={infoImg} /> */}
                  {/*<div className="col-md-6">
                    <div class="quizes_boxlist_new">
                        
                        <span className=" icon icon-download"></span>
                        <p>{content.total_quiz_takens} {t("quizTaken")}</p>     
                        </div>
                    </div>*/}

                  {/* <img src={infoImg} /> */}
                  {/*<div className="col-md-6">
                        <div class="quizes_boxlist_new">
                        
                        <span className=" icon icon-question"></span>
                        <p>{content.number_of_questions} {t("questions")}</p>      
                        </div> */}
                </div>
              </div>

              <h6
                className="pt-2 heading-text"
                style={{ color: "#132D5B" }}
                dangerouslySetInnerHTML={{ __html: props.content.name }}
              ></h6>

              <h7 className="pt-2">
                ({t("content_id")}: {props.content.id})
              </h7>

              <div class="user-content-info">
                <p
                  dangerouslySetInnerHTML={
                    props.content.description
                      ? { __html: props.content.description }
                      : null
                  }
                ></p>
              </div>

              <div
                class="quize_listing"
                onClick={() => goToContentDetailsPage(props.content.id)}
              >
                <button class="continue blue-bg-btn">
                  {t("contentDetails")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default ContentComponent;
