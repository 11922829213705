/* eslint-disable */
import axios from "axios";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/contact-us.css";
import "../less/dashboard.css";
import "../less/icon-font.css";
import "../less/releway-font.css";

function ContactUs(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }

  const [banners, setBanners] = useState();
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  const [submitted, setSubmitted] = useState();

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const goToDashboard = () => {
    props.history.push("/dashboard");
  };

  const findTopicInEnglish = (topic, count = 1) => {
    if (count <= 7) {
      if (t("contact_topic_" + count, { lng: "ja" }) === topic) {
        return t("contact_topic_" + count, { lng: "en" });
      } else {
        return findTopicInEnglish(topic, ++count);
      }
    } else {
      return topic;
    }
  };

  const submitForm = () => {
    if (!submitted) {
      const f = document.getElementById("ctForm");
      const data = {
        first_name: "",
        last_name: "",
        email_id: "",
        topic: "",
        message: "",
      };
      let valid = true;
      for (const name in data) {
        const val = f.elements.namedItem(name).value;
        console.log("val", val);
        if (val) {
          document.getElementById("e_" + name).style.display = "none";
          if (name === "topic") {
            data[name] = findTopicInEnglish(val);
          } else {
            data[name] = val;
          }
        } else {
          valid = false;
          document.getElementById("e_" + name).style.display = "block";
        }
      }
      if (valid === true) {
        if (cookies["user"]) {
          data["user_id"] = cookies["user"].id;
        } else {
          data["user_id"] = null;
        }
        axios({
          method: "post",
          url: "/api/enquiries/create",
          headers: {
            locale: globalLangCode,
            "x-access-token": "Memo " + cookies["access_token"], //getToken(),
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            setSubmitted(true);
          })
          .catch((error) => {
            console.log("error", error);
            setSubmitted(null);
          });
      }
    }
  };
  const goToHowItWorksPage = () => {
    props.history.push("/howitworks");
  };
  return (
    <React.Fragment>
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("contact_us")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("contact_us")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      <br></br>
      <br></br>

      <div>
        <div class="container contact-us-wrapper">
          {submitted === true ? (
            <div class="row justify-content-center">
              <div class="col-sm-10 col-md-9 col-lg-8">
                <div class="success-message animated fadeInRight">
                  {/* <div><img src={thankyou} /></div> */}
                  <h1 style={{ color: "#294177" }}>
                    {t("thank_you_for_contacting")}
                  </h1>
                  <h4 style={{ color: "#294177" }}>
                    {t("we_will_do_our_best")}
                  </h4>
                  {/* <div class="home-btn"><a href="javascript:void(0)" class="btn-background">{t("return_to_home")}</a></div> */}
                </div>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="address-mail-info animated fadeInRight">
                  <h3>{t("postal_address")}</h3>
                  <div>
                    <div>{t("postal_address_loc")}</div>
                  </div>
                  <div class="website">
                    <a href="https://www.memorizeitall.com/">
                      <span class="icon icon-globe"></span>
                      <span>www.memorizeitall.com</span>
                    </a>
                  </div>
                  <div>
                    {t("if_you_have_questions")} {t("user_manual_text")}{" "}
                    {t("or_the")} {t("faq_text")} {t("for_solving")}
                  </div>
                  <div class="emails">
                    <h3>{t("email")}</h3>
                    <div>
                      <div class="row pb-1">
                        <div class="col-sm-5">
                          {t("how_to_use_memorizeitall")}
                        </div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:howto@memorizeitall.email">
                            howto@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">
                          {t("suggest_new_categories")}
                        </div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:suggestions@memorizeitall.email">
                            suggestions@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("report_an_error")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:admin@memorizeitall.email">
                            admin@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("reg_contents")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:contents@memorizeitall.email">
                            contents@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("for_creators")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:creator@memorizeitall.email">
                            creator@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("advertisements")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:ad@memorizeitall.email">
                            ad@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("complaints")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:complaints@memorizeitall.email">
                            complaints@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("report_abuses")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:abuse@memorizeitall.email">
                            abuses@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("vacancies")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:vacancies@memorizeitall.email">
                            vacancies@memorizeitall.email
                          </a>
                        </div>
                      </div>
                      <div class="row pb-1">
                        <div class="col-sm-5">{t("other_issues")}</div>
                        <div class="col-sm-7">
                          {" "}
                          :{" "}
                          <a href="mailto:info@memorizeitall.email">
                            info@memorizeitall.email
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="contact-form animated fadeInRight">
                  <div class="pb-3">{t("you_can_use_our_contact_form")}</div>
                  <form id="ctForm">
                    <input
                      type="text"
                      name="first_name"
                      placeholder={t("contact_first_name")}
                    />
                    <div
                      id="e_first_name"
                      style={{
                        display: "none",
                        backgroundColor: "red",
                        width: "400px",
                        "margin-top": "-15px",
                      }}
                    >
                      {t("contact_first_name_required")}
                    </div>
                    <input
                      type="text"
                      name="last_name"
                      placeholder={t("contact_family_name")}
                    />
                    <div
                      id="e_last_name"
                      style={{
                        display: "none",
                        backgroundColor: "red",
                        width: "400px",
                        "margin-top": "-15px",
                      }}
                    >
                      {t("contact_family_name_required")}
                    </div>
                    <input
                      type="text"
                      name="email_id"
                      placeholder={t("contact_email_address")}
                    />
                    <div
                      id="e_email_id"
                      style={{
                        display: "none",
                        backgroundColor: "red",
                        width: "400px",
                        "margin-top": "-15px",
                      }}
                    >
                      {t("contact_email_address_required")}
                    </div>
                    <select name="topic">
                      <option value="" selected disabled hidden>
                        {t("contact_topic")}
                      </option>
                      <option value={t("contact_topic_1")}>
                        {t("contact_topic_1")}
                      </option>
                      <option value={t("contact_topic_2")}>
                        {t("contact_topic_2")}
                      </option>
                      <option value={t("contact_topic_3")}>
                        {t("contact_topic_3")}
                      </option>
                      <option value={t("contact_topic_4")}>
                        {t("contact_topic_4")}
                      </option>
                      <option value={t("contact_topic_5")}>
                        {t("contact_topic_5")}
                      </option>
                      <option value={t("contact_topic_6")}>
                        {t("contact_topic_6")}
                      </option>
                      <option value={t("contact_topic_7")}>
                        {t("contact_topic_7")}
                      </option>
                    </select>
                    <div
                      id="e_topic"
                      style={{
                        display: "none",
                        backgroundColor: "red",
                        width: "400px",
                        "margin-top": "-15px",
                      }}
                    >
                      {t("contact_topic_required")}
                    </div>
                    {/* <input type="text" placeholder={t("topic")} /> */}
                    <textarea
                      name="message"
                      placeholder={t("your_comments_or_questions")}
                    ></textarea>
                    <div
                      id="e_message"
                      style={{
                        display: "none",
                        backgroundColor: "red",
                        width: "400px",
                        margin: "-15px 0 15px 0",
                      }}
                    >
                      {t("your_comments_or_questions_required")}
                    </div>
                  </form>
                  <div class="submit-btn">
                    <a
                      href="javascript:void(0)"
                      onClick={() => submitForm()}
                      class="btn-background  blue-bg-btn"
                    >
                      {t("contact_submit")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactUs;
