/* eslint-disable */

import React, { useEffect, useState } from "react";
//import { useTranslation } from 'react-i18next';
import i18next from "i18next";
// import "../images/favicon.ico"
import axios from "axios";
import dashlogo from "../images/dash-logo.png";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/login.css";
import "../less/releway-font.css";
import LanguagesComponent from "./LanguagesComponent";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

export function LoginHeader(props) {
  console.log("props", props);
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken()

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});
  const loginLanguage = cookies["login_language"]; //JSON.parse(getUserLoginLanguage())
  console.log("====i18next.language===", i18next.language);
  console.log("====login_language===", loginLanguage);
  let loginLangcode;
  if (loginLanguage) {
    loginLangcode = loginLanguage.lang_code;
  } else if (language) {
    loginLangcode = language.lang_code;
  } else {
    loginLangcode = i18next.language;
  }
  console.log("loginheader language.lang_code=", loginLangcode);
  const setCookieLoginLanguage = (loginLanguage) => {
    let exp_time = cookies["expiry_time"];
    if (!exp_time) {
      let exp = 30 * 24 * 60 * 60;
      console.log("in setCookieLoginLanguage", exp);
      exp_time = new Date();
      exp_time.setTime(exp_time.getTime() + exp * 1000);
    }
    setCookie("login_language", JSON.stringify(loginLanguage), {
      path: "/",
      exp_time,
    });
  };
  useEffect(() => {
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: loginLangcode,
      },
    })
      .then((response) => {
        console.log("loginheader languages", response.data.data);
        response.data.data.forEach((languageObj) => {
          console.log("i18next.language", i18next.language);
          if (languageObj.lang_code === i18next.language) {
            console.log("here");
            setCookieLoginLanguage(languageObj);
            //setUserLoginLanguage(languageObj)
            setLanguage(languageObj);
          }
        });
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, [props.refresh]);

  useEffect(() => {
    if (props.fromLandingPage) {
      //do nothing
      document.getElementById("id_login_button").style.display = "block";
    } else {
      document.getElementById("id_login_button").style.display = "none";
    }
  }, []);

  const setCookieGlobalLanguage = (globalLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    console.log("in setCookieGlobalLanguage", exp);
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("global_language", JSON.stringify(globalLanguage), {
      path: "/",
      expires,
    });
  };

  const handelLanguageChange = (language) => {
    console.log(language);
    setCookieGlobalLanguage(language);
    //Do not set the cookie login_language here,
    //since already in LanguageComponent.

    //setUserLoginLanguage(language)

    setLanguage(language);
    window.location.reload();
  };
  const handleLoginClick = () => {
    props.history.push("/login");
  };

  return (
    <React.Fragment>
      <section class="navtop animated fadeInDown">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="social-icons">
                <a
                  href="https://www.facebook.com/memorizeitall"
                  target="_blank"
                  class="facebook"
                >
                  <span class="icon-facebook"></span>
                </a>
                <a
                  href="https://twitter.com/memorizeitall"
                  target="_blank"
                  class="twitter"
                >
                  <span class="icon-twitter"></span>
                </a>
                <a
                  href="https://linkedin.com/company/memorizeitall"
                  target="_blank"
                  class="linkedin"
                >
                  <span class="icon-linkedin"></span>
                </a>
                {/* <a href="javascript:void(0)" class="instagram"><span class="icon-instagram"></span></a> */}
              </div>
            </div>
            <div class="col-6 right-drop">
              <div class="language mob_disp">
                {/* <div class="dropdown">
                            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                           
                                {language ?
                                    (
                                        <React.Fragment>

                                            <img  height='30%'  width='30%' src={(COUNTRY_FLAG + language.flag)} alt="Img"></img> 
                                            <span class="marR02">{language.name}</span>
                                        </React.Fragment>)
                                : null}
                            </a>
                            <Languages languages={languages} onSelectLanguage={handelLanguageChange}/>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="navtop animated fadeInDown">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <nav className="navbar navbar-expand-lg navbar-dark memorize-navbar animated fadeInDown">
                <div className="container">
                  {/* <!-- Brand --> */}
                  <a className="navbar-brand" href="javascript:void(0)">
                    <img src={dashlogo} />
                    {/* <p>{t('app_name')}</p> */}
                    <p>
                      Memorize<span>it</span>all
                    </p>
                  </a>
                </div>
              </nav>
            </div>
            <div className="col-6 right-drop">
              <div className="language">
                <div className="dropdown">
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  >
                    {language ? (
                      <React.Fragment>
                        <img
                          height="30%"
                          width="30%"
                          src={COUNTRY_FLAG + language.flag}
                          alt="Img"
                        ></img>{" "}
                        <span>{language.name}</span>
                      </React.Fragment>
                    ) : null}
                  </a>
                  <LanguagesComponent
                    languages={languages}
                    onSelectLanguage={handelLanguageChange}
                  />
                </div>
              </div>
            </div>
            <a
              id="id_login_button"
              onClick={handleLoginClick}
              href="javascript:void()"
              className="login blue-bg-btn"
            >
              {t("login")}
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default LoginHeader;
