/* eslint-disable */ 
import React, {useState, useEffect,useContext} from 'react'
import {useHistory} from 'react-router-dom'
import qs from 'qs'
import "../less/my-credits.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/quiz-creation.css"
// import "../less/owl.carousel.min.css"
import "../less/attributes.css"

import axios from 'axios';



import { useTranslation } from 'react-i18next';
import {Context } from '../Reducers/Store'
import { useCookies } from "react-cookie";

function SelectAttributes(props) {

    console.log("attributes", props)

    const { t } = useTranslation()
    const [cookies, setCookie] = useCookies(['access_token', 'user','global_language', 'login_language'])

    const [state, dispatch] = useContext(Context)

    const user = cookies['user'] //getUser();
    const user_type_id = user.user_type_id;
    console.log('user_type_id',user_type_id)
    const token = cookies['access_token'] // getToken();
    const globalLang = cookies['global_language'] //JSON.parse(getUserGlobalLanguage());
    let globalLangCode = globalLang.lang_code;
    let globalLangId = globalLang.id;

    const [quizType, setQuizType] = useState(2);
    const [quizMode, setQuizMode] = useState(1);
    const [amountOfCredits,setAmountOfCredits] = useState("")
    
    const [complexity, setComplexity] = useState(3);
    const [languages, setLanguages] = useState()
    const [primaryLanguageId, setPrimaryLanguageId] = useState()
    const [primaryLanguage, setPrimaryLanguage] = useState()
    const [availableLanguages, setAvailableLangauges] = useState([])
    const [availableLanguageIds, setAvailableLangaugeIds] = useState([])

    const [myGroups, setMyGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState()

    const [contentTypes, setContentTypes] = useState()
    const [contentModes, setContentModes] = useState()
    const [contentComplexities, setContentComplexities] = useState()

    useEffect (() => {
        dispatch({type: 'UPDATE_CURRENT_MENU',payload:'id_attributes_page'})
    }, [])
    useEffect(() => {
       
        axios({
            method: 'get',
            url: '/api/language/list',
            headers:{
                'locale': globalLangCode,
            },
        })
        .then(response => {
        console.log("languages", response.data.data)
        setLanguages(response.data.data)
        // setUserSession(token, response.data.user);
        }).catch(error => {
        // removeUserSession();
        });

        axios({
            method: 'post',
            url: '/api/groups/list/all',
            headers:{
                'locale': "en",
                'x-access-token': "Memo "+token,
            }
        })
        .then(response => {
        console.log("response", response)
        setMyGroups(myGroups => myGroups.concat(response.data.data));
        }).catch(error => {
        });

        axios({
            method: 'get',
            url: '/api/qd_types/list',
            headers:{
                'Content-Type' : 'application/x-www-form-urlencoded',
                'x-access-token': "Memo "+token,
                'locale': globalLangCode,
            }
        })
        .then(response => {
        console.log("qd types", response.data.data)
        setContentTypes(response.data.data)
        // setUserSession(token, response.data.user);
        }).catch(error => {
        // removeUserSession();
        });

        axios({
            method: 'get',
            url: '/api/qd_modes/list',
            headers:{
                'Content-Type' : 'application/x-www-form-urlencoded',
                'x-access-token': "Memo "+token,
                'locale': globalLangCode,
            }
        })
        .then(response => {
        console.log("qd modes", response.data.data)
        setContentModes(response.data.data)
        // setUserSession(token, response.data.user);
        }).catch(error => {
        // removeUserSession();
        });

        axios({
            method: 'get',
            url: '/api/qd_complexities/list',
            headers:{
                'Content-Type' : 'application/x-www-form-urlencoded',
                'x-access-token': "Memo "+token,
                'locale': globalLangCode,
            }
        })
        .then(response => {
        console.log("qd complexities", response.data.data)
        setContentComplexities(response.data.data)
        // setUserSession(token, response.data.user);
        }).catch(error => {
        // removeUserSession();
        });
    }, [])

    useEffect(() => {
       if(myGroups.length>0){
           setSelectedGroup(myGroups[0].group_id)
       } 
    }, [myGroups])

    useEffect(() => {
        if (quizMode == 3){
            document.getElementById("amount-block").style.display = "block"
        } else {
            document.getElementById("amount-block").style.display = "none"
        }
    }, [quizMode, quizType, complexity])

    const showErrorMessage = (index) => {
        switch (index) {
            case 0: //Primary language error.
                document.getElementById("errorMessage").innerHTML = ""
                break;
            case 1: //Paid content must have no. of credits.
                document.getElementById("errorMessage").innerHTML = t("please_enter_credits_for_content")
                document.getElementById("errorMessage").style.display = "block"
                break;
            case 2:
                document.getElementById("errorMessage").innerHTML = t("please_select_language")
                document.getElementById("errorMessage").style.display = "block"
                break;
            case 3:
                document.getElementById("errorMessage").innerHTML = t("please_enter_credits_as_number")
                document.getElementById("errorMessage").style.display = "block"
                break;
        }

    }
    const goToCreateContentPage = () => {
        let group_id
        if(quizType == 3){
            group_id = selectedGroup
        }else{
            group_id = null
        }

        if(!availableLanguages.length) {
            showErrorMessage(2)
            return
        } else if (quizMode == 3 && !amountOfCredits){
            showErrorMessage(1)
            return
        } else if (quizMode == 3 && amountOfCredits) {
            let validPossitiveInt = ['0','1','2','3','4','5','6','7','8','9']
            let arrCredits = amountOfCredits.split("");
            console.log('arrCredits',arrCredits)
            for(let i=0; i < arrCredits.length; i++) {
                if(!validPossitiveInt.includes(arrCredits[i])) {
                    showErrorMessage(3)
                    return
                }
            }
        }
        showErrorMessage(0)
        if(quizMode == 3){
            console.log("paid")
            
            props.history.push({
                pathname: "/selectcategories",
                state: {
                    quizType:quizType,
                    quizMode:quizMode,
                    groupId:group_id,
                    complexity:complexity,
                    primaryLanguage : primaryLanguage,
                    availableLanguages: availableLanguages,
                    amount: amountOfCredits
                }
            })
            
        } else {
            console.log("free")
            //console.log('primaryLanguage',primaryLanguage)
            
            props.history.push({
                pathname: "/selectcategories",
                state: {
                    quizType:quizType,
                    quizMode:quizMode,
                    groupId:group_id,
                    complexity:complexity,
                    primaryLanguage : primaryLanguage,
                    availableLanguages: availableLanguages,
                }
            })
        }
        
       
    }

    /*
    const primaryLanguageSelection = (id, name, code, flag) => {
        console.log('id',id)
        setPrimaryLanguageId(id)
        setPrimaryLanguage({
            id:id,
            name:name,
            code:code,
            flag:flag
        })
    }
    */
    const languageAddition = (id, name, code, flag) => {
        let remove = false;
        let add = false;
        let tempArray = [];
        let tempIdArray = [];
        if (availableLanguages.length > 0) {
            availableLanguages.map(language => {
                if (language.id === id) {
                    remove = true;
                } else {

                    tempArray.push({
                        id: language.id,
                        name: language.name,
                        code: language.code,
                        flag: language.flag
                    })
                    tempIdArray.push(language.id)

                }
            })
        }
        if (remove == false) {

            tempArray.push({
                id: id,
                name: name,
                code: code,
                flag: flag
            })
            tempIdArray.push(id)

        }
        setAvailableLangauges(tempArray)
        setAvailableLangaugeIds(tempIdArray)
    }

    useEffect(()=>{
        if(!availableLanguages || !availableLanguages.length) return
        let setPrimary = false
        for(let i=0; i<availableLanguages.length; i++) {
            if(globalLangId == availableLanguages[i].id) {
                setPrimary = true
                setPrimaryLanguage(availableLanguages[i])
                
            }
        }
        if(!setPrimary) {
            setPrimaryLanguage(availableLanguages[0])
        }
    },[availableLanguages])

    const groupSelection = (id) => {
        setSelectedGroup(id)
     }

     const showRestrictedMessage = () => {
        console.log("showRestrictedMessage")
        document.getElementById('id-restrictedModal').click()
    }
    return (
        <React.Fragment>
            {/* <BasicHeader 
                {...props}
            /> */}
             <div className="inner-header">
        <div className="container">
            <div className="row">
                <div className="col-sm-7 col-md-6 col-lg-6">
                    <div className="breadcrumb-item animated fadeInRight">
                        <ul>
                            <li><a href="javascript:void(0)"><span className="icon-home1"></span> {t("home")}</a></li>
                            <li><span className="icon-next"></span></li>
                            <li><a href="javascript:void(0)" className="active">{t("create_content")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="attributes-wrapper contentIconWrapper">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-10 col-md-8 col-lg-7">
                    <div className="attributes-box animated fadeInRight">
                        <div className="inner-space">
                            <h4>{t('please_select_content_type')}</h4>
                           
                            <div className="row row-eq-height">
                                <div className="col-sm-4 col-md-4 col-lg-3"
                                    onClick={() => setQuizType(2)}
                                >
                                    <a href="javascript:void(0)" className={quizType == 2 ? "inner-boxs active" : "inner-boxs"}>
                                        <span className='contentIcon'>
                                        <span class="icon icon-globe"></span>
                                        </span>
                                        <span>{t("publicTitle")}</span>
                                    </a>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-3"
                                    onClick={() => setQuizType(1)}
                                >
                                    <a href="javascript:void(0)" className={quizType == 1 ? "inner-boxs active" : "inner-boxs"}>
                                        <span className='contentIcon'>
                                        <span class="icon icon-lock"></span>
                                        </span>
                                        <span>{t("Private")}</span>
                                    </a>
                                </div>
                                {
                                    user_type_id == '2' //|| user_type_id === '3' 
                                    ?
                                    (
                                        <div className="col-sm-4 col-md-4 col-lg-3"
                                        onClick={() => showRestrictedMessage()}
                                        >
                                            <a href="javascript:void(0)" className={quizType == 3 ? "inner-boxs active" : "inner-boxs"}
                                                data-toggle="modal" 
                                                data-backdrop="static"
                                                data-target="#restrictedModal" 
                                                id={"id-restrictedModal"}>
                                                <span className='contentIcon'>
                                                <span class="icon icon-m-group"></span>
                                                </span>
                                                <span>{t("group")}</span>
                                                <span class="icon-circle-minus1 restrictIcon"></span>
                                            </a>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="col-sm-4 col-md-4 col-lg-3"
                                        onClick={() => setQuizType(3)}
                                        >
                                            <a href="javascript:void(0)" className={quizType == 3 ? "inner-boxs active" : "inner-boxs"}>
                                                <span className='contentIcon'>
                                                <span class="icon icon-m-group"></span>
                                                </span>
                                                <span>{t("group")}</span>
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            quizType === 3 
                            ?
                            (
                                <div className="inner-space">
                                    <h4>{t('select_group')}</h4>
                                
                                    <div className="row row-eq-height">
                                        <select id="myGroupsList"
                                            onChange={(e)=>groupSelection(e.target.value)}
                                        >
                                            {
                                                myGroups.length > 0 ?
        
                                                (
                                                    myGroups.map(group => (
                                                        <option value={group.group_id}>{group.name}</option>
                                                    ))
                                                )
                                                :
                                                null
                                            }
                                        </select>
                                        
                                    </div>
                                </div>
                           
                            )
                            :
                            null
                        }
                       
                        <div className="inner-space">
                            <h4>{t('please_select_content_mode')}</h4>
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-3"
                                    onClick={() => setQuizMode(1)}
                                >
                                    <a href="javascript:void(0)" className={quizMode == 1 ? "inner-boxs active" : "inner-boxs"}>
                                        <span className='contentIcon'>
                                        <span class="icon icon-free3"></span>
                                        </span>
                                        <span>{t("free")}</span>
                                    </a>
                                </div>

                                {
                                    user_type_id === '2' || user_type_id === '3' 
                                    ?
                                    (
                                        <div className="col-sm-4 col-md-4 col-lg-3"
                                            onClick={() => showRestrictedMessage()}
                                        >
                                            <a href="javascript:void(0)" className={quizMode == 3 ? "inner-boxs active" : "inner-boxs"}
                                                 data-toggle="modal" 
                                                 data-backdrop="static"
                                                 data-target="#restrictedModal" 
                                                 id={"id-restrictedModal"}>
                                                <span className='contentIcon'>
                                                <span class="icon icon-paid3"></span>
                                                </span>
                                                <span>{t('paid')}</span>
                                                <span class="icon-circle-minus1 restrictIcon"></span>
                                            </a>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="col-sm-4 col-md-4 col-lg-3"
                                            onClick={() => setQuizMode(3)}
                                        >
                                            <a href="javascript:void(0)" className={quizMode == 3 ? "inner-boxs active" : "inner-boxs"}>
                                                <span className='contentIcon'>
                                                <span class="icon icon-paid3"></span>
                                                </span>
                                                <span>{t('paid')}</span>
                                                
                                            </a>
                                        </div>
                                    )
                                }
                                {/* 
                                <div className="col-sm-4 col-md-4 col-lg-3"
                                    onClick={() => setQuizMode(2)}
                                >
                                    <a href="javascript:void(0)" className={quizMode == 2 ? "inner-boxs active" : "inner-boxs"}>
                                        <span class="icon icon-invite"></span>
                                        <span>{t("invite")}</span>
                                    </a>
                                </div>
                                */}
                            </div>
                        </div>
                        <div className="inner-space" id="amount-block" style={{display:"none"}}>
                            <h4>{t('amt')}</h4>
                            <input id="amountValue" type="text" placeholder={t('enter_content_amount')}
                            value={amountOfCredits}
                            onChange={e => setAmountOfCredits(e.target.value)}/>
                        </div>
                        <div className="inner-space">
                            <h4>{t("please_select_level")}</h4>
                            <div className = "row b-buttons">
                                {
                                    contentComplexities ? 
                                    (
                                        contentComplexities.length > 0 ?
                                        contentComplexities.map(complex => (
                                            <div className="col-sm-12 col-md-12 col-lg-4" 
                                                onClick={() => setComplexity(complex.id)}
                                            >
                                                <a href="javascript:void(0)" className={complex.id == complexity ?"btn-border blue-bg-btn btn-block active" : "btn-border blue-bg-btn btn-block"}>{complex.name}</a>
                                            </div>
                                        ))
                                        :
                                        null
                                    )
                                    :
                                    null
                                }
                            </div>
                            {/* <div className="row b-buttons">
                                <div className="col-sm-12 col-md-12 col-lg-4" 
                                     onClick={() => setComplexity(3)}
                                >
                                    <a href="javascript:void(0)" className={complexity == 3 ?"btn-border btn-block active" : "btn-border btn-block"}>{t("Begineer")}</a>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4"
                                    onClick={() => setComplexity(2)}
                                >
                                    <a href="javascript:void(0)" className={complexity == 2 ?"btn-border btn-block active" : "btn-border btn-block"}>{t("Intermediate")}</a>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4"
                                    onClick={() => setComplexity(1)}
                                >
                                    <a href="javascript:void(0)" className={complexity == 1 ?"btn-border btn-block active" : "btn-border btn-block"}>{t("expert")}</a>
                                </div>
                            </div> */}
                        </div>
                        <div className="inner-space">
                            <h4>{t("please_select_language")}</h4>
                            <div className="row b-buttons">
                                {
                                    languages ? 
                                    (
                                        languages.map(language => (
                                            <div className="col-sm-12 col-md-12 col-lg-3" 
                                                onClick={() =>languageAddition(language.id, language.name, language.lang_code, language.flag)}
                                            >
                                                <a href="javascript:void(0)" className={availableLanguageIds.includes(language.id) ? "btn-border blue-bg-btn btn-block active" : "btn-border blue-bg-btn btn-block"}>{t(language.name)}</a>
                                            </div>
                                        ))
                                    )
                                    :
                                    null
                                }
                                
                            </div>
                        </div>
                        <div 
                        id="errorMessage"
                        style={{display:"none", backgroundColor:"red", width:"400px"}}>
                            {t("please_select_language")}
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-4"
                                onClick={goToCreateContentPage}
                            >
                                <a href="javascript:void(0)" className="btn-background btn-block blue-bg-btn">
                                    {/* {t("next_in_attributes_page")}  */}
                                    <span className="icon-next"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="restrictedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{t('access_restricted')}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{t('upgrade_your_membership')}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>
    {/* <BasicFooter /> */}
   
        </React.Fragment>
    )
}

export default SelectAttributes
