/* eslint-disable */
import React, { useEffect, useImperativeHandle, useState } from "react";
import S3 from "react-aws-s3";

import axios from "axios";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { showErrorMessageToUser } from "../Utils/toastr";

const EditAnswerOptions = React.forwardRef((props, ref) => {
  const {
    languages,
    answerLangDetails,
    currentLanguage,
    optionName,
    optionIdFor,
    optionNumber,
    answerTextId,
    answerMode,
    answerTypeId,
    questionId,
    optionNo,
  } = props;
  //console.log('answerMode=' + answerMode)
  console.log("props::: ", props);
  // const [answerModeState, setAnswerModeState] = useState(answerMode)
  //console.log("in edit answer options")
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken();

  console.log("answerLangDetails::: ", optionNo, answerLangDetails);

  const [answerFiles, setAnswerFile] = useState([]);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ANSWERS_PATH = awsImageUrl + "/uploads/answers/";
  const config = {
    bucketName: "memoriz",
    dirName: "uploads/answers" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "vFDqjA3FjLkiSycSB3stxO4m3mJpNCx7jpqwdpjE",
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
  };

  const ReactS3Client = new S3(config);

  const uploadAnswerFileToLocal = (event) => {
    console.log("uploadAnswerFileToLocal answerMode", answerMode);
    //console.log('here', answerMode, optionNo)
    let tempanswerFiles = [];
    if (answerFiles && answerFiles.length > 0) {
      console.log("answerFiles.length=", answerFiles.length);
      //Does it exists languageId
      for (let i = 0; i < languages.length; i++) {
        //console.log('element', element)
        if (languages[i].id == currentLanguage.id) {
          console.log("currentLanguage.id=" + currentLanguage.id);
          console.log("file=", event.target.files[0]);
          tempanswerFiles.push({
            languageId: currentLanguage.id,
            file: event.target.files[0],
          });
        } else {
          console.log("copying existing answers edited");
          if (answerFiles.length > 0) {
            for (let j = 0; j < answerFiles.length; j++) {
              if (languages[i].id == answerFiles[j].languageId) {
                console.log("har har");
                tempanswerFiles.push(answerFiles[j]);
              }
            }
          }
        }
      }
    } else {
      tempanswerFiles.push({
        languageId: currentLanguage.id,
        file: event.target.files[0],
      });
    }
    setAnswerFile(tempanswerFiles);

    if (answerMode == 3) {
      console.log("answerMode == 3");
      var image = document.getElementById(
        "answerImagePreview_" + currentLanguage.id + "_" + optionNo
      );
      image.src = URL.createObjectURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    console.log("answerFiles=", answerFiles);
  }, [answerFiles]);

  useImperativeHandle(ref, () => ({
    async validate(questionId, optionNumber) {
      return new Promise((resolve, reject) => {
        console.log(questionId, optionNumber);
        if (answerFiles && answerFiles.length > 0 && answerMode !== 2) {
          console.log("I'm clicked");
          let fileUploadPromises = [];
          for (let i = 0; i < languages.length; i++) {
            for (let j = 0; j < answerFiles.length; j++) {
              if (languages[i].id == answerFiles[j].languageId) {
                const newFileName =
                  "answer_" +
                  languages[i].code +
                  "_" +
                  optionNumber +
                  "_" +
                  questionId;
                console.log("newFileName", newFileName);
                /*
                      await ReactS3Client.uploadFile(answerFile, newFileName)
                      .then(data => console.log(data))
                      .catch(err => console.error(err))  
                      */
                const fd = new FormData();
                fd.append("file", answerFiles[j].file);
                fd.append("filename", newFileName);
                console.log("answerFile", answerFiles[j].file);

                // For Media Type
                if (answerMode == 3) {
                  var media_type = 1; // Image File
                } else if (answerMode == 4) {
                  var media_type = 2; // Audio File
                } else if (answerMode == 5) {
                  var media_type = 3; // Video File
                } else {
                  var media_type = 0; // Text
                }
                fileUploadPromises.push(
                  axios({
                    method: "post",
                    url:
                      "/api/answers/upload/image?filename=" +
                      newFileName +
                      "&media_type=" +
                      media_type,
                    headers: {
                      locale: "en",
                      "x-access-token": "Memo " + token,
                      "Content-Type": "multipart/form-data",
                    },
                    data: fd,
                  })
                );
              }
            }
          }
          Promise.all(fileUploadPromises)
            .then(() => {
              console.log("success uploading answers");
              resolve();
            })
            .catch((error) => {
              console.log("error", error.response.data[0].msg);
              showErrorMessageToUser(error.response.data[0].msg);
              reject(error.response.data[0].msg);
              //throw new Error(error.response.data[0].msg)
            });
        } else {
          resolve();
        }
      });
    },
  }));

  const createMarkup = (language, optionNo) => {
    console.log("text", language.answerDetails[optionNo - 1].text);
    //fontSize: "36px",fontFamily: 'arial'
    return {
      __html:
        `<input type='text' placeholder='Enter Text' style={{display:'block'}}
                 id= '` +
        `answerText_` +
        language.languageId +
        "_" +
        optionNo +
        `'` +
        ` value='` +
        language.answerDetails[optionNo - 1].text +
        `'` +
        `/>`,
    };
  };
  return (
    <React.Fragment>
      <div class="option-list">
        <div class="custom-control custom-radio">
          {answerLangDetails
            ? answerLangDetails.map((language) => (
                <input
                  type="radio"
                  class="custom-control-input"
                  id={"option" + optionNo}
                  name="Option"
                  value="Option"
                  defaultChecked={
                    language.answerDetails[optionNo - 1].is_right_answer === 1
                      ? true
                      : false
                  }
                  // checked={language.answerDetails[optionNo-1].is_right_answer === 1 ? true : false}
                />
              ))
            : null}

          <label class="custom-control-label" for={"option" + optionNo}>
            {t("option") + optionNo}
          </label>
        </div>
        <div class="inner-option">
          <div class="select-txt">(Select this as correct answer for text)</div>

          {/* {
                                                              languages ? 
                                                              languages.map(language => (
                                                                <div class="option-input"
                                                                   style = {currentLanguage.id === language.id ? {display:"block"}:{display:"none"}}
                                                                >
                                                                <input type="text"
                                                                    style={{display:"block"}}
                                                                    id={"answerText_"+language.id+"_"+ optionNo} 
                                                                />
                                          
                                                                </div>
                                                              ))
                                                              :
                                                              null
                                                    } */}
          {answerLangDetails
            ? answerLangDetails.map((language) => (
                <div
                  class="option-input"
                  style={
                    currentLanguage.id === language.languageId
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {/*  defaultValue={language.answerDetails[optionNo-1].text} */}
                  <div
                    dangerouslySetInnerHTML={createMarkup(language, optionNo)}
                  />
                  {/*  
                                                                <input type="text"
                                                                    style={{display:"block"}}
                                                                    id={"answerText_"+language.languageId+"_"+ optionNo} 
                                                                    defaultValue={language.answerDetails[optionNo-1].text}
                                                                    
                                                                    placeholder="Enter Text"
                                                                />
                                                                */}
                </div>
              ))
            : null}

          {answerLangDetails
            ? answerLangDetails.map((language) =>
                language.answerDetails[optionNo - 1].media_type == 1 ||
                language.answerDetails[optionNo - 1].media_type == 2 ||
                language.answerDetails[optionNo - 1].media_type == 3 ? (
                  <div
                    class="option-input"
                    style={
                      currentLanguage.id === language.languageId
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <input
                      type="file"
                      name="file"
                      onChange={uploadAnswerFileToLocal}
                      style={{ display: "block" }}
                      id={
                        "uploadButton_" + language.languageId + "_" + optionNo
                      }
                    />
                    {/* <label for={"uploadButton"+optionNo} >{answerFile ? answerFile.name : language.answerDetails[optionNo-1].file_path}</label> */}

                    {/*<span>{answerFiles ? '' : language.answerDetails[optionNo-1].file_path}</span> */}
                    <span>
                      {answerFiles && answerFiles.length > 0
                        ? ""
                        : language.answerDetails[optionNo - 1].file_path}
                    </span>
                    <img
                      id={
                        "answerImagePreview_" +
                        language.languageId +
                        "_" +
                        optionNo
                      }
                      width="200px"
                      style={{ display: "none" }}
                      src={
                        ANSWERS_PATH +
                        language.answerDetails[optionNo - 1].file_path
                      }
                    />
                    {/* <label>{(ANSWERS_PATH + language.answerDetails[optionNo-1].file_path )}</label> */}
                  </div>
                ) : null
              )
            : null}

          {/*
                                                      <input type="file" name="file" 
                                                            
                                                           onChange={uploadAnswerFileToLocal}
                                                          style={{display:"none"}}
                                                            id = {"uploadButton"+optionNo}
                                                            
                                    
                                                        />
                                                        <img 
                                                        id={"answerImagePreview"+optionNo} width="200px" 
                                                        style={{display:"none"}}
                                                        src={(ANSWERS_PATH)}
                                                    /> 
                                                  
                                                  */}
        </div>
      </div>
    </React.Fragment>
  );
});

export default EditAnswerOptions;
