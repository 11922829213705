/* eslint-disable */

import React, { useEffect, useState } from "react";

import axios from "axios";
import qs from "qs";

import { useCookies } from "react-cookie";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";

function GroupMemberComponent(props) {
  //console.log('GroupMemberComponent.props', props)

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const user = cookies["user"]; //getUser();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [organization, setOrganization] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [profile_pic_filename, setProfile_pic_filename] = useState("");
  const [profilePicUploaded, setProfilePicUploaded] = useState(0);
  const [open_close, setOpenClose] = useState(0); //Close = 0
  const [owner_name, setOwnerName] = useState("");

  const [members, setMembers] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  //Set the member to delete, from modal dialog.
  const [toDeleteMember, setToDeleteMember] = useState(null);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fetchMembers = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/groups/users/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        term: query,
        page: currentPage,
      }),
    })
      .then((response) => {
        console.log("/groups/users/list response", response.data.data);
        setMembers(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchMembers(data.currentPage);
    setCurrent(data.currentPage);
  };

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/groups/" + props.group_id,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        let group_details = response.data.data;
        console.log("/api/groups/ response.data", response.data.data);
        setGroupName(group_details.group_name);
        setGroupDesc(group_details.description);
        setOrganization(group_details.organization);
        setProfile_pic(group_details.profile_pic);
        setProfile_pic_filename(group_details.profile_pic);
        setOpenClose(group_details.open_close);
        setOwnerName(group_details.owner_name);
      })
      .catch((error) => {});

    fetchMembers();
  }, [props.refreshMembers]);

  const onInviteUserClick = (evt) => {
    let email = document.getElementById("email_to_invite").value.trim();
    console.log("email", email);
    axios({
      method: "post",
      url: "/api/groups/invite/userswithemail",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: props.group_id,
        email: email,
        invited_to_become: 0, //group member
      }),
    })
      .then((response) => {
        document.getElementById("id-close-inviteUserModal").click();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onDeleteMemberClick = (yes_no) => {
    console.log("onDeleteMemberClick");
    if (yes_no == 0) {
      document.getElementById("id-close-deleteMemberModal").click();
      setToDeleteMember(null);
      return;
    }
    axios({
      method: "post",
      url: "/api/groups/users/delete",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: toDeleteMember.group_id,
        user_id_to_delete: toDeleteMember.user_id,
      }),
    })
      .then((response) => {
        document.getElementById("id-close-deleteMemberModal").click();
        setToDeleteMember(null);
        fetchMembers();
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  const onMemberContentClick = (member) => {
    axios({
      method: "post",
      url: "/api/groups/users/toggle/cancreatecontent",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: member.group_id,
        user_id_to_toggle: member.user_id,
      }),
    })
      .then((response) => {
        fetchMembers(current);
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };
  const onMemberPostNewsClick = (member) => {
    axios({
      method: "post",
      url: "/api/groups/users/toggle/cancreatenews",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        group_id: member.group_id,
        user_id_to_toggle: member.user_id,
      }),
    })
      .then((response) => {
        fetchMembers(current);
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessageToUser(error.response.data.msg);
      });
  };

  const onInviteClick = () => {
    document.getElementById("id-inviteUserModal").click();
  };

  const onMemberDeleteClick = (member) => {
    setToDeleteMember(member);
    document.getElementById("deleteMemberModal").click();
  };
  const onDeleteMemberCloseClick = () => {
    console.log("onDeleteMemberCloseClick called");
    setToDeleteMember(null);
    document.getElementById("id-close-deleteMemberModal").click();
  };
  const handleSearchChange = (event) => {
    const search_query = event.target.value;

    console.log("search_query=" + search_query);

    if (!search_query) {
      //Members Reset.
      setTotalPages(0);
      setTotal(0);
      setMembers([]);
      setQuery(search_query);
    } else {
      setQuery(search_query);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (loading == true) {
      console.log("calling fetchMembers...");
      fetchMembers();
    }
  }, [loading, query]);

  return (
    <React.Fragment>
      <div class="memberPanel memberNewsPanel ">
        <div class="position-relative mb-2 header">
          <h6 class="blueThemeText panelHeading">{t("group_members")}</h6>
          <div class="d-flex align-items-center searchBox">
            <div class="position-relative">
              <a
                class="actionIcon inviteUserAction  mr-1 cursor-pointer"
                data-toggle="modal"
                data-backdrop="static"
                data-target="#inviteUserModal"
                id="id-inviteUserModal"
                onClick={() => onInviteClick()}
              >
                <span class="icon icon-plus"></span>
              </a>
            </div>
            <div class="search_content">
              {/* <i class="fa fa-search"></i> */}
              <DebounceInput
                minLength={1}
                debounceTimeout={500}
                onChange={(event) => handleSearchChange(event)}
                className="inputBox border-box w-100"
                placeholder={t("search_here")}
              />
              {/* 
                            <input
                              type="text"
                              value={query}
                              name="search"
                              id="search-input"
                              placeholder={t("search_here")}
                              onChange={(e) => handleSearchChange(e)}
                          />
                          */}
            </div>
            {/* <input type="text" placeholder="Search user" class="inputBox border-box w-100"></input> */}
          </div>
        </div>
        <div class="memberList">
          {members
            ? members.map((member) => (
                <div class="memberBox position-relative">
                  <div class="memberDetail">
                    <div class="photo">
                      <img src={PROFILE_PIC + member.profile_pic} alt=""></img>
                    </div>
                    <div class="info ml-2">
                      <span class="d-block mb-1">{member.name}</span>
                      <span class="mailId">{member.email_id}</span>
                    </div>
                  </div>
                  <div class="actionBtns d-flex">
                    <a
                      href="javascript:void(0)"
                      class={
                        member.can_create_content
                          ? "actionIcon"
                          : "actionIcon noAccess"
                      }
                      onClick={() => onMemberContentClick(member)}
                      title={
                        member.can_create_content
                          ? t("disable_content_creation")
                          : t("enable_content_creation")
                      }
                    >
                      <span class="icon icon-m-create"></span>
                    </a>
                    {/*                                        
                                        <a href="javascript:void(0)" class={member.can_post_news ? "actionIcon middleActionIcon" : "actionIcon middleActionIcon noAccess"}
                                            onClick={()=>onMemberPostNewsClick(member)}
                                            title={member.can_post_news ? t('disable_news_creation') : t('enable_news_creation')}>
                                            <span class="icon icon-news text-white"></span>
                                        </a>
                                         */}

                    <a
                      href="javascript:void(0)"
                      class="actionIcon ml-1"
                      data-toggle="modal"
                      data-target="#deleteMemberModal"
                      data-backdrop="static"
                      onClick={() => onMemberDeleteClick(member)}
                      title={t("delete_member_from_group")}
                    >
                      <span class="icon icon-close1"></span>
                    </a>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div class="text-center pt-4 memberNewsPaging">
          {total > PAGE_LIMIT ? (
            <div className="d-flex flex-row py-4 align-items-center justify-content-center">
              <Pagination
                totalRecords={total}
                totalPages={totalPages}
                currentPage={current}
                pageLimit={PAGE_LIMIT}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div id="deleteMemberModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                {t("delete_user") +
                  " " +
                  (toDeleteMember ? toDeleteMember.email_id : null)}{" "}
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="id-close-deleteMemberModal"
                onClick={() => onDeleteMemberCloseClick()}
              >
                <span className="icon icon-close1"></span>
              </button>
            </div>
            <div class="modal-body text-center">
              <span> {t("are_you_sure_to_delete_user")}</span>
            </div>
            <div class="modal-footer">
              <a
                href="javascript:void(0)"
                class="btn btn-default blue-bg-btn"
                data-dismiss="modal"
                onClick={() => onDeleteMemberClick(0)}
              >
                No
              </a>

              <a
                href="javascript:void(0)"
                class="btn btn-default blue-bg-btn"
                onClick={() => onDeleteMemberClick(1)}
              >
                Yes
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        role="dialog"
        id="inviteUserModal"
        data-dismiss="modal"
      >
        <div className="modal-dialog">
          <div class="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">{t("invite_user")}</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="id-close-inviteUserModal"
              >
                <span className="icon icon-close1"></span>
              </button>
            </div>
            <div className="modal-body">
              <div class="inviteUserPanel">
                <div class="shadowPanelInner">
                  <div class="inviteUserHeading position-relative d-flex">
                    <div class="pl-70px d-flex align-items-center mb-3">
                      <span class="inviteUserLogo">
                        <img
                          src={GROUPS_PATH + profile_pic_filename}
                          alt=""
                          class="img-fluid"
                        />
                      </span>
                      <h5 class="blueThemeText fw-600">{groupName}</h5>
                    </div>
                    <div class="inviteUserBox">
                      <a href="javascript:void(0)" class="inviteUser hoverIcon">
                        <span class="icon icon-add-user"></span>
                      </a>
                    </div>
                  </div>

                  <div class="organisationField mb-4">
                    <input
                      type="text"
                      placeholder={t("organization")}
                      class="w-100"
                      value={organization}
                    />
                  </div>
                  <div class="inviteUserText">
                    <span class="d-block mb-4 blueThemeText fw-600">
                      {t("invite_the_following")}
                    </span>
                    <div class="position-relative">
                      <input
                        type="text"
                        placeholder={t("input_email_address")}
                        class="w-100"
                        id="email_to_invite"
                      />
                      <a
                        href="javascript:void(0)"
                        class="addCloseIcon hoverIcon"
                        onClick={() => onInviteUserClick()}
                      >
                        <span class="icon icon-plus"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default GroupMemberComponent;
