/* eslint-disable */ 

import React from 'react'
import { useTranslation } from 'react-i18next';
import "../less/dashboard.css"
import "../less/bootstrap.min.css"
import "../less/releway-font.css"
import "../less/icon-font.css"
import "../less/animate.min.css"
import "../less/common.css"
import "../less/campaign.css"

import campaignimg1 from '../images/campaign/image1.png'
import campaignimg2 from '../images/campaign/image2.png'
import campaignimg3 from '../images/campaign/image3.png'
import campaignimg4 from '../images/campaign/image4.png'
import campaignimg5 from '../images/campaign/image5.png'

function Campaign(props) {

    const { t } = useTranslation()

    const goToDashboard = () => {
        props.history.push('/dashboard');
    }

    return (
        <React.Fragment>
            <div class="inner-header">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-6 col-lg-6">
                            <h2 class="animated fadeInLeft">{t("campaign")}</h2>
                        </div>
                        <div class="col-sm-7 col-md-6 col-lg-6">
                            <div class="breadcrumb-item animated fadeInRight">
                                <ul>
                                    <li onClick={goToDashboard}><a href="javascript:void(0)"><span class="icon-home1"></span> {t('home')}</a></li>
                                    <li><span class="icon-next"></span></li>
                                    <li><a href="javascript:void(0)" class="active">{t("campaign")}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="campaignList">
        <div class="container">
            <div class="custList">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <img src={campaignimg1} class="animated fadeInLeft" />
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12 d-flex align-items-center">
                        <div class="description animated fadeInRight">
                            <h3>Think you know about coronavirus(COVID-19)</h3>
                            <p>Synergistically foster fully tested ROI whereas interoperable expertise. Energistically maximize client-based products rather than accurate alignments. Appropriately.</p>
                            <h5>Start date : 23/09/2020 16:40</h5>
                            <h5>Start date : 23/09/2020 16:40</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <img src={campaignimg2} class="animated fadeInLeft" />
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12 d-flex align-items-center">
                        <div class="description animated fadeInRight">
                            <h3>Think you know about coronavirus(COVID-19)</h3>
                            <p>Synergistically foster fully tested ROI whereas interoperable expertise. Energistically maximize client-based products rather than accurate alignments. Appropriately.</p>
                            <h5>Start date : 23/09/2020 16:40</h5>
                            <h5>Start date : 23/09/2020 16:40</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <img src={campaignimg3} class="animated fadeInLeft" />
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12 d-flex align-items-center">
                        <div class="description animated fadeInRight">
                            <h3>Think you know about coronavirus(COVID-19)</h3>
                            <p>Synergistically foster fully tested ROI whereas interoperable expertise. Energistically maximize client-based products rather than accurate alignments. Appropriately.</p>
                            <h5>Start date : 23/09/2020 16:40</h5>
                            <h5>Start date : 23/09/2020 16:40</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <img src={campaignimg4} class="animated fadeInLeft" />
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12 d-flex align-items-center">
                        <div class="description animated fadeInRight">
                            <h3>Think you know about coronavirus(COVID-19)</h3>
                            <p>Synergistically foster fully tested ROI whereas interoperable expertise. Energistically maximize client-based products rather than accurate alignments. Appropriately.</p>
                            <h5>Start date : 23/09/2020 16:40</h5>
                            <h5>Start date : 23/09/2020 16:40</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <img src={campaignimg5} class="animated fadeInLeft" />
                    </div>
                    <div class="col-md-8 col-lg-8 col-sm-12 d-flex align-items-center">
                        <div class="description animated fadeInRight">
                            <h3>Think you know about coronavirus(COVID-19)</h3>
                            <p>Synergistically foster fully tested ROI whereas interoperable expertise. Energistically maximize client-based products rather than accurate alignments. Appropriately.</p>
                            <h5>Start date : 23/09/2020 16:40</h5>
                            <h5>Start date : 23/09/2020 16:40</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            

        </React.Fragment>
    )
}

export default Campaign
