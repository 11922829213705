/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";

function EditAnswerInputOptions(props) {
  const {
    languages,
    currentLanguage,
    optionName,
    optionIdFor,
    optionNumber,
    answerTextId,
    answerMode,
    answerTypeId,
    questionId,
    optionNo,
    answerLangDetails,
  } = props;

  console.log("answerLangDetails-sohel", answerLangDetails);

  console.log("in edit answer INPUT options");
  const { t } = useTranslation();

  const createMarkup = (language) => {
    //console.log('text',language.answerDetails[optionNo-1].text)
    return {
      __html:
        `<input type='text' placeholder='Enter Input' 
               id= '` +
        `answerInput_` +
        language.languageId +
        "_" +
        1 +
        `'` +
        ` value='` +
        language.answerDetails[0].text +
        `'` +
        `/>`,
    };
  };

  return (
    <React.Fragment>
      <div class="option-list">
        <div class="custom-control custom-radio">
          {answerLangDetails
            ? answerLangDetails.map((language) => (
                <input
                  type="radio"
                  class="custom-control-input"
                  id={"option" + 1}
                  name="Option"
                  value="Option"
                  defaultChecked={
                    language.answerDetails[0].is_right_answer === 1
                      ? true
                      : false
                  }
                />
              ))
            : null}
          <label class="custom-control-label" for={"option" + 1}>
            {t("option") + 1}
          </label>
        </div>
        <div class="inner-option">
          <div class="select-txt">{t("select_this_as_correct_answer")}</div>

          {answerLangDetails
            ? answerLangDetails.map((language) => (
                <div
                  class="option-input"
                  style={
                    currentLanguage.id === language.languageId
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div
                    style={{ fontSize: "36px", fontFamily: "arial" }}
                    class="inner-box-input"
                    dangerouslySetInnerHTML={createMarkup(language)}
                  />
                  {/* 
                                                                <input type="text"
                                                                    style={{display:"block"}}
                                                                    id={"answerInput_"+language.languageId+"_"+1} 
                                                                    defaultValue={language.answerDetails[0].text}
                                                                    placeholder="Enter Input"
                                                                />
                                                                */}
                </div>
              ))
            : null}
          <input
            type="file"
            name="file"
            style={{ display: "none" }}
            id={"uploadButton" + 1}
          />
          <img
            id={"answerImagePreview" + 1}
            width="200px"
            style={{ display: "none" }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditAnswerInputOptions;
