/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/emails.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import { useCookies } from "react-cookie";
import "../less/seller.css";
import "../less/test.css";
import Pagination from "./Pagination";
//import imgbanner from "../images/banner.png"

function Notifications(props) {
  const PAGE_LIMIT = 10;

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const user = cookies["user"]; //getUser();
  const token = cookies["access_token"]; //getToken();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let NOTIFICATIONS_PATH = awsImageUrl + "/uploads/notification/";

  const [page, setPage] = useState(1);
  const [notificationList, setNotificationList] = useState(null);
  const [toggleRefreshData, setToggleRefreshData] = useState(false);
  // Pagination
  const [totalNotificationsPages, setTotalNotificationsPages] = useState(null);
  const [totalNotifications, setTotalNotifications] = useState(null);
  const [currentNotifications, setCurrentNotifications] = useState(1);

  const fetchNotifications = async (currentPage = 1) => {
    axios({
      method: "get",
      url: `/api/my/notifications/list?limit=${PAGE_LIMIT}&page=${currentPage}`,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        console.log("response", response.data);
        setNotificationList(response.data);

        setTotalNotificationsPages(response.data.total_pages);
        setTotalNotifications(response.data.total);

        //setTotalPages(response.data.total_pages)
        // showMessageToUser(response.data.msg)
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, [toggleRefreshData]);

  const deleteNotification = (notification) => {
    console.log("Delete Notification");

    axios({
      method: "get",
      url: "/api/notification/delete/" + notification.id,
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        console.log("response", response.data);
        setToggleRefreshData(!toggleRefreshData);
      })
      .catch((error) => {});
  };

  const onNotificationsPageChanged = (data) => {
    //setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchNotifications(data.currentPage);
    setCurrentNotifications(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("notifications")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("notifications")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-9 col-lg-8">
              <div class="emails-list-box animated fadeInRight">
                <div id="accordion" class="faq-tabs animated fadeInRight">
                  {notificationList && notificationList.data.length > 0
                    ? notificationList.data.map((notification, index) => (
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                              <a
                                href="javascript:void(0)"
                                class=""
                                data-toggle="collapse"
                                data-target={"#collapse" + index}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {t("from")}{" "}
                                {notification.from_user_id_data.name} "
                                {notification.title}"
                              </a>
                            </h5>
                          </div>
                          <div
                            id={"collapse" + index}
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              <div class="info">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: notification.content,
                                  }}
                                ></p>
                              </div>
                              {notification.image ? (
                                <div class="info">
                                  <img
                                    src={
                                      NOTIFICATIONS_PATH + notification.image
                                    }
                                  />
                                </div>
                              ) : null}
                              <div class="action-btn">
                                <a
                                  href="javascript:void(0)"
                                  class="btn-border"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteNotification(notification);
                                  }}
                                >
                                  {" "}
                                  {t("delete")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>

              {totalNotifications > PAGE_LIMIT ? (
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                  <Pagination
                    totalRecords={totalNotifications}
                    totalPages={totalNotificationsPages}
                    currentPage={currentNotifications}
                    pageLimit={PAGE_LIMIT}
                    pageNeighbours={1}
                    onPageChanged={onNotificationsPageChanged}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default Notifications;
