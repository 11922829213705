/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "../less/create-content.css";
import { showErrorMessageToUser, showMessageToUser } from "../Utils/toastr";
import EditContentDeckName from "./EditContentDeckName";

import "react-quill/dist/quill.core.css";
import "../assets/quill-css/quill.snow.css";
import { tHydrate } from "../Utils/HydrateT";
import Pagination from "./Pagination";

const PAGE_LIMIT = 20;

function ContentsEditUnpublished(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken()
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());

  console.log("content edit", props);
  const user_id = cookies["user"].id;

  const contentId = props.match.params.quizdeckId;
  const [languageId, setLanguageId] = useState(globalLang.id);
  const primaryLanguageId = globalLang.id;
  const [allLanguages, setAllLanguages] = useState();
  const languagesFromProps = props.location.state.languages;
  const [languages, setLanguages] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [primarylanguageOfThisDeck, setPrimaryLanguageOfThisDeck] = useState();
  const [languagesOfThisDeck, setLanguagesOfThisDeck] = useState();

  const [languageDataset, setLanguageDataset] = useState([]);
  const [contentDeckDetails, setContentDeckDetails] = useState();

  const [currentLanguage, setCurrentLanguage] = useState(globalLang.id);

  const [defaultLangId, setDefaultLangId] = useState(globalLang.id);
  //const refs = useRef([]);

  const refs = useRef([]);
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const [questionsList, setQuestionsList] = useState();

  const [selectedFile, setSelectedFile] = useState(null);
  const [answerMode, setAnswerMode] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleRadioChange = (e) => {
    setAnswerMode(e.target.value);
  };
  const handleUpload = async () => {
    if (!selectedFile || !answerMode) {
      showErrorMessageToUser(t("bulk_upload_please_select_file"));
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("quiz_deck_id", contentId);
    formData.append("ans_mode_id", answerMode);

    setUploading(true);
    setUploadSuccess(false);
    try {
      const response = await axios.post(
        "/api/questions/upload-bulk-question",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            locale: globalLangCode,
            "x-access-token": "Memo " + token, //getToken(),
          },
        }
      );
      setUploading(false);
      console.log("Upload successful:", response.data);

      if (response.data && response.data[0].msg) {
        // showMessageToUser(response.data[0].msg);
        setUploadSuccess(true);
        // window.location.reload();
      }
    } catch (error) {
      setUploading(false);
      if (error.response && error.response.data && error.response.data[0].msg) {
        console.log(error.response.data[0].msg);
        console.error("Upload error:", error.response.data[0].msg);
        showErrorMessageToUser(error.response.data[0].msg);
      } else {
        showErrorMessageToUser(
          "Failed to upload. Please refresh the page and try again."
        );
      }
    }
  };

  let globalLangCode = globalLang.lang_code;

  useEffect(() => {
    console.log("Comp. did mount called");
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setAllLanguages(response.data.data);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/quiz_deck/details",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token, //getToken(),
        "Content-Type": "application/json",
      },
      data: {
        quiz_deck_id: contentId,
        language_id: primaryLanguageId,
      },
    })
      .then((response) => {
        console.log("ContentDeckDetails", response.data.details);
        setContentDeckDetails(response.data.details);
        setPrimaryLanguageOfThisDeck(response.data.details.language_id);
        setLanguagesOfThisDeck(response.data.details.languages);
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });

    axios({
      method: "get",
      url: `/api/question/upload-details/${contentId}`,
      headers: {
        locale: "en",
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
      },
    })
      .then((response) => {
        console.log("upload details::::", response.data[0].data.status);
        if (response.data && response.data[0].data.status === "pending") {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });

    fetchQuestions();
  }, []);
  useEffect(() => {
    //if(currentQuestion && modalShown) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image&async=true";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
    //}
  }, [questionsList]);

  useEffect(() => {
    console.log(languages);
    if (languages.length > 0) {
      setAvailableLanguages([]);
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].id != primaryLanguageId) {
          setAvailableLanguages((lang) => lang.concat(languages[i]));
        }
      }
    }
  }, [languages]);

  useEffect(() => {
    console.log("sssssssssssss");
    console.log("allLanguages.length", allLanguages);
    console.log("sss contentDeckDetails", contentDeckDetails);
    if (contentDeckDetails && allLanguages) {
      console.log("contentDeckDetails", contentDeckDetails);
      console.log("allLanguages", allLanguages);
      let localavaialblelanaguages = [];
      let localPrimarylanguage = {};
      //setPrimaryLanguageOfThisDeck(contentDeckDetails.language_id)
      //setLanguagesOfThisDeck(contentDeckDetails.languages)
      let lang_of_this_deck = contentDeckDetails.languages;
      for (let i = 0; i < allLanguages.length; i++) {
        for (let j = 0; j < lang_of_this_deck.length; j++) {
          if (allLanguages[i].id == lang_of_this_deck[j].id) {
            if (allLanguages[i].id != contentDeckDetails.language_id) {
              localavaialblelanaguages.push({
                id: allLanguages[i].id,
                name: allLanguages[i].name,
                code: allLanguages[i].lang_code,
                flag: allLanguages[i].flag,
                content_name: lang_of_this_deck[j].translation
                  ? lang_of_this_deck[j].translation.name
                  : "",
                content_desc: lang_of_this_deck[j].translation
                  ? lang_of_this_deck[j].translation.description
                  : "",
              });
            } else if (allLanguages[i].id == contentDeckDetails.language_id) {
              localPrimarylanguage = {
                content_name: lang_of_this_deck[j].translation
                  ? lang_of_this_deck[j].translation.name
                  : "",
                content_desc: lang_of_this_deck[j].translation
                  ? lang_of_this_deck[j].translation.description
                  : "",
              };
            }
          }
        }
      }
      for (let i = 0; i < allLanguages.length; i++) {
        if (allLanguages[i].id == contentDeckDetails.language_id) {
          localPrimarylanguage = {
            ...localPrimarylanguage,
            id: allLanguages[i].id,
            name: allLanguages[i].name,
            code: allLanguages[i].lang_code,
            flag: allLanguages[i].flag,
          };
        }
      }
      let localLanguages = [];
      for (let i = 0; i < localavaialblelanaguages.length; i++) {
        localLanguages.push(localavaialblelanaguages[i]);
      }
      localLanguages.unshift(localPrimarylanguage);
      console.log("lang_of_this_deck", lang_of_this_deck);
      console.log("localavaialblelanaguages", localavaialblelanaguages);
      console.log("localLanguages", localLanguages);
      //    console.log(primaryLanguage)
      //    console.log(languages)
      setAvailableLanguages(localavaialblelanaguages);
      setPrimaryLanguage(localPrimarylanguage);
      setLanguages(localLanguages);
      setCurrentLanguage(localPrimarylanguage);
    }
  }, [contentDeckDetails, allLanguages]);

  useEffect(() => {
    if (
      languageDataset.length > 0 &&
      languageDataset.length === languages.length
    ) {
      axios({
        method: "post",
        url: "/api/quiz_deck/update/language",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token, //getToken(),
        },
        data: qs.stringify({
          quiz_deck_id: contentId,
          dataset: languageDataset,
        }),
      })
        .then((response) => {
          console.log("language updated for deck", response.data);
          showMessageToUser(response.data.msg);
        })
        .catch((error) => {
          // removeUserSession();
          console.log("error", error);
        });
    }
  }, [languageDataset]);

  const publishTheQuizDeck = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/publish",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        "x-access-token": "Memo " + token,
        locale: "en",
      },
      data: qs.stringify({
        quiz_deck_id: contentId,
      }),
    })
      .then((response) => {
        props.history.push({
          pathname: "/genrelist",
          state: {
            ...props.location.state,
          },
        });
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  const checkDataSet = () => {
    if (languages) {
      for (let i = 0; i < languages.length; i++) {
        const e = document.getElementById("errorMessage_" + (i + 1));
        if (e) {
          e.style.display = "none";
        }
        let localName = document.getElementById("contentName_" + (i + 1));
        let localDesc = refs.current[i]
          ? refs.current[i].getContentDesc(i + 1)
          : null;
        //let localDesc = document.querySelector('#contentDesc_' + (i + 1) + ' .ql-editor');//document.getElementById("contentDesc_"+(i+1)).value
        if (
          localName &&
          localName.value !== "" &&
          localDesc &&
          localDesc.innerHTML !== ""
        ) {
        } else {
          if (e) {
            e.style.display = "block";
          }
          return 0;
        }
      }
      return makeDataset();
    }
  };

  const makeDataset = () => {
    setLanguageDataset([]);
    for (let i = 0; i < languages.length; i++) {
      const e = document.getElementById("errorMessage_" + (i + 1));
      if (e) {
        e.style.display = "none";
      }
      let localName = document.getElementById("contentName_" + (i + 1)).value;
      let localDesc = refs.current[i]
        ? refs.current[i].getContentDesc(i + 1)
        : null;
      //let localDesc = document.querySelector('#contentDesc_' + (i + 1) + ' .ql-editor').innerHTML;//document.getElementById("contentDesc_"+(i+1)).value

      let localIsDefault = 0;
      if (primaryLanguage.id === languages[i].id) {
        localIsDefault = 1;
      }
      setLanguageDataset((languageDataset) =>
        languageDataset.concat({
          quiz_deck_name: localName,
          quiz_deck_desc: localDesc,
          language_id: languages[i].id,
          is_default: localIsDefault,
        })
      );
    }
    console.log(languageDataset);
  };

  const editQuestionAndAnswer = (questionId, question) => {
    console.log("going");
    if (languages.length == 0) return;
    axios({
      method: "post",
      url: "/api/answers/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        quiz_deck_id: contentId,
        language_id: languageId,
        question_id: questionId,
      }),
    })
      .then((response) => {
        console.log("answer response from ", response.data.data);
        let ansModeId = response.data.data.answers[0].ans_mode_id;
        console.log(ansModeId);

        let state = {
          contentId: contentId,
          contentDeckDetails: {
            language_id: languageId,
            languages: languages,
          },
          questionDetails: question,
          answerMode: ansModeId,
          isPublished: 0,
        };
        console.log("state", state);

        props.history.push({
          pathname: "/editquestion/" + questionId,
          state: state,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const goToAddQuestionsPage = () => {
    console.log(languages);
    console.log(availableLanguages);
    console.log(primaryLanguage);

    props.history.push({
      pathname: "/addquestions/" + contentId,
      state: {
        quiz_deck_id: contentId,
        languages: languages,
        availableLanguages: languagesOfThisDeck,
        //primaryLanguage: primaryLanguage,
        isPublished: 0,
      },
    });
  };

  const fetchQuestions = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/questions/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: contentId,
        language_id: languageId,
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        console.log("questions list", response.data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setQuestionsList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPageChanged = (data) => {
    // setLoading(true);
    fetchQuestions(data.currentPage);
    setCurrent(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("edit_contents")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("edit_contents")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="create-content-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-7">
              <div class="create-content-tabs animated fadeInRight">
                <ul class="nav nav-tabs nav-justified">
                  {languages
                    ? languages.map((language) => (
                        <li
                          class="nav-item"
                          onClick={() => changeLanguage(language)}
                        >
                          <a
                            href=""
                            data-toggle="tab"
                            class={
                              defaultLangId == language.id
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            <img src={COUNTRY_FLAG + language.flag} />
                            <span>{language.name}</span>
                          </a>
                        </li>
                      ))
                    : null}
                </ul>
                {/* <!-- Tab panes --> */}
                {languages
                  ? languages.map((language, index) => (
                      <React.Fragment>
                        <EditContentDeckName
                          language={language}
                          currentLanguage={currentLanguage}
                          questionNo={index + 1}
                          ref={(ins) => (refs.current[index] = ins)}
                        />
                        <div
                          id={"errorMessage_" + (index + 1)}
                          style={{
                            display: "none",
                            backgroundColor: "red",
                            width: "400px",
                          }}
                        >
                          {t("enter_content_for_all")}
                        </div>
                      </React.Fragment>
                    ))
                  : null}
              </div>
              <div class="text-center pt-4">
                <a
                  href="javascript:void(0)"
                  onClick={() => checkDataSet()}
                  class="btn-background blue-bg-btn"
                >
                  Save
                </a>
                {/* <a onClick={publishTheQuizDeck} href="javascript:void(0)" class="btn-background">Publish now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("edit_question")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="create-content-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-9">
              <div class="create-content-tabs animated fadeInRight">
                <div class="tab-content">
                  <div class="tab-pane container active" id="EnglishTab">
                    <div class="add-quiz-btn" onClick={goToAddQuestionsPage}>
                      <a href="javascript:void(0)">
                        <span class="icon icon-plus blue-bg-btn"></span>{" "}
                        <span>{t("add_questions")}</span>
                      </a>
                    </div>
                    <div
                      class="add-quiz-btn"
                      id="modalOpenButton"
                      data-toggle="modal"
                      data-target="#RateandReview"
                      // style={{ cursor: "pointer" }}
                      style={{
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        pointerEvents: isDisabled ? "none" : "auto",
                        opacity: isDisabled ? 0.5 : 1,
                      }}
                    >
                      <a href="javascript:void(0)">
                        <span class="icon icon-upload blue-bg-btn"></span>
                        <span>{t("bulk_upload_q_a")}</span>
                      </a>
                    </div>
                    <div class="create-content-accordion">
                      <div id="accordion" class="accordion">
                        {questionsList
                          ? questionsList.map((question, index) => (
                              <div class="question-id">
                                <div className="card id-no">
                                  <div className="card-header bg-transparent h-100">
                                    <span>
                                      {(current - 1) * PAGE_LIMIT + index + 1}/
                                    </span>
                                    <span>{question.id}</span>
                                  </div>
                                </div>
                                <div className="card question-title">
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    onClick={() =>
                                      editQuestionAndAnswer(
                                        question.id,
                                        question
                                      )
                                    }
                                  >
                                    <div class="row">
                                      <div
                                        class="col-sm-9"
                                        dangerouslySetInnerHTML={{
                                          __html: question.name,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center pt-4">
                {total > PAGE_LIMIT ? (
                  <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                    <Pagination
                      totalRecords={total}
                      totalPages={totalPages}
                      currentPage={current}
                      pageLimit={PAGE_LIMIT}
                      pageNeighbours={1}
                      onPageChanged={onPageChanged}
                    />
                  </div>
                ) : null}
              </div>
              <div class="text-center pt-4">
                <a
                  onClick={() => publishTheQuizDeck()}
                  href="javascript:void(0)"
                  class="btn-background blue-bg-btn"
                >
                  {t("publish_now")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="RateandReview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="RateandReview"
        aria-hidden="true"
      >
        <div className="modal-dialog flexible-modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("bulk_upload_q_a")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-header">
              <p>
                <strong>{t("bulk_upload_modal_section1_title")} </strong>

                <p>{t("bulk_upload_modal_section1_paragraph1")}</p>
                <p>{t("bulk_upload_modal_section1_paragraph2")}</p>
                <p className="pl-5">
                  <ol>
                    <li>{t("bulk_upload_modal_section1_text_format_title")}</li>
                    <li>
                      {t("bulk_upload_modal_section1_body_qa_format_title")}
                    </li>
                  </ol>
                </p>
                <p>{t("bulk_upload_modal_section1_paragraph3")}</p>
                <strong>{t("bulk_upload_modal_section2_title")}</strong>
                <p>{t("bulk_upload_modal_section2_paragraph1")}</p>
                <p className="pl-5">
                  <ul>
                    <li>{t("bulk_upload_modal_section2_english")}</li>
                    <li>{t("bulk_upload_modal_section2_japanese")}</li>
                    <li>{t("bulk_upload_modal_section2_chainese")}</li>
                    <li>{t("bulk_upload_modal_section2_hindi")}</li>
                  </ul>
                </p>
                <p>{t("bulk_upload_modal_section2_paragraph2")}</p>
                <strong>{t("bulk_upload_modal_section3_title")}</strong>
                <p className="pl-5">
                  <ul>
                    <li>{t("bulk_upload_modal_section3_bullet1")}</li>
                    <li>{t("bulk_upload_modal_section3_bullet2")}</li>
                  </ul>
                </p>
                <p>{t("bulk_upload_modal_section3_paragraph1")}</p>
                <strong>{t("bulk_upload_modal_section4_title")}</strong>
                <p>{tHydrate("bulk_upload_modal_section4_paragraph1")}</p>
                <p>{t("bulk_upload_modal_section4_paragraph2")}</p>
              </p>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <input type="file" onChange={handleFileChange} />
                </div>
                <div className="radioContainer" style={{ marginTop: "20px" }}>
                  <label style={{ fontWeight: "bold" }}>
                    {t("bulk_upload_select_answer_type")}
                  </label>
                  <div>
                    <div>
                      <input
                        type="radio"
                        name="answerMode"
                        value="1"
                        onChange={handleRadioChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{t("bulk_upload_input_type_answer")}</label>
                      <label className="pl-1">
                        {" "}
                        <a href="/text_input_answer_type.xlsx">
                          <button class="btn-background blue-bg-btn">
                            {" "}
                            {t("bulk_upload_download_sample_file")}
                          </button>
                        </a>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="2"
                        name="answerMode"
                        onChange={handleRadioChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{t("bulk_upload_multiple_choice_answer")}</label>
                      <label>
                        {" "}
                        <a href="/multiple_choice_answer_type.xlsx">
                          <button class="btn-background blue-bg-btn">
                            {" "}
                            {t("bulk_upload_download_sample_file")}
                          </button>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {uploading && (
                    <label style={{ color: "#294177" }}>
                      {t("bulk_uploading")}...
                    </label>
                  )}
                </div>
                <div>
                  {uploadSuccess && (
                    <label style={{ color: "green" }}>
                      {t("bulk_upload_success_message")}
                    </label>
                  )}
                </div>

                <div className="save-cancel-btn" style={{ marginTop: "15px" }}>
                  <a
                    onClick={handleUpload}
                    href="javascript:void(0)"
                    class="btn-background blue-bg-btn"
                  >
                    {t("bulk_upload_save_button")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <BasicFooter /> */}
      <ToastContainer />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default ContentsEditUnpublished;
