/* eslint-disable */

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import qs from "qs";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import folder from "../images/folder.png";
import { Context } from "../Reducers/Store";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/folder.css";
import "../less/test.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function MyFolders(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  const user = cookies["user"]; //getUser();
  console.log(user);
  const user_type = user.user_type_id;
  const user_name = user.name;

  const [totalPagesInMyFolder, setTotalPagesInMyFolder] = useState();
  const [myFoldersList, setMyFoldersList] = useState([]);
  const [totalFolders, setTotalFolders] = useState();

  const [newFolderName, setNewFolderName] = useState();

  const [banners, setBanners] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_contents_page" });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });

    axios({
      method: "post",
      url: "/api/folders/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
      },
    })
      .then((response) => {
        console.log("response", response);
        setTotalPagesInMyFolder(response.data.total_pages);
        setTotalFolders(response.data.total);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    for (let i = 1; i <= totalPagesInMyFolder; i++) {
      axios({
        method: "post",
        url: "/api/folders/list",
        headers: {
          locale: "en",
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          page: i,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setMyFoldersList((myFoldersList) =>
            myFoldersList.concat(response.data.data)
          );
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
  }, [totalPagesInMyFolder]);

  const addFolder = () => {
    axios({
      method: "post",
      url: "/api/folders/create",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        folder_name: newFolderName,
      }),
    })
      .then((response) => {
        console.log("response", response);
        window.location.reload();
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  useEffect(() => {
    console.log(myFoldersList);
  }, [myFoldersList]);

  const goToMyFolderDetails = (id, name, parent_folder_id, created_at) => {
    props.history.push({
      pathname: "/myfolderdetails",
      state: {
        id: id,
        name: name,
        parent_folder_id: parent_folder_id,
        myFoldersList: myFoldersList,
        created_at: created_at,
      },
    });
  };

  return (
    <React.Fragment>
      {/* <BasicHeader
                userName = {user_name}
                {...props}
            /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("my_folders")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("my_folders")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}

      <section class="gener_gap">
        <div class="container">
          <div class="d-flex align-items-center performance2">
            <div class="mr-auto">
              <h3 style={{ color: "#1d5190!important" }}>{t("my_folders")}</h3>
            </div>
            {/* <div class="d-flex flex-wrap align-items-end folder-all cust_link">
                    <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5>
                    
                </div> */}

            {user_type != 2 ? (
              <div class="add-quiz-btn folder-create">
                <a
                  data-toggle="modal"
                  data-target="#myModal"
                  className="blue-bg-btn"
                  href="javascript:void(0)"
                >
                  <span class="icon icon-plus mr-1"></span>
                  {t("create_folder")} <span></span>
                </a>
              </div>
            ) : null}
          </div>

          <div class="row" id="owl-carousel">
            {totalFolders == myFoldersList.length
              ? myFoldersList
                ? myFoldersList.length > 0
                  ? myFoldersList.map((myfolder) => (
                      <div
                        class="col-lg-2 col-md-3 col-sm-4 col-6"
                        onClick={() =>
                          goToMyFolderDetails(
                            myfolder.id,
                            myfolder.name,
                            myfolder.parent_folder_id,
                            myfolder.created_at
                          )
                        }
                      >
                        <div class="item">
                          <div class="cust_genre">
                            <img src={folder} />
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: myfolder.name,
                              }}
                            ></h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null
              : null}
          </div>
        </div>
      </section>

      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{t("create_folder")}</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <form method="#">
                <div class="form-group">
                  <label>{t("folder_name")}</label>
                  <input
                    value={newFolderName}
                    type="text"
                    name="folderName"
                    class="form-control"
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />
                  <br></br>
                  <div
                    onClick={addFolder}
                    class="btn btn-primary btn_cst blue-bg-btn"
                  >
                    {t("addTitle")}
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default blue-bg-btn"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default MyFolders;
