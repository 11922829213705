/* eslint-disable */
import axios from "axios";
import Chart from "chart.js";
import qs from "qs";
import React, { useEffect, useState } from "react";
import S3 from "react-aws-s3";
import { useTranslation } from "react-i18next";
import { LinkedIn } from "react-linkedin-login-oauth2";
import ReactPlayer from "react-player";
import dashlogo from "../images/sns_logo.png";

import firebase from "firebase";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { oauth } from "../Utils/oauth.js";
import { showErrorMessageToUser, showMessageToUser } from "../Utils/toastr";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/quizRes.css";
import "../less/test.css";
//import "../less/quiz.css"

import quesImg from "../images/ques.png";

import canvasTxt from "../js/canvas-txt";

let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let GENER_PATH = awsImageUrl + "/uploads/genre/";
let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
let PROFILE_PIC = awsImageUrl + "/uploads/users/";
let QUESTIONS_PATH = awsImageUrl + "/uploads/questions/";
let ANSWERS_PATH = awsImageUrl + "/uploads/answers/";
let RESULTS_PATH = awsImageUrl + "/uploads/results/";

var FB, $;
const colors = [
  "#104178",
  "#c00002",
  "#7f807b",
  "#c3e6cb",
  "#dc3545",
  "#6c757d",
];

function ContentResults(props) {
  //console.log("content results", props )

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const user = cookies["user"]; //getUser();
  const token = cookies["access_token"];
  const [totalQuestionsAsked, setTotalQuestionsAsked] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [skippedAnswers, setSkippedAnswer] = useState(0);
  const [wrongAnswers, setWrongAnswer] = useState(0);

  const [wholeResult, setWholeResult] = useState([]);
  const [correctAnswerPercentage, setCorrectAnswerPercentage] = useState(0);
  const [skippedAnswerPercentage, setSkippedAnswerPercentage] = useState(0);
  const [wrongAnswerPercentage, setWrongAnswerPercentage] = useState(0);
  const [loadedAllResultData, setLoadedAllResultData] = useState(false);

  const quizDeckId = props.match.params.quizdeckId;

  const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState();
  const [totalNumberOfDownloads, setTotalNumberOfDownloads] = useState();
  const [paidOrFreeContent, setPaidOrFreeContent] = useState();
  const [contentName, setContentName] = useState();
  const [creatorName, setCreatorName] = useState();

  const [chartImage, setChartImage] = useState();
  const [chartImageData, setChartImageData] = useState();
  const [uploadedChartImage, setUploadedChartImage] = useState();

  const [chartRef, setChartRef] = useState();
  const [animationCompleted, setanimationCompleted] = useState();

  useEffect(() => {
    if (window.localStorage.getItem("PLAY_CONTENT_PAGE_STATE")) {
      let page_state = JSON.parse(
        window.localStorage.getItem("PLAY_CONTENT_PAGE_STATE")
      );

      console.log("state.PLAY_CONTENT_PAGE_STATE", page_state);
      setTotalQuestionsAsked(page_state.numberOfQuestions);
      setCorrectAnswers(page_state.total_right_answers);
      setSkippedAnswer(page_state.total_skipped_answers);
      setWrongAnswer(page_state.total_wrong_answers);
      setWholeResult(page_state.wholeResult);
      console.log(
        "page_state.total_right_answers",
        page_state.total_right_answers
      );
      console.log("wholeResult", wholeResult);
      setCorrectAnswerPercentage(
        parseInt(
          (page_state.total_right_answers / page_state.numberOfQuestions) * 100
        )
      );
      setSkippedAnswerPercentage(
        parseInt(
          (page_state.total_skipped_answers / page_state.numberOfQuestions) *
            100
        )
      );
      setWrongAnswerPercentage(
        parseInt(
          (page_state.total_wrong_answers / page_state.numberOfQuestions) * 100
        )
      );
      //window.localStorage.removeItem("PLAY_CONTENT_PAGE_STATE")
      setLoadedAllResultData(true);
    }
  }, []);

  useEffect(() => {
    console.log("Chart Ref changed", chartRef);
  }, [chartRef]);
  useEffect(() => {
    if (contentName && animationCompleted) {
      createFileFromChart();
    }
  }, [contentName, animationCompleted]);

  useEffect(() => {
    console.log("Outer loadedAllResultData ", loadedAllResultData);
    if (!loadedAllResultData) return;
    console.log(
      "correctAnswerPercentage, wrongAnswerPercentage, skippedAnswerPercentage",
      correctAnswerPercentage,
      wrongAnswerPercentage,
      skippedAnswerPercentage
    );
    console.log("loadedAllResultData true");
    var donutOptions = {
      cutoutPercentage: 60,
      legend: {
        position: "right",
        padding: 5,
        labels: { pointStyle: "circle", usePointStyle: true },
      },
      animation: {
        onComplete: function () {
          setanimationCompleted(true);
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            var currentValue = dataset.data[tooltipItem.index];
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);
            return percentage + "%";
          },
        },
      },
    };
    var chDonutData1 = {
      labels: ["Bootstrap", "Popper", "Other"],
      datasets: [
        {
          backgroundColor: colors.slice(0, 3),
          borderWidth: 0,
          data: [74, 11, 40],
        },
      ],
    };
    var chDonut1 = document.getElementById("chDonut1");
    if (chDonut1) {
      console.log("chDonut1");
      setChartRef(
        new Chart(chDonut1, {
          type: "pie",
          data: chDonutData1,
          options: donutOptions,
        })
      );
    }

    var chDonutData2 = {
      labels: [
        t("right_answer") + ":" + correctAnswerPercentage + "%",
        t("wrong_answer") + ":" + wrongAnswerPercentage + "%",
        t("unanswered") + ":" + skippedAnswerPercentage + "%",
      ],
      datasets: [
        {
          backgroundColor: colors.slice(0, 3),
          borderWidth: 0,
          data: [
            correctAnswerPercentage,
            wrongAnswerPercentage,
            skippedAnswerPercentage,
          ],
        },
      ],
    };
    console.log("chDonutData2", chDonutData2);
    var chDonut2 = document.getElementById("chDonut2");
    if (chDonut2) {
      console.log("chDonut2");
      setChartRef(
        new Chart(chDonut2, {
          type: "pie",
          data: chDonutData2,
          options: donutOptions,
        })
      );
    }

    var chDonutData3 = {
      labels: ["Angular", "React", "Other"],
      datasets: [
        {
          backgroundColor: colors.slice(0, 3),
          borderWidth: 0,
          data: [21, 45, 55, 33],
        },
      ],
    };
    var chDonut3 = document.getElementById("chDonut3");
    if (chDonut3) {
      console.log("chDonut3");
      setChartRef(
        new Chart(chDonut3, {
          type: "pie",
          data: chDonutData3,
          options: donutOptions,
        })
      );
    }

    const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
    let globalLangCode = globalLang.lang_code;

    axios({
      method: "post",
      url: "/api/quiz_deck/shortdetails",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token, //getToken(),
        "Content-Type": "application/json",
      },
      data: {
        quiz_deck_id: quizDeckId,
      },
    })
      .then((response) => {
        console.log("details of this quiz deck are", response.data);
        setContentName(response.data.details.name);
        setCreatorName(response.data.details.user_name);
        setTotalNumberOfQuestions(response.data.details.questioncount);
        //setTotalNumberOfDownloads(response.data.details.quiz_taken)
        //setPaidOrFreeContent(response.data.details.amount);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, [correctAnswerPercentage, wrongAnswerPercentage, skippedAnswerPercentage]);

  const ReactS3ClientConfig = {
    bucketName: "memoriz",
    dirName: "uploads/results" /* optional */,
    region: "ap-southeast-1",
    accessKeyId: "AKIAWHED5POKH4CAAJLY",
    secretAccessKey: "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",
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image&async=true";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [wholeResult]);

  const createFileFromChart = () => {
    let SNSChartRef;

    let RESULT_PIXEL_RATIO = (function () {
      let ctx = document.getElementById("result-canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;

      return dpr / bsr;
    })();

    let SNS_PIXEL_RATIO = (function () {
      let ctx = document.getElementById("sns-chart").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;

      return dpr / bsr;
    })();

    const createHiDPICanvas = function (w, h, ratio, canvasId) {
      if (!ratio) {
        ratio =
          canvasId === "result-canvas" ? RESULT_PIXEL_RATIO : SNS_PIXEL_RATIO;
      }
      var can = document.getElementById(canvasId);
      can.width = w * ratio;
      can.height = h * ratio;
      can.style.width = w + "px";
      can.style.height = h + "px";
      can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
      return can;
    };

    var SNSChart = createHiDPICanvas(360, 170, 2, "sns-chart");

    if (SNSChart) {
      SNSChartRef = new Chart(SNSChart, {
        type: "pie",
        data: {
          labels: [t("right_answer"), t("wrong_answer"), t("unanswered")],
          datasets: [
            {
              backgroundColor: colors.slice(0, 3),
              borderWidth: 0,
              data: [
                correctAnswerPercentage,
                wrongAnswerPercentage,
                skippedAnswerPercentage,
              ],
            },
          ],
        },
        options: {
          cutoutPercentage: 50,
          legend: {
            position: "right",
            padding: 5,
            labels: { pointStyle: "circle", usePointStyle: true },
          },
          animation: false,
        },
        plugins: [
          {
            afterDatasetsDraw: (chartInstance, easing) => {
              console.log("afterDatasetsDraw");
              const ctx = chartInstance.chart.ctx;
              chartInstance.data.datasets.forEach((dataset, i) => {
                const meta = chartInstance.getDatasetMeta(i);
                if (!meta.hidden && meta.type === "pie") {
                  meta.data.forEach((element, index) => {
                    // Draw the text in white, with the specified font
                    if (dataset.data[index] > 0 && dataset.data[index] <= 100) {
                      ctx.fillStyle = "white";
                      const fontSize = 18;
                      const fontStyle = "normal";
                      const fontFamily = "Helvetica Neue";
                      ctx.font = Chart.helpers.fontString(
                        fontSize,
                        fontStyle,
                        fontFamily
                      );
                      // Just naively convert to string for now
                      const dataString = dataset.data[index].toString();
                      // Make sure alignment settings are correct
                      ctx.textAlign = "center";
                      ctx.textBaseline = "middle";
                      const position = element.tooltipPosition();
                      console.log(
                        position.x,
                        position.y,
                        position.y - fontSize / 2.5
                      );
                      ctx.fillText(
                        dataString + "%",
                        position.x,
                        position.y - fontSize / 2.5
                      );
                    }
                  });
                }
              });
            },
          },
        ],
      });
    }

    let base_image = new Image();
    base_image.src = dashlogo;
    const c = createHiDPICanvas(400, 230, 1.8, "result-canvas");
    const ctx = c.getContext("2d");

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, c.width, c.height);
    // Advertisement
    canvasTxt.fontSize = 14;
    canvasTxt.fontWeight = "bold";
    ctx.fillStyle = "#a56f48";
    canvasTxt.align = "left";
    canvasTxt.vAlign = "top";
    canvasTxt.drawText(ctx, "Advertisement", 10, 5, 113, 14);

    ctx.fillStyle = "#000";
    const convertName = (name, limit = 80) => {
      if (name.length > limit) {
        return name.substring(0, limit) + '... "';
      }
      return name;
    };

    const cname = '"' + contentName + '"';

    canvasTxt.drawText(
      ctx,
      t("see_my_result_for_memorizing") +
        "\n" +
        convertName(cname) +
        "\n" +
        t("using_memorizeitall"),
      110,
      5,
      287,
      65
    );

    base_image.onload = function (e) {
      // Score Chart
      ctx.drawImage(SNSChartRef.canvas, 50, 85, 330, 140);
      // Logo
      ctx.drawImage(base_image, 280, 185, 120, 40);

      // Creating File from final canvas
      const base64url = c.toDataURL();
      setChartImageData(base64url);
      fetch(base64url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "sns-share", { type: "image/png" });
          console.log("file", file);
          setChartImage(file);
        });
    };
  };

  const ReactS3Client = new S3(ReactS3ClientConfig);
  const uploadResultToS3 = () => {
    return new Promise((resolve, reject) => {
      if (uploadedChartImage) {
        resolve(uploadedChartImage);
      } else {
        const filename = `${user.id}-${new Date().valueOf()}`;
        const fullUrl = RESULTS_PATH + filename + ".png";
        ReactS3Client.uploadFile(chartImage, filename)
          .then((data) => {
            setUploadedChartImage(fullUrl);
            resolve(fullUrl);
          })
          .catch((err) => {
            resolve(fullUrl);
          });
      }
    });
  };

  // Twitter
  const twitterShare = () => {
    var provider = new firebase.auth.TwitterAuthProvider();

    function getProvider(providerId) {
      switch (providerId) {
        case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
          return new firebase.auth.GoogleAuthProvider();
        case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
          return new firebase.auth.FacebookAuthProvider();
        case firebase.auth.GithubAuthProvider.PROVIDER_ID:
          return new firebase.auth.GithubAuthProvider();
        default:
          throw new Error(`No provider implemented for ${providerId}`);
      }
    }

    const supportedPopupSignInMethods = [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ];

    const postOnTwitter = (auth) => {
      var consumerSecret = "LZi6CJVMn3BBb6qCsf76ud7j7SUWBBuQhOdHDqsQZBVcodCOvE";
      var oauth_consumer_key = "CzVA0t1vowXHb1Zpn9e64uuzn";
      var nonce = oauth.nonce(32);
      var tokenSecret = auth.credential.secret;
      var oauth_token = auth.credential.accessToken;
      var ts = Math.floor(new Date().getTime() / 1000);
      var timestamp = ts.toString();

      const generateOauthSignature = (url) => {
        var accessor = {
          consumerSecret: consumerSecret,
          tokenSecret: tokenSecret,
        };

        var params = {
          oauth_version: "1.0",
          oauth_consumer_key: oauth_consumer_key,
          oauth_token: oauth_token,
          oauth_timestamp: timestamp,
          oauth_nonce: nonce,
          oauth_signature_method: "HMAC-SHA1",
        };
        var message = {
          method: "POST",
          action: url,
          parameters: params,
        };

        //lets create signature
        oauth.SignatureMethod.sign(message, accessor);
        let normPar = oauth.SignatureMethod.normalizeParameters(
          message.parameters
        );
        // console.log("Normalized Parameters: " + normPar);
        let baseString = oauth.SignatureMethod.getBaseString(message);
        // console.log("BaseString: " + baseString);
        let sig =
          oauth.getParameter(message.parameters, "oauth_signature") + "=";
        // console.log("Non-Encode Signature: " + sig);
        let encodedSig = oauth.percentEncode(sig); //finally you got oauth signature
        // console.log("Encoded Signature: " + encodedSig);
        return encodedSig;
      };

      const signature = generateOauthSignature(
        "https://api.twitter.com/1.1/statuses/update_with_media.json"
      );

      const authHeader = `OAuth oauth_consumer_key="${oauth_consumer_key}", oauth_nonce="${nonce}", oauth_signature="${signature}", oauth_signature_method="HMAC-SHA1", oauth_timestamp="${timestamp}", oauth_token="${oauth_token}", oauth_version="1.0"`;
      const fd = new FormData();
      fd.append("media[]", chartImage);
      fd.append("status", "https://memorizeitall.com");
      axios({
        method: "POST",
        url: "https://cors-anywhere.herokuapp.com/https://api.twitter.com/1.1/statuses/update_with_media.json",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader,
        },
        data: fd,
      })
        .then((result) => {
          showMessageToUser("Successfully Tweeted!");
          console.log(result.data);
        })
        .catch((error) => {
          showErrorMessageToUser("Not Successfully Tweeted!");
          console.log(error);
        });
    };

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(postOnTwitter)
      .catch(async (error) => {
        console.log("Error", error);
        if (
          error.email &&
          error.credential &&
          error.code === "auth/account-exists-with-different-credential"
        ) {
          const providers = await firebase
            .auth()
            .fetchSignInMethodsForEmail(error.email);
          const firstPopupProviderMethod = providers.find((p) =>
            supportedPopupSignInMethods.includes(p)
          );

          // Test: Could this happen with email link then trying social provider?
          if (!firstPopupProviderMethod) {
            return showErrorMessageToUser(
              `Your account is linked to a provider that isn't supported.`
            );
          }

          const linkedProvider = getProvider(firstPopupProviderMethod);
          linkedProvider.setCustomParameters({ login_hint: error.email });

          const result = await firebase.auth().signInWithPopup(linkedProvider);
          result.user.linkWithCredential(error.credential);
          postOnTwitter(result);
        }
      });
  };

  // Facebook

  window.fbAsyncInit = function () {
    window["FB"].init({
      appId: "4020616848047773",
      xfbml: true,
      version: "v2.8",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  const facebookshare = () => {
    // window['FB'].login(()=>{
    //     var auth = window['FB'].getAuthResponse();
    // var provider = new firebase.auth.FacebookAuthProvider();

    // firebase.auth().signInWithPopup(provider)
    //     .then(function (auth) {
    //     if(auth){
    const openShareDialog = (url) => {
      // console.log('uploaded ref', url)
      return new Promise((resolve, reject) => {
        window["FB"].ui(
          {
            method: "share",
            display: "popup",
            name: "Facebook Dialogs",
            media: [url],
          },
          function (response) {
            resolve(response);
          }
        );
      });
    };
    uploadResultToS3()
      .then(openShareDialog)
      .then((res) => {
        console.log(res);
        showMessageToUser("Post Successfully shared!");
      })
      .catch((e) => {
        console.log(e);
        showErrorMessageToUser("Post was not Successfully shared!");
      });
    // }
    //})//, { scope: 'email' });
  };

  // LinkedIn
  const UploadImageToLinkedIn = (urn, access_token, where) => {
    //let preURL = 'https://thingproxy.freeboard.io/fetch/'
    let preURL = "https://cors-anywhere.herokuapp.com/";
    if (chartImage) {
      console.log(chartImage);
      console.log("data of image is there to upload");
      console.log(
        where.value.uploadMechanism[
          "com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"
        ].uploadUrl
      );
      //let url = 'https://cors-anywhere.herokuapp.com/' + where.value.uploadMechanism["com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"].uploadUrl;
      let url =
        preURL +
        where.value.uploadMechanism[
          "com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"
        ].uploadUrl;
      const config = {
        headers: {
          "X-Restli-Protocol-Version": "2.0.0",
          //'Content-Type' : 'application/json',

          "Content-Type": "image/png",
          Authorization: `Bearer ${access_token}`,
        },
      };
      axios
        .put(url, chartImage, config)
        .then((response) => {
          console.log("uploaded image...");
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  const registerUploadImageToLinkedIn = (urn, access_token) => {
    let post_data = {
      registerUploadRequest: {
        owner: "urn:li:person:" + urn,
        recipes: ["urn:li:digitalmediaRecipe:feedshare-image"],
        serviceRelationships: [
          {
            identifier: "urn:li:userGeneratedContent",
            relationshipType: "OWNER",
          },
        ],
      },
    };
    axios({
      method: "post",
      url: "https://cors-anywhere.herokuapp.com/https://api.linkedin.com/v2/assets?action=registerUpload",
      headers: {
        "X-Restli-Protocol-Version": "2.0.0",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: JSON.stringify(post_data),
    })
      .then((response) => {
        console.log("success registerUploadImageToLinkedIn");
        console.log(response.data);
        UploadImageToLinkedIn(urn, access_token, response.data);

        //return response.data
      })
      .catch((error) => {
        console.log(error.response);
        return null;
      });
  };

  const linkedInPost = async (urn, access_token) => {
    //let preURL = 'https://thingproxy.freeboard.io/fetch/'
    let preURL = "https://cors-anywhere.herokuapp.com/";
    //---------- 1. Register the image for upload ----------
    let post_data = {
      registerUploadRequest: {
        owner: "urn:li:person:" + urn,
        recipes: ["urn:li:digitalmediaRecipe:feedshare-image"],
        serviceRelationships: [
          {
            identifier: "urn:li:userGeneratedContent",
            relationshipType: "OWNER",
          },
        ],
        //"supportedUploadMechanism":[ "SYNCHRONOUS_UPLOAD" ]
      },
    };
    axios({
      method: "post",
      url: preURL + "https://api.linkedin.com/v2/assets?action=registerUpload",
      headers: {
        "X-Restli-Protocol-Version": "2.0.0",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: JSON.stringify(post_data),
    }).then((response) => {
      console.log("success registerUploadImageToLinkedIn");
      let imageMetadata = response.data;
      console.log(response.data);
      console.log(imageMetadata.value.asset);
      let res = imageMetadata.value.asset.split(":");
      let asset_id = res[res.length - 1];
      console.log(asset_id);
      //---------- 2. Upload the image.
      console.log("before");
      //UploadImageToLinkedIn(urn,access_token,response.data )
      if (chartImage) {
        console.log(chartImage);
        console.log("data of image is there to upload");
        console.log(
          response.data.value.uploadMechanism[
            "com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"
          ].uploadUrl
        );
        let url =
          preURL +
          response.data.value.uploadMechanism[
            "com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"
          ].uploadUrl;

        const config = {
          headers: {
            "X-Restli-Protocol-Version": "2.0.0",
            //'Content-Type' : 'application/json',
            "Content-Type": "application/octet-stream", //'image/png',
            Authorization: `Bearer ${access_token}`,
          },
        };
        axios
          .put(url, chartImage, config)
          .then((response) => {
            console.log("uploaded image...");
            console.log(response);
            console.log("after");

            axios({
              method: "get",
              url: preURL + "https://api.linkedin.com/v2/assets/" + asset_id,
              headers: {
                "X-Restli-Protocol-Version": "2.0.0",
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
              },
            })
              .then((response) => {
                console.log("success in getting status of image");
                console.log(response.data);

                //return response.data
              })
              .catch((error) => {
                console.log(error.response);
                return null;
              });

            //---------- 3. Use the link to share the post.
            /* 
                        let post_data = {
                            "content": {
                                "contentEntities": [
                                    {
                                        "entityLocation": "https://www.example.com/content.html",
                                        "thumbnails": [
                                            {
                                                "resolvedUrl": "https://www.example.com/image.jpg"
                                            }
                                        ]
                                    }
                                ],
                                "title": "Again Share"
                            },
                            "distribution": {
                                "linkedInDistributionTarget": {}
                            },
                            "owner": "urn:li:person:" + urn, //"urn:li:person:324_kGGaLE",
                            "subject": "Test Share Subject",
                            "text": {
                                "text": "Test Share Try !"
                            }
                        };
                       */
            let post_data = {
              content: {
                contentEntities: [
                  {
                    entity: imageMetadata.value.asset,
                  },
                ],
                title: "Test Share with Content title",
                shareMediaCategory: "IMAGE",
              },
              distribution: {
                linkedInDistributionTarget: {},
              },
              owner: "urn:li:person:" + urn,
              subject: "Test Share Subject",
              text: {
                text: "https://memorizeitall.com",
              },
            };

            axios({
              method: "post",
              url: preURL + "https://api.linkedin.com/v2/shares",
              headers: {
                "X-Restli-Protocol-Version": "2.0.0",
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
                // 'cache-control': 'no-cache',
              },
              data: JSON.stringify(post_data),
              //qs.stringify({})
            })
              .then((response) => {
                console.log(imageMetadata.value.asset);
                console.log("success");
                showMessageToUser("Post Successfully shared!");
              })
              .catch((error) => {
                console.log(error.response);
                showErrorMessageToUser("Post was not Successfully shared!");
              });
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    });

    /*
        
        */
  };
  const handleLinkedInSuccess = (data) => {
    //let preURL = 'https://thingproxy.freeboard.io/fetch/'
    let preURL = "https://cors-anywhere.herokuapp.com/";
    console.log("success");
    console.log(data.code);
    axios({
      method: "post",
      url: preURL + "https://www.linkedin.com/oauth/v2/accessToken",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        grant_type: "authorization_code",
        code: data.code,
        redirect_uri: `${window.location.origin}/signin-linkedin`,
        client_id: "867287ffa5ii9t",
        client_secret: "Fu9yE7B5h7bkn5KA",
      }),
    })
      .then((response) => {
        console.log("linkedin response", response);
        const access_token = response.data.access_token;
        console.log(access_token);
        axios({
          method: "get",
          url: preURL + "https://api.linkedin.com/v2/me",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "cache-control": "no-cache",
          },
        })
          .then((response) => {
            console.log("linkedin response", response);
            console.log(response.data);

            const urn = response.data.id;

            //registerUploadImageToLinkedIn(urn,access_token);
            linkedInPost(urn, access_token);
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleLinkedInFailure = (data) => {
    console.log(data);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
           /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("result")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("result")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="descriptionArea mb-20px">
          <div class="descriptionText">
            <div class="content">
              <span class="descriptionWidth">Content name:</span>
              <span dangerouslySetInnerHTML={{ __html: contentName }}></span>
            </div>
            <div class="content">
              <span class="descriptionWidth">Creator:</span>
              <span
                class="cursor-pointer"
                dangerouslySetInnerHTML={{ __html: creatorName }}
              ></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 col-md-12">
            <div class="row statusRow">
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 mb-20px">
                <div class="cards">
                  <div class="car_img">
                    {/* <img src={icn2png} /> */}
                    <span className="icon icon-tick"></span>
                  </div>
                  <div class="text_card">
                    {/* <p>{t("right_answer")}</p> */}
                    <span>{correctAnswers}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 mb-20px">
                <div class="cards">
                  <div class="car_img">
                    {/* <img src={icn3png} /> */}
                    <span className="icon icon-close"></span>
                  </div>
                  <div class="text_card">
                    {/* <p>{t("wrong_answer")}</p> */}
                    <span>{wrongAnswers}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 thirdCol">
                <div class="cards">
                  <div class="car_img">
                    {/* <img src={icn4png} /> */}
                    <span className="icon icon-info1"></span>
                  </div>
                  <div class="text_card">
                    {/* <p>{t("unanswered")}</p> */}
                    <span>{skippedAnswers}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 fourthCol">
                <div class="cards">
                  <div class="car_img">
                    {/* <img src={questionspng} /> */}
                    <span className="icon icon-question"></span>
                  </div>
                  <div class="text_card">
                    {/* <p>{t('total_number_of_questions')}</p> */}
                    {totalNumberOfQuestions ? (
                      <span>{totalNumberOfQuestions}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="canvasCard">
              <div class="side_cards">
                <div>
                  <canvas id="chDonut2"></canvas>
                </div>
                <div class="memorizeImg">
                  <img
                    src="https://i.postimg.cc/4dB3GhDd/Microsoft-Teams-image.png"
                    alt="memorizeimg"
                    class="img-fluid memorizeLogo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1 col-md-12 pl-0 chartArea">
            <div class="socialCanvasArea">
              <div class="socialBtns">
                <div class="linkedinBtn">
                  <LinkedIn
                    clientId="867287ffa5ii9t"
                    // response_type ='code'
                    onFailure={handleLinkedInFailure}
                    onSuccess={handleLinkedInSuccess}
                    redirectUri={`${window.location.origin}/signin-linkedin`} //{`${window.location.origin}/linkedin`}
                    scope="r_emailaddress r_liteprofile w_member_social"
                    redirectPath="/signin-linkedin" //'/linkedin'
                    state="14789632"
                    // className="linkedin"
                  >
                    <a href="javascript:void(0)" class="linkedin">
                      <span class="icon-linkedin"></span>
                    </a>
                  </LinkedIn>
                </div>
                <div class="fbBtn">
                  <button class="btn-facebook" onClick={() => facebookshare()}>
                    <a href="javascript:void(0)" class="facebook">
                      <span class="icon-facebook"></span>
                    </a>
                  </button>
                </div>
                <div class="twitterBtn">
                  <button class="btn-twitter" onClick={() => twitterShare()}>
                    <a href="javascript:void(0)" class="twitter">
                      <span class="icon-twitter"></span>
                    </a>
                  </button>
                </div>
              </div>
              <div class="snsText">
                {t(
                  "share_my_score_on_sns_and_earn_points_for_purchasing_more_contents"
                )}
              </div>
              {/* <FacebookShareButton
                        url={chartImagedata}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton> */}
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-lg-12 col-md-12 your_ans">
            <h3>{t("your_results")}</h3>

            {wholeResult
              ? wholeResult.map((result, index) =>
                  result.selectedAnswerObject !== undefined ? (
                    result.selectedAnswerObject.id ===
                    result.correctAnswerObject.id ? (
                      <React.Fragment>
                        <h5 class="ques">
                          <img src={quesImg} /> {t("question_result_page")}{" "}
                          {index + 1}
                        </h5>
                        <h6>
                          <strong>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: result.question.name,
                              }}
                            ></div>
                          </strong>
                        </h6>
                        {result.question.qus_mode_id == 2 ? (
                          <div class="ans_opt text-center">
                            <img
                              src={QUESTIONS_PATH + result.question.file_path}
                            />
                          </div>
                        ) : null}
                        {result.question.qus_mode_id == 3 ||
                        result.question.qus_mode_id == 4 ? (
                          <div class="ans_opt">
                            {result.question.qus_mode_id == "3" ? (
                              <div className="repeatplayaudio">
                                <ReactPlayer
                                  width="100%"
                                  //height="100%"
                                  url={
                                    QUESTIONS_PATH + result.question.file_path
                                  }
                                  controls={true}
                                  // Disable right click
                                  onContextMenu={(e) => e.preventDefault()}
                                  // Disable download button
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="repeatplayaudio repeatplayaudio1">
                                <ReactPlayer
                                  width="100%"
                                  //height="100%"
                                  url={
                                    QUESTIONS_PATH + result.question.file_path
                                  }
                                  controls={true}
                                  // Disable right click
                                  onContextMenu={(e) => e.preventDefault()}
                                  // Disable download button
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : null}

                        <h6>
                          <strong>{t("your_answer")}</strong>
                        </h6>
                        <div class="col-xs-12 ans_sec">
                          <div class="options right_ans">
                            <div class="icon_check">
                              <span class="icon icon-tick"></span>
                            </div>
                            {result.selectedAnswerObject.ans_mode_id == 2 ? (
                              <div class="ans_opt">
                                {/* <p></p> */}
                                <p>{result.selectedAnswerObject.name}</p>
                              </div>
                            ) : null}
                            {result.selectedAnswerObject.ans_mode_id == 3 ? (
                              <div class="ans_opt text-center">
                                <p>
                                  <span>
                                    <img
                                      src={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                    />
                                  </span>
                                </p>
                              </div>
                            ) : null}
                            {result.selectedAnswerObject.ans_mode_id == 4 ||
                            result.selectedAnswerObject.ans_mode_id == 5 ? (
                              <div class="ans_opt">
                                {result.selectedAnswerObject.ans_mode_id ==
                                "4" ? (
                                  <div className="repeatplayaudio">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="repeatplayaudio repeatplayaudio1">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="bord_bot"></div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h5 class="ques">
                          <img src={quesImg} /> {t("question_result_page")}{" "}
                          {index + 1}
                        </h5>
                        <h6>
                          <strong>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: result.question.name,
                              }}
                            ></div>
                          </strong>
                        </h6>
                        {result.question.qus_mode_id == 2 ? (
                          <div class="ans_opt text-center">
                            <img
                              src={QUESTIONS_PATH + result.question.file_path}
                            />
                          </div>
                        ) : null}
                        {result.question.qus_mode_id == 3 ||
                        result.question.qus_mode_id == 4 ? (
                          <div class="ans_opt">
                            {result.question.qus_mode_id == "3" ? (
                              <div className="repeatplayaudio">
                                <ReactPlayer
                                  width="100%"
                                  //height="100%"
                                  url={
                                    QUESTIONS_PATH + result.question.file_path
                                  }
                                  controls={true}
                                  // Disable right click
                                  onContextMenu={(e) => e.preventDefault()}
                                  // Disable download button
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="repeatplayaudio repeatplayaudio1">
                                <ReactPlayer
                                  width="100%"
                                  //height="100%"
                                  url={
                                    QUESTIONS_PATH + result.question.file_path
                                  }
                                  controls={true}
                                  // Disable right click
                                  onContextMenu={(e) => e.preventDefault()}
                                  // Disable download button
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : null}
                        <h6>
                          <strong>{t("your_answer")}</strong>
                        </h6>
                        <div class="col-xs-12 ans_sec">
                          <div class="options wrong">
                            <div class="icon_check">
                              <span className="icon icon-close"></span>
                            </div>

                            {result.selectedAnswerObject.ans_mode_id == 2 ? (
                              <div class="ans_opt">
                                <p>
                                  <span
                                    class=""
                                    dangerouslySetInnerHTML={{
                                      __html: result.selectedAnswerObject.name,
                                    }}
                                  ></span>
                                </p>
                                {/* <p></p> */}
                              </div>
                            ) : null}
                            {result.selectedAnswerObject.ans_mode_id == 3 ? (
                              <div class="ans_opt text-center">
                                <p>
                                  <span class="">
                                    <img
                                      src={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                    />
                                  </span>
                                </p>
                              </div>
                            ) : null}
                            {result.selectedAnswerObject.ans_mode_id == 4 ||
                            result.selectedAnswerObject.ans_mode_id == 5 ? (
                              <div class="ans_opt">
                                {/* <p><strong>{t("option")} {result.selectedAnswerIndex}</strong></p> */}
                                {result.selectedAnswerObject.ans_mode_id ==
                                4 ? (
                                  <div className="repeatplayaudio">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="repeatplayaudio repeatplayaudio1">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.selectedAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <h6>
                          <strong>{t("corerct_answer")}</strong>
                        </h6>
                        <div class="col-xs-12 ans_sec">
                          <div class="options right_ans">
                            <div class="icon_check">
                              <span className="icon icon-tick"></span>
                            </div>
                            {result.correctAnswerObject.ans_mode_id == 2 ? (
                              <div class="ans_opt">
                                <p>
                                  <span
                                    class=""
                                    dangerouslySetInnerHTML={{
                                      __html: result.correctAnswerObject.name,
                                    }}
                                  ></span>
                                </p>
                                {/* <p></p> */}
                              </div>
                            ) : null}
                            {result.correctAnswerObject.ans_mode_id == 3 ? (
                              <div class="ans_opt text-center">
                                <p>
                                  <span class="">
                                    <img
                                      src={
                                        ANSWERS_PATH +
                                        result.correctAnswerObject.file_path
                                      }
                                    />
                                  </span>
                                </p>
                              </div>
                            ) : null}
                            {result.correctAnswerObject.ans_mode_id == 4 ||
                            result.correctAnswerObject.ans_mode_id == 5 ? (
                              <div class="ans_opt">
                                {result.correctAnswerObject.ans_mode_id == 4 ? (
                                  <div className="repeatplayaudio">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.correctAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="repeatplayaudio repeatplayaudio1">
                                    <ReactPlayer
                                      width="100%"
                                      //height="100%"
                                      url={
                                        ANSWERS_PATH +
                                        result.correctAnswerObject.file_path
                                      }
                                      controls={true}
                                      // Disable right click
                                      onContextMenu={(e) => e.preventDefault()}
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="bord_bot"></div>
                      </React.Fragment>
                    )
                  ) : result.inputAnswer !== undefined &&
                    result.inputAnswer === result.inputCorrectAnswer ? (
                    <React.Fragment>
                      <h5 class="ques">
                        <img src={quesImg} /> {t("question_result_page")}{" "}
                        {index + 1}
                      </h5>
                      <h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: result.question.name,
                          }}
                        ></div>
                      </h6>
                      {result.question.qus_mode_id == 2 ? (
                        <div class="ans_opt text-center">
                          <img
                            src={QUESTIONS_PATH + result.question.file_path}
                          />
                        </div>
                      ) : null}
                      {result.question.qus_mode_id == 3 ||
                      result.question.qus_mode_id == 4 ? (
                        <div class="ans_opt">
                          {result.question.qus_mode_id == 3 ? (
                            <div className="repeatplayaudio">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            <div className="repeatplayaudio repeatplayaudio1">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : null}
                      <h6>
                        <strong>{t("corerct_answer")}</strong>
                      </h6>
                      <div class="col-xs-12 ans_sec">
                        <div class="options right_ans">
                          <div class="icon_check">
                            <span className="icon icon-tick"></span>
                          </div>
                          <div class="ans_opt">
                            <p
                              class=""
                              dangerouslySetInnerHTML={{
                                __html: result.inputAnswer,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <div class="bord_bot"></div>
                    </React.Fragment>
                  ) : result.inputAnswer !== undefined &&
                    result.inputAnswer !== result.inputCorrectAnswer ? (
                    <React.Fragment>
                      <h5 class="ques">
                        <img src={quesImg} /> {t("question_result_page")}{" "}
                        {index + 1}
                      </h5>
                      <h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: result.question.name,
                          }}
                        ></div>
                      </h6>
                      {result.question.qus_mode_id == 2 ? (
                        <div class="ans_opt text-center">
                          <img
                            src={QUESTIONS_PATH + result.question.file_path}
                          />
                        </div>
                      ) : null}
                      {result.question.qus_mode_id == 3 ||
                      result.question.qus_mode_id == 4 ? (
                        <div class="ans_opt">
                          {result.question.qus_mode_id == 3 ? (
                            <div className="repeatplayaudio">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            <div className="repeatplayaudio repeatplayaudio1">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : null}
                      <h6>
                        <strong>{t("your_answer")}</strong>
                      </h6>
                      <div class="col-xs-12 ans_sec">
                        <div class="options wrong">
                          <div class="icon_check">
                            <span className="icon icon-close"></span>
                          </div>

                          <div class="ans_opt">
                            <p>{result.inputAnswer}</p>
                          </div>
                        </div>
                      </div>
                      <h6>{t("corerct_answer")}</h6>
                      <div class="col-xs-12 ans_sec">
                        <div class="options right_ans">
                          <div class="icon_check">
                            <span className="icon icon-tick"></span>
                          </div>
                          <div class="ans_opt">
                            <p>{result.inputCorrectAnswer}</p>
                          </div>
                        </div>
                      </div>
                      <div class="bord_bot"></div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h5 class="ques">
                        <img src={quesImg} /> {t("question_result_page")}{" "}
                        {index + 1}
                      </h5>
                      <h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: result.question.name,
                          }}
                        ></div>
                      </h6>
                      {result.question.qus_mode_id == 2 ? (
                        <div class="ans_opt text-center">
                          <img
                            src={QUESTIONS_PATH + result.question.file_path}
                          />
                        </div>
                      ) : null}
                      {result.question.qus_mode_id == 3 ||
                      result.question.qus_mode_id == 4 ? (
                        <div class="ans_opt">
                          {result.question.qus_mode_id == 3 ? (
                            <div className="repeatplayaudio">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            <div className="repeatplayaudio repeatplayaudio1">
                              <ReactPlayer
                                width="100%"
                                //height="100%"
                                url={QUESTIONS_PATH + result.question.file_path}
                                controls={true}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                // Disable download button
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : null}
                      <h6>
                        <strong>{t("your_answer")}</strong>
                      </h6>
                      <div class="col-xs-12 ans_sec">
                        <div class="options wrong unanswered">
                          <div class="icon_check">
                            <span className="icon icon-close"></span>
                          </div>

                          <div class="ans_opt">
                            <p>{t("unanswered")}</p>
                          </div>
                        </div>
                      </div>
                      <h6>
                        <strong>{t("corerct_answer")}</strong>
                      </h6>
                      <div class="col-xs-12 ans_sec">
                        <div class="options right_ans">
                          <div class="icon_check">
                            <span className="icon icon-tick"></span>
                          </div>
                          {result.correctAnswerObject.ans_mode_id == 1 ? (
                            <div class="ans_opt">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: result.inputCorrectAnswer,
                                }}
                              ></p>
                            </div>
                          ) : null}
                          {result.correctAnswerObject.ans_mode_id == 2 ? (
                            <div class="ans_opt">
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: result.correctAnswerObject.name,
                                  }}
                                ></span>
                              </p>
                              {/* <p ></p> */}
                            </div>
                          ) : null}
                          {result.correctAnswerObject.ans_mode_id == 3 ? (
                            <div class="ans_opt text-center">
                              <p>
                                <span>
                                  <img
                                    src={
                                      ANSWERS_PATH +
                                      result.correctAnswerObject.file_path
                                    }
                                  />
                                </span>
                              </p>
                            </div>
                          ) : null}
                          {result.correctAnswerObject.ans_mode_id == 4 ||
                          result.correctAnswerObject.ans_mode_id == 5 ? (
                            <div class="ans_opt">
                              {result.correctAnswerObject.ans_mode_id == 4 ? (
                                <div className="repeatplayaudio">
                                  <ReactPlayer
                                    width="100%"
                                    //height="100%"
                                    url={
                                      ANSWERS_PATH +
                                      result.correctAnswerObject.file_path
                                    }
                                    controls={true}
                                    // Disable right click
                                    onContextMenu={(e) => e.preventDefault()}
                                    // Disable download button
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="repeatplayaudio repeatplayaudio1">
                                  <ReactPlayer
                                    width="100%"
                                    //height="100%"
                                    url={
                                      ANSWERS_PATH +
                                      result.correctAnswerObject.file_path
                                    }
                                    controls={true}
                                    // Disable right click
                                    onContextMenu={(e) => e.preventDefault()}
                                    // Disable download button
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: "nodownload",
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div class="bord_bot"></div>
                    </React.Fragment>
                  )
                )
              : null}
          </div>
        </div>
      </div>
      <canvas
        id="sns-chart"
        style={{
          "max-height": "170px",
          "max-width": "360px",
          position: "absolute",
          "z-index": "-3",
        }}
      ></canvas>
      <canvas
        id="result-canvas"
        style={{ position: "absolute", "z-index": "-3" }}
      ></canvas>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

function HelmetMetaData() {
  let location = useLocation();
  let currentUrl = "https://memorizeitall.com" + location.pathname;
  let image = "https://unsplash.com/photos/da_9GHX5z80";
  return (
    <Helmet>
      <title>My Memorizeit content result</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={"title"} />
      <meta property="quote" content={"quote"} />
      <meta name="description" content={"description"} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={"title"} />
      <meta property="og:quote" content={"quote"} />
      <meta property="og:hashtag" content={"hashtag"} />
      <meta
        property="og:image"
        content={"https://unsplash.com/photos/da_9GHX5z80"}
      />
      <meta property="og:image:type" content="image/jpeg" />

      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={"description"} />
    </Helmet>
  );
}

export default ContentResults;
